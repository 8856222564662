import React, { useState, useContext, useEffect } from "react";
import Logo from "../../../Assets/images/telxyLogo.svg";
import EmailModal from "../../../Common/EmailModal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import userAvatar from "../../../Assets/images/userAvatar.svg";
import { updateProviderStatus } from "../../../Utils/ApiManager";
import { SocketContext } from "../../../context/socket";
import "./toggle.css";
import Notifcation from "./Notifcation";
import capitalizeFirst from "../../../Common/CaptilizeFirstLetter";
import { NotificationForCardExpire } from "../../../Actions/Actions";
import store from "../../../Store/Store";
let RommURL = process.env.REACT_APP_PATIENT_URL;
export const Header = ({
  toggleSidenavBar,
  toggleDarkMode,
  darkMode,
  ProviderInCall,
}) => {
  const socket = useContext(SocketContext);
  let dispatch = useDispatch();
  const [notification, setnotifications] = useState(true);
  const [profilepic, setprofilepic] = useState(false);
  const state = useSelector((state) => state);
  const {
    LoginObject,
    StripePackagePurchaseDetailObject,
    updateProfileImagee,
    AllPatientsAccepted,
  } = useSelector((state) => state);
  const [prevProviderName, setPrevProviderName] = useState(null);
  const [notificationCounter, setnotificationsCounter] = useState(0);
  const [counterEffect, setCounterEffect] = useState(false);
  const [checked, setChecked] = useState();
  const toggleChecked = () => {
    setChecked((value) => !value);
  };
  let NotifyForCardExpires = store && store.getState()?.NotifyForCardExpires;
  let isProviderMessagesOpens =
    store && store.getState()?.isProviderMessagesOpens;
  const userName = state.LoginObject.username;
  const [roomLink, setRoomLink] = useState(userName);
  const [toggleModal, setToggleModal] = useState(false);
  const [isMessageSectionOpen, setIsMessageSectionOpen] = useState(false);
  const [dispatchForNotifys, setDispatchForNotifys] = useState();
  let navigate = useNavigate();

  console.log(
    "redux length alert ",
    NotifyForCardExpires.length,
    counterEffect
  );
  useEffect(() => {
    setIsMessageSectionOpen(isProviderMessagesOpens);
  }, [isProviderMessagesOpens]);
  useEffect(() => {
    if (dispatchForNotifys) {
      dispatchNotfiyForText(dispatchForNotifys, isMessageSectionOpen);
    }
  }, [dispatchForNotifys]);

  const dispatchNotfiyForText = (patientdata, Arg) => {
    if (LoginObject.id === patientdata.providerId && Arg != true) {
      let obj = {};
      obj.msg = `${patientdata.PatName} just texted you`;

      dispatch(NotificationForCardExpire(obj));
    }
  };

  useEffect(() => {
    if (LoginObject.username) {
      socket.on("messageReceiveNotificationPro", (patientdata) => {
        console.log(patientdata, "yogibear", isProviderMessagesOpens);
        setDispatchForNotifys(patientdata);
        // dispatchNotfiyForText(patientdata);
      });
    }
  }, []);

  const openModal = () => {
    setToggleModal(true);
  };
  // const handleLogout = () => {
  //   updateProviderStatuss();

  //   localStorage.clear();
  //   sessionStorage.clear();
  //   window.location.href = "/";
  // };

  useEffect(() => {
    if (LoginObject.username) {
      socket.on("new_patient_added", (patientdata) => {
        console.log("socket trigger one time");
        if (
          patientdata.obj.providerName === LoginObject.username &&
          prevProviderName !== patientdata.obj.providerName
        ) {
          let obj = {};

          obj.msg = `New patient ${patientdata.obj.patientFirstName} added`;
          dispatch(NotificationForCardExpire(obj));
          setCounterEffect(!counterEffect);
          setPrevProviderName(patientdata.obj.providerName);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (profilepic) {
      setnotifications(true);
    }
  }, [profilepic]);
  useEffect(() => {
    if (!notification) {
      setprofilepic(false);
    }
  }, [notification]);

  useEffect(() => {
    setnotificationsCounter(NotifyForCardExpires.length);
  }, [JSON.stringify(NotifyForCardExpires)]);

  const updateProviderStatuss = () => {
    socket.emit("show_provider_online", "offline");
    try {
      updateProviderStatus(LoginObject.username, "offline").then(
        async (res) => {}
      );
    } catch (err) {}
  };
  const copyRoomLink = () => {
    navigator.clipboard.writeText(RommURL + roomLink);
    toast.info("Invitation link Copied");
  };
  console.log("notificationCounter", notificationCounter);
  return (
    <>
      {toggleModal ? (
        <EmailModal
          setToggleModal={setToggleModal}
          RoomUrl={RommURL + roomLink}
        />
      ) : null}
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo mr-5">
            <img src={Logo} className="mr-2" alt="logo" />
          </a>
          <a className="navbar-brand brand-logo-mini">
            <img src={Logo} alt="logo" />
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
          >
            <span className="icon-menu" onClick={() => toggleSidenavBar()}>
              <i className="fas fa-bars"></i>
            </span>
          </button>
          <ul className="navbar-nav mr-lg-2">
            <li className="nav-item nav-search d-none d-lg-block">
              <div className="input-group">
                <div
                  className="input-group-prepend hover-cursor"
                  id="navbar-search-icon"
                >
                  <span className="input-group-text" id="search">
                    {StripePackagePurchaseDetailObject.workflowFeatures
                      .EmailNotifications ? (
                      <>
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Mail"
                          className="fas fa-envelope  mr-2"
                          onClick={openModal}
                        ></i>
                          <i
                      title="Copy Link"
                      className="far fa-copy mr-2"
                      onClick={() => {
                        copyRoomLink();
                      }}
                    ></i>
                     <div style={{ width: "570px" }}>
                  <input
                    type="text"
                    className="form-control textarea"
                    id="navbar-search-input"
                    value={roomLink ? RommURL + roomLink : RommURL}
                    placeholder="invitation link"
                    disabled
                    aria-describedby="basic-addon2"
                    style={{ color: "GrayText" }}
                  />
                </div>
                      </>

                    ) : (
                      <></>
                    )}

                  
                  </span>
                </div>
               
              </div>
            </li>
          </ul>

          <ul className="navbar-nav navbar-nav-right">
            <li
              className="nav-item  mt-2 darkModeToogle"
              data-toggle="tooltip"
              data-placement="top"
              title={darkMode ? "Switch to Day Mode" : "Switch to Dark Mode"}
            >
              {/* <a className="nav-link">
              <div class="toggle__fill"></div>

                <i
                  style={{ marginBottom: "12px", marginRight: "-22px" }}
                  class={
                    darkMode
                      ? "fas fa-sun  text-warning"
                      : " fas  fa-moon text-warning"
                  }
                ></i>
              </a> */}
              <label className=" d-flex togglei mt-0 ml-2 ">
                <input
                  className="toggle__inputi  ml-5 "
                  name="check"
                  type="checkbox"
                  checked={checked}
                  onClick={() => toggleDarkMode()}
                  id="myggle"
                />
                <div className="toggle__filli">
                  {darkMode ? (
                    <>
                      <span>🌞</span>
                    </>
                  ) : (
                    <>
                      <span className="ml-1  pl-4">🌜</span>
                    </>
                  )}
                </div>
              </label>
            </li>
            <li
              className={
                notification ? "nav-item dropdown show" : "nav-item dropdown "
              }
              onClick={() => setnotifications(!notification)}
            >
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                data-toggle="dropdown"
              >
                <i className="far fa-bell"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-danger">
                  {NotifyForCardExpires.length == 0
                    ? ""
                    : NotifyForCardExpires.length}
                </span>
                <span className=""></span>
              </a>

              <Notifcation
                notification={notification}
                NotifyForCardExpires={NotifyForCardExpires}
              />
            </li>
            <li
              onClick={() => setprofilepic(!profilepic)}
              className={
                profilepic
                  ? "nav-item nav-profile dropdown"
                  : "nav-item nav-profile dropdown show"
              }
            >
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                id="profileDropdown"
              >
                {updateProfileImagee ? (
                  <>
                    <img
                      src={"https://dashboard.telxy.com/" + updateProfileImagee}
                      alt="profile"
                    />
                  </>
                ) : (
                  <>
                    <img src={userAvatar} alt="profile" />
                  </>
                )}
                {/* <img src={userAvatar} alt="profile" /> */}
                <b
                  style={{
                    fontSize: "14px",
                    marginLeft: "0.5rem",
                    fontWeight: "600",
                  }}
                >
                  {capitalizeFirst(LoginObject.fullname)}
                </b>
              </a>
              <div
                className={
                  profilepic
                    ? "dropdown-menu dropdown-menu-right navbar-dropdown show"
                    : "dropdown-menu dropdown-menu-right navbar-dropdown "
                }
                aria-labelledby="profileDropdown"
              >
                <a
                  className="dropdown-item"
                  onClick={() => navigate("/settings")}
                >
                  <i className="fas fa-ellipsis-v text-primary"></i>
                  Settings
                </a>
                {/* <a className="dropdown-item" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt  text-primary"></i>
                  Logout
                </a> */}
              </div>
            </li>
            <li className="nav-item nav-settings d-none d-lg-flex">
              <a className="nav-link">
                <i className="fas fa-sliders-v"></i>
              </a>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right rounded d-lg-none align-self-center p-2 text-white"
            type="button"
            data-toggle="offcanvas"
            onClick={() => toggleSidenavBar()}
          >
            <span className="icon-menu fas fa-bars ml-1">
              <i className="fas fa-sliders-v"></i>
            </span>
          </button>
        </div>
      </nav>
    </>
  );
};
