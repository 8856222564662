import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import icon from "../../../../Assets/images/packageicon.png";
function TransactionCard() {
  const [packageName, setPackageName] = useState();
  const [loading, setLoading] = useState(false);
  const { LoginObject } = useSelector((state) => state);
  const [expireDate, setExpireDate] = useState();
  useEffect(() => {
    setLoading(true);
    getTransactionStripe(LoginObject);
  }, []);
  const getTransactionStripe = async (value) => {
    let transactionList;
    try {
      transactionList = await axios.get(
        process.env.REACT_APP_APIS + `get-transaction=1&userId=${value.id}`
      );
    } catch (err) {}
    let data = transactionList.data;
    //  let pkg=data.packageName
    //  setPackageName(pkg)
    if (data && data.length > 0) {
      var lastTransactionObject = data[data.length - 1];
      setPackageName(lastTransactionObject.packageName);
      setExpireDate(lastTransactionObject.expiryDate);
    }
    setLoading(false);
  };
  const capitalizeFirst = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  };
  return (
    <div className="card-body">
      {/* <p className="mb-4">Package</p> */}
      {/* {loading ? (
        <>
          {" "}
          <div
            className="spinner-border text-light spinner-border-sm"
            role="status"
          ></div>{" "}
        </>
      ) : (
        <>
          {" "}
          <p className="fs-30 mb-2"> {capitalizeFirst(packageName)}</p>
          <p>Expire at {expireDate}</p>
        </>
      )} */}

      <div className="d-flex">
        <div
          className="PackageMainDashboard"
          style={
            packageName === "gold"
              ? { backgroundColor: "rgb(246 199 81)" }
              : packageName === "silver"
              ? { backgroundColor: "#71797E" }
              : { backgroundColor: "#39648d" }
          }
        >
          <img src={icon} />
        </div>

        <div className="d-flex flex-column justify-content-center">
          <h5 className="PackageMainDashboardH5">
            {loading ? (
              <>
                <div
                  className={
                    packageName === "gold"
                      ? "spinner-border text-gold spinner-border-sm"
                      : packageName === "silver"
                      ? "spinner-border text-silver spinner-border-sm"
                      : "spinner-border text-metal spinner-border-sm"
                  }
                  role="status"
                ></div>
              </>
            ) : (
              <>
                <h5
                  className="PackageMainDashboardH5"
                  style={{ color: "rgb(246 199 81)" }}
                >
                  {" "}
                  {capitalizeFirst(packageName === "gold" && packageName)}
                </h5>
                <h5
                  className="PackageMainDashboardH5"
                  style={{ color: "#71797E" }}
                >
                  {" "}
                  {capitalizeFirst(packageName === "silver" && packageName)}
                </h5>
                <h5
                  className="PackageMainDashboardH5"
                  style={{ color: "#39648d" }}
                >
                  {" "}
                  {capitalizeFirst(packageName === "metal" && packageName)}
                </h5>
              </>
            )}
          </h5>
          {/* <p className="PackageMainDashboardp">Current Package</p> */}

          <p className="PackageMainDashboardp">Expires at {expireDate}</p>
        </div>
      </div>
    </div>
  );
}
export default TransactionCard;
