import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//../../../Assets/images/usageicon.png
import icon from "../../../../Assets/images/Vector-stopwatch-10-minutes-png.png"
import { getParticpantMin } from "../../../../Utils/ApiManager";
function ConsumedMinutes() {
  const [noOFMin, setNoOfMin] = useState();
  const { LoginObject, StripePackagePurchaseDetailObject } = useSelector(
    (state) => state
  );
  useEffect(() => {
    const pkg = StripePackagePurchaseDetailObject?.packageName;
    if (pkg == "silver" || pkg == "metal" || pkg == "free") {
      getPatLimiatation();
    } else {
      setNoOfMin("UNLIMITED");
    }
  }, []);
  const getPatLimiatation = async () => {
    let res = await getParticpantMin();
    if (res && res.data) {
      const filteredArr = res.data.filter(
        (item) => item.user_id === LoginObject?.id && item.totalMinConsumed != 0
      );
      if (filteredArr && filteredArr.length > 0) {
        setNoOfMin(filteredArr[0]?.totalMinConsumed);
      } else {
        setNoOfMin(0);
      }
    }
  };

  return (
    <div>
      <div className="card-body">
        <div className="d-flex">
          <div
            className="minutesMain"
            style={{ backgroundColor: "#ffff", height: "59px", width: "57px" }}
          >
            <img src={icon} />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <h5 className="minutesMainH5">
              {" "}
              {false ? (
                <>
                  <div
                    className="spinner-border  spinner-border-sm"
                    role="status"
                    style={{ color: "#12b8c3" }}
                  ></div>
                </>
              ) : (
                <h5 className="minutesMainH5" style={{ color: "#12b8c3" }}>
                  {noOFMin && noOFMin == "UNLIMITED"
                    ?  <h4>{noOFMin}</h4> 
                    : parseFloat(noOFMin).toFixed(2)}
                </h5>
              )}
            </h5>
            <p className="minutesMainp">Total Min Left</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsumedMinutes;
