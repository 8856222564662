import React from "react";
import PersonalInfo from "./PersonalInfo";
import ProffesionalInfo from "./ProffesionalInfo";

export default function ProfileInfo() {
  return (
    <>
      <div className="tab-content" style={{
        width: '100%',
        display: 'contents'
      }}>
        <div
          role="tabpanel"
          aria-hidden="false"
          className="fade tab-pane active show"
        >
          <div className="settings-header">
            <h5>Personal Info</h5>
            <p>Update your account details</p>
          </div>
          <div className="settings-control p-3">
            <PersonalInfo />
          </div>
        </div>

      </div>
    </>
  );
}
