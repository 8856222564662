import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { swalConfirmation } from "../../../Utils/commonFunctions";
import axios from "axios";

export default function RoomSetting() {
  const [roomName, setRoomName] = useState("");
  const { LoginObject } = useSelector((state) => state);
  const [isLoadingUpdateRoom, setIsLoadingUpdateRoom] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);

  useEffect(() => {
    setRoomName(LoginObject && LoginObject?.username);
  }, [LoginObject?.username]);

  const updateRoomName = async () => {
    setIsLoadingUpdateRoom(true);



    let formData = new FormData();
    formData.append("userId", LoginObject?.id);
    formData.append("roomName", roomName);
    let path = process.env.REACT_APP_APIS + `update-room=1`;
    axios
      .post(path, formData)
      .then(async (res) => {
        if (res && res.data.includes("updated")) {
          // toast.success("Your username/roomname updated successfully");
          window.location.href = "/";
          //  setLogOutModal(true);
        } else if (res && res.data.includes("RoomID cannot be empty!")) {
          toast.error("Room Name cannot be empty");
        } else if (res && res.data.includes("RoomId alread exists :atifo")) {
          toast.error("Room Name already Exist");
        }
      })
      .catch((err) => {
        setIsLoadingUpdateRoom(false);
        toast.error(err.data);
      });
  };


  const onUpdateRoomConfirmation = async () => {
    let swalObj = {};
    swalObj.text = "You have to SignIn again to occur changes";
    swalObj.title = "Are you sure you want to update your room/username?";
    swalObj.icon = "warning";
    swalObj.dangerMode = true;
    swalObj.buttons = ["No", "Yes"];

    let IsUpdated = await swalConfirmation(swalObj);
    if (IsUpdated) {
      updateRoomName()
    }
  }
  return (
    <>
      <div className="social-settings">
        <div className="settings-header">
          <h5>Room Settings</h5>
          <p>Customize your patient’s check-in and waiting room experience.</p>
        </div>

        <div className="form-col form-body">
          <form action="/">
            <div className="row">
              <div className="col-md-12 col-xl-12">
                <div className="form-group" style={{ width: "28%" }}>
                  <label>RoomName/Username</label>
                  <input
                    className="form-control form-control-lg group_formcontrol"
                    value={roomName}
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Room Name"
                    onChange={(e) => setRoomName(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-6 col-xl-4 ">
                <button
                  type="button"
                  className="btn btn-delete btn-primary"
                  onClick={onUpdateRoomConfirmation}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
