import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import capitalizeFirst from "../../../../Common/CaptilizeFirstLetter";
import DefaultAudio from "../../../../Common/DefaultAudio";

const GroupParticpant = ({
  isShowCallActionsButton,
  getReconnectedParticpant,
  getReconnectingParticipant,
  participant,
  isVoiceMute,
  toggleScreenShareButton,
  localScreen,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [isVideoEnabaled, setIsVideoEnabaled] = useState(true);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [isAudioEnabaled, setIsAudioEnabaled] = useState(true);
  const videoRef = useRef();
  const ScreenRef = useRef();
  const {
    PatientInfo,
    IsProviderSystmCamEnable,
    LoginObject,
    IsPatSystmCamEnable,
  } = useSelector((state) => state);
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    if (participant && participant.videoTracks) {
      setVideoTracks(trackpubsToTracks(participant.videoTracks));
      setAudioTracks(trackpubsToTracks(participant.audioTracks));
    }
    console.log("participant", participant);

    const trackSubscribed = (track) => {
      track.on("disabled", () => updateDisabledTrackUI(track));
      track.on("enabled", () => updateEnabledTrackUI(track));
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const updateDisabledTrackUI = (track) => {
      console.log("track kind", track);
      if (track.kind === "audio") {
        setIsAudioEnabaled(track.isEnabled);

        return;
      }
      if (track.kind === "video") {
        setIsVideoEnabaled(track.isEnabled);
        return;
      }
    };

    const updateEnabledTrackUI = (track) => {
      if (track.kind === "audio") {
        setIsAudioEnabaled(track.isEnabled);

        return;
      }
      if (track.kind === "video") {
        setIsVideoEnabaled(track.isEnabled);
        return;
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };
    const handleReconnecting = () => setIsReconnecting(true);
    const handleReconnected = () => setIsReconnecting(false);
    if (participant) {
      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);
      participant.on("reconnecting", handleReconnecting);
      participant.on("reconnected", handleReconnected);
    }
    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      if (participant) {
        participant.off("reconnecting", handleReconnecting);
        participant.off("reconnected", handleReconnected);
        // participant.removeAllListeners();
      }
    };
  }, [participant]);

  // useEffect(() => {
  //   if (participant && participant.videoTracks.size > 1 && localScreen) {
  //     setVideoTracks(trackpubsToTracks(participant.videoTracks));
  //     setAudioTracks(trackpubsToTracks(participant.audioTracks));
  //   }
  // }, [participant?.videoTracks.size]);
  useEffect(() => {
    const videoTrack = videoTracks[0];
    let screenShare;
    if (videoTrack) {
      if (videoTracks && videoTracks.length > 1) {
        screenShare = videoTracks[1];
        screenShare.attach(ScreenRef.current);
        if (!localScreen && toggleScreenShareButton) {
          toggleScreenShareButton(true);
        }
      } else if (
        // For screen share on Audio
        videoTracks &&
        videoTracks.length == 1 &&
        videoTracks[0]?.name == "screen" &&
        !localScreen
      ) {
        screenShare = videoTracks[0];
        screenShare.attach(ScreenRef.current);
        //setIsScreenShareOnAudioOn(true);
        if (!localScreen && toggleScreenShareButton) {
          toggleScreenShareButton(true);
        }
      } else {
        if (!localScreen && toggleScreenShareButton) {
          toggleScreenShareButton(false);
        }
      }
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
        if (videoTracks && videoTracks.length > 1) {
          screenShare.detach();
        }
        // For audio detach
        if (
          videoTracks &&
          videoTracks.length == 1 &&
          videoTracks[0]?.name == "screen" &&
          !localScreen
        ) {
          screenShare.detach();
        }
      };
    } else {
      if (!localScreen && toggleScreenShareButton) {
        toggleScreenShareButton(false);
      }
    }
  }, [videoTracks]);

  useEffect(() => {
    if (videoTracks[0]) setIsVideoEnabaled(videoTracks[0]?.isEnabled);
  }, [videoTracks, videoTracks[0]?.isEnabled, participant]);
  useEffect(() => {
    if (audioTracks[0]) setIsAudioEnabaled(audioTracks[0]?.isEnabled);
  }, [audioTracks, audioTracks[0]?.isEnabled, participant]);

  // *****************************For Proiveder Check***************
  let provider = LoginObject && LoginObject.username;
  let providerAudioCall = "nothing";

  if (provider && IsProviderSystmCamEnable) {
    providerAudioCall = false;
  } else if (provider && !IsProviderSystmCamEnable) {
    providerAudioCall = true;
  }
  //*********************************For Pat Check******************** */
  let patAudioCall = "noting";

  if (!provider && IsPatSystmCamEnable) {
    patAudioCall = false;
  } else if (!provider && !IsPatSystmCamEnable) {
    patAudioCall = true;
  }
  //
  let tracks = Array.from(participant.videoTracks.values()).map(
    (publication) => publication.track
  );
  let isAudioScreenShare = tracks[0]?.name == "screen" && !localScreen;
  console.log(
    "part",
    participant.videoTracks.size == 1,
    participant,
    videoTracks,
    "moiz",
    "providerAudioCall",
    providerAudioCall,
    "patAudioCall",
    patAudioCall,
    "IsPatSystmCamEnable",
    IsPatSystmCamEnable,
    "IsProviderSystmCamEnable",
    IsProviderSystmCamEnable,
    "isAudioScreenShare",
    isAudioScreenShare
  );

  //  let audionly=participant.videoTracks?.length == 0
  //  let audioplusScreenshare=participant.ideoTracks?.length == 1 && participant.videoTracks[0]?.name == "screen"
  //  let videoonly=participant.videoTracks?.length == 1 &&  videoTracks[0]?.name == "video" && participant.videoTracks[0]?.name != "screen"
  //  let videoPLusScreen=participant.videoTracks?.length > 1 && participant.videoTracks[1]?.name == "screen"
  //  console.log('audionly',audionly,'audioplusScreenshare',audioplusScreenshare,'videoonly',videoonly,'videoPLusScreen',videoPLusScreen)

  // For Provider Side disappear check

  return (
    <>
      {isAudioScreenShare && !localScreen ? (
        <>
          <video
            id={false ? "screenShareElement Adjusted" : "screenShareElement"}
            style={
              localScreen
                ? {
                    width: "100%",
                    height: "99%",
                    borderRadius: "7px",
                    position: "absolute",
                    marginTop: "95px",
                  }
                : {
                    width: "100%",
                    height: "99%",
                    borderRadius: "7px",
                  }
            }
            ref={ScreenRef}
            autoPlay={false}
          />
        </>
      ) : null}

      {!isShowCallActionsButton ? (
        <>
          {videoTracks && videoTracks.length > 1 ? (
            <>
              <video
                id={"screenShareElement"}
                style={{
                  width: "100%",
                  height: "99%",
                  borderRadius: "7px",
                }}
                ref={ScreenRef}
                autoPlay={false}
              />
              <div
                className="disabled-icons"
                style={
                  !isVideoEnabaled
                    ? {
                        width: "100%",
                        height: "100%",
                        background: "black",
                        objectFit: "contain",
                        position: "relative",
                      }
                    : null
                }
              >
                {!isVideoEnabaled && (
                  <>
                    <i
                      className="fas fa-video-slash text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: "9",
                        top: "40%",
                        width: "100%",
                      }}
                    ></i>
                  </>
                )}
                {!isAudioEnabaled && (
                  <>
                    <i
                      className="fas fa-microphone-slash text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: "9",
                        top: "40%",
                        width: "100%",
                      }}
                    ></i>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <>
                <div className="disabled-icons">
                  {true ? (
                    !isVideoEnabaled && (
                      <>
                        <i
                          className="fas fa-video-slash text-white"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            zIndex: "9",
                            top: "40%",
                            width: "100%",
                          }}
                        ></i>
                      </>
                    )
                  ) : (
                    <>
                      {" "}
                      {!isVideoEnabaled && (
                        <>
                          <i
                            className="fas fa-video-slash text-white"
                            style={{
                              width: "100%",
                              height: "100%",
                              background: "black",
                              objectFit: "contain",
                              position: "relative",
                            }}
                          ></i>
                        </>
                      )}
                    </>
                  )}
                </div>

                {!patAudioCall && participant.videoTracks.size != 0 ? (
                  <video
                    id={"videoElement"}
                    style={{
                      width: "100%",
                      height: "99%",
                      borderRadius: "7px",
                    }}
                    ref={videoRef}
                    autoPlay={false}
                  />
                ) : (
                  <>
                    {" "}
                    {participant.videoTracks.size == 0 &&
                    patAudioCall == true ? (
                      <DefaultAudio
                        circle={true}
                        AudioName={
                          provider
                            ? capitalizeFirst(participant.identity[0]) +
                              capitalizeFirst(participant.identity[1])
                            : capitalizeFirst(
                                participant.identity[0] +
                                  capitalizeFirst(participant.identity[1])
                              )
                        }
                      />
                    ) : null}
                  </>
                )}
                {!patAudioCall && participant.videoTracks.size == 0 ? (
                  <>
                    {" "}
                    <DefaultAudio
                      circle={true}
                      AudioName={
                        provider
                          ? capitalizeFirst(participant.identity[0]) +
                            capitalizeFirst(participant.identity[1])
                          : capitalizeFirst(
                              participant.identity[0] +
                                capitalizeFirst(participant.identity[1])
                            )
                      }
                    />
                  </>
                ) : null}
                {patAudioCall &&
                  participant.videoTracks.size == 1 &&
                  patAudioCall == true &&
                  !isAudioScreenShare && (
                    <video
                      id={"videoElement"}
                      style={{
                        width: "100%",
                        height: "99%",
                        borderRadius: "7px",
                      }}
                      ref={videoRef}
                      autoPlay={false}
                    />
                  )}
              </>

              <>
                {!providerAudioCall && participant.videoTracks.size != 0 ? (
                  <video
                    id={"videoElement"}
                    style={{
                      width: "100%",
                      height: "98%",
                      borderRadius: "7px",
                      visibility: !isVideoEnabaled ? "hidden" : null,
                    }}
                    ref={videoRef}
                    autoPlay={false}
                  />
                ) : (
                  <>
                    {participant.videoTracks.size == 0 &&
                    providerAudioCall == true ? (
                      <DefaultAudio
                        circle={true}
                        AudioName={
                          provider
                            ? capitalizeFirst(participant.identity[0]) +
                              capitalizeFirst(participant.identity[1])
                            : capitalizeFirst(
                                participant.identity[0] +
                                  capitalizeFirst(participant.identity[1])
                              )
                        }
                      />
                    ) : null}
                  </>
                )}
                {participant.videoTracks.size == 1 &&
                providerAudioCall == true &&
                !isAudioScreenShare ? (
                  <>
                    {" "}
                    <video
                      id={"videoElement"}
                      style={{
                        width: "100%",
                        height: "98%",
                        borderRadius: "7px",
                        visibility: !isVideoEnabaled ? "hidden" : null,
                      }}
                      ref={videoRef}
                      autoPlay={false}
                    />
                  </>
                ) : null}
                {participant.videoTracks.size == 0 &&
                providerAudioCall == false &&
                !isAudioScreenShare ? (
                  <>
                    <DefaultAudio
                      circle={true}
                      AudioName={
                        provider
                          ? capitalizeFirst(participant.identity[0]) +
                            capitalizeFirst(participant.identity[1])
                          : capitalizeFirst(
                              participant.identity[0] +
                                capitalizeFirst(participant.identity[1])
                            )
                      }
                    />{" "}
                  </>
                ) : null}
              </>

              {/* {!patAudioCall && !providerAudioCall && (
                <DefaultAudio
                  circle={true}
                  AudioName={
                    provider
                      ? capitalizeFirst(participant.identity[0]) +
                        capitalizeFirst(participant.identity[1])
                      : capitalizeFirst(
                          participant.identity[0] +
                            capitalizeFirst(participant.identity[1])
                        )
                  }
                />
              )} */}
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default GroupParticpant;
