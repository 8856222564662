import React, { useState, useEffect } from "react";
import { getRoomHistory } from "../../../../Utils/ApiManager";
import { useSelector } from "react-redux";
import icon from "../../../../Assets/images/usageicon.png";
function TotalMinutes() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const state = useSelector((state) => state);
  const userName = state.LoginObject.username;

  useEffect(() => {
    setIsLoading(true);
    try {
      getRoomHistory(userName).then((res) => {
        var count = 0;
        res.data.rooms.map((el) => {
          if (el.room_name === userName) {
            count += el.duration_sec;
            setTotalMinutes(parseInt(count / 60));
          }
        });

        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
    }
  }, []);
  return (
    <div>
      <div className="card-body">
        {/* <p className="mb-4">Total Usage </p>
        {isLoading ? (
          <>
            <div className="spinner-border text-light spinner-border-sm" role="status"></div>
          </>
        ) : (
          <>
            <p className="fs-30 mb-2">{totalMinutes}</p>
            <p>minutes</p>
          </>
        )} */}

        <div className="d-flex">
          <div className="minutesMain" style={{ backgroundColor: "#12b8c3" }}>
            <img src={icon} />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <h5 className="minutesMainH5">
              {" "}
              {isLoading ? (
                <>
                  <div
                    className="spinner-border  spinner-border-sm"
                    role="status"
                    style={{ color: "#12b8c3" }}
                  ></div>
                </>
              ) : (
                <h5 className="minutesMainH5" style={{ color: "#12b8c3" }}>
                  {totalMinutes}
                </h5>
              )}
            </h5>
            <p className="minutesMainp">Total Room Minutes</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalMinutes;
