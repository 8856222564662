import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getRoomHistory } from "../../../../Utils/ApiManager";
import icon from "../../../../Assets/images/roomicon.png";
function TotalRoomsCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [roomHistory, setRoomHistory] = useState([]);
  const state = useSelector((state) => state);
  const userName = state.LoginObject.username;
  let roomsCount = roomHistory.length;
  useEffect(() => {
    setIsLoading(true);
    try {
      getRoomHistory(userName).then((res) => {
        let filteredArr = res.data.rooms.filter((item) => {
          return item.room_name === userName;
        });
        setRoomHistory(filteredArr);
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
    }
  }, []);
  return (
    <>
      <div className="card ">
        <div className="card-body">
          {/* <p className="mb-4">Today’s Rooms</p>

          {isLoading ? (
            <>
              <div
                className="spinner-border text-light spinner-border-sm"
                role="status"
              ></div>
            </>
          ) : (
            <>
              <p className="fs-30 mb-2">{roomsCount}</p>
            </>
          )} */}
          <div className="d-flex">
            <div className="RoomMainDashboard">
              <img src={icon} />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h5 className="RoomMainDashboardH5">
                {isLoading ? (
                  <>
                    <div
                      className="spinner-border text-primary spinner-border-sm"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>{roomsCount}</>
                )}
              </h5>
              <p className="RoomMainDashboardp">Today's Appearance</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TotalRoomsCard;
