import React from "react";

export default function Card({
  CarOneTitle,
  CardOneBody,
  CarTwoTitle,
  CarTwoBody,
  handleClickOnPaymentMode,
  SubscriptionList,
  paymentMode,
  handleStepperButton,
  secondArgument,
  firstArgument,
  Active,
  ActiveSecond,
}) {
  return (
    <>
      <></>
      {CarOneTitle === "Payment" ||
      CardOneBody === "One Month Payment" ||
      CarOneTitle === "Subscription Cancel" ? null : (
        <div
          className={
            Active ? "card ml-2  active" : "card ml-2 border border-grey "
          }
          onClick={() => handleClickOnPaymentMode(firstArgument)}
          style={
            Active != true
              ? {
                  width: "100%",
                  backgroundColor: "#f4f7f9",
                }
              : {}
          }
        >
          <div className="card-body">
            <p>{CarOneTitle}</p>
            {/* <hr />
          <p> {CardOneBody}</p> */}
          </div>
        </div>
      )}

      {CarTwoTitle == "New Subscription" ||
      CarTwoTitle === "Update Subscription" ? null : (
        <div
          className={
            ActiveSecond
              ? "card ml-2  active "
              : "card ml-2 border border-grey  "
          }
          onClick={() => handleClickOnPaymentMode(secondArgument)}
          style={
            ActiveSecond != true
              ? {
                  width: "100%",
                  backgroundColor: "#f4f7f9",
                }
              : {}
          }
        >
          <div className="card-body">
            <p>{CarTwoTitle}</p>
            {/* <hr />
          <p>{CarTwoBody}</p> */}
          </div>
        </div>
      )}
    </>
  );
}
