import React from 'react'
import './signalStrengthIndicator.css'

export default function SignalStrengthIndicator({levels}) {
    let className = 'signal-bars mt1 sizing-box ';

  switch (levels) {
    case 5:
      className += 'good four-bars';
      break;
    case 4:
      className += 'good four-bars';
      break;
    case 3:
      className += 'ok three-bars';
      break;
      case 2:
        className += 'ok two-bars';
        break;
        case 1:
        className += 'bad one-bar';
        break;
        case 0:
          className = 'null';
          break;

    default:
      break;
  }

    
  return (
   <>
   <div class={className}>
    <div class="first-bar bar"></div>
    <div class="second-bar bar"></div>
    <div class="third-bar bar"></div>
    <div class="fourth-bar bar"></div>
    <div class="fifth-bar bar"></div>
  </div>
   </>
  )
}
