import React from 'react'

export default function SettingNav({ tabs, tab, handleSetTabs }) {
  return (
    <>
      <nav
        className="nav nav-tabs"
        activekey="GeneralSettings"
        role="tablist"
      >
        {tabs.map((el, index) => {
          if (el.name == null) {

          } else {
            return <a key={index}
              id={el.id}
              role="tab"
              data-rb-event-key="GeneralSettings"
              aria-selected="true"
              onClick={() => handleSetTabs(el.name)}
              className={tab === el.name ? "nav-item nav-link active" : "nav-item nav-link"}
            >
              {el.name}
            </a>

          }

        })}
      </nav>
    </>
  )
}
