import React, { useEffect } from "react";
import { updateCreditCard } from "../../../Utils/ApiManager";
import { useSelector } from "react-redux";
import userAvatar from "../../../Assets/images/telxyLogo.svg";

export default function ReceiptCardUpdate({ updatedCardDetail }) {
  const { LoginObject } = useSelector((state) => state);
  const TdStyles = {
    border: "1px Solid Black",
  };
  useEffect(() => {
    updateCard();
  }, []);

  const updateCard = () => {
    let formData = new FormData();
    let cusInfo = updatedCardDetail && updatedCardDetail.customer;
    let cardInfo = updatedCardDetail && updatedCardDetail.token.card;
    formData.append("cusId", cusInfo.id);
    formData.append("name", cusInfo.name);
    formData.append("email", cusInfo.email);
    formData.append("brand", cardInfo.brand);
    formData.append("exp_month", cardInfo.exp_month);
    formData.append("exp_year", cardInfo.exp_year);
    formData.append("fingerprint", cardInfo.fingerprint);
    formData.append("last4", cardInfo.last4);
    formData.append("balance", cusInfo.balance);
    formData.append("userId", LoginObject && LoginObject.id);
    updateCreditCard(LoginObject && LoginObject.id, formData)
      .then((res) => {})
      .catch((err) => {});
  };
  return (
    <>
      {/* <div className="d-flex justify-content-center align-items-center ">
        <table className="table table-striped">
          <thead>
            <th style={{ width: "156px" }}></th>
            <th></th>
          </thead>
          <tbody>
            <tr style={TdStyles}>
              <td>
                <h6 className="font-weight-bold text-primary">
                  Customer Name:
                </h6>
              </td>
              <td>
                <h6> {updatedCardDetail.customer.name}</h6>
              </td>
            </tr>
            <tr style={TdStyles}>
              <td>
                <h6 className="font-weight-bold text-primary">Email:</h6>
              </td>
              <td>
                <h6>{updatedCardDetail.customer.email}</h6>
              </td>
            </tr>

            <tr style={TdStyles}>
              <td>
                <h6 className="font-weight-bold text-primary">Brand:</h6>
              </td>
              <td>
                <h6>{updatedCardDetail.token.card.brand}</h6>
              </td>
            </tr>

            <tr style={TdStyles}>
              <td>
                <h6 className="font-weight-bold text-primary">Exp_month:</h6>
              </td>
              <td>
                <h6>{updatedCardDetail.token.card.exp_month}</h6>
              </td>
            </tr>
            <tr style={TdStyles}>
              <td>
                <h6 className="font-weight-bold text-primary">Exp_year:</h6>
              </td>
              <td>
                <h6>{updatedCardDetail.token.card.exp_year}</h6>
              </td>
            </tr>
            <tr style={TdStyles}>
              <td>
                <h6 className="font-weight-bold text-primary">Finger Print:</h6>
              </td>
              <td>
                <h6>{updatedCardDetail.token.card.fingerprint}</h6>
              </td>
            </tr>

            <tr style={TdStyles}>
              <td>
               
                <h6 className="font-weight-bold text-primary">Last4:</h6>
              </td>
              <td>
               
                <h6>{updatedCardDetail.token.card.last4}</h6>
              </td>
            </tr>
            <tr style={TdStyles}>
              <td>
               
                <h6 className="font-weight-bold text-primary">Balance:</h6>
              </td>
              <td>
               
                <h6>${updatedCardDetail.customer.balance}</h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <div className="row">
        <div className=" w-100 col-xs-offset-1 col-sm-offset-1 col-md-offset-3">
          <div className="row">
            <div className="receipt-header">
              <div className="col-xs-12 col-sm-12 col-md-12 ">
                <div className="receipt-left d-flex justify-content-center">
                  <img
                    className="img-responsive"
                    alt="iamgurdeeposahan"
                    src={userAvatar}
                    style={{ width: "71px", borderRadius: "43px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="receipt-header receipt-header-mid">
              <div className="col-xs-8 col-sm-8 col-md-8 text-left">
                <div className="receipt-right">
                  {/* <h3 className="fw-bold text-primary">
                    {updatedCardDetail.customer.name}
                  </h3> */}
                  <div className="d-flex">
                    {/* <h5 className="text-primary fw-bolder">Name :</h5> */}
                    {/* <h5 className="fw-bold">
                      {updatedCardDetail.customer.name}
                    </h5> */}
                  </div>

                  <div className="d-flex">
                    {/* <h5 className="text-primary fw-bolder">Email :</h5>
                    <h5 className="fw-bold">
                      {updatedCardDetail.customer.email}
                    </h5> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="col-md-9">Card Updation:</td>
                  <td className="col-md-3">
                    <i className="fa fa-inr"></i>
                    <div className="css-y6xoqf mb-2">
                      <span className="css-jtz284">Succeded</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="col-md-9">Name:</td>
                  <td className="col-md-3">
                    <i className="fa fa-inr"></i>
                    {updatedCardDetail.customer.name}
                  </td>
                </tr>
                <tr>
                  <td className="col-md-9">Email:</td>
                  <td className="col-md-3">
                    <i className="fa fa-inr"></i>
                    {updatedCardDetail.customer.email}
                  </td>
                </tr>
                <tr>
                  <td className="col-md-9">Brand:</td>
                  <td className="col-md-3">
                    <i className="fa fa-inr"></i>
                    {updatedCardDetail.token.card.brand}
                  </td>
                </tr>
                <tr>
                  <td className="col-md-9">Expiration month:</td>
                  <td className="col-md-3">
                    <i className="fa fa-inr"></i>{" "}
                    {updatedCardDetail.token.card.exp_month}
                  </td>
                </tr>
                <tr>
                  <td className="col-md-9">Expiration year:</td>
                  <td className="col-md-3">
                    <i className="fa fa-inr"></i>
                    {updatedCardDetail.token.card.exp_year}
                  </td>
                </tr>

                <tr>
                  <td className="col-md-9">Last4:</td>
                  <td className="col-md-3">
                    <i className="fa fa-inr"></i>{" "}
                    {updatedCardDetail.token.card.last4}
                  </td>
                </tr>

                <tr>
                  <td className="col-md-9">Finger Print:</td>
                  <td className="col-md-3">
                    <i className="fa fa-inr"></i>{" "}
                    {updatedCardDetail.token.card.fingerprint}
                  </td>
                </tr>

                <tr>
                  <td className="text-center">
                    <h6 className="text-primary fw-bolder">Balance:</h6>
                  </td>
                  <td className="text-left text-danger">
                    <h6>
                      <strong>
                        <i className="fa fa-inr"></i>{" "}
                        {"$" +
                          Math.abs(updatedCardDetail.customer.balance / 100)}
                      </strong>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
