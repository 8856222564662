import React from "react";
import Loader from "../../../Common/Spinner";

function UpComingInvoice({
  upComingNextInvoice,
  timeStampIntoDate,
  previousBalance,
  upcomingLoading,
}) {
  const separeteDate = (val) => {
    console.log(previousBalance, "previousBalance");

    if (val) {
      let k = val && val?.split(",");

      return k[0];
    }
  };

  return (
    <>
      <div className="col-sm-12">
        <div className="settings-header">
          <h5>Upoming Invoice /Next Month</h5>
        </div>
        <table
          id="example"
          className="display expandable-table dataTable no-footer"
          style={{ width: "100%" }}
          role="grid"
        >
          <thead>
            <tr role="row">
              <th
                className="select-checkbox sorting_disabled"
                rowSpan="1"
                colSpan="1"
                aria-label="Quote#"
                style={{ width: "246px" }}
              >
                Date
              </th>
              <th
                className=""
                tabIndex="0"
                aria-controls="example"
                rowSpan="1"
                colSpan="1"
                aria-label="Quote#"
                aria-sort="ascending"
                style={{ width: "186px" }}
              >
                Description
              </th>
              <th
                className=""
                tabIndex="0"
                aria-controls="example"
                rowSpan="1"
                colSpan="1"
                style={{ width: "186px" }}
              >
                Amount
              </th>
              <th
                className=""
                tabIndex="0"
                aria-controls="example"
                rowSpan="1"
                colSpan="1"
                style={{ width: "186px" }}
              >
                Remaining Balance(After upcoming next Month purchase)
              </th>
            </tr>
          </thead>
          <tbody>
            {upcomingLoading ? (
              <>
                <td>
                  <Loader />{" "}
                </td>
              </>
            ) : (
              <>
                {upComingNextInvoice &&
                  upComingNextInvoice.map((item, i) => (
                    <tr className="odd" key={i}>
                      <>
                        <td className=" select-checkbox" key={i}>
                          <span className="badge badge-warning badge-pill px-25">
                            {" "}
                            {separeteDate(timeStampIntoDate(item.period.start))}
                          </span>{" "}
                          <span>
                            {" "}
                            <i class="fas fa-arrow-alt-circle-right ml-2 mr-2 text-primary"></i>
                          </span>
                          <span className="badge badge-warning badge-pill px-25">
                            {" "}
                            {separeteDate(timeStampIntoDate(item.period.end))}
                          </span>
                        </td>
                        <td className=" select-checkbox" key={i}>
                          {item.description}
                        </td>
                        <td className=" select-checkbox" key={i}>
                          <i class="fas fa-dollar-sign text-primary"></i>
                          {item.amount < 0
                            ? Math.abs(item.amount) / 100
                            : Math.abs(item.amount) / 100}
                        </td>
                        <td className=" select-checkbox" key={i}>
                          <i class="fas fa-dollar-sign text-primary"></i>
                       {Math.abs(previousBalance / 100).toFixed(2) -
                               Math.abs(item.amount / 100).toFixed(2) <
                          0
                            ? 0
                            : parseFloat(Math.abs(previousBalance / 100) -
                            Math.abs(item.amount/100)).toFixed(2)
                              }
                        </td>
                      </>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default UpComingInvoice;
