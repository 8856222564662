import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../Common/Loader/Loader";

import logo from ".././../Assets/images/forgot.png";
export default function EmailModal({ setToggleModal, RoomUrl }) {
  const [patientEmail, setPatientEmail] = useState(null);
  const [forgotEmail, forgotSetEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mailSent, isMailSent] = useState(false);
  const handleClose = () => setToggleModal(false);
  const topCategory = ["Category 1"];
  const [error, setError] = useState(null);
  const state = useSelector((state) => state);
  const userName = state.LoginObject.username;

  const nodeMaileSendMail = async () => {
    if (isValidEmail && forgotEmail != null && forgotEmail != "") {
      let formData = new FormData();
      formData.append("email", forgotEmail);
      try {
        setIsLoading(true);
        let path = process.env.REACT_APP_APIS + `forgotPassword=1`;
        axios
          .post(path, formData)
          .then((response) => {
            if (response && response.data) {
              console.log(response, "responce");
              setIsLoading(false);
              // heres hnadle close
              // toast.info(response.data);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            //   toast.error("Something went wrong please try later");
          });
        setIsLoading(false);
        isMailSent(true);
        // hereis handle close
        // let res = await axios.post(`http://localhost:3001/create`, { friendlyName: loginUser })
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    } else {
      setError("error in email");
    }
  };
  useEffect(() => {
    if (forgotEmail) {
    } else {
      setError("");
    }
  }, [forgotEmail]);
  const isValidEmail = () => {
    let isEmailValid;
    if (forgotEmail) {
      isEmailValid =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          forgotEmail
        );
      if (isEmailValid) {
        setError("");
      } else {
        setError("Email Invalid");
      }
    } else {
      setError("");
    }

    if (forgotEmail === null) {
      setError("");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "forgotEmail") {
      setError("");
      forgotSetEmail(value);
      return;
    }
  };

  return (
    <>
      <div
        className="modal fade show d-block small"
        // id="encounterfieldadd"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{
          background: "rgba(18, 18, 18, 0.68)",
          display: "flex",
        }}
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-body">
              <>
                {" "}
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="justify-content-center 
             align-items-center"
                >
                  <div>
                    <img
                      style={{
                        height: "120px",
                        marginTop: "18px",
                      }}
                      src={logo}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      textAlign: "center",
                      alignContent: "center",
                    }}
                  >
                    <p style={{ fontSize: 21 }}> Forgot password</p>
                  </div>

                  <p style={{ marginTop: "6px", color: "grey" }}>
                    Enter your Email and we will send you a link{" "}
                  </p>
                  <p style={{ marginTop: "-8px", color: "grey" }}>
                    to reset your password
                  </p>
                  <div className="  " style={{ paddingTop: "20px" }}>
                    <div className="">
                      {mailSent ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ color: "#67d13b", fontSize: 16 }}>
                            We have sent you an Email please check your{" "}
                          </p>
                          <p
                            style={{
                              marginTop: "-8px",
                              color: "#67d13b",
                              fontSize: 16,
                            }}
                          >
                            inbox to reset your password
                          </p>
                          {/* {error?<p>fdsfsdfsdf</p>:<></>} */}

                          <div
                            style={{
                              paddingTop: "20px",
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "center",
                              width: "270px",
                            }}
                          >
                            <button
                              style={{
                                width: "270px",
                                display: "inline-flex",
                                height: "40px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              type="button"
                              className="btn btn-primary"
                              data-dismiss="modal"
                              onClick={handleClose}
                              disabled={error}
                            >
                              Ok
                              {isLoading && (
                                <Loader small={true} large={false} />
                              )}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="loginForm">
                            <label
                              style={{
                                fontSize: 15,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Email
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="forgotEmail"
                              id="email"
                              placeholder="Email"
                              onChange={handleChange}
                              aria-describedby={error ? "invalid" : "valid"}
                              value={forgotEmail}
                              onBlur={() => {
                                if (forgotEmail) isValidEmail();
                              }}
                              disabled={isLoading}
                              //   onChange={handleUserName}
                              //   onKeyDown={onEnterLogin}
                              //   aria-describedby={userNameError ? "invalid" : "valid"}
                              //   onBlur={() => {
                              //     if (username) isValidName();
                              //   }}
                            />
                            {/* {error ? (
                          <p className="text-danger">
                            Special characters are not allowed except '@' and
                            '_'
                          </p>
                        ) : (
                          ""
                        )} */}
                            {/* {userNameError ? (
                          <p className="text-danger">Please enter username</p>
                        ) : undefined} */}
                          </div>
                          <div
                            style={{
                              paddingTop: "20px",
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "center",
                              width: "270px",
                            }}
                          >
                            <button
                              style={{
                                width: "270px",
                                display: "inline-flex",
                                height: "40px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              type="button"
                              className="btn btn-primary"
                              data-dismiss="modal"
                              onClick={nodeMaileSendMail}
                              disabled={error}
                            >
                              Send
                              {isLoading && (
                                <Loader small={true} large={false} />
                              )}
                            </button>
                          </div>
                        </>
                      )}

                      <div
                        className=""
                        style={{ textAlign: "center", padding: "30px" }}
                      >
                        <p style={{ cursor: "pointer" }} onClick={handleClose}>
                          <i
                            class="mr-2	fa fa-angle-left"
                            aria-hidden="true"
                          ></i>
                          Back to Login{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
