import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { swalConfirmation } from "../../../Utils/commonFunctions";
import axios from "axios";
import Loader from "../../../Common/Loader/Loader";
import { EmptyCheck } from "../../../Utils/ValidationsFunctions";
export default function Index() {
  const [passOjb, setPassObj] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPass: "",
  });
  const [isLaodiing, setIsLoading] = useState(false);
  const { LoginObject } = useSelector((state) => state);
  const [error, isError] = useState(false);
  const [error1, isError1] = useState(false);
  const [samePassError, setSamePassError] = useState(false);
  const [newerror, isnewError] = useState(false);

  useEffect(() => {
    if (passOjb.confirmPass != passOjb.newPassword) {
      isnewError(true);
      return;
    }
    isnewError(false);
  }, [passOjb.confirmPass]);
  useEffect(() => {
    if (
      passOjb.currentPassword != null &&
      passOjb.currentPassword != "" &&
      passOjb.newPassword != null &&
      passOjb.newPassword != "" &&
      passOjb.currentPassword === passOjb.newPassword
    ) {
      setSamePassError(true);
      return;
    }
    setSamePassError(false);
  }, [passOjb.currentPassword, passOjb.newPassword]);
  const checkValidation = () => {
    let result;
    if (passOjb.currentPassword != null && passOjb.currentPassword != "") {
      result = true;
      isError(false);
    } else {
      isError(true);
      result = false;
    }
    if (passOjb.newPassword != null && passOjb.newPassword != "") {
      result = true;
    } else {
      isError1(true);
      result = false;
    }

    return result;
  };
  const checkValidationforsamepassword = () => {
    let result;

    if (passOjb.confirmPass === passOjb.newPassword) {
      result = true;
    } else {
      result = false;
      isnewError(true);
    }
    return result;
  };
  const updatePassword = async () => {
    let i = true;
    let isSameChk = true;
    i = checkValidation();
    isSameChk = checkValidationforsamepassword();
    console.log(i,isSameChk, "i");
    if (i && isSameChk && passOjb.currentPassword!=passOjb.newPassword) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("userId", LoginObject.id);
      formData.append("currentPassword", passOjb.currentPassword);
      formData.append("newPassword", passOjb.newPassword);

      let path = process.env.REACT_APP_APIS + `updatePassword=1`;
      axios
        .post(path, formData)
        .then(async (res) => {
          if (res.data && res.data.msg === "Password changed successfully!") {
            setPassObj({
              currentPassword: "",
              newPassword: "",
              confirmPass: "",
            });
            toast.success("Your password updated successfully");
            setIsLoading(false);
          } else {
            toast.error("Your current password is incorrect");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.data);
        });
    } else {
      console.log("error");
    }
  };

  const handleChangePass = (e) => {
    let { name, value } = e.target;
    console.log(name, "name");
    if (name === "currentPassword") {
      isError(false);
    }
    if (name === "newPassword") {
      isError1(false);
    }
    setPassObj((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="social-settings">
        <div className="settings-header">
          <h5>Update you Password</h5>
        </div>

        <div className="form-col form-body">
          <form action="/">
            <div className="row">
              <div className="col-md-12 col-xl-12">
                <div className="form-group" style={{ width: "28%" }}>
                  <label>Current Password</label>
                  <input
                    name="currentPassword"
                    className="form-control form-control-lg group_formcontrol"
                    value={passOjb.currentPassword}
                    type="password"
                    class="form-control"
                    id="currentPassword"
                    placeholder="Current Password"
                    onChange={handleChangePass}
                    disabled={isLaodiing}
                    aria-describedby={error ? "invalid" : "valid"}
                  />
                </div>
              </div>
              <div className="col-md-12 col-xl-12">
                <div className="form-group" style={{ width: "28%" }}>
                  <label>New Password</label>
                  <input
                    name="newPassword"
                    className="form-control form-control-lg group_formcontrol"
                    value={passOjb.newPassword}
                    type="password"
                    class="form-control"
                    id="newPassword"
                    placeholder="New Password"
                    onChange={handleChangePass}
                    disabled={isLaodiing}
                    aria-describedby={error1 ? "invalid" : "valid"}
                  />
                </div>
              </div>
              <div className="col-md-12 col-xl-12">
                <div className="form-group" style={{ width: "28%" }}>
                  <label>Confirm Password</label>
                  <input
                    name="confirmPass"
                    className="form-control form-control-lg group_formcontrol"
                    value={passOjb.confirmPass}
                    type="password"
                    class="form-control"
                    id="confirmpass"
                    aria-describedby={newerror ? "invalid" : "valid"}
                    placeholder="Confirm Passowrd"
                    onChange={handleChangePass}
                    disabled={isLaodiing}
                  />
                </div>
                {newerror ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-16px",
                      marginBottom: "13px",
                    }}
                  >
                    New and confirm password not matching
                  </p>
                ) : (
                  <></>
                )}
                {samePassError ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        marginTop: "-16px",
                        marginBottom: "13px",
                      }}
                    >
                      You cannot update the same password
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 col-xl-4 ">
                <button
                  style={{
                    width: "110px",
                    height: "35px",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  type="button"
                  className="btn btn-delete btn-primary"
                  onClick={updatePassword}
                  disabled={isLaodiing}
                >
                  Update
                  {isLaodiing && <Loader small={true} forButton={true} />}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
