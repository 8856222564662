import React, { useEffect, useState, useContext } from "react";
import Loader from "../../Common/Spinner";
import {
  deleteRecording,
  fetchCompositions,
  viewMediaRecording,
} from "../../Utils/ApiManager";
import { secondsToTime, swalConfirmation } from "../../Utils/commonFunctions";
import { toast } from "react-toastify";
import { SocketContext } from "../../context/socket";

export default function CompositionRecordings({
  onDeleteRender,
  setOnDeleteRender,
  getRoomAHistory,
  IsComposedButton,
  roomWithRecoridngs,
  isViewMediaCallBack,
  getCompositions,
}) {
  const today = new Date().toISOString().slice(0, 10);
  const [playableMedia, setPlayableMedia] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [renderEffect, setRenderEffect] = useState(false);

  const [compositionDetail, setCompositionDetails] = useState(null);
  let socket = useContext(SocketContext);
  useEffect(() => {
    if (roomWithRecoridngs && roomWithRecoridngs.length > 0) {
      callFetchComposition();
    }
    socket.on("status-update-recording", (patientdata) => {
      // debugger;
      setRenderEffect(!renderEffect);
    });
  }, [roomWithRecoridngs]);

  const callFetchComposition = () => {
    setIsLoading(true);
    let obj = {
      AllRooms: roomWithRecoridngs,
    };
    fetchCompositions(obj)
      .then((res) => {
        if (res && res.data) {
          setIsLoading(false);
          let arr = [];
          setPlayableMedia(res.data.VideoRoom);
          IsComposedButton(res.data.VideoRoom);
          res.data.VideoRoom.map((el) => {
            if (el.compositions.length > 0) {
              arr.push(el);
            }
          });

          getCompositions(arr);
          setCompositionDetails(arr);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const setDate = (event) => {
    setSelectedDate(event.target.value);
  };
  const viewMedia = async (el) => {
    setIsLoading(true);
    let compositionSids = el[0].sid;
    let obj = { compositionSid: compositionSids };
    let result = await viewMediaRecording(obj);
    if (result && result.data) {
      //callBack function
      isViewMediaCallBack(true, result.data.url.body.redirect_to);
      setIsLoading(false);
    }
  };
  const confrimation = async (el) => {
    let swalObj = {};
    swalObj.text = "Are you sure that you want to delete this Recording?";
    swalObj.title = "Are you sure?";
    swalObj.icon = "warning";
    swalObj.dangerMode = true;
    swalObj.buttons = ["No ", "Yes"];

    let willDelete = await swalConfirmation(swalObj);

    if (willDelete) {
      deleteRecordings(el);
      // let head = "Deleted!";
      // let body = "User has been deleted!";
      // let status = "success";

      // swalSuccessBox(head, body, status);
    }
  };
  const deleteRecordings = (val) => {
    if (val && val.compositions && val.compositions.length > 0) {
      let obj = {
        sid: val.compositions[0].sid,
      };
      deleteRecording(obj).then((res) => {
        toast.info("Recording Deleted");
        getRoomAHistory();
        setOnDeleteRender(!onDeleteRender);
      });
    }
  };

  return (
    <>
      <div>
        <div>
          <div>
            <p className="card-title">Composed Recordings</p>
            {false ? (
              <Loader small={false} large={true} />
            ) : (
              <>
                <div 
                className="meetinghistory"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  <div >
                    <div className="" style={{ marginRight: "10px" }}>
                      <label htmlFor="start Date" className=" ">
                        Start Date
                      </label>
                      <input
                        style={{ height: "35px", width: "240px" }}
                        type="date"
                        className="form-control "
                        name="startDate"
                        onChange={setDate}
                        max={today}
                      // defaultValue={startDate}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <label htmlFor="start Date">End Date</label>
                      <input
                        style={{ height: "35px", width: "240px" }}
                        // value={endDate}
                        type="date"
                        max={today}
                        className="form-control"
                      // name={"endDate"}
                      // onChange={setDate}
                      // defaultValue={endDate}
                      />
                    </div>
                  </div>
                  <div
                    className=" align-self-center"
                    style={{ textAlign: "end" }}
                  >
                    <button
                      style={{
                        marginRight: "20px",
                        marginLeft: "10px",
                        marginTop: "25px",
                      }}
                      className="btn  btn-primary "
                    //   onClick={() => clearFilters()}
                    >
                      Clear Filters 
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive container-fluid">
                      <div
                        id="example_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6"></div>
                          <div className="col-sm-12 col-md-6"></div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="example"
                              className="display  expandable-table dataTable no-footer"
                              style={{ width: "100%" }}
                              role="grid"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className="select-checkbox "
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Quote#"
                                    style={{ width: "176px" }}
                                  >
                                    Room Name
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    style={{ width: "210px" }}
                                  >
                                    Video Duration
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    style={{ width: "210px" }}
                                  >
                                    Resolution
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    style={{ width: "210px" }}
                                  >
                                    Size
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    style={{ width: "210px" }}
                                  >
                                    Formate
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Status: activate to sort column ascending"
                                    style={{ width: "196px" }}
                                  >
                                    Action{" "}
                                  </th>
                                  {/* <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Updated at: activate to sort column ascending"
                          style={{ width: "155px" }}
                        >
                          Updated at
                        </th> */}
                                  <th
                                    className="details-control sorting_disabled"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label=""
                                    style={{ width: "53" }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {isLoading ? (
                                  <Loader small={false} large={true} />
                                ) : (
                                  <>
                                    {compositionDetail &&
                                      compositionDetail.length > 0 ? (
                                      compositionDetail.map((el) => {
                                        let size = ((el.compositions[0].size)/1000000).toFixed(2)
                                        return (
                                          <>
                                            <tr className="odd">
                                              <td>{el.name}</td>
                                              <td>
                                                {secondsToTime(
                                                  el.compositions[0].duration
                                                )}
                                              </td>
                                              <td>{el.compositions[0].resolution}</td>
                                              <td>{size}{''} MB</td>
                                              <td>{el.compositions[0].format}</td>

                                              <td>
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    viewMedia(el.compositions)
                                                  }
                                                >
                                                  {isLoading ? (
                                                    <>
                                                      {" "}
                                                      <Loader
                                                        small={true}
                                                        large={false}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <i
                                                        class="fas fa-eye"
                                                        style={{
                                                          color: "#4b49ac",
                                                          fontSize: 21,
                                                          marginRight: "5px",
                                                        }}
                                                      ></i>
                                                    </>
                                                  )}
                                                </span>
                                                {"  "}
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    confrimation(el)
                                                  }
                                                >
                                                  {isLoading ? (
                                                    <>
                                                      {" "}
                                                      <Loader
                                                        small={true}
                                                        large={false}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      {" "}
                                                      <i
                                                        class="fas fa-trash"
                                                        style={{
                                                          color: "#4b49ac",
                                                          fontSize: 20,
                                                        }}
                                                      ></i>
                                                    </>
                                                  )}
                                                </span>
                                                {"  "}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <tr
                                        className="odd"
                                      // key={index}
                                      >
                                        <td></td>

                                        <br></br>
                                        <td>
                                          <span className="badge badge-warning-grey badge-pill px-25">
                                            <p style={{ color: "Black" }}>
                                              {" "}
                                              No Records to show
                                            </p>
                                          </span>
                                        </td>
                                        <td></td>
                                        <br></br>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5"></div>
                          <div className="col-sm-12 col-md-7"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                      paddingRight: "25px",
                      paddingTop: "10px",
                    }}
                  ></div>
                </div>
              </>
            )}

            {/* //old Table Design */}
          </div>
        </div>
      </div>
    </>
  );
}
