import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import "./Success.css";
import {
  getCardDetailsApi,
  getParticpantMin,
  getPatientLimitations,
  getPaymentIntentFromSubId,
  insertTotalMin,
  PatientLimitations,
  paymentIntentApi,
  saveCreditCard,
  updateConsumedMinutesApi,
  updatePatientLimitations,
} from "../../Utils/ApiManager";
import FileViewer from "../Room/RoomRightSidenav/RoomMessages/Messages/FileViewer";
import Loader from "../../Common/Spinner";
import { Obj } from "../../Common/PatientLimitations";

export default function Success({ SuccessUrlState }) {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [balance, setBalance] = useState();
  const [address, setAddress] = useState();
  const [create_at, setCreate_at] = useState();
  const [session, setSession] = useState();
  const [popUp, setPopUpFlag] = useState(false);
  const [currency, setCurrency] = useState(false);
  const [amount, setAmonutDeducted] = useState(false);
  const [cardDetails, setCardDetails] = useState(false);
  const [paymentIntentDetail, setPaymentIntentDetail] = useState();
  const [receipt_url, setreceipt_url] = useState(null);
  const [fileViewer, setFileViewerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nextPayment, setNextPayment] = useState(false);

  let location = useLocation();
  let sesssionId = null;

  React.useEffect(() => {
    let url = window.location.href;
    let splitUrl = SuccessUrlState && SuccessUrlState.split("?");

    if (typeof splitUrl != "undefined" && splitUrl && splitUrl.length > 1) {
      sesssionId = splitUrl && splitUrl[1].split("=");
      setSession(sesssionId);

      if (sesssionId[0] === "session_id") {
        getSessionInfo(sesssionId);
        getCusIdFromSessionId(sesssionId);
        setPopUpFlag(true);
      }
      //hideURLParams();
    }
  }, [location]);

  const convertDateintoSlashDate = (address) => {
    let unixTimestamp = address;
    var date = new Date(unixTimestamp * 1000);
    var creatinDate = date.toLocaleDateString("en-US");
    let z = creatinDate.split("/");
    let zz = z[1] + "/" + z[0] + "/" + z[2];

    return zz;
  };
  function getURLParameter(name) {
    return decodeURI(
      (RegExp(name + "=" + "(.+?)(&|$)").exec(location.search) || [, null])[1]
    );
  }
  const saveTransactionToDb = async (
    saveTransation,
    sesssionId,
    flagOneTime,
    receiptUrlVar,
    nextAttemptValue,
    created
  ) => {
    let sess;

    if (flagOneTime) {
      sess = sesssionId;
    } else {
      sess = sesssionId[1];
    }

    let formData = new FormData();
    formData.append("created_at", created);
    formData.append("expiryDate", nextAttemptValue);
    formData.append("currency", saveTransation?.currency);
    formData.append("packageName", saveTransation?.packageName);
    formData.append("amount", saveTransation?.amount);
    formData.append(
      "recieptUrl",
      receiptUrlVar ? receiptUrlVar : saveTransation?.recieptUrl
    );
    formData.append("customerId", "Payment");
    formData.append("transactionId", sess);
    formData.append("userId", saveTransation?.userId);
    formData.append("upgradeStatus", sess);
    try {
      await axios
        .post(process.env.REACT_APP_APIS + "save-transaction=1", formData)
        .then(function (response) {
          setPatientLimitations(saveTransation);
          setMinutesLimit(saveTransation);

          setIsLoading(false);
        });
    } catch (err) {}
  };
  const checkIFProviderWithLimitationAlreadyExists = async (saveTransation) => {
    let pkg = saveTransation?.packageName;
    const limitations =
      pkg === "gold"
        ? Obj?.GOLD_PATIENT_LIMITATIONS
        : pkg === "silver"
        ? Obj?.SILVER_PATIENT_LIMITATIONS
        : pkg === "metal"
        ? Obj?.METAL_PATIENT_LIMITATIONS
        : Obj?.FREE_PATIENT_LIMITATIONS;

    let res = await getPatientLimitations();
    if (res && res.data) {
      const filteredArr = res.data.filter(
        (item) => item.user_id === saveTransation?.userId
      );

      if (filteredArr.length > 0) {
        //Run Update API
        let formData = new FormData();
        formData.append("id", saveTransation?.userId);
        formData.append("noOFAttempts", limitations);
        formData.append("userName", null);
        updatePatientLimitations(formData);
      } else {
        let formData = new FormData();
        formData.append("id", saveTransation?.userId);
        formData.append("noOFAttempts", limitations);
        formData.append("userName", null);

        PatientLimitations(formData);
      }
    }
  };

  const setPatientLimitations = (saveTransation) => {
    checkIFProviderWithLimitationAlreadyExists(saveTransation);
  };
  const setMinutesLimit = async (saveTransation) => {
    let pkg = saveTransation?.packageName;
    const minutes =
      pkg === "gold"
        ? Obj?.GOLD_MIN_LIMITATIONS
        : pkg === "silver"
        ? Obj?.SILVER_MIN_LIMITATIONS
        : pkg === "metal"
        ? Obj?.METAL_MIN_LIMITATIONS
        : Obj?.FREE_MIN_LIMITATIONS;

    let res = await getParticpantMin();
    if (res && res.data) {
      const filteredArr = res.data.filter(
        (item) => item.user_id === saveTransation?.userId
      );
      if (filteredArr.length > 0) {
        let formData = new FormData();
        formData.append("userid", saveTransation?.userId);
        formData.append("id", saveTransation?.userId);
        formData.append("totalMinConsumed", minutes);
        updateConsumedMinutesApi(formData);
      } else {
        let formData = new FormData();
        formData.append("id", saveTransation?.userId);
        formData.append("totalMinConsumed", minutes);
        formData.append("userName", null);
        insertTotalMin(formData);
      }
    }
  };
  const hideURLParams = () => {
    //Parameters to hide (ie ?success=value, ?error=value, etc)
    var hide = ["success", "error"];
    for (var h in hide) {
      if (getURLParameter(h)) {
        window.history.replaceState(
          null,
          document.getElementsByTagName("title")[0].innerHTML,
          window.location.pathname
        );
      }
    }
  };
  const handle = () => {
    // forceRefresh();
    window.close();
  };
  const displayPopUp = (val) => {
    setPopUpFlag(val);
  };
  const getCusIdFromSessionId = async (session) => {
    let obj = {
      sesssionId: session,
    };
    try {
      await axios
        .post(process.env.REACT_APP_APIS + "get-customer-id", obj)
        .then(function (response) {});
    } catch (err) {}
  };
  const getPaymentIntentsFromSubId = (res) => {
    if (res && res.data && res.data.fullSession) {
      let obj = {};
      obj.invoiceId = res.data.fullSession.invoice;
      getPaymentIntentFromSubId(obj)
        .then((res) => {
          if (res && res.data) {
            setreceipt_url(res.data.hostedInvoice);
          }
        })
        .catch((err) => {});
    }
  };

  const addMonths = (numOfMonths, date = new Date()) => {
    date.setMonth(date.getMonth() + numOfMonths);

    return date;
  };
  const timeStampIntoDate = (createdTimeStamp) => {
    let date = new Date(createdTimeStamp * 1000);

    return date.toLocaleString();
  };
  const retrieveNextPayment = async (subId) => {
    try {
      let obj = {
        customerId: subId,
      };
      let url =
        (await process.env.REACT_APP_APIS_GLOBAL_LOCALHOST) +
        "/invoicePayments";

      let response = await axios.post(url, obj);

      let currentperiod = timeStampIntoDate(
        response.data && response.data.invoiceDetails.period_end
      );
      let created = timeStampIntoDate(
        response.data && response.data.invoiceDetails.period_start
      );
      let periodObj = {};
      periodObj.created = created;
      periodObj.currentperiod = currentperiod;

      return periodObj;
    } catch (err) {}
  };
  const getSessionInfo = async () => {
    setIsLoading(true);
    let url = process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/get-session-info";

    try {
      let obj = {
        session_id: sesssionId[1],
      };
      await axios.post(url, obj).then(async (response) => {
        let res = response && response?.data?.sessionOneTime;

        if (res) {
          oneTimePayment(response);
          return;
        }

        // retreive paymentIntentFromSubid
        getPaymentIntentsFromSubId(response);
        // For next Payment retrieve

        let customerDetailsId = response && response?.data?.customerDetails?.id;

        const { created, currentperiod } = await retrieveNextPayment(
          customerDetailsId
        );
        //

        let schedule = response && response?.data?.schedule?.current_phase;

        let customerDetails = response && response?.data?.customerDetails;
        let email = customerDetails?.email;
        let balance = customerDetails?.balance;
        let create_at = customerDetails?.created;
        let name = customerDetails?.name;
        let address = customerDetails?.address?.country;
        let currency = customerDetails?.currency;
        let end_date = schedule?.end_date;
        // let d = new Date(address);
        let slashDate = convertDateintoSlashDate(schedule?.start_date);
        let expiryDateSlash = convertDateintoSlashDate(schedule?.end_date);

        let amount = response && response?.data?.amount;

        let packageName = null;
        if (amount === 14900) {
          packageName = "gold";
        }
        if (amount === 5000) {
          packageName = "metal";
        }
        if (amount === 1000) {
          packageName = "silver";
        }
        nodeMaileSendMail(
          email,
          balance,
          create_at,
          end_date,
          name,
          address,
          currency,
          amount,
          packageName
        );

        let saveTransation = {
          userId: response && response?.data?.userId,
          creationDate: slashDate,
          currency: customerDetails?.currency,
          expiryDate: expiryDateSlash,
          recieptUrl: null,
          transactionId: obj?.session_id,
          customerId: customerDetails?.id,
          amount: response && response?.data?.amountTotal,
          packageName: packageName,
        };

        saveTransactionToDb(
          saveTransation,
          sesssionId,
          false,
          false,
          currentperiod,
          created
        );
        let userId = response && response?.data?.userId;
        getCardDetails(customerDetails, userId);
        setEmail(email);
        setAddress(address);
        setCreate_at(slashDate);
        setBalance(balance);
        setName(name);
        setCurrency(currency);
        setAmonutDeducted(amount);
      });
    } catch (err) {}
  };
  const nodeMaileSendMail = async (
    email,
    balance,
    create_at,
    end_date,
    name,
    address,
    currency,
    amount,
    packageName
  ) => {
    let startDateFormate = convertDateintoSlashDate(create_at);
    let endDateFormate = convertDateintoSlashDate(end_date);
    let obj = {};
    obj.email = email;
    obj.balance = balance;
    obj.create_at = startDateFormate;
    obj.end_date = endDateFormate;
    obj.name = name;
    obj.currency = currency;
    obj.amount = amount / 100;
    obj.packageName = packageName;

    let formData = new FormData();
    formData.append("mailsentto", email);
    formData.append("subject", "Package Purchased");
    formData.append(
      "msg",
      `Name:${obj.name} Balance:${obj.balance} pkgCreation_Date:${obj.create_at} phgEndDate:${obj.end_date} Currency:${obj.currency} Amount:${obj.amount}$ Package_Name:${obj.packageName} `
    );
    formData.append("mailpass", "ug40jdxoflbqm");
    try {
      let res = await axios
        .post(process.env.REACT_APP_APIS, formData)
        .then((response) => {
          if (response && response.data) {
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try later");
        });
      // let res = await axios.post(`http://localhost:3001/create`, { friendlyName: loginUser })
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  const oneTimePayment = async (response) => {
    let res = response && response?.data?.sessionOneTime;

    let slashDate = convertDateintoSlashDate(res?.created);
    let expiryDateSlash = convertDateintoSlashDate(res?.expires_at);

    let result = expiryDateSlash.split("/");
    let n = result[2] + "-" + result[1] + "-" + result[0];
    const date = new Date(n);
    let expiry = addMonths(1, date);
    let newFormat = expiry.toLocaleDateString("en-US");
    let z = newFormat.split("/");
    let b = z[1] + "/" + z[0] + "/" + z[2];

    let packageName =
      res?.amount_total === 5000
        ? "metalOneTime"
        : res?.amount_total === 14900
        ? "goldOneTime"
        : res?.amount_total === 1000
        ? "silverOneTime"
        : "free";
    let sesssionId = res?.id;
    let saveTransation = {
      userId: res?.metadata?.id,
      creationDate: slashDate,
      currency: res?.currency,
      expiryDate: b,
      recieptUrl: null,
      transactionId: res?.id,
      customerId: res?.payment_intent,
      amount: res?.amount_total,
      packageName: packageName,
    };

    setEmail(res?.customer_details?.email);
    setAddress(res?.customer_details?.address?.country);
    setCreate_at(slashDate);
    setBalance(0);
    setAmonutDeducted(response && response?.data?.amountTotal);
    setName(res?.customer_details?.name);
    getPaymentIntentAndCardDetail(
      res?.payment_intent,
      res?.metadata?.id,
      sesssionId,
      saveTransation
    );
  };
  const getPaymentIntentAndCardDetail = (
    id,
    userId,
    sesssionId,
    saveTransation
  ) => {
    let obj = {
      paymentIntentId: id,
    };
    paymentIntentApi(obj)
      .then(async (res) => {
        let receiptUrlVar = null;
        receiptUrlVar =
          res && res.data && res.data.paymentIntent.charges.data[0].receipt_url;
        await saveTransactionToDb(
          saveTransation,
          sesssionId,
          true,
          receiptUrlVar
        );
        setPaymentIntentDetail(res && res.data && res.data.paymentIntent);
        saveCardDetailsOneTime(
          res && res.data && res.data.paymentIntent,
          userId
        );
      })
      .catch((err) => {});
  };
  const saveCardDetailsOneTime = async (payIntent, userId) => {
    let charger = payIntent.charges.data[0];
    setreceipt_url(charger && charger.receipt_url);
    let formData = new FormData();
    formData.append("cusId", payIntent.id);
    formData.append("name", charger.billing_details.name);
    formData.append("email", charger.billing_details.email);
    formData.append("brand", charger.payment_method_details.card.brand);
    formData.append("exp_month", charger.payment_method_details.card.exp_month);
    formData.append("exp_year", charger.payment_method_details.card.exp_year);
    formData.append(
      "fingerprint",
      charger.payment_method_details.card.fingerprint
    );
    formData.append("last4", charger.payment_method_details.card.last4);
    formData.append("balance", 0);
    formData.append("userId", userId);

    try {
      saveCreditCard(formData)
        .then((response) => {
          if (response && response.data) {
            // setCardDetails(response.data);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try later");
          toast.error(err);
        });
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
    return;
  };

  const getCardDetails = async (customerDetails, userId) => {
    try {
      let obj = {
        cusId: customerDetails?.id,
      };

      getCardDetailsApi(obj)
        .then((response) => {
          if (response && response.data) {
            saveCardDetailss(response.data, userId);
            setCardDetails(response.data);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try later");
          toast.error(err);
        });
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
    return;
  };
  const saveCardDetailss = async (res, userId) => {
    let resp = res.cards;
    let respCard = res.cards.card;
    let billingDetail = res.cards.billing_details;
    let formData = new FormData();
    formData.append("cusId", resp.customer);
    formData.append("name", billingDetail.name);
    formData.append("email", resp.billing_details.email);
    formData.append("brand", respCard.brand);
    formData.append("exp_month", respCard.exp_month);
    formData.append("exp_year", respCard.exp_year);
    formData.append("fingerprint", respCard.fingerprint);
    formData.append("last4", respCard.last4);
    formData.append("balance", 0);
    formData.append("userId", userId);
    try {
      // let obj = {
      //   cusId: customerDetails?.id,
      //   name: billingDetail.name,
      //   email: resp.billing_details.email,
      //   brand: respCard.brand,
      //   exp_month: respCard.exp_month,
      //   exp_year: respCard.exp_year,
      //   fingerprint: respCard.fingerprint,
      //   last4: respCard.last4,
      //   balance: 0,
      // };

      saveCreditCard(formData)
        .then((response) => {
          if (response && response.data) {
            // setCardDetails(response.data);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try later");
          toast.error(err);
        });
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
    return;
  };
  const openFileViewer = () => {
    setFileViewerOpen(true);
    displayPopUp();
  };

  return (
    <>
      {fileViewer ? (
        <FileViewer
          fileExt={"pdf"}
          file={receipt_url}
          // close={toggleImage}
        />
      ) : null}
      {!isLoading && popUp ? (
        <>
          <div
            className={popUp ? "modal fade show" : "modal"}
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-modal={popUp ? "true" : "false"}
            aria-labelledby="staticBackdropLabel"
            aria-hidden={popUp ? "true" : "false"}
            style={
              popUp
                ? {
                    display: "block",
                    paddingRight: "17px ",
                    background: "rgba(18, 18, 18, 0.68)",
                  }
                : { display: "none" }
            }
            role={popUp ? "dialog" : null}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Payment Details
                  </h5>
                </div>
                <>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="modal-body">
                      <div className="d-flex justify-content-between align-items-center switchflex">
                        <div className="modal-width-60">
                          <div className="row">
                            <div className="col-sm-12 mobileModal">
                              <div className="m-3">
                                {popUp ? (
                                  <div style={{ textAlign: "center" }}>
                                    <h5
                                      className="modal-title pb-4 boldcenter"
                                      id="exampleModalLabel"
                                    ></h5>
                                  </div>
                                ) : (
                                  <div style={{ textAlign: "center" }}>
                                    <h6>
                                      Please enter your information to join
                                      provider.
                                    </h6>
                                  </div>
                                )}

                                <div style={{ textAlign: "center" }}>
                                  <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">
                                      Name :{" "}
                                    </h6>
                                    <h6 className="text-muted"> {name}</h6>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">
                                      Deducted Amount:
                                    </h6>
                                    <h6 className="text-muted">
                                      ${amount / 100}.00
                                    </h6>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Email:</h6>
                                    <h6>{email}</h6>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">
                                      Address:
                                    </h6>
                                    <h6>{address}</h6>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">
                                      Receipt Url:
                                    </h6>
                                    <h6>
                                      <a href={receipt_url} download="mycv.pdf">
                                        <i
                                          class="fa fa-download"
                                          style={{ marginRight: "4px" }}
                                        ></i>
                                        Download
                                      </a>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>

                <div className="modal-footer">
                  {false ? undefined : (
                    <>
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-primary mt-2"
                          onClick={() => displayPopUp()}
                        >
                          Close{" "}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
