import MessageBubble from "./MessageBubble";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class ConversationsMessages extends PureComponent {
  render = () => {
    console.log(
      " this.props.identity",
      this.props.identity,
      this.props.messages,
      "this.props.messages"
    );

    //

    return (
      <div className="ul-divs">
        <div className="messages">
          {this.props.messages.map((m) => {
            if (m.author === this.props.identity)
              return (
                <MessageBubble
                  deleteMessage={this.props.deleteMessage}
                  attributeName={m.state.attributes}
                  //   rplyMessageAttributes={this.props.rplyMessageAttributes}
                  rplyMsgUiUpdate={this.props.rplyMsguiUpdate}
                  onReplyMessage={this.props.onReplyMessage}
                  messageEdit={this.props.messageEdit}
                  // loaderForMediaSent={this.props.loaderForMediaSent}
                  toggleImage={this.props?.toggleImage}
                  key={m.index}
                  direction="outgoing"
                  message={m}
                />
              );
            else if (m.body != null || m.type === "media") {
              return (
                <MessageBubble
                  messageEdit={this.props.messageEdit}
                  deleteMessage={this.props.deleteMessage}
                  attributeName={m.state && m.state?.attributes}
                  //  rplyMessageAttributes={this.props.rplyMessageAttributes}
                  rplyMsgUiUpdate={this.props.rplyMsguiUpdate}
                  onReplyMessage={this.props.onReplyMessage}
                  // loaderForMediaSent={this.props.loaderForMediaSent}
                  toggleImage={this.props?.toggleImage}
                  key={m.index}
                  direction="incoming"
                  message={m}
                />
              );
            }
          })}
        </div>
      </div>
    );
  };
}

ConversationsMessages.propTypes = {
  identity: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ConversationsMessages;
