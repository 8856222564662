import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import PublicNav from "../PublicView/PublicNav.js";


function PublicLayout({ }) { 

  let { PatientDataDetails, OnGoingCallState } = useSelector((state) => state);
  return (
    <>
      <div className={PatientDataDetails && OnGoingCallState ? "main patientExits" : 'main'}>
        {/* <PublicNav /> */}
        <div className={'PublicMain'}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default PublicLayout;
