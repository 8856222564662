export const Obj = {
  SILVER_PATIENT_LIMITATIONS: 3,
  GOLD_PATIENT_LIMITATIONS: "UNLIMITED",
  METAL_PATIENT_LIMITATIONS: 6,
  FREE_PATIENT_LIMITATIONS: 0,
  GOLD_MIN_LIMITATIONS: "UNLIMITED",
  METAL_MIN_LIMITATIONS: 120,
  SILVER_MIN_LIMITATIONS: 2,
  FREE_MIN_LIMITATIONS: 0,
  Silver_Unique_Chat_Particpant: 3,
  Metal_Unique_Chat_Particpant: 25,
  Gold_Unique_Chat_Particpant: "UNLIMITED",
};
