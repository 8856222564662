import React, { useState, useEffect, useContext } from "react";
import Loader from "../../Common/Loader/Loader";
import {
  changePaitentStatus,
  getPatientJoiningStatusApi,
  getPatientList,
  getProviderStatus,
  patientJoinRoomApi,
} from "../../Utils/ApiManager";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import WebComponentWebCompon from "./WebComponent";
import { useNavigate } from "react-router-dom";
import {
  PatientData,
  PatientAction,
  isPatSystemCamEnable,
  groupChatUniqueIdentifier,
  patientEndProviderImage,
} from "../../Actions/Actions";
import axios from "axios";
import store from "../../Store/Store";
import BrowserNotification from "../../Common/browserNotifications";
import { SocketContext } from "../../context/socket";
import logo from "../../Assets/images/bellmedex-logo-removebg-preview.jpg";
import capitalizeFirst from "../../Common/CaptilizeFirstLetter";
import { EmptyCheck } from "../../Utils/ValidationsFunctions";

export default function PatientPopup({
  handleSmallScreen,

  onJoinsSmallScreenClass,

  setTogglePopupScreen,
  closeModel,
  room_Name,
  patientJoiningPopup,
  togglePopupScreen,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [callWeb, setCallWeb] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientList, setPatientList] = useState();
  const [online, setOnline] = useState(false);
  const [firstNameValidate, setFirstNameValidate] = useState(null);
  const [lastNameValidate, setLastNameValidate] = useState(null);

  // const [patientJoiningPopup, setPatientJoiningPopup] = useState(false);
  const [joiningUserObject, setJoiningUserObject] = useState(null);
  //const [togglePopupScreen, setTogglePopupScreen] = useState(false);
  let dispatch = useDispatch();
  const socket = useContext(SocketContext);
  let intervalForTime = null;
  let patientID;
  const navigate = useNavigate();
  const { RoomInfo, LoginObject, PatientDataDetails, SystemInfo } = useSelector(
    (state) => state
  );

  const state = useSelector((state) => state);
  useEffect(() => {
    getProviderStatuss();
  }, []);
  useEffect(() => {
    if (LoginObject && !LoginObject.username) {
      isSystemCameraAvaialble(SystemInfo.name);
    }
  }, []);

  //********************************for APi every 5 second*********************** */
  useEffect(() => {
    if (room_Name) {
      socket.on("show_provider_online", (obj) => {
        if (obj.obj.username === room_Name) {
          setOnline(true);
        }
        if (obj.obj === "offline") {
          setOnline(false);
        }
        getProviderStatuss();
      });
    }
  }, []);

  useEffect(() => {
    if (email) {
    } else {
      setError("");
    }
  }, [email]);
  const [cameraActive, setCameraActive] = useState(false);

  // useEffect(() => {
  //   let mediaStream = null;

  //   async function checkCamera() {
  //     if (SystemInfo.name === "chrome") {
  //       return;
  //     }
  //     try {
  //       mediaStream = await navigator.mediaDevices.getUserMedia({
  //         video: true,
  //       });

  //       setCameraActive(mediaStream.active);
  //     } catch (error) {
  //       console.log("Error getting media stream:", error);
  //     }
  //   }

  // //  checkCamera();

  //   return () => {
  //     // if (mediaStream) {
  //     //   mediaStream.getTracks().forEach((track) => track.stop());
  //     // }
  //   };
  // }, []);
  console.log("cameraActive", cameraActive);

  //-------------------For Camera Permisision---------------------

  //----------------------------------------------End camera Permission playground

  const isSystemCameraAvaialble = (broswer) => {
    console.log("broswer", broswer);
    if (broswer === "chrome") {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          console.log(devices, "broswer for Final Test");

          let cameraFound = false;
          devices.forEach(function (device) {
            if (device.kind === "videoinput" && device.deviceId != "") {
              cameraFound = true;
            }
          });
          if (cameraFound) {
          } else {
          }
          console.log("For Cmar redux", cameraFound);
          dispatch(isPatSystemCamEnable(cameraFound));
        })
        .catch(function (error) {});
    } else {
      navigator.mediaDevices
        .getUserMedia()
        .then(function (devices) {
          let cameraFound = false;
          console.log("devices mobile ", devices);
          devices.forEach(function (device) {
            console.log("else devie", device);
            if (device.kind === "videoinput" && device.deviceId != "") {
              cameraFound = true;
            }
          });
          console.log("cameraFound for mac and mobile", cameraFound);
          dispatch(isPatSystemCamEnable(cameraFound));
        })
        .catch(function (error) {});
    }
  };

  const isValidEmail = () => {
    if (email) {
      let isEmailValid =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          email
        );
      if (isEmailValid) {
        setError("");
      } else {
        setError("Error email invalid");
      }
    } else {
      setError("");
    }
    if (email === null) {
      setError("");
    }
  };
  useEffect(() => {
    intervalForTime = setInterval(
      () => getPatientJoiningStatus(RoomInfo),
      5000
    );
    return () => {
      clearInterval(intervalForTime);
    };
  }, []);

  const getProviderStatuss = () => {
    try {
      getProviderStatus(RoomInfo).then(async (res) => {
        if (res && res.data && res.data.providerStatus === "online") {
          if (res.data.image) dispatch(patientEndProviderImage(res.data.image));
          setOnline(true);
        } else {
          setOnline(false);
        }
      });
    } catch (err) {}
  };

  // const formatAMPM = (date) => {
  //   var hours = date.getHours();
  //   var minutes = date.getMinutes();
  //   var seconds = date.getSeconds();
  //   // var ampm = hours >= 12 ? 'pm' : 'am';
  //   // hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   var strTime = hours + ":" + minutes + ":" + seconds + " ";
  //   return strTime;
  // };

  const addPatient = (firstName, lastName, email, existPatient) => {
    // var roomName = window.location.href.split("/").pop();
    // let time = formatAMPM(new Date());
    // var newDate = new Date().toISOString().split("T")[0];
    // --------time Zone---------------
    //  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // let z = newDate.split("-");
    //  z = z[2] + "/" + z[1] + "/" + z[0];
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      roomName: RoomInfo,
      // joiningDateAndTime: z + " " + time + " TimeZone-" + timezone,
      joiningDateAndTime: new Date(),
    };
    let obj = {};
    obj.patientFirstName = firstName;
    obj.providerName = room_Name;
    var querystring = require("query-string");

    try {
      axios
        .post(
          process.env.REACT_APP_APIS + "addPatient=1",
          querystring.stringify(data),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )

        .then((response) => {
          let patId = response.data.split(":").pop();
          obj.id = patId;
          socket.emit("patient-status-to-provider", obj);
          if (response.data === `User added userid:${patId}`) {
            socket.emit("new_patient_added", { obj });
          }
          if (response.data) {
            changePaitentStatus(RoomInfo, "waiting", patId).then((res) =>
              console.log("changePaitentStatus", res)
            );
            setTogglePopupScreen(true);
            dispatch(PatientAction(patId));
          }
        });
    } catch (err) {}
    // }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstNameValidate("");
      setFirstName(value);
      return;
    }
    if (name === "lastName") {
      setLastNameValidate("");
      setLastName(value);
      return;
    }
    if (name === "email") {
      setError("");
      setEmail(e.target.value);

      return;
    }
  };

  const getPatientJoiningStatus = async (RoomInfo) => {
    let pateintInfoo = store && store.getState()?.PatientInfo;
    if (pateintInfoo) {
      patientID = pateintInfoo;
      getPatientList(RoomInfo).then((res) => {
        let arr = res && res.data;
        const results = arr.filter((element) => {
          return element.active === "accepted";
        });
        // dispatch(groupChatUniqueIdentifier(results))
        console.log("results", results);

        if (results && results.length > 0) {
          let currentPatient = results.find((el) => el.id === patientID);
          if (currentPatient) {
            setPatientList(currentPatient);
            setCallWeb(true);
            patientJoinRoom(
              currentPatient.fname + currentPatient.lname,
              currentPatient.id
            );
          } else {
            if (patientJoiningPopup) {
            } else {
            }
            // }
          }
        }
      });
    }
  };

  const patientJoinRoom = (patientName, patId) => {
    // if patient popoup for entring information is shown dont add again

    if (patientJoiningPopup) {
      patientJoinRoomApi(RoomInfo, patientName).then((res) => {
        let joiningUser = patientName && patientName.split(" ").join("");
        let obj = {
          patId: patId,
          joiningUser: joiningUser,
          roomName: res.data.roomname,
          token: res.data.usertoken,
          ProviderFullName: res.data.fullname,
          ProviderEmail: res.data.email,
          providerId: res.data.userid,
        };
        if (PatientDataDetails === null) {
          dispatch(PatientData(obj));
        }

        //  setPatientJoiningPopup(false)
        setTogglePopupScreen(obj);
        setTogglePopupScreen(false);
        navigate(`/room/${res.data.roomname}`);
      });
    }
  };

  const CheckIfPatientWithSameNameExistInRoom = async () => {
    let ifPatientExist = false;
    getPatientList(RoomInfo).then((res) => {
      let patientList = res.data;
      if (patientList && patientList.length > 0) {
        let userExist = patientList.find(
          (patient) =>
            patient.fname.toLowerCase() === firstName.toLowerCase() &&
            patient.lname.toLowerCase() === lastName.toLowerCase() &&
            patient.status === RoomInfo
        );
        if (userExist) {
          ifPatientExist = true;
        } else {
          ifPatientExist = false;
        }
      } else {
        ifPatientExist = false;
      }
    });
    return ifPatientExist;
  };

  const onJoinCallThisFN = async () => {
    let firstNameVlidate = await EmptyCheck(firstName);
    let lastNameValidate = await EmptyCheck(lastName);
    let EmailValidate = await EmptyCheck(email);
    if (firstNameVlidate) {
      setFirstNameValidate("Required");
    }
    if (lastNameValidate) {
      setLastNameValidate("Required");
    }
    if (EmailValidate) {
      setError("Required");
    }

    if (firstName && lastName && email) {
      setIsLoading(true);
      let patientExist = await CheckIfPatientWithSameNameExistInRoom();

      if (patientExist) {
        toast.info("Patient with same name and email already join the room!");
        // setIsLoading(false)
        handleSmallScreen(true);
        addPatient(firstName, lastName, email, patientExist);
      } else {
        handleSmallScreen(true);
        addPatient(firstName, lastName, email);
      }
      getProviderStatuss();
    }
  };

  return (
    <>
      {/* {callWeb ? (
        <></>
        // <BrowserNotification
        //   title={"Telxy"}
        //   tag={"Alert"}
        //   icon={logo && logo}
        //   body={"Provider accept your request"}
        //   // timeout={1500}
        //   // onClick={";p"}
        // />
      ) : null} */}
      <div
        className={patientJoiningPopup ? "modal fade show" : "modal"}
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-modal={patientJoiningPopup ? "true" : "false"}
        aria-labelledby="staticBackdropLabel"
        aria-hidden={patientJoiningPopup ? "true" : "false"}
        style={
          patientJoiningPopup
            ? {
                display: "block",
                paddingRight: "17px ",
                background: "rgba(18, 18, 18, 0.68)",
              }
            : { display: "none" }
        }
        role={patientJoiningPopup ? "dialog" : null}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Telxy
              </h5>
            </div>
            <div className="modal-body">
              {togglePopupScreen ? (
                <div style={{ textAlign: "center" }}>
                  <h5
                    className="modal-title pb-2 boldcenter"
                    id="exampleModalLabel"
                  >
                    Please wait for your turn and doctor will let you in.
                  </h5>
                  <p className={online ? "online mb-3" : "offline mb-3"}>
                    {" "}
                    {online ? (
                      <b>
                        Dr.{capitalizeFirst(room_Name)}{" "}
                        <span className="fas fa-circle text-success"></span>
                      </b>
                    ) : (
                      <b>Dr.{capitalizeFirst(room_Name)} Offline</b>
                    )}
                  </p>
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <h5 className={online ? "online mt-3" : "offline mt-3"}>
                    {" "}
                    {online ? (
                      <>
                        <b>Dr.{capitalizeFirst(room_Name)}</b>{" "}
                        <i class="fas fa-circle text-success"></i>
                      </>
                    ) : (
                      <>
                        <b>Dr.{capitalizeFirst(room_Name)} is Offline</b>{" "}
                        <i class="fas fa-circle text-success"></i>
                      </>
                    )}
                  </h5>
                  <h6 className="mt-4">
                    Please enter your information to join provider.
                  </h6>
                </div>
              )}

              <div className="d-flex mt-4 justify-content-between align-items-center switchflex">
                <div
                  className={
                    onJoinsSmallScreenClass
                      ? "modal-width-60 joined"
                      : "modal-width-60"
                  }
                >
                  <div className="row">
                    <div className="col-sm-12 mobileModal">
                      <div className="m-3">
                        <div style={{ textAlign: "center" }}></div>
                        <div className="form-group mb-2">
                          <label>First Name*</label>
                          <span
                            className=" ml-1"
                            style={{
                              fontSize: "11px",
                              color: "red",
                              fontFamily: "system-ui",
                            }}
                          >
                            {" "}
                          </span>
                          <input
                            type="text"
                            disabled={togglePopupScreen}
                            className="form-control"
                            name="firstName"
                            onChange={onChange}
                            placeholder="First Name"
                            aria-label="firstName"
                            aria-describedby={
                              firstNameValidate ? "invalid" : "valid"
                            }
                            value={firstName.trimStart()}
                          />
                        </div>

                        <div className="form-group mb-2">
                          <label>Last Name*</label>{" "}
                          <span
                            className=" ml-1"
                            style={{
                              fontSize: "11px",
                              color: "red",
                              fontFamily: "system-ui",
                            }}
                          >
                            {" "}
                          </span>
                          <input
                            type="text"
                            disabled={togglePopupScreen}
                            className="form-control"
                            name="lastName"
                            onChange={onChange}
                            placeholder="Last Name"
                            aria-label="firstName"
                            aria-describedby={
                              lastNameValidate ? "invalid" : "valid"
                            }
                            value={lastName.trimStart()}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label>Email* </label>
                          <span
                            className=" ml-1"
                            style={{
                              fontSize: "11px",
                              color: "red",
                              fontFamily: "system-ui",
                            }}
                          ></span>
                          <input
                            disabled={togglePopupScreen}
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={onChange}
                            placeholder="Email"
                            aria-label="email"
                            aria-describedby={error ? "invalid" : "valid"}
                            value={email}
                            onBlur={() => {
                              if (email) isValidEmail();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {togglePopupScreen ? (
                  <WebComponentWebCompon
                    onJoinsSmallScreenClass={onJoinsSmallScreenClass}
                    togglePopupScreen={togglePopupScreen}
                  />
                ) : (
                  <div
                    className={
                      onJoinsSmallScreenClass
                        ? "flex-fill modal-video joined"
                        : "flex-fill modal-video "
                    }
                  >
                    <i className="fas fa-video text-white"></i>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              {togglePopupScreen ? undefined : (
                <>
                  <div className="modal-footer mt-1">
                    <button
                      type="button"
                      className="btn btn-primary mt-2"
                      onClick={() => closeModel(false)}
                    >
                      Cancel
                    </button>

                    <button
                      style={{
                        display: "inline-flex",
                        width: "92px",
                        height: "35px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      type="button"
                      className="btn btn-primary  mt-2"
                      disabled={error}
                      onClick={(e) => {
                        onJoinCallThisFN();
                      }}
                    >
                      Join {isLoading ? <Loader small={true} /> : undefined}{" "}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
