import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AllAcceptedPatients,
  AllPatientList,
  startCounterRemianing,
  stopCounterRemaining,
} from "../../../Actions/Actions";
import ClockTimer from "../../../Assets/images/TimerClock.png";
import MyStopwatch from "../../../Common/CallDuration";
import icon from "../../../Assets/images/IconList.svg";
import { getParticpantMin, getPatientList } from "../../../Utils/ApiManager";
import userAvatar from "../../../Assets/images/userAvatar.svg";
import TelxyLogo from "../../../Assets/images/telxyLogo.svg";
import { SocketContext } from "../../../context/socket";
import { toast } from "react-toastify";
import store from "../../../Store/Store";
import RecModal from "./RecModal";
import CountdownApiExample from "./CountDownCunter";
const Index = ({
  EnableDisableNoiseCancellation,
  checkDeduct,
  countdownMin,
  setCountDownMin,
  updateMinApi,
  getDeviceId,
  MediaLabel,
  patMessageCounter,
  roomName,
  countRemoteParticipants,
  showMessageSection,
  toggleRightSidenav,
  showMessagesSection,
  setPatMessageCounter,
  RightBar,
}) => {
  let state = store && store.getState();
  let dispatch = useDispatch();
  const socket = useContext(SocketContext);

  let {
    LoginObject,
    PatientDataDetails,
    updateProfileImagee,
    ProivderImage,
    StartCounters,
    StopCounters,
    onDestroyDeductMins,
    StripePackagePurchaseDetailObject,
  } = useSelector((state) => state);
  const [countParticipants, setCountRemoteParticipants] = useState();
  const [messageCounter, setMessageCounter] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [title, setTitle] = useState("Dropdown");
  const [msgNotify, setMsgNotify] = useState(false);
  const [isMsgScreenOpen, setIsMsgScreenOpen] = useState(false);
  const [isOpenRecRules, setOpenRules] = useState(false);
  const [NoiseCancellationChecked, setNoiseCancellationChecked] =
    useState(true);

  const [stopCounter, setStopCounter] = useState(false);
  useEffect(() => {
    if (StartCounters) {
      dispatch(stopCounterRemaining(false));
      setStopCounter(false);
      //Call Get Api
      getAPi();
    }
  }, [StartCounters]);
  useEffect(() => {
    if (StopCounters) {
      setStopCounter(true);

      dispatch(startCounterRemianing(false));
    }
  }, [StopCounters]);

  const toggleChecked = (event) => {
    const { checked } = event.target;
    setNoiseCancellationChecked(checked);
    EnableDisableNoiseCancellation(false, null, checked);
  };

  const getAPi = async () => {
    let res = await getParticpantMin();
    if (res && res.data) {
      const filteredArr = res.data.filter(
        (item) => item.user_id === LoginObject?.id
      );
      if (filteredArr && filteredArr.length > 0) {
        setCountDownMin(filteredArr[0]?.totalMinConsumed * 60000);
      }
    }
  };

  const stateEmptyOnCompletion = () => {
    dispatch(startCounterRemianing(false));
    // setStopCounter(true);
  };
  useEffect(() => {
    let arr = [
      ...Array(countRemoteParticipants && countRemoteParticipants)
        .fill(0)
        .map((x) => ({ Avatar: x })),
    ];
    setCountRemoteParticipants(arr);
  }, [countRemoteParticipants]);
  useEffect(() => {
    if (showMessagesSection) {
      setMsgNotify(false);
      setIsMsgScreenOpen(showMessagesSection);
    } else {
      setIsMsgScreenOpen(showMessagesSection);
    }
    console.log("effect trigger here ", showMessagesSection);
  }, [showMessagesSection]);

  useEffect(() => {
    if (!LoginObject.username && !isMsgScreenOpen) {
      console.log("in Effect");

      socket.on("messageReceiveNotification", (patientdata) => {
        notifyFunction(patientdata, isMsgScreenOpen);
      });
    } else if (isMsgScreenOpen && !LoginObject.username) {
      socket.off("messageReceiveNotification");
    }
  }, [isMsgScreenOpen]);

  const notifyFunction = (patientdata, isMsgScreenOpenn) => {
    let PatientDataDetailss = store && store.getState()?.PatientDataDetails;

    console.log("isMsgScreenOpen", isMsgScreenOpen);

    if (
      !isMsgScreenOpenn &&
      PatientDataDetailss.patId === patientdata.id &&
      PatientDataDetailss.providerId === patientdata.providerId
    ) {
      setMsgNotify(!isMsgScreenOpen);
    }
  };
  const handleRecModalClose = () => {
    setOpenRules(false);
  };
  const openRecRulesPanel = () => {
    setOpenRules(true);
  };
  let imageAvatar;
  if (typeof updateProfileImagee === "string") {
    imageAvatar = process.env.REACT_APP_IMAGE_BASE_PATH + updateProfileImagee;
  } else {
    if (ProivderImage) {
      imageAvatar = process.env.REACT_APP_IMAGE_BASE_PATH + ProivderImage;
    } else {
      imageAvatar = userAvatar;
    }
  }
  let recordingCheck =
    StripePackagePurchaseDetailObject &&
    StripePackagePurchaseDetailObject?.recordings &&
    StripePackagePurchaseDetailObject?.recordings?.recordingEnbled;
  const pkg = StripePackagePurchaseDetailObject?.packageName;

  return (
    <>
      {isOpenRecRules ? <RecModal onClose={handleRecModalClose} /> : null}
      <div className="chat-header">
        {LoginObject.username ? null : (
          <div>
            <img src={TelxyLogo} alt="Telxy" width={50} />
          </div>
        )}

        <div className="user-details">
          <div className="d-lg-none ml-2">
            <ul className="list-inline mt-2 mr-2">
              <li className="list-inline-item">
                <a className="text-muted px-0 left_side" data-chat="open">
                  <i className="fas fa-arrow-left"></i>
                </a>
              </li>
            </ul>
          </div>
          <figure className="avatar ml-1">
            <img
              id="providerAvatar"
              src={imageAvatar}
              className="rounded-circle"
              alt="image"
            />
          </figure>
          <div className="d-flex flex-column">
            <h5>{roomName}</h5>
            <p className="text-gray JustPatientSide">
              Welcome to Dr {roomName}
            </p>
          </div>
        </div>
        {/* {pkg == "silver" || (pkg == "metal" && !LoginObject?.id) ? null : (
          <div className="d-flex mt-3 ml-2">
            <label className=" d-flex toggle mt-0 ml-2 " htmlFor="myToggle">
              {" "}
              No{" "}
            </label>
            <label className=" d-flex toggle mt-0 ml-2 " htmlFor="myToggle">
              <input
                className="toggle__input  ml-5"
                name=""
                type="checkbox"
                checked={NoiseCancellationChecked}
                onChange={toggleChecked}
                id="myToggle"
              />
              <div className="toggle__fill"></div>
            </label>
            <label className=" d-flex toggle mt-0 ml-2 " htmlFor="myToggle">
              {" "}
              Yes
            </label>
          </div>
        )} */}
        <div className="d-flex">
          {" "}
          {LoginObject && LoginObject.username ? (
            <>
              {recordingCheck ? (
                <>
                  {" "}
                  <span className="material-icons" onClick={openRecRulesPanel}>
                    <i
                      className="fas fa-record-vinyl"
                      style={{ fontSize: "33px", color: "#4b49ac" }}
                    ></i>
                  </span>
                </>
              ) : (
                <></>
              )}

              {/* <div className="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={showDropdown}
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  Camera
                </button>
                <div
                  className={`dropdown-menu ${showDropdown ? "show" : ""}`}
                  aria-labelledby="dropdownMenuButton"
                >
                  {MediaLabel &&
                    MediaLabel.map((el) => {
                      return (
                        <a
                          className="dropdown-item"
                          onClick={() => getDeviceId(el.deviceId)}
                        >
                          {el.deviceLabel}
                        </a>
                      );
                    })}
                </div>
              </div> */}

              <div className="ml-3  mr-3">
                {countParticipants &&
                  countParticipants.length > 0 &&
                  countParticipants.map((el) => {
                    return (
                      <div className="avatar avatar-xs group_img group_header">
                        <img
                          className="avatar-img rounded-circle"
                          alt="User Image"
                          src={userAvatar}
                        />
                      </div>
                    );
                  })}

                <div
                  className="avatar avatar-xs group_img group_header usersHeaderCustom"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Open Patient List"
                  onClick={() => toggleRightSidenav()}
                >
                  {/* <span className="avatar-title rounded-circle">+</span> */}
                  <img src={icon} alt="+" />
                </div>
              </div>
            </>
          ) : undefined}
          <div className="chat-options">
            <ul className="list-inline">
              <li className="list-inline-item in-a-call d-flex align-items-center ">
                {LoginObject && LoginObject.username ? (
                  <>
                    <div className="position-relative">
                      {" "}
                      <i
                        className="fas fa-user-clock bg-primary p-2 mr-2 text-white rounded d-flex"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Room Time"
                      >
                        {" "}
                        <span className="ml-1">
                          <MyStopwatch />{" "}
                        </span>
                      </i>
                    </div>
                    {countdownMin != 0 ? (
                      <div className="position-relative">
                        {" "}
                        <i
                          className="fas fa-user-clock bg-primary p-2 mr-2 text-white rounded d-flex"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remaining Call Minutes"
                        >
                          {" "}
                          <span className="ml-1">
                            <CountdownApiExample
                              checkDeduct={checkDeduct}
                              stateEmptyOnCompletion={stateEmptyOnCompletion}
                              updateMinApi={updateMinApi}
                              stopCounter={stopCounter}
                              counterMilliseconds={countdownMin}
                            />{" "}
                          </span>
                        </i>
                      </div>
                    ) : null}
                  </>
                ) : undefined}
                {/* <span className="icon-call">
                                <i className="fas fa-phone-alt"></i>
                            </span> */}
                {!LoginObject.username ? (
                  <i
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Messege"
                    className="fas fa-comment-dots bg-primary p-2 text-white rounded"
                    onClick={() => showMessageSection(!showMessagesSection)}
                  ></i>
                ) : null}

                {msgNotify ? (
                  <span class="position-absolute  start-0 translate-middle badge rounded-pill badge-danger">
                    <span>1+</span>
                  </span>
                ) : null}

                {/* <span className="call-text">in call : 14</span> */}
              </li>
              <li className="list-inline-item">
                {/* For Mobile Screeen  */}
                {/* <a
                onClick={() => setRightBar(!RightBar)}
                className="add-person-call no-bg d-flex align-items-center dream_profile_menu"
              >
                {LoginObject && LoginObject.username ? (
                  <span className="icon-call">
                    <i className="fas fa-user-plus"></i>
                  </span>
                ) : undefined}
              </a> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
