import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import AudioCircle from "./AudioCircle";

function DefaultAudio({ loader, text,circle,AudioName}) {
  
  return (
    <div className="Default_audio">

      {circle?<><AudioCircle AudioName={AudioName} /></>:
        text ?
          <div style={{ color: '#eee' }}>
            {text}
          </div>
          :
          <>
            {!loader ? (
              <i className="fas fa-video-slash text-white"></i>
            ) : (
              <PulseLoader color="#36d7b7" />
            )}
          </>
      }
    </div>
  );
}

export default DefaultAudio;
