import React, { useEffect, useState } from "react";
import Modal from "../../../Common/Modal";
import cart from "../../../Assets/images/cart.jpg";
import logo from "../../../Assets/images/telxyLogo.svg";
import axios from "axios";
import Loader from "../../../Common/Spinner";
import "../../../Assets/css/cofirmationBill.css";
import { useSelector } from "react-redux";
import capitalizeFirst from "../../../Common/CaptilizeFirstLetter";
import {
  AddPaymentEventsToDb,
  getPaymentIntentFromSubId,
  get_Latest_Invoice,
} from "../../../Utils/ApiManager";

export default function ConfirmationBill({ closeModel, name, packagename }) {
  const [paymentEvents, setPaymentEvents] = useState([]);
  const [created, setCreated] = useState();
  const [endPeriod, setPeriodEnd] = useState();
  const [nextPaymentAttemp, setNextPaymentAttempt] = useState();
  const [invoiceDetail, setinvoiceDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [mapLines, setMapLines] = useState([]);

  const { SubscriptionList, LoginObject, Latest_Invoice } = useSelector(
    (state) => state
  );
  useEffect(() => {
    console.log("mapLines", mapLines);
    if (mapLines && mapLines.data) {
      mapLines.data.map((el) => {
        finalInvoice(el);
      });
    }
  }, [mapLines]);

  useEffect(() => {
    if (Latest_Invoice) {
      recepitAfterupgradation(name);
    }
  }, [Latest_Invoice]);
  useEffect(() => {
    if (paymentEvents.length > 0) {
      saveTransaction(name);
    }
  }, [paymentEvents]);

  const recepitAfterupgradation = async (name) => {
    if (name === "upgrade" || name === "Downgrade") {
      get_Latest_Invoicee();
    }
  };
  // const getInvoiceLines = async () => {
  //   setIsLoading(true);
  //   try {
  //     let obj = {
  //       customerId: SubscriptionList[0].customer,
  //     };
  //     let url = process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoiceLines";
  //     axios.post(url, obj).then((res) => {
  //       setIsLoading(false);
  //       let invoiceDetailEvents = PaymentEvents(res.data && res.data.lines);

  //       setPaymentEvents(invoiceDetailEvents);

  //       // setCreated(result);
  //     });
  //   } catch (err) {
  //     setIsLoading(false);
  //   }
  // };
  const PaymentEvents = (events) => {
    let res = events.data.map((el) => {
      let obj = {
        description: el.description,
        Qty: el.plan.interval_count,
        amount: el.amount / 100,
      };
      return obj;
    });
    return res;
  };
  const timeStampIntoDate = (createdTimeStamp) => {
    let date = new Date(createdTimeStamp * 1000);

    return date.toLocaleString();
  };
  const get_Latest_Invoicee = () => {
    let obj = {};
    obj.invoiceId = Latest_Invoice;
    get_Latest_Invoice(obj).then((res) => {
      setIsLoading(false);
      let startPeriod = timeStampIntoDate(res.data.hostedInvoice.period_start);

      setMapLines(res.data.hostedInvoice.lines);

      let periodEnd = timeStampIntoDate(res.data.hostedInvoice.period_end);

      setPeriodEnd(periodEnd);

      setCreated(startPeriod);
      let invoiceDetailEvents = PaymentEvents(
        res.data && res.data.hostedInvoice.lines
      );

      setPaymentEvents(invoiceDetailEvents);
      setinvoiceDetails(res.data.hostedInvoice);
    });
  };

  const finalInvoice = (el) => {
    let a = el.description;
    let extracted = a.match(/Metal|Silver|GOLD/g);
    let formData = new FormData();
    formData.append("prorationSenario", extracted);
    formData.append("description", el.description);
    formData.append("subscriptionId", invoiceDetail.subscription);
    formData.append("amount", el.amount / 100);
    formData.append("userId", LoginObject.id);
    formData.append("qty", "1");
    formData.append("cusId", invoiceDetail.customer);
    formData.append("customerEmail ", invoiceDetail.customer_email);

    let url = process.env.REACT_APP_APIS + "setprorationSenario=1";
    axios.post(url, formData).then((res) => {});
  };
  // const getInvoiceRecords = async () => {
  //   setIsLoading(true);
  //   try {
  //     let obj = {
  //       customerId: SubscriptionList[0].customer,
  //     };
  //     let url =
  //       process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoicePayments";
  //     axios.post(url, obj).then((res) => {
  //       setIsLoading(false);
  //       setinvoiceDetails(res.data.invoiceDetails);
  //       let startPeriod = timeStampIntoDate(
  //         res.data.invoiceDetails.period_start
  //       );

  //       // let invoiceDetailEvents = await PaymentEvents(res.data.invoiceDetails.lines);
  //       let periodEnd = timeStampIntoDate(res.data.invoiceDetails.period_end);
  //       //setNextPaymentAttempt(currentperiod);
  //       //  setPaymentEvents(invoiceDetailEvents);
  //       setPeriodEnd(periodEnd);

  //       setCreated(startPeriod);
  //     });
  //   } catch (err) {
  //     setIsLoading(false);
  //   }
  // };

  const saveTransactionHistory = async (el) => {
    let formData = new FormData();
    formData.append("created_at", created);
    formData.append("expiryDate", endPeriod);
    formData.append("currency", "");
    formData.append("packageName", "Silver");
    formData.append("amount", el.amount);
    formData.append("recieptUrl", "");
    formData.append("customerId", name);
    formData.append("transactionId", "nul;");
    formData.append("userId", "null");
    formData.append("upgradeStatus", "null");
    try {
      await axios
        .post(process.env.REACT_APP_APIS + "save-transaction=1", formData)
        .then(function (response) {
          setIsLoading(false);
        });
    } catch (err) {}
  };
  const saveTransaction = (name) => {
    //saveTransactionHistory(paymentEvents[1]);
    paymentEvents.map(async (el) => {
      let Invoiceobj = invoiceDetail && invoiceDetail;

      let formData = new FormData();
      formData.append("customer", name);
      formData.append("customerEmail", Invoiceobj.customer_email);
      formData.append("status", LoginObject.username);
      formData.append("is_deleted", 0);
      formData.append("paymentMethod", Invoiceobj.collection_method);
      formData.append("subId", Invoiceobj.subscription);
      formData.append("currentPeriod", created);
      formData.append("endPeriod", endPeriod);
      formData.append("country", Invoiceobj.account_country);
      formData.append("description", el.description);
      formData.append("qty", el.Qty);
      formData.append("unitPrice", el.amount);
      formData.append("amount", el.amount);
      formData.append("total", Invoiceobj.total / 100);
      formData.append("amountDue", Invoiceobj.amount_due / 100);
      try {
        AddPaymentEventsToDb(formData, LoginObject.username).then(
          (response) => {}
        );
      } catch (err) {
        // toast.info("Something Went Wrong");
      }
    });
  };
  const replaceTerms = (str) => {
    let strTerm = str;
    if (strTerm.includes("Unused time")) {
      strTerm = strTerm.replace("Unused time", "Remaining amount left");
    }
    if (strTerm.includes("Remaining time")) {
      strTerm = strTerm.replace("Remaining time", "total amount charge");
    }
    if (strTerm.includes("1 ×")) {
      strTerm = strTerm.replace("1 ×", "Next Month charge on");
    }

    return strTerm;
  };

  return (
    <>
      <Modal
        title={"Invoice"}
        close={() => closeModel(false)}
        removeMarginTop="remove"
      >
        {isLoading ? (
          <Loader small={false} large={true} />
        ) : (
          <>
            <body>
              <div
                className="page "
                // style={{ marginBottom: "-15.7rem !important" }}
                size="A4"
              >
                <div className="p-3">
                  <section className="top-content bb d-flex justify-content-between">
                    <div className="logo">
                      <img
                        src={logo}
                        alt=""
                        style={{ height: "2rem" }}
                        className="img-fluid"
                      />
                    </div>
                    {/* <div className="top-left">
                      <div className="graphic-path">
                        <p>Customer</p>
                      </div>
                      <div className="position-relative">
                        <p>
                          Customer ID.{" "}
                          <span>{invoiceDetail && invoiceDetail.customer}</span>
                        </p>
                      </div>
                    </div> */}
                  </section>

                  <section className="store-user mt-1">
                    <div className="col-12">
                      <div className="row justify-content-between w-100">
                        <div className="col-7">
                          <p>User,</p>
                          <h2>{capitalizeFirst(LoginObject.username)}</h2>
                          {/* <div className="txn mt-2">
                            SubId:
                            <b>
                              {invoiceDetail && invoiceDetail.subscription}
                            </b>{" "}
                          </div> */}
                          <div className="txn mt-2 ">
                            Created:<b className="ml-1">{created}</b>{" "}
                          </div>
                          <div className=" d-flex" >
                            <div className=" txn mt-2"> Period:</div>
                           <div className="mt-1 ml-1">
                              <span className="badge badge-warning badge-pill px-25 ">
                                {created}
                              </span>
                              <span className="">to</span>
                              <span className="badge badge-warning badge-pill px-25 ">
                                {endPeriod}
                              </span>
                              </div>
                          </div>
                          <div className="txn mt-2">
                            Download:
                            <a 
                              href={invoiceDetail && invoiceDetail.invoice_pdf}
                              download="myInvocie.pdf"
                            >
                              <i
                                class="fa fa-download ml-1 "
                                style={{ marginRight: "4px",color:'GrayText'}}
                              ></i>
                              Download
                            </a>
                          </div>
                        </div>
                        <div className="col-4">
                          <p>{name}</p>
                          <h2>{capitalizeFirst(packagename)}</h2>
                          <div className="txn mt-2">
                            Customer :{" "}
                            <b>
                              {invoiceDetail && invoiceDetail.customer_name}
                            </b>{" "}
                          </div>

                          <div className="txn mt-2">
                            Customer Email:
                            <b>
                              {invoiceDetail && invoiceDetail.customer_email}
                            </b>{" "}
                          </div>
                          <div className="txn mt-2">
                            Country:
                            <b>
                              {invoiceDetail && invoiceDetail.account_country}
                            </b>{" "}
                          </div>
                          {/* <div className="txn mt-2">
                            Payment Method:
                            <b>
                              {invoiceDetail &&
                              invoiceDetail.collection_method ==
                                "charge_automatically"
                                ? "Stripe will automatically attempt to charge the customer when downgradation/upgradation happens"
                                : ""}
                            </b>{" "}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="product-area mt-4 scrollit">
                    <table className="table table-hover ">
                      <thead>
                        <tr>
                          <td>Item Description</td>
                          <td>Quantity</td>
                          <td>Price</td>

                          {/* <td>Total</td> */}
                        </tr>
                      </thead>
                      <tbody>
                        {paymentEvents && paymentEvents.length == 0 ? (
                          <>
                            {" "}
                            <Loader small={false} large={true} />
                          </>
                        ) : (
                          paymentEvents.map((el, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {replaceTerms(el.description)}
                                    </div>
                                  </div>
                                </td>
                                <td>{el.Qty}</td>
                                <td>
                                  <i class="fas fa-dollar-sign text-primary"></i>
                                  {el.amount < 0
                                    ? Math.abs(el.amount)
                                    : Math.abs(el.amount)}
                                </td>
                                {/* <td>${el.amount}</td> */}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </section>

                  <section className="balance-info">
                    <div className="d-flex justify-content-end">
                      <div className="text-grey-m2">
                        {/* <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                          Total
                        </div> */}

                        <div className="my-2">
                          <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                          <span className="text-600 text-90">
                            Amount Remaining
                          </span>{" "}
                          <i class="fas fa-dollar-sign text-primary"></i>
                          {Math.abs(
                            invoiceDetail && invoiceDetail.ending_balance / 100
                          )}
                        </div>

                        {/* <div className="my-2">
                          <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                          <span className="text-600 text-90">Total:</span> $
                          {invoiceDetail && invoiceDetail.total / 100}
                        </div> */}

                        <div className="my-2">
                          {/* <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "} */}
                          {/* <span className="text-600 text-90">Remaining Balance:</span>{" "}
                          <span className="badge badge-warning badge-pill px-25">
                            ${invoiceDetail && Math.abs(invoiceDetail.starting_balance / 100)}
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </section>

                  <footer>
                    <hr />
                  </footer>
                </div>
              </div>
            </body>
          </>
        )}
      </Modal>
    </>
  );
}
