import React, { useEffect } from "react";
import { useState } from "react";
import Loader from "../../../Common/Spinner";
import { get_Transaction_Api, RefundApiCall } from "../../../Utils/ApiManager";
import { useSelector,useDispatch } from "react-redux";
import RefundPopup from "./refundPopup";
import { packagePurchaseDetailObject, } from "../../../Actions/Actions";
import axios from "axios";
import { toast } from "react-toastify";
import { Packages } from "../../../Common/Packages";


export default function Refund() {
  let dispatch=useDispatch()
  const { LoginObject } = useSelector((state) => state);
  const [Refund, setRefund] = useState(0);
  const [reason, setReason] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [amount, setAmount] = useState(0);
  const [expiry, setExpiry] = useState(null);
  const [created, setCreation] = useState(null);
  const [proratedAmount, setProratedAmount] = useState(null);
  const [refundPop, setRefundPopup] = useState(false);
  const [refundSucceedPayment, setRefundSucceedPayment] = useState([]);

  useEffect(() => {
    getTransactionStripe();
  }, []);
  useEffect(() => {
    if (created && expiry) {
      let res = expiry.split("/");
      let res1 = created.split("/");
      let new_date = res[1] + "/" + res[0] + "/" + res[2];

      let new_date1 = res1[1] + "/" + res1[0] + "/" + res1[2];

      let date1 = new Date();
      let date2 = new Date(new_date);
      let date3 = new Date(new_date1);

      let diffTime = Math.abs(date2 - date1);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      let diffTime2 = Math.abs(date2 - date3);
      let diffDays2 = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));
      let proratedAmount = Math.round(amount * (diffDays / diffDays2));
      setProratedAmount(proratedAmount);
    }
  }, [expiry, created]);
  useEffect(() => {
    if (proratedAmount) {
      setRefund(proratedAmount);
    }
  }, [proratedAmount]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "refund") {
      setRefund(value);
      return;
    }
    if (name === "reason") {
      setReason(value);
      return;
    }
  };
  const getTransactionStripe = async (value) => {
    get_Transaction_Api(LoginObject.id)
      .then((res) => {
        let transactionList = res && res.data;

        if (transactionList && transactionList.length > 0) {
          let lastTransactionObject =
            transactionList[transactionList.length - 1];

          setPaymentIntent(lastTransactionObject.customerid);
          setAmount(lastTransactionObject.amount / 100);
          setExpiry(lastTransactionObject.expiryDate);
          setCreation(lastTransactionObject.created_at);
        }
      })
      .catch((err) => {});
  };
  const refundApiCall = () => {
    let obj = {};
    obj.Refund = proratedAmount;
    obj.reason = reason;
    obj.paymentIntent = paymentIntent;
    RefundApiCall(obj)
      .then((res) => {
        if (res && res.data) {
          setRefundPopup(true);
          setRefundSucceedPayment(res && res.data && res.data.refund);
          saveTransaction()
        }
      })
      .catch(() => {});
  };
  const closeModal = () => {
    setRefundPopup(false);
  };
  const saveTransaction = async () => {
    let a = Date.now();
    let z = new Date(a);
    let yyyy = z.getFullYear();
    let mm = z.getMonth() + 1;
    let dd = z.getDate();
    let formattedToday = dd + "/" + mm + "/" + yyyy;

    let formData = new FormData();
    formData.append("created_at", formattedToday);
    formData.append("expiryDate", "Free");
    formData.append("currency", "none");
    formData.append("packageName", "free");
    formData.append("amount", "0$");
    formData.append("recieptUrl", "reciept_urlFree");
    formData.append("customerId", "Cus_Free");
    formData.append("transactionId", "trans-Free");
    formData.append("userId", LoginObject?.id);
    try {
      await axios
        .post(process.env.REACT_APP_APIS + "save-transaction=1", formData)
        .then(function (response) {
          let selectedPackage = Packages.find((ele) => ele.packageName === "free");
          dispatch(packagePurchaseDetailObject(selectedPackage));
           toast.success("Free Version Enabled");
        });
    } catch (err) {}
  };
  return (
    <>
      <div className="settings-header">
        <h5>Refund Details</h5>
      </div>
      {refundPop ? (
        <RefundPopup paymentDetail={refundSucceedPayment} close={closeModal} />
      ) : null}
      {false && <Loader />}
      {!false && (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className=" border-0">
            <div
              className="row pl-3 pr-3 pt-4 CardDetailsinSettings"
              style={{ backgroundColor: "#ffff" }}
            >
              <div className="col-sm-7 border-line pb-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Refund</label>

                  <input
                    style={{ backgroundColor: "#fff" }}
                    onChange={handleChange}
                    type="number"
                    name="refund"
                    disabled
                    className="form-control"
                    value={Refund}
                    placeholder="Amount"
                    size="15"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="CardNumber">Reason</label>

                  <div className="row px-3">
                    <textarea
                      style={{ backgroundColor: "#fff" }}
                      onChange={handleChange}
                      type="text"
                      name="reason"
                      placeholder="Reason"
                      value={reason}
                      size="18"
                      id="cr_no"
                      className="form-control"
                      minLength="19"
                      maxLength="19"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 text-sm-center justify-content-center pt-4 pb-4">
                <button
                  type="submit"
                  className="btn btn-primary text-center mt-4"
                  onClick={() => refundApiCall()}
                >
                  Refund
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}