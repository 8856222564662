import React, { Component } from "react";
import { connect } from "react-redux";

class FileViewer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div
          className={
            this.props.fileExt ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? ""
              : "divblackbghide   displayblock"
          }
          style={{ zIndex: this.props.zIndex ? this.props.zIndex : "9px" }}
        >
          <div
            className="modal fade show d-block"
            id="encounterfieldadd"
            tabIndex={-1}
            style={{ zIndex: "5465654654" }}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered mt-0 pt-3  "
              style={{ maxWidth: "56rem" }}
            >
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between align-items-center">
                  <h5 className="modal-title" id="exampleModalLabel">
                    View File
                  </h5>
                  {/* <a
                    target="_blank"
                    className="p-1 btn btn-primary"
                    style={{
                      position: "absolute",
                      right: "95px",
                      marginTop: "6px",
                      height: "35px",
                      width: "31px",
                    }}
                    download
                    href={
                      this.props.fileExt === "jpeg"
                        ? this.props.file
                        : this.props.fileExt === "jpg"
                        ? this.props.file
                        : this.props.fileExt === "tiff"
                        ? this.props.file
                        : this.props.fileExt === "jfif"
                        ? this.props.file
                        : this.props.fileExt === "png"
                        ? this.props.file
                        : this.props.fileExt === "xml"
                        ? this.props.callFromMessageDetails
                          ? this.props.file
                          : this.props.file
                        : this.props.fileExt === "pdf"
                        ? this.props.file
                        : this.props.fileExt ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ? this.props.file
                        : this.props.fileExt === "html"
                        ? this.props.file
                        : this.props.fileExt === "plain"
                        ? this.props.file
                        : ""
                    }
                  >
                    <i className="fa fa-download fontsize18 textwhite cursorpointer"></i>
                  </a> */}
                  <button
                    className=" btn btn-primary"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.props.close(false)}
                  >
                    <i className="far fa-times-circle align-self-center"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row clearfix">
                    <div className="col-12">
                      <div className="card">
                        <div
                          className="row clearfix w-100"
                          style={{
                            alignSelf: "center",
                            justifyContent: "center",
                          }}
                        >
                          {this.props.fileExt === "jpeg" ||
                          this.props.fileExt === "jpg" ||
                          this.props.fileExt === "tiff" ||
                          this.props.fileExt === "jfif" ||
                          this.props.fileExt === "png" ? (
                            <img
                            style={{width:'100%',objectFit:'contain'}}
                              alt=""
                              src={
                                this.props.fileExt === "jpeg"
                                  ? this.props.file
                                  : this.props.fileExt === "jpg"
                                  ? this.props.file
                                  : this.props.fileExt === "tiff"
                                  ? this.props.file
                                  : this.props.fileExt === "jfif"
                                  ? this.props.file
                                  : this.props.fileExt === "png"
                                  ? this.props.file
                                  : ""
                              }
                              className="patientDocumentiframe"
                            />
                          ) : (
                            <iframe
                              ref={(el) => (this.componentRef = el)}
                              title="i"
                              style={{
                                width: "100%",
                                border: "none",
                                minHeight: "80vh",
                                zIndex: 999999,
                                marginRight: "10px",
                                paddingLeft: "20px",
                              }}
                              src={
                                this.props.fileExt === "xml"
                                  ? this.props.callFromMessageDetails
                                    ? this.props.file
                                    : this.props.file
                                  : this.props.fileExt === "pdf"
                                  ? this.props.file
                                  : this.props.fileExt ===
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  ? this.props.file
                                  : this.props.fileExt === "html"
                                  ? this.props.file
                                  : this.props.fileExt === "plain"
                                  ? this.props.file
                                  : ""
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, null)(FileViewer);
