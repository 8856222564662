import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import capitalizeFirst from "../../../../Common/CaptilizeFirstLetter";
import DefaultAudio from "../../../../Common/DefaultAudio";

const GroupParticpantAudio = ({
  participant,
  isVoiceMute,
}) => {
  const [audioTracks, setAudioTracks] = useState([]);
  const [isAudioEnabaled, setIsAudioEnabaled] = useState(true);


  const audioRef = useRef();


  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  // audio useefects ***
  useEffect(() => {
    if (participant && participant.audioTracks) {
      setAudioTracks(trackpubsToTracks(participant.audioTracks));
    }
    console.log("remoteParticipants", participant);

    const trackSubscribed = (track) => {
      if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };
    if (participant) {
      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);
    }
    return () => {
      setAudioTracks([]);
    };
  }, [participant]);
  // end of useeffect

  //useeffect 2
  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);
  // end of useefect 2

  // useefect 3
  useEffect(() => {
    if (audioTracks[0]) setIsAudioEnabaled(audioTracks[0]?.isEnabled);
  }, [audioTracks, audioTracks[0]?.isEnabled, participant]);
  // eend of 3rd useefect

  return (
    <>
     {/* audio enabale condition */}
     {!isAudioEnabaled && (
        <>
          <i
            className="fas fa-microphone-slash text-white"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              zIndex: "9",
              top: "40%",
              width: "100%",
            }}
          ></i>
        </>
      )}
      {!isAudioEnabaled && (
        <>
          <i
            className="fas fa-microphone-slash text-white"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              zIndex: "9",
              top: "40%",
              width: "100%",
            }}
          ></i>
        </>
      )}
      <audio ref={audioRef} autoPlay={true} muted={isVoiceMute} />

    </>
  );
};

export default GroupParticpantAudio;
