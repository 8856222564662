import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AllAcceptedPatients,
  AllPatientList,
  AllPendingStatus,
  LoginAction,
  NotificationForCardExpire,
  onCompleteAction,
  PatientData,
  RoomForPatient,
  startCounterRemianing,
  stopCounterRemaining,
} from "../../../../Actions/Actions";
import {
  getPatientList,
  onAcceptCall,
  deletedSelectedUserFromList,
  UserStatusPendingApi,
  removeParticpant,
  getPatientLimitations,
  updatePatientLimitations,
  getParticpantMin,
} from "../../../../Utils/ApiManager";
import Loader from "../../../../Common/Loader/Loader";
import userAvatar from "../../../../Assets/images/userAvatar.svg";
// import swal from "sweetalert";
import {
  convertTo12HourFormat,
  nodeMaileSendMail,
  swalConfirmation,
  swalConfirmationforAccept,
  swalSuccessBox,
  TimeZoneConversion,
  sortPatientArr,
} from "../../../../Utils/commonFunctions";
import capitalizeFirst from "../../../../Common/CaptilizeFirstLetter";
import { SocketContext } from "../../../../../src/context/socket";
import store from "../../../../Store/Store";
import moment from "moment";

const Index = ({
  setCountDownMin,
  toggleRightSidenav,
  tabChange,
  changeRightSideTab,
  searchInput,
  joinedParticipants,
}) => {
  //global Variabnle
  let dispatch = useDispatch();
  let intervalForTime = null;
  const location = useLocation();
  let pathNameVar = location.pathname;
  const socket = useContext(SocketContext);

  const {
    LoginObject,
    RoomInfo,
    PatientInfo,
    RetreiveAllPatientList,
    isProviderCameFromEhrProv,
    AllPendingPat,
    AllPatientsAccepted,
    StripePackagePurchaseDetailObject,
    onCompletions,
  } = useSelector((state) => state);

  const [ParticipantsActive, setParticipantsActive] = useState("");

  const [isMessageButtonDisable, setIsMessageButtonDisable] = useState(0);
  const [patientList, setPatientList] = useState([]);
  const [patientList2, setPatientList2] = useState([]);
  const [toggle, setToggle] = useState("");
  const [pathName, setPathName] = useState("");
  const [id, setMatchID] = useState();
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [isLoadingPatientList, setIsLoadingPatientList] = useState(false);
  const [handleSpecificCallEnd, setHandleSpecifcCallEnd] = useState(null);
  const [effectForPatientCallEnd, seteffectForPatientCallEnd] = useState(false);
  const [formatter, setformatter] = useState([]);
  console.log(formatter, "time");

  useEffect(() => {
    if (onCompletions && AllPatientsAccepted.length > 0) {
      AllPatientsAccepted &&
        AllPatientsAccepted.map((el) => {
          thiswillEndCallWithSpecificPatient(el);
          dispatch(onCompleteAction(false));
          setCountDownMin(0);
        });
    }
  }, [onCompletions]);

  useEffect(() => {
    setPatientList(RetreiveAllPatientList);
    setPatientList2(RetreiveAllPatientList);
  }, [RetreiveAllPatientList]);
  useEffect(() => {
    // setPatientList(RetreiveAllPatientList)
    socket.on("patient-status-to-provider", (paitentObject) => {
      let tempPatientList = [...patientList];
      tempPatientList.map((pat) => {
        if (pat.id === paitentObject.id) {
          pat.active = "waiting";
        }
      });
      setPatientList(tempPatientList);
      setPatientList2(tempPatientList);
    });
  }, []);

  useEffect(() => {
    setIsMessageButtonDisable(joinedParticipants);
  }, [joinedParticipants]);

  useEffect(() => {
    if (searchInput) {
      let tempPats = [...patientList2];
      tempPats.forEach((el) => {
        el.fullName = el.fname + " " + el.lname;
      });
      tempPats = tempPats.filter(
        (el) => el.fname && el.fullName.toLowerCase().includes(searchInput)
      );
      setPatientList(tempPats);
    } else {
      setPatientList(patientList2);
    }
  }, [searchInput]);

  useEffect(() => {
    setIsLoadingPatientList(true);
    intervalForTime = setInterval(() => {
      getPatientLists();
    }, 5000);

    return () => {
      clearInterval(intervalForTime);
    };
  }, []);

  useEffect(() => {
    if (pathNameVar.length > 1 && pathName !== pathNameVar)
      setPathName(pathNameVar);
  }, [pathNameVar, toggle]);

  const getPatientLists = () => {
    let IsLoginTrue = LoginObject.username;
    if (IsLoginTrue) {
      getPatientList(IsLoginTrue)
        .then((res) => {
          let list = res && res.data;
          let reduxAllPatientList =
            store && store.getState()?.RetreiveAllPatientList;
          setPatientList(list);
          setPatientList2(list);
          if (list.length != reduxAllPatientList.length) {
            setPatientList2(list);
            dispatch(AllPatientList(list));
          }

          const results = list.filter((element) => {
            return element.active === "accepted";
          });
          let reduxAcceptedList =
            store && store.getState()?.AllPatientsAccepted;

          if (reduxAcceptedList.length != results.length) {
            dispatch(AllAcceptedPatients(results));
          }

          let pendingList = list.filter((element) => {
            return element.active === "pending";
          });
          let reduxPendingList = store && store.getState()?.AllPendingPat;

          if (pendingList.length != reduxPendingList.length) {
            dispatch(AllPendingStatus(pendingList));
          }

          setIsLoadingPatientList(false);
        })
        .catch((error) => {
          console.log("getPatientLists", error);
        });
    } else {
      getPatientList(RoomInfo)
        .then((res) => {
          let list = res.data && res.data;
          list.map((el) => {
            if (el.id === PatientInfo && el.active === "pending") {
              seteffectForPatientCallEnd(true);

              window.location.reload();
            }
          });
        })
        .catch((error) => {
          console.log("getPatientList", error);
        });
    }
  };
  useEffect(() => {
    if (seteffectForPatientCallEnd && handleSpecificCallEnd) {
      socket.emit("End-Call-With-Specific-Patient", handleSpecificCallEnd);
    }
  }, [effectForPatientCallEnd, handleSpecificCallEnd]);

  function makeShortString(text, count) {
    return text && text.slice(0, count) + (text.length > count ? "..." : "");
  }
  const startCounter = () => {
    dispatch(startCounterRemianing(true));
  };
  const PackageCheckLimitations = async () => {
    let res = await getPatientLimitations();
    let checkForLimit = false;
    let filteredArr;
    if (res && res.data) {
      filteredArr = res.data.filter(
        (item) =>
          (item.user_id === LoginObject?.id && item.noOFAttempts != 0) ||
          item.noOFAttempts == "UNLIMITED"
      );
      checkForLimit =
        filteredArr.length > 0 &&
        (filteredArr[0]?.noOFAttempts > 0 ||
          filteredArr[0]?.noOFAttempts == "UNLIMITED");
    }
    return {
      checkForLimit: checkForLimit,
      filteredArr: filteredArr[0]?.noOFAttempts,
    };
  };
  const PackageCheckMInutes = async () => {
    let checkForMinute = false;
    let resMin = await getParticpantMin();
    if (resMin && resMin.data) {
      const filteredArr1 = resMin.data.filter(
        (item) => item.user_id === LoginObject?.id && item.totalMinConsumed != 0
      );

      checkForMinute =
        filteredArr1.length > 0 && filteredArr1[0]?.totalMinConsumed > 0;
    }
    return checkForMinute;
  };
  const onAcceptPatient = async (patientObj) => {
    const pkg = StripePackagePurchaseDetailObject?.packageName;
    if (pkg == "silver" || pkg == "metal") {
      let checkForLimit = await PackageCheckLimitations();
      let checkForMinute = await PackageCheckMInutes();
      if (checkForLimit?.checkForLimit && checkForMinute) {
        onAcceptCall(patientObj)
          .then(async (res) => {
            // toast.info("Call Accepted");

            startCounter();

            let limitation = checkForLimit?.filteredArr - 1;
            let formData = new FormData();
            formData.append("id", LoginObject?.id);
            formData.append("noOFAttempts", limitation);
            formData.append("userName", null);
            updatePatientLimitations(formData);

            let obj = {};
            obj.ActiveStatus = true;
            obj.id = patientObj.id;
            if (isProviderCameFromEhrProv) {
              toggleRightSidenav();
            }

            dispatch(PatientData(patientObj));
            dispatch(RoomForPatient(patientObj.status));
          })
          .catch((error) => {
            console.log("onAcceptPatient", error);
          });
      } else {
        toast.info("Package Limitations Exceded");
        //show pop up Limitations excedded
      }
    } else {
      onAcceptCall(patientObj)
        .then(async (res) => {
          let obj = {};
          obj.ActiveStatus = true;
          obj.id = patientObj.id;
          if (isProviderCameFromEhrProv) {
            toggleRightSidenav();
          }

          dispatch(PatientData(patientObj));
          dispatch(RoomForPatient(patientObj.status));
        })
        .catch((error) => {
          console.log("onAcceptPatient", error);
        });
    }
  };
  const thiswillEndCallWithSpecificPatient = (patientObj) => {
    UserStatusPendingApi(LoginObject, patientObj)
      .then(async (res) => {
        removeParticpant(patientObj).then((res) => {});
        setHandleSpecifcCallEnd(patientObj);
        let obj = {};
        obj.ActiveStatus = false;
        obj.id = patientObj.id;
      })
      .catch((error) => {
        console.log("thiswillEndCallWithSpecificPatient", error);
      });
  };
  const showHideUpdateRow = (val, el) => {
    setMatchID(el.id);
  };
  const deleteSelectedPatient = (el) => {
    setLoadingDelete(true);

    const { id, status } = el;
    deletedSelectedUserFromList(id, status)
      .then((res) => {
        setLoadingDelete(false);
      })
      .catch((error) => setLoadingDelete(false));
    getPatientLists();
  };

  const confrimation = async (el) => {
    let swalObj = {};
    swalObj.text = "Are you sure that you want to delete this User?";
    swalObj.title = "Are you sure?";
    swalObj.icon = "warning";
    swalObj.dangerMode = true;
    swalObj.buttons = ["No ", "Yes"];

    let willDelete = await swalConfirmation(swalObj);

    if (willDelete) {
      deleteSelectedPatient(el);
      getPatientLists();
      // let head = "Deleted!";
      // let body = "User has been deleted!";
      // let status = "success";

      // swalSuccessBox(head, body, status);
    }
  };

  const confirmationForPatientEndCall = async (el) => {
    let swalObj = {};
    swalObj.text = "Are you sure that you want to End Call with this User?";
    swalObj.title = "Are you sure?";
    swalObj.icon = "warning";
    swalObj.dangerMode = true;
    swalObj.buttons = ["No ", "Yes"];

    let willEndCall = await swalConfirmation(swalObj);

    if (willEndCall) {
      let obj = el;
      obj.roomName = LoginObject.username;
      obj.patId = el.id;
      obj.joiningUser = el.lname + el.fname;
      dispatch(stopCounterRemaining(true));
      await thiswillEndCallWithSpecificPatient(obj);

      // await deleteSelectedPatient(el);
      // nodeMaileSendMail()

      // let head = "Ended!";
      // let body = "Call is Ended";
      // let status = "success";

      // swalSuccessBox(head, body, status);
    }
  };
  const confirmationForPatientAcceptCall = async (el) => {
    //First check PackageName ad then  get Limitations and then minus one Partpcipant
    let swalObj = {};
    swalObj.text =
      "Are you sure that you want to start call with this patient?";
    swalObj.title = "Are you sure?";
    swalObj.className = "success";
    swalObj.icon = "success";
    swalObj.confirmButtonColor = "#198754";
    swalObj.buttons = ["No ", "Yes"];
    let willEndCall = await swalConfirmationforAccept(swalObj);

    if (willEndCall) {
      await onAcceptPatient(el);
    }
  };
  const timeConversionto12Hrs = (t) => {
    let date = t;
    let options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <>
      <div
        role="tabpanel"
        aria-hidden={tabChange == "Participants" ? "false" : "true"}
        className={
          tabChange == "Participants"
            ? "fade tab-pane active show"
            : "fade tab-pane"
        }
      >
        <div>
          {isLoadingPatientList ? (
            <Loader large={true} />
          ) : (
            <>
              {patientList && patientList.length > 0 ? (
                sortPatientArr(patientList).map((el, index) => {
                  return (
                    <div className="participants-list" key={index}>
                      <ul
                        className="user-list mt-2"
                        onMouseOver={() => showHideUpdateRow(true, el)}
                        onMouseOut={() => showHideUpdateRow(false, el)}
                      >
                        <li
                          onClick={() => setParticipantsActive("index2")}
                          className={
                            ParticipantsActive == "index2"
                              ? "d-flex Active"
                              : "d-flex"
                          }
                        >
                          <div>
                            {el.active === "accepted" ? (
                              <div className="avatar avatar-online">
                                <img
                                  src={userAvatar}
                                  className="rounded-circle"
                                  alt="image"
                                />
                              </div>
                            ) : el.active === "waiting" ? (
                              <div className="avatar avatar-waiting">
                                <img
                                  src={userAvatar}
                                  className="rounded-circle"
                                  alt="image"
                                />
                              </div>
                            ) : (
                              <div className="avatar avatar-offline">
                                <img
                                  src={userAvatar}
                                  className="rounded-circle"
                                  alt="image"
                                />
                              </div>
                            )}
                          </div>
                          <div className="users-list-body d-flex justify-content-between">
                            <div style={{ overflow: "auto" }}>
                              <h5>
                                {capitalizeFirst(el.fname) +
                                  " " +
                                  capitalizeFirst(el.lname)}
                              </h5>
                              {el.active === "accepted" ? (
                                <div className="d-flex justify-content-between">
                                  <p className="fw-bolder pr-3">
                                    {" "}
                                    {el.active}{" "}
                                  </p>
                                  <p className="text-secondary">
                                    <i className="fas fa-clock "></i>{" "}
                                    {TimeZoneConversion(el.datetime)}
                                  </p>
                                </div>
                              ) : el.active === "waiting" ? (
                                <div className="d-flex justify-content-between">
                                  <p className="fw-bolder pr-3">waiting</p>
                                  <p className="text-secondary">
                                    <i className="fas fa-clock "></i>{" "}
                                    {TimeZoneConversion(el.datetime)}
                                  </p>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-between">
                                  <p className="fw-bolder pr-3"> pending </p>
                                  <p className="text-secondary">
                                    <i className="fas fa-clock"></i>{" "}
                                    {TimeZoneConversion(el.datetime)}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>

                          {el.id === id ? (
                            <div className="d-flex justify-content-end mt-1">
                              {el.active === "accepted" ? (
                                <i
                                  className="fas fa-phone-slash p-2 m-2 btn-danger text-white rounded"
                                  onClick={() =>
                                    confirmationForPatientEndCall(el)
                                  }
                                ></i>
                              ) : (
                                <>
                                  {el.active != "pending" ? (
                                    <>
                                      {" "}
                                      <i
                                        className="fas fa-phone-alt p-2 m-2 btn-success text-white rounded"
                                        onClick={() =>
                                          confirmationForPatientAcceptCall(el)
                                        }
                                      ></i>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}

                              {isMessageButtonDisable > 1 ? undefined : (
                                <i
                                  className="far fa-comments p-2 m-2 btn-primary text-white rounded"
                                  onClick={() =>
                                    changeRightSideTab("Messages", el)
                                  }
                                >
                                  {" "}
                                </i>
                              )}
                              {el.active === "accepted" ? null : (
                                <i
                                  className={
                                    isLoadingDelete
                                      ? "p-2 m-2 btn-danger text-white rounded"
                                      : "fa fa-trash-alt p-2 m-2 btn-danger text-white rounded "
                                  }
                                  onClick={() => confrimation(el)}
                                >
                                  {isLoadingDelete ? (
                                    <div className="circleLoading"></div>
                                  ) : undefined}
                                </i>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </div>
                  );
                })
              ) : (
                <> No patient join you yet</>
              )}{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Index;
