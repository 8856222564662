import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  dateCheck,
  FilterArray,
  FilterArray2,
  getTomorrowDate,
  searchDataBetweenDates,
} from "../../../Utils/commonFunctions";
import moment from "moment";
import axios from "axios";
import { ParticiptantGraph } from "./ParticpantbarGraph";
import { useSelector } from "react-redux";

export default function ParticipantGraph() {
  let LoginUser = useSelector((state) => state.LoginObject);
  const [totalRooms, setTotalRooms] = useState();
  const [dataSet, setDataSet] = useState();
  const [
    dataFilteredBetweenStartDateAndEndDate,
    setDataFilteredBetweenStartDateAndEndDate,
  ] = useState();
  const [result, setResult] = useState();
  const [keyValue, setKeyValue] = useState();
  const [days, setDays] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [roooms, setRooms] = useState();
  const [participtantGraphDataSet, setParticiptantGraphDataSet] = useState();
  const [totalPart, setTotalPart] = useState();
  const [filterDatesLabelForBarGraph, setFilterDatesLabelForBarGraph] =
    useState();
  const [onlyDatee, setonlyDate] = useState();
  useLayoutEffect(() => {
    const getTommorwwDatee = async () => {
      const { startDefaultValue, defaultValue } = await getTomorrowDate();
      setStartDate(startDefaultValue);
      setEndDate(defaultValue);
    };
    getTommorwwDatee();
  });

  useEffect(() => {
    if (endDate && startDate) {
      const searchDataBetweenDatesFiler = async () => {
        let onlyDateee = await searchDataBetweenDates(startDate, endDate);

        setFilterDatesLabelForBarGraph(onlyDateee);
        setonlyDate(onlyDateee);
      };
      searchDataBetweenDatesFiler();
      getAllRoomDates();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (roooms) {
      const dateCheckEffect = async () => {
        let res = await dateCheck(startDate, endDate, roooms);
        if (res) compareArray(res);
      };
      dateCheckEffect();
    }
  }, [roooms]);

  const dateCheck = (from, to, check) => {
    let dates = [];
    const startdate = new Date(from);
    const enddate = new Date(to);

    while (startdate <= enddate) {
      dates.push(new Date(startdate));
      startdate.setDate(startdate.getDate() + 1);
    }
    return dates;
  };
  const partciptantsCalculation = (a, b) => {
    let makingObject =
      result &&
      result.map((el) => {
        return {
          partLabel: new Date(el?.create_time).toDateString(),
          participtant:
            el.unique_participant_identities === 0 ||
            el.unique_participant_identities === null
              ? 0
              : el.unique_participant_identities - 1,
        };
      });

    let count = 0;
    let dupess =
      makingObject &&
      makingObject.reduce((a, c) => {
        let d = c.partLabel;
        a[d] = a[d] ? a[d] + 1 : 1;

        count = c.participtant + count;

        return a;
      }, {});
    let test = [];
    makingObject &&
      makingObject.map((participant) => {
        let oldFound = test.findIndex(
          (oldParticipant) => oldParticipant.partLabel === participant.partLabel
        );
        if (oldFound !== -1) {
          test[oldFound] = {
            ...test[oldFound],
            participtant:
              +test[oldFound].participtant + +participant.participtant,
          };
        } else {
          test.push(participant);
        }
      });
    let t = test.map((el) => ({
      label: moment(el.partLabel).format("MM-DD-YYYY"),
      maxParticipants: el.participtant,
    }));

    let map = {};

    keyValue && keyValue.forEach((item) => (map[item.LabelKey] = item));
    t.forEach((item) => (map[item.label] = item));
    const resultff = Object.values(map);

    let pushInParticiptantDataSetArray = [];

    resultff.map((a) => {
      if (a.maxParticipants !== undefined || null) {
        pushInParticiptantDataSetArray.push(a.maxParticipants);
      } else {
        pushInParticiptantDataSetArray.push(0);
      }
    });

    const sum = pushInParticiptantDataSetArray.reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    setTotalPart(sum);
    setParticiptantGraphDataSet(pushInParticiptantDataSetArray);
  };
  useEffect(() => {
    //MinutesGraph();
    partciptantsCalculation();
  }, [result, keyValue]);

  const compareArray = async (resdate) => {
    const { dupes, result } = await FilterArray(roooms, resdate);
    setDataFilteredBetweenStartDateAndEndDate(dupes);

    setResult(result);
    const { keyVaue, pushInDataSetArray, sum, days } = await FilterArray2(
      filterDatesLabelForBarGraph,
      dupes
    );

    setDays(days);
    setKeyValue(keyVaue);
    setTotalRooms(sum);
    setDataSet(pushInDataSetArray);
  };

  const getAllRoomDates = async () => {
    let res = await axios.get(
      process.env.REACT_APP_APIS +
        `get-room-history=1&conversationSid=${LoginUser?.username}`
    );
    const results =
      res &&
      res.data &&
      res.data.rooms.filter((element) => {
        // 👇️ using AND (&&) operator
        return element.room_name === LoginUser?.username;
      });


    setRooms(results);
  };

  const setDate = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (name == "startDate") {
      setStartDate(value);
      return;
    }
    if (name == "endDate") {
      setEndDate(value);
      return;
    }
  };

  return (
    <>
      <div className="col-md-6  stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="chartjs-size-monitor">
              <div className="chartjs-size-monitor-expand">
                <div className="font-weight-bold">
                  Total appointments
                  <hr></hr>
                </div>
              </div>
              <div className="chartjs-size-monitor-shrink">
                <div className=""></div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group mr-2 ">
                  <label htmlFor="start Date" className=" ">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control "
                    name="startDate"
                    onChange={setDate}
                    defaultValue={startDate}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="start Date">End Date</label>
                  <input
                    // value={endDate}
                    //min={endDate}
                    type="date"
                    className="form-control"
                    name={"endDate"}
                    onChange={setDate}
                    defaultValue={endDate}
                  />
                </div>
              </div>
              <div className="col-sm-2 align-self-center">
                <button
                  style={{
                    paddingtop: "8px",
                    paddingbottom: "8px",
                    paddingleft: "14px",
                  }}
                  className="btn  btn-primary search-btn-filter"
                  onClick={() => searchDataBetweenDates(startDate, endDate)}
                >
                  <i className="fas fa-search primary fa-2x	"></i>
                </button>
              </div>
            </div>
            {/* <div style={{ textAlign: "end", width: "100%" }}>
              <div className="mb-5">
                <button
                  className="btn  btn-primary"
                  onClick={() => searchDataBetweenDates(startDate, endDate)}
                >
                  Search
                </button>
              </div>
            </div> */}
            <div
              style={{
                position: "relative",
                height: "auto",
                minHeight: "18rem",
                width: "calc(100% - 1vw)",
              }}
            >
              {" "}
              <ParticiptantGraph
                dataSet={participtantGraphDataSet}
                filterDatesLabelForBarGraph={filterDatesLabelForBarGraph}
                dataFilteredBetweenStartDateAndEndDate={
                  dataFilteredBetweenStartDateAndEndDate
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
