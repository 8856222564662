import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StripeCustom.css";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { packgNameChossen, popupPurchase } from "../../../Actions/Actions";

const ProductDisplay = ({
  label,
  saveTransaction,
  disableBtn,
  buttonLabelDownUp,
  packageName,
}) => {
  return (
    <>
      <section>
        <div className="product">
          <div></div>
        </div>
        <div className="">
          {disableBtn ? (
            ""
          ) : (
            <button
            name={packageName}
              disabled={disableBtn}
              className={
                disableBtn ? "btn btn-secondary mr-4" : "btn btn-primary "
              }
              style={{ fontSize: "12px" }}
              onClick={saveTransaction}
            >
              {label === "payment"
                ? "Payment"
                : label === "subscribe"
                ? "Subscribe"
                : null}
            </button>
          )}

          {buttonLabelDownUp === "Active" ||
          buttonLabelDownUp === "empt" ? null : (
            <button
              className={"btn btn-primary "}
              name={buttonLabelDownUp}
              style={{ fontSize: "12px" }}
              onClick={(e) =>
                saveTransaction(e, packageName, buttonLabelDownUp)
              }
            >
              {buttonLabelDownUp}
            </button>
          )}
        </div>
      </section>
    </>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function NewCustomStripe({
  continueDispatch,
  onupgradeAndDowngrade,
  buttonLabelDownUp,
  disableBtn,
  callSaveTrans,
  subscription,
  label,
  setIsLoading,
  customStripeRenwal,
  packageName,
  price,
}) {
  let dispatch = useDispatch();
  const { LoginObject, ContinuePurchasing,poackage_Choosen } = useSelector((state) => state);

  const location = useLocation();

  const [message, setMessage] = useState("");

  React.useEffect(() => {
    
    if (continueDispatch) {
      console.log('rigger three time')
    purchaseProduct(continueDispatch);
    }
  }, [continueDispatch]);
  React.useEffect(() => {
    if (callSaveTrans) {
      saveTransaction();
    }
  }, [callSaveTrans]);

  const saveTransaction = async (e, arg) => {
    
    const { name } = e.target;
    dispatch(packgNameChossen(name))

    console.log(name, "name");

    if (name === "Downgrade" || name === "upgrade") {
      onupgradeAndDowngrade(e, arg, name);
      return;
    }
    // purchaseProduct()

    dispatch(popupPurchase(true));

  };

  const purchaseProduct = async (ContinuePurchasing) => {
 
    setIsLoading(true);
    let returnedVal = await customStripeRenwal();
    let newValue = returnedVal * 100;

    let url =
      process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/create-checkout-session";
    let userInfo = {};
    userInfo.username = LoginObject && LoginObject?.username;
    userInfo.FullName = LoginObject && LoginObject?.fullName;
    userInfo.id = LoginObject && LoginObject?.id;

    try {
    
      let obj = {
        subscription: subscription,
        packageName: poackage_Choosen,
        userInfoId: LoginObject && LoginObject?.id,
        loggedUser: userInfo,
        price: newValue,
        selectedPackType: ContinuePurchasing.selectedPackageValue,
      };
      

      await axios.post(url, obj).then(function (response) {
   
        let url = false;
        let sessionDetails = null;
        let ProductDetail = null;
        setIsLoading(false);
        url = response && response?.data?.url;
        sessionDetails = response && response?.data?.sessionDetails;
        ProductDetail = response && response?.data?.ProductDetail;

        if (typeof url === "string") {
          //  setTimeout( window.close(), 5000);

          window.open(url);
          window.location.reload(false);
        }
        if (typeof ProductDetail != null && typeof sessionDetails != null) {
        }
      });
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay
      saveTransaction={saveTransaction}
      disableBtn={disableBtn}
      label={label}
      buttonLabelDownUp={buttonLabelDownUp}
      packageName={packageName}
    />
  );
}
