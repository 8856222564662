import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Obj } from "../../../Common/PatientLimitations";
import RoomMessages from "./RoomMessages";
import RoomParticipants from "./RoomParticipants";
import { getIntiatedChat } from "../../../Utils/ApiManager";
import { LoginObject } from "../../../Actions/Actions";
const Index = ({
  setCountDownMin,
  showMessageSection,
  showMessagesSection,
  joinedParticipants,
  RightBar,
  toggleRightSidenav,
}) => {
  let LoginUser = useSelector((state) => state.LoginObject);
  const [tabChange, settabChange] = useState(
    LoginUser?.username ? "Participants" : "Messages"
  );
  const [SearchBar, setSearchBar] = useState(false);
  const [patientObject, setPatientObject] = useState({});
  const [searchInput, setSeachInput] = useState("");
  const [showMessageTab, setShowMessageTab] = useState();

  const { StripePackagePurchaseDetailObject } = useSelector((state) => state);
  let pkg = StripePackagePurchaseDetailObject?.packageName;
  let check = 0;
  if (pkg == "silver") {
    check = Obj?.Silver_Unique_Chat_Particpant;
  } else if (pkg == "metal") {
    check = Obj?.Metal_Unique_Chat_Particpant;
  } else if (pkg == "gold") {
    check = Obj?.Gold_Unique_Chat_Particpant;
  }
  const [noOFAttempts, setNoOfAttempts] = useState(check);
  let PatientDataDetails = useSelector((state) => state.PatientDataDetails);

  useEffect(() => {
    if (!LoginUser?.username && tabChange === "Messages") {
      setPatientObject(PatientDataDetails);
    } else if (
      LoginUser?.username &&
      tabChange === "Messages" &&
      showMessageTab > 1
    ) {
      console.log("step 1 clear fix");
      setPatientObject({});
    }
  }, [tabChange, showMessageTab]);
  console.log("this render");
  useEffect(() => {
    setShowMessageTab(joinedParticipants);
  }, [joinedParticipants]);

  useEffect(() => {
    if (LoginUser && LoginUser.username) {
      settabChange("Participants");
    }
  }, []);
  const getPatLimiatation = async (patientObj) => {
    let formData = new FormData();
    formData.append("chat_service_sid", "CH123");

    let res = await getIntiatedChat(formData);
    // let res = await axios.post(
    //   process.env.REACT_APP_APIS + `get-conversations=1`
    // );
    // get Conversation From db if packag eis metal or
    if (res && res.data.length == 0) {
      return {
        permission: noOFAttempts - 1,
        allowPart: [{ allow: "all" }],
      };
    }
    if (res && res?.data.length > 0) {
      let result = res.data.filter((el) => el.userName == LoginUser.id);

      if (result && result.length > 0) {
        if (noOFAttempts === "UNLIMITED") {
          return { permission: true, allowPart: [{ allow: "all" }] };
        } else {
          let allowParticpant = result.filter(
            (el) => el.friendly_name == LoginUser.id
          );

          return {
            permission: noOFAttempts - result.length > 0,
            allowPart: allowParticpant,
          };
        }
      }
    }
  };
  const changeRightSideTab = async (tab, patientObj) => {
    const { permission, allowPart } = await getPatLimiatation(patientObj);
    if (permission || allowPart.length > 0) {
      settabChange(tab);
      setPatientObject(patientObj);
    } else {
      toast.info("Package Limitation Exceded");
    }
  };

  const handleSearch = (e) => {
    let search = e.target.value;
    search = search.toLowerCase();
    setSeachInput(search);
  };
  const TabChange = (val) => {
    settabChange(val);
  };

  let a;
  if (tabChange !== "Participants") {
    a = (
      <>
        <form>
          <div className="smile-col p-2">
            <a>
              <i className="fas fa-smile text-primary"></i>
            </a>
          </div>
          <div className="attach-col p-2">
            <a>
              <i className="fas fa-paperclip text-primary"></i>
            </a>
          </div>
          <input
            type="text"
            className="form-control chat_form"
            placeholder="Enter Message....."
          />
          <div className="form-buttons">
            <button className="btn send-btn" type="submit">
              <span className="material-icons">
                <i className="fas fa-share text-primary"></i>
              </span>
            </button>
          </div>
          <div className="specker-col mb-0">
            <a>
              <span className="material-icons">
                <i className="fas fa-microphone text-primary"></i>
              </span>
            </a>
          </div>
        </form>
      </>
    );
  } else {
    a = <></>;
  }

  return (
    <>
      {/* {AllPatientsAccepted.length>0 &&AllPatientsAccepted && AllPatientsAccepted.map((el)=>{
     return <RoomMessages
     
      joinedParticipants={joinedParticipants}
     
      patientObject={el}
    />

    })} */}
      <div
        className={
          RightBar
            ? "right-sidebar right_sidebar_profile video-right-sidebar  hide-right-sidebar"
            : `right-sidebar right_sidebar_profile video-right-sidebar show-right-sidebar ${
                showMessagesSection ? "active-Patient" : ""
              }`
        }
        id="middle1"
      >
        <div className="chat p-0">
          <div className="right-sidebar-wrap active">
            <div
              className="slimScrollDiv"
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <div
                className="slimscroll"
                style={{
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <div className="left-chat-title d-flex justify-content-between align-items-center p-3 with-bg">
                  {LoginUser.username && tabChange == "Participants" ? (
                    <div className="Patient-Search">
                      <input
                        type="text"
                        alt="input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearch}
                      />
                      <i className="fas fa-search primary "></i>
                    </div>
                  ) : null}

                  <div className="contact-close_call text-right">
                    <a
                      onClick={toggleRightSidenav}
                      className="close_profile close_profile4"
                    >
                      <span className="material-icons">
                        <i className="fas fa-times"></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="chat-body pt-3">
                  <div className="group-call-tab">
                    <nav
                      className="nav nav-tabs"
                      activekey={
                        tabChange == "Messages" ? "Messages" : "Participants"
                      }
                      role="tablist"
                    >
                      {LoginUser && LoginUser.username ? (
                        <a
                          onClick={() => {
                            TabChange("Participants");
                            // settabChange("Participants");
                            localStorage.clear();
                            sessionStorage.clear();
                          }}
                          role="tab"
                          data-rb-event-key="Participants"
                          aria-selected={
                            tabChange == "Participants" ? "true" : "false"
                          }
                          className={
                            tabChange == "Participants"
                              ? "nav-item nav-link active"
                              : "nav-item nav-link"
                          }
                        >
                          Patients
                        </a>
                      ) : undefined}
                      {tabChange != "Participants" || showMessageTab > 1 ? (
                        <a
                          onClick={() => {
                            TabChange("Messages");
                          }}
                          role="tab"
                          data-rb-event-key="Messages"
                          aria-selected={
                            tabChange == "Messages" ? "true" : "false"
                          }
                          className={
                            tabChange == "Messages"
                              ? "nav-item nav-link active"
                              : "nav-item nav-link  "
                          }
                        >
                          {showMessageTab > 1 ? "Group Messages" : "Messages"}
                        </a>
                      ) : undefined}
                    </nav>
                    <div className="tab-content">
                      {patientObject && tabChange == "Messages" && (
                        <RoomMessages
                          showMessageSection={showMessageSection}
                          showMessagesSection={showMessagesSection}
                          joinedParticipants={joinedParticipants}
                          tabChange={tabChange}
                          patientObject={patientObject}
                        />
                      )}

                      <RoomParticipants
                        setCountDownMin={setCountDownMin}
                        toggleRightSidenav={toggleRightSidenav}
                        joinedParticipants={joinedParticipants}
                        tabChange={tabChange}
                        changeRightSideTab={changeRightSideTab}
                        searchInput={searchInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="slimScrollRail"
                style={{
                  width: "7px",
                  height: "100%",
                  position: "absolute",
                  top: "0px",
                  display: "none",
                  borderRadius: "7px",
                  background: "rgb(51, 51, 51)",
                  opacity: "0.2",
                  Zindex: "90",
                  right: "1px",
                }}
              ></div>
            </div>
            <div className="chat-footer pt-0">
              {tabChange !== "Participants" ? (
                <>
                  {/* <form>
                                            <div className="smile-col">
                                                <a  >
                                                    <i className="far fa-smile"></i>
                                                </a>
                                            </div>
                                            <div className="attach-col">
                                                <a  >
                                                    <i className="fas fa-paperclip"></i>
                                                </a>
                                            </div>
                                            <input
                                                type="text"
                                                style={{ padding: "10px 100px" }}
                                                className="form-control chat_form"
                                                placeholder="Enter Message....."
                                            />
                                            <div className="form-buttons">
                                                <button className="btn send-btn" type="submit">
                                                    <span className="material-icons">
                                                        <i className="fas fa-share"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="specker-col">
                                                <a  >
                                                    <span className="material-icons">
                                                        <i className="fas fa-microphone"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </form> */}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
