import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import StripeButton from "./Stripemethod/Stripe";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Common/Loader/Loader";
import axios from "axios";
import { Packages } from "../../Common/Packages";
import { toast } from "react-toastify";
import Card from "./card";
import Button from "./button";
import Stepper from "./Stepper";
import PackageFeatureList from "./PackageFeatureList";
import background from "../../Assets/images/background.png";
import recurring from "../../Assets/images/recurring.png";
import SubscriptionScreen from "./SubscriptionScreen";
import "./popRecurring.css";

import FreeVersionBanner from "./FreeVersionBanner";

import {
  subscriptionList,
  packagePurchaseDetailObject,
  stripePackageType,
  totalDays,
  sessionID,
  NavigateDasboardIssue,
  popupPurchase,
  continesBuying,
  Latest_Invoice_Stripe,
} from "../../Actions/Actions";
import ConfirmationBill from "./Stripemethod/ConfirmationPopupBills";
import ConfirmationUpgradationAndDowngradation from "./ConfirmationUpgradationAndDowngradation.js";
import { SocketContext } from "../../context/socket";
import Modal from "../../Common/Modal";
import {
  getParticpantMin,
  getPatientLimitations,
  insertTotalMin,
  PatientLimitations,
  roomDestroy,
  updateConsumedMinutesApi,
  updatePatientLimitations,
} from "../../Utils/ApiManager";
import { Obj } from "../../Common/PatientLimitations";

function Index({
  setTabLocationPath,
  tabLocationPath,
  stripeLatestObject,
  lastStripeTrasactionObject,
  history,
}) {
  //88888888888888old-----------------------
  const [scroll, setScroller] = useState(true);
  const handleScrollToTop = () => {
    setScroller(!scroll);
  };

  const handleScrollToTopfunction = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    handleScrollToTopfunction();
  }, [scroll]);
  //-------------------------old -----------------------
  useEffect(() => {
    dispatch(NavigateDasboardIssue(false));
  }, []);

  //-----------------------------------
  const handleReloadwithRoom = async () => {
    if (RoomSid) {
      let obj = { roomSid: RoomSid };
      await roomDestroy(obj);
    }
  };
  useEffect(() => {
    if (true) {
      window.onbeforeunload = function () {
        // return true;
        handleReloadwithRoom();
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState("6M");
  const [selectedValue, setSelectedValue] = useState("recurring");
  const [continueDispatch, setContinueDispatch] = useState();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [callSaveTrans, setCallSaveTrans] = useState(false);
  const [stepper, handleStepper] = useState(0);
  const [stepperMetal, handleStepperMetal] = useState(10);
  const [stepperSilver, handleStepperSilver] = useState(20);
  // const [paymentMode, setPaymentMode] = useState(false);
  const [subscriptionLists, setSubscriptionList] = useState(false);
  const [subscriptionListsMetal, setSubscriptionListMetal] = useState(false);
  const [subscriptionListsSilver, setSubscriptionListSilver] = useState(false);
  const [deletedSubObj, setDeletedSubObj] = useState(false);
  const [freeVersionBanner, setFreeVersionBanner] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [gmtValue, setGmtValue] = useState(false);
  const [expireDate, setExpireDate] = useState(false);
  const [confirmationPopupBill, setConfirmationPopupBill] = useState(null);
  const [handleNavigate, setHandleNavigate] = useState(false);
  const [name, setLabelName] = useState();
  const [packagename, setPackageName] = useState();
  const [openConfirmntion, setOpenConfirmation] = useState(false);
  const [val, setVal] = useState(null);
  const [packagenamee, setPackageNamee] = useState(null);
  const [nameFromBtn, setNameFromBtn] = useState(null);

  // const [paymentEvents, setPaymentEvents] = useState();
  // const [created, setCreated] = useState();
  // const [endPeriod, setPeriodEnd] = useState();
  // const [nextPaymentAttemp, setNextPaymentAttempt] = useState();
  // const [invoiceDetail, setinvoiceDetails] = useState();

  const {
    LoginObject,
    PopUpOpenForStripePurchase,

    StripePackagePurchaseDetailObject,
    SubscriptionList,
    SessionID,
    RoomSid,
  } = useSelector((state) => state);
  //************ */ For By default Bewhaviour************************
  const socket = useContext(SocketContext);
  let packageName =
    StripePackagePurchaseDetailObject &&
    StripePackagePurchaseDetailObject?.packageName;
  let checkk = packageName === "gold" ? true : false;
  let checkkSilver = packageName === "silver" ? true : false;
  let checkkSilverOne = packageName === "silverOneTime" ? true : false;
  let checkkMetal = packageName === "metal" ? true : false;
  let checkkMetalOne = packageName === "metalOneTime" ? true : false;
  let checkkGoldOne = packageName === "goldOneTime" ? true : false;

  const [paymentMode, setPaymentMode] = useState(
    checkkMetal
      ? "cancelMetal"
      : checkkSilver
      ? "cancelSilver"
      : checkk
      ? "cancelSubscription"
      : false
  );
  //************************************** */
  const metalPackagesName = [
    "Unlimited audio and video",
    "HIPAA compliant",
    "Business Associates Agreement",
    "Personalized room URL",
  ];
  const silverPackagesName = [
    "HD/SD quality",
    "Personalize waiting room",
    "Text and email alerts",
    "Photo capture",
  ];
  const goldPackagesName = [
    "White label",
    "Customize your Doxy.me ",
    "    Custom subdomain",
    "Shared rooms",
  ];
  useEffect(() => {
    socket.on("Subscription_updated", (data) => {
      console.log("Subscription_updated", data);
    });
  }, []);

  useEffect(() => {
    let obj = SubscriptionList.subscriptionLists;

    if (obj) {
    } else {
      {
        let reduxObj = SubscriptionList;
        let newObject =
          reduxObj &&
          reduxObj.map((el) => {
            let startDate = el.current_period_start;
            let EndDate = el.current_period_end;
            var date = new Date(startDate * 1000);
            var eD = new Date(EndDate * 1000);

            return {
              subId: el.id,
              startDate: date.toLocaleDateString("en-US"),
              endDate: eD.toLocaleDateString("en-US"),
              amount: el.plan.amount,
            };
          });

        let p = newObject.map((el) => el.amount);
        if (p[0] === 14900) {
          setSubscriptionList(newObject);
          return;
        }
        if (p[0] === 5000) {
          setSubscriptionListMetal(newObject);

          return;
        }
        if (p[0] === 1000) {
          setSubscriptionListSilver(newObject);
          //  setSubscriptionListMetal(newObject);
          return;
        }
      }
    }
  }, [SubscriptionList]);
  const getMonth = (val) => {
    setGmtValue(val);
  };

  // let packageName = StripePackagePurchaseDetailObject.packageName;
  let buttonLabelSilver =
    packageName === "gold"
      ? "Downgrade"
      : packageName === "silver"
      ? "Active"
      : packageName === "metal"
      ? "Downgrade"
      : "empt";
  let buttonLabelMetal =
    packageName === "gold"
      ? "Downgrade"
      : packageName === "metal"
      ? "Active"
      : packageName === "silver"
      ? "upgrade"
      : "empt";
  let buttonLabelGold =
    packageName === "gold"
      ? "Active"
      : packageName === "metal"
      ? "upgrade"
      : packageName === "silver"
      ? "upgrade"
      : "empt";

  const radioBtnValue = (e) => {
    const { checked } = e.target;
    const { name } = e.target;
    if (checked) {
      setSubscription(name);
    }
  };

  const callTrans = (val) => {
    setCallSaveTrans(val);
  };
  const handleStepperButton = (key) => {
    switch (key) {
      case "-":
        handleStepper(0);
        break;
      case "++":
        handleStepperSilver(20);
        break;
      case "--":
        handleStepperMetal(10);
        break;

      case "+":
        handleStepper(1);
        break;
      case "updtSub":
        handleStepper(2);
        break;
      case "cancelSub":
        handleStepper(3);
      default:
        break;
      case "cancelMetal":
        handleStepperMetal(4);
        break;
      case "updateMetal":
        handleStepperMetal(5);
        break;
      case "subScriptionMetal":
        handleStepperMetal(6);
        break;
      case "cancelSilver":
        handleStepperSilver(7);
        break;
      case "updateSilver":
        handleStepperSilver(8);
        break;
      case "subscriptionSilver":
        handleStepperSilver(9);
        break;
    }
  };
  const handleOneTimePayment = () => {
    setSubscription("1T");
    callTrans(true);
  };
  const handleClickOnPaymentMode = (mode) => {
    if (mode === "payment") {
      setSubscription("1T");
    }
    setPaymentMode(mode);
  };

  const deleteSub = (obj, BackSubScreen) => {
    setDeletedSubObj(obj);
    DeleteSub(BackSubScreen, obj);
  };
  const DeleteSub = (BackSubScreen, obj) => {
    try {
      //setLoading(true);
      setIsLoading(true);
      axios
        .post(
          process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/Delete-Subscription",
          obj
        )
        .then(function (response) {
          setIsLoading(false);

          let deletedRecord = response?.data?.deletedSub;

          let selectedPackage = Packages.find(
            (ele) => ele.packageName === "free"
          );

          dispatch(packagePurchaseDetailObject(selectedPackage));
          saveTransaction();
          handleStepperButton(BackSubScreen);
          toast.warning("Subscription Deleted ,Free version is activated");
          setFreeVersionBanner(true);

          dispatch(subscriptionList([]));
          dispatch(totalDays("free"));
          navigate("/dashboard");
        });
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onupgradeAndDowngrade = (val, packageNamee, name) => {
    setVal(val);
    setPackageNamee(packageNamee);
    setNameFromBtn(name);

    setOpenConfirmation(true);
    // downUpSub(val, packageNamee, name);
  };
  const getTransactionStripe = async () => {
    let transactionList = await axios.get(
      process.env.REACT_APP_APIS +
        `get-transaction=1&userId=${LoginObject && LoginObject?.id}`
    );

    let data = transactionList.data;
    if (data && data.length === 0) {
      dispatch(packagePurchaseDetailObject(Packages[0]));
    } else if (data && data.length > 0) {
      var lastTransactionObject = data[data.length - 1];

      let trans = lastTransactionObject?.packageName;
      if (
        trans === "metalOneTime" ||
        trans === "silverOneTime" ||
        trans === "goldOneTime"
      ) {
      }

      // FOR WATCH
      if (trans === "free") {
        dispatch(totalDays("free"));
      }

      let lastTransactionId = lastTransactionObject?.transactionId;

      dispatch(sessionID(lastTransactionId));

      let selectedPackage = Packages.find(
        (ele) => ele.packageName === lastTransactionObject.packageName
      );

      dispatch(packagePurchaseDetailObject(selectedPackage));
    }
  };
  const downUpSub = (eve, packageName, name, amountDue) => {
    try {
      let SessionId = SessionID;

      let obj = {
        sessionId: SessionId,
        gmtFormat: gmtValue,
        packageName: packageName,
      };

      let url =
        process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/dowgradeUpgrade";
      axios.post(url, obj).then((res) => {
        dispatch(
          Latest_Invoice_Stripe(
            res && res.data && res?.data?.dataAmount.latest_invoice
          )
        );

        saveTransactionDownGrade(
          res?.data?.subscription,
          res?.data?.sessUpdt,
          res?.data?.dataAmount,
          packageName,
          name,
          amountDue
        );
      });
    } catch (err) {}
  };
  const checkIFProviderWithLimitationAlreadyExists = async (
    packagName,
    userId
  ) => {
    let pkg = packagName;
    const limitations =
      pkg === "gold"
        ? Obj.GOLD_PATIENT_LIMITATIONS
        : pkg === "silver"
        ? Obj.SILVER_PATIENT_LIMITATIONS
        : pkg === "metal"
        ? Obj.METAL_PATIENT_LIMITATIONS
        : 0;

    let res = await getPatientLimitations();
    if (res && res.data) {
      const filteredArr = res.data.filter((item) => item.user_id === userId);

      if (filteredArr.length > 0) {
        //Run Update API
        let formData = new FormData();
        formData.append("id", userId);
        formData.append("noOFAttempts", limitations);
        formData.append("userName", null);
        updatePatientLimitations(formData);
      } else {
        let formData = new FormData();
        formData.append("id", userId);
        formData.append("noOFAttempts", limitations);
        formData.append("userName", null);

        PatientLimitations(formData);
      }
    }

    // For Consumed Minutes
    let reso = await getParticpantMin();
    const filteredArrMin = reso.data.filter((item) => item.user_id === userId);
    if (reso && reso.data) {
      let pkg = packagName;
      const minutes =
        pkg === "gold"
          ? Obj?.GOLD_MIN_LIMITATIONS
          : pkg === "silver"
          ? Obj?.SILVER_MIN_LIMITATIONS
          : pkg === "metal"
          ? Obj?.METAL_MIN_LIMITATIONS
          : Obj?.FREE_MIN_LIMITATIONS;

      if (filteredArrMin.length > 0) {
        let formData = new FormData();
        //Run Update Min API
        formData.append("userid", LoginObject?.id);
        formData.append("id", LoginObject?.id);
        formData.append("totalMinConsumed", minutes);
        updateConsumedMinutesApi(formData);
      } else {
        // Run Insert Api
        let formData = new FormData();
        formData.append("id", LoginObject?.id);
        formData.append("totalMinConsumed", minutes);
        formData.append("userName", null);
        insertTotalMin(formData);
      }
    }
  };

  const saveTransactionDownGrade = async (
    subscription,
    session,
    dataAmount,
    packageName,
    name,
    amountDue
  ) => {
    let newObj = await getInvoiceRecord(subscription.customer);

    let currentperiodStart = timeStampIntoDate(
      subscription && subscription.current_period_start
    );
    let currentperiodEnd = timeStampIntoDate(
      subscription && subscription.current_period_end
    );

    setIsLoading(true);
    let a = Date.now();
    let z = new Date(a);
    let yyyy = z.getFullYear();
    let mm = z.getMonth() + 1;
    let dd = z.getDate();
    let formattedToday = dd + "/" + mm + "/" + yyyy;
    let amount = subscription.items.data[0].price.unit_amount * 10;

    let formData = new FormData();
    formData.append("created_at", currentperiodStart);
    formData.append("expiryDate", currentperiodEnd);
    formData.append("currency", subscription.currency);
    formData.append("packageName", packageName);
    formData.append("amount", amountDue);
    formData.append("recieptUrl", "reciept_url");
    formData.append("customerId", name);
    formData.append("transactionId", session.id);
    formData.append("userId", LoginObject && LoginObject?.id);
    try {
      await axios
        .post(process.env.REACT_APP_APIS + "save-transaction=1", formData)
        .then(async (response) => {
          checkIFProviderWithLimitationAlreadyExists(
            packageName,
            LoginObject && LoginObject?.id
          );
          dispatch(stripePackageType(packageName));

          await getTransactionStripe();
          setPackageName(packageName);

          //   await recepitAfterupgradation(name);
          setLabelName(name);
          let val = await closeModel(true);

          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (handleNavigate) {
      navigate("/dashboard");

      toast.info("Package updated");
    }
  }, [handleNavigate]);

  // const timeStampIntoDate = (createdTimeStamp) => {
  //   let date = new Date(createdTimeStamp * 1000);

  //   return date.toLocaleString();
  // };
  // const recepitAfterupgradation =async (name) => {

  //   if (name === "upgrade" || name === "Downgrade") {
  //    await getInvoiceRecords();
  //   await  getInvoiceLines();
  //   }
  // };

  // const getInvoiceRecords = async () => {
  //   try {
  //     let obj = {
  //       customerId: SubscriptionList[0].customer,
  //     };
  //     let url =
  //       process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoicePayments";
  //     axios.post(url, obj).then((res) => {

  //       setinvoiceDetails(res.data.invoiceDetails);
  //       let startPeriod = timeStampIntoDate(
  //         res.data.invoiceDetails.period_start
  //       );

  //      // let invoiceDetailEvents = await PaymentEvents(res.data.invoiceDetails.lines);
  //       let periodEnd = timeStampIntoDate(res.data.invoiceDetails.period_end);
  //       //setNextPaymentAttempt(currentperiod);
  //     //  setPaymentEvents(invoiceDetailEvents);
  //       setPeriodEnd(periodEnd);

  //       setCreated(startPeriod);
  //     });
  //   } catch (err) {}
  // };

  // const getInvoiceLines = async () => {
  //   try {
  //     let obj = {
  //       customerId: SubscriptionList[0].customer,
  //     };
  //     let url = process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoiceLines";
  //     axios.post(url, obj).then((res) => {

  //       let invoiceDetailEvents =  PaymentEvents(res.data && res.data.lines);

  //       setPaymentEvents(invoiceDetailEvents);

  //       // setCreated(result);
  //     });
  //   } catch (err) {}
  // };

  // const PaymentEvents = (events) => {
  //   let res = events.data.map((el) => {
  //     let obj = {
  //       description: el.description,
  //       Qty: el.plan.interval_count,
  //       amount: el.amount / 100,
  //     };
  //     return obj;
  //   });
  //   return res;
  // };
  const timeStampIntoDate = (createdTimeStamp) => {
    let date = new Date(createdTimeStamp * 1000);

    return date.toLocaleString();
  };

  const getInvoiceRecord = async (customerId) => {
    let amount_remaining;
    let period_end;
    let objNew = {};

    let obj = {
      customerId: customerId,
    };
    let url = process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoicePayments";
    await axios.post(url, obj).then(async (res) => {
      let date = await timeStampIntoDate(res.data.invoiceDetails.period_end);
      let z = date.split(",");

      amount_remaining = res.data.invoiceDetails.amount_remaining;
      period_end = z[0];
      objNew.amount_remaining = amount_remaining;
      objNew.period_end = period_end;

      setExpireDate(obj);
    });

    return objNew;
  };

  const saveTransaction = async () => {
    let a = Date.now();
    let z = new Date(a);
    let yyyy = z.getFullYear();
    let mm = z.getMonth() + 1;
    let dd = z.getDate();
    let formattedToday = dd + "/" + mm + "/" + yyyy;

    let formData = new FormData();
    formData.append("created_at", formattedToday);
    formData.append("expiryDate", "Free");
    formData.append("currency", "none");
    formData.append("packageName", "free");
    formData.append("amount", "0$");
    formData.append("recieptUrl", "reciept_urlFree");
    formData.append("customerId", "Cus_Free");
    formData.append("transactionId", "trans-Free");
    formData.append("userId", LoginObject?.id);
    try {
      await axios
        .post(process.env.REACT_APP_APIS + "save-transaction=1", formData)
        .then(function (response) {
          // setAlertEnable(true);
          // setStripee(response);

          dispatch(stripePackageType("free"));

          // toast.success("Your package activated");
        });
    } catch (err) {}
  };

  const updateSub = () => {
    try {
      let SessionId = SessionID;

      let obj = { sessionId: SessionId, gmtFormat: gmtValue };

      let url =
        process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/updateSubscription";
      axios.post(url, obj).then((res) => {
        let resVar = res?.data?.subscriptionUpdated;
        let amount = res?.data?.packageAmount;
        if (resVar) {
          toast.warning(
            "Subscription Updated ,New Plan Cancel Date Suucessfully set"
          );

          //For toggline to main screen after update
          let key =
            amount === 5000
              ? "--"
              : amount === 14900
              ? "-"
              : amount === 1000
              ? "++"
              : null;

          handleStepperButton(key);
        }
      });
    } catch (err) {}
  };
  const closeModel = (val) => {
    setConfirmationPopupBill(val);

    setHandleNavigate(!val);
  };
  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  const AmountFortransaction = async (name) => {
    try {
      let obj = {
        subId: SubscriptionList[0].id,
        packageName: name,
      };
      let url =
        process.env.REACT_APP_APIS_GLOBAL_LOCALHOST +
        "/PreviewSubscriptionBill";
      let response = await axios.post(url, obj);
      return response;
    } catch (err) {}
  };
  const updateDowngradeSub = async (name) => {
    let response = await AmountFortransaction(name);
    let amountDue = response.data.invoice.amount_due;

    setOpenConfirmation(false);
    downUpSub(val, packagenamee, nameFromBtn, amountDue);
  };
  const closeModalPopup = () => {
    dispatch(popupPurchase(false));
    dispatch(continesBuying(false));
  };
  useEffect(() => {
    if (PopUpOpenForStripePurchase) {
      continuePurchasing();
    }
  }, [PopUpOpenForStripePurchase]);

  const continuePurchasing = () => {
    console.log("click continue");
    let obj = {};
    obj.continueBuying = true;
    obj.selectedPackageValue = "recurring";
    setContinueDispatch(obj);
    // dispatch(continesBuying(obj));
  };

  let CarOneTitle = "Subscription Cancel";
  let CardOneBody = "Trigger cancel Subscription";
  let CarTwoTitle = "Update Subscription";
  let CarTwoBody = "This will update Subscription";
  let Card3Title = "Payment";
  let Card3Body = "One Month Payment";
  let Card4Title = "New Subscription";
  let Card4Body = "Get Subscription ";
  let packageNamee =
    StripePackagePurchaseDetailObject &&
    StripePackagePurchaseDetailObject.packageName;
  let a =
    StripePackagePurchaseDetailObject &&
    StripePackagePurchaseDetailObject?.StripePurchasedPackageDetails
      ?.packageName;
  let checkForBtnDisableGold = packageNamee === "gold";
  let checkFrBtnDisableOneTimeGold = packageNamee === "goldOneTime";
  let checkForBtnDisableSilver = packageNamee === "silver";
  let checkForBtnDisableSilverOneTime = packageNamee === "silverOneTime";
  let checkForBtnDisableMetal = packageNamee === "metal";
  let checkForBtnDisableMetalOneTime = packageNamee === "metalOneTime";
  let checkForBtnDisableFree = packageNamee === "free";
  let checkForBtnDisableFree2 = a;
  let goldChck = checkk
    ? true
    : checkForBtnDisableFree
    ? true
    : checkForBtnDisableFree2
    ? true
    : checkkGoldOne || checkkMetalOne || checkkSilverOne
    ? true
    : false;
  console.log("selectedValue", selectedValue);
  let checkForBlur = StripePackagePurchaseDetailObject.packageName;
  let isBlurActive =
    checkForBlur == "silverOneTime" ||
    checkForBlur === "metalOneTime" ||
    checkForBlur == "goldOneTime";

  return (
    <>
      {isBlurActive ? (
        <div
          style={{
            backgroundImage: `url(${background})`,
            // background: 'red',
            minHeight: "70vh",
            minWidth: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no - repeat",
            backgroundSize: "cover",
            filter: "blur(8px)",
            webkitFilter: "blur(8px)",
          }}
        ></div>
      ) : (
        <div>
          <div>
            {/* {PopUpOpenForStripePurchase ? (
              <Modal
                title={"Recurring Type"}
                close={closeModalPopup}
                continuee={continuePurchasing}
              >
                <div class="container">
                  <div class="row justify-content-center Recurring">
                    <div class="col-md-12 mt-3 mb-3">
                      <div class=" text-center justify-content-center shadow-lg  card-1 border-0 bg-white px-sm-2">
                        <div class="card-body show  ">
                          <div class="row">
                            <div class="col">
                              <h5>
                                <b>Select Purchase Type</b>
                              </h5>
                              <p>
                                {" "}
                              
                                <span class=" ml-1 cursor-pointer">
                                  {" "}
                                
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                          <div class="radio-group row justify-content-between ">
                            <div class={selectedValue === "oneTime"? "ml-sm-2 mx-1  w-30  py-0 text-center radio selected " : "ml-sm-2 mx-1  w-30  py-0 text-center radio"}>
                              <div class="flex-row">
                                <div class="col">
                                  <div class="pic">
                                    {" "}
                                    <img
                                      class="irc_mut img-fluid"
                                      src="https://i.imgur.com/6r0XCez.png"
                                      width="100"
                                      height="100"
                                    />{" "}
                                  </div>
                                  <div class="form-check">
                                    <input
                                      name="group"
                                      value="oneTime"
                                      label="oneTime"
                                      checked={selectedValue === "oneTime"}
                                      onChange={handleChange}
                                      class="form-check-input"
                                      type="radio"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      One time Payment
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class={selectedValue === "recurring"? "ml-sm-2 mx-1  w-30  py-0 text-center radio selected " : "ml-sm-2 mx-1  w-30  py-0 text-center radio"}>
                              <div class="flex-row">
                                <div class="col">
                                  <div class="pic">
                                    {" "}
                                    <img
                                      class="irc_mut img-fluid"
                                      src={recurring}
                                      width="100"
                                      height="100"
                                    />{" "}
                                  </div>
                                  <div class="form-check">
                                    <input
                                      name="recurringradio"
                                      value="recurring"
                                      label="recurring"
                                      checked={selectedValue === "recurring"}
                                      onChange={handleChange}
                                      class="form-check-input"
                                      type="radio"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexRadioDefault2"
                                    >
                                      Subscription 
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null} */}
            <div>
              <div>
                {packageNamee === "free" ||
                packageNamee === "silver" ||
                packageNamee === "silverOneTime" ||
                packageNamee === "metal" ||
                packageNamee === "metalOneTime" ||
                packageNamee === "gold" ||
                packageNamee === "goldOneTime" ? (
                  <FreeVersionBanner label={packageNamee} />
                ) : null}
              </div>
              <h6 style={{ marginLeft: "17px" }} id="note-h6">
                {" "}
                <b>Note:</b>You can cancel Telxy Plan(for example, Metal, Silver
                or Gold) at any time. After you cancel it, your plan will expire
                at the end of your current billing cycle. To avoid getting
                charged for another billing cycle, you need to cancel your plan
                at least one day before your next scheduled billing date
              </h6>

              {isLoading ? (
                <Loader small={false} large={true} />
              ) : (
                <div>
                  <div>
                    <section id="pricing-box-container">
                      {openConfirmntion ? (
                        <>
                          <ConfirmationUpgradationAndDowngradation
                            name={packagenamee}
                            closeConfirmation={closeConfirmation}
                            updateDowngradeSub={updateDowngradeSub}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {confirmationPopupBill ? (
                        <ConfirmationBill
                          name={name}
                          packagename={packagename}
                          // paymentEvents={paymentEvents && paymentEvents}
                          // created={created}
                          // endPeriod={endPeriod}
                          // nextPaymentAttemp={nextPaymentAttemp}
                          // invoiceDetail={invoiceDetail}
                          closeModel={closeModel}
                        />
                      ) : undefined}
                      <div id="pricing-boxes">
                        <div
                          className={
                            packageNamee === "silver"
                              ? "pricing-box has-discount activated"
                              : "pricing-box has-discount"
                          }
                          id="pricing-premium"
                        >
                          <h3>Silver</h3>

                          <div className="pricing-box-content">
                            <div className="pricing-cta">
                              <span id="premium-message-icon"></span>

                              <div className="price">
                                <span className="default-price">
                                  {/* <span className="default-price-amount">20</span> */}
                                </span>
                                <span className="promo-price">
                                  <sup>$</sup>
                                  <span className="promo-price-amount">10</span>
                                  <sub>/mo</sub>
                                </span>
                              </div>
                              <p className="price-subtext">
                                <span className="price-toggle-text">
                                  Pay Monthly
                                </span>
                              </p>
                              <>
                                {stepperSilver === 20 ? (
                                  <>
                                    {/* <ListFeatures List={silverPackagesName} price={"$10"} /> */}

                                    {LoginObject && LoginObject?.username ? (
                                      <div className="d-flex ">
                                        {subscriptionListsSilver.length > 0 ? (
                                          <Card
                                            CardDisable={
                                              checkkSilver
                                                ? true
                                                : checkForBtnDisableFree
                                                ? true
                                                : checkForBtnDisableFree2
                                                ? true
                                                : checkkSilverOne
                                                ? true
                                                : false
                                            }
                                            Active={
                                              paymentMode === "cancelSilver"
                                                ? true
                                                : false
                                            }
                                            ActiveSecond={
                                              paymentMode === "updateSilver"
                                                ? true
                                                : false
                                            }
                                            CarOneTitle={CarOneTitle}
                                            CardOneBody={CardOneBody}
                                            CarTwoTitle={CarTwoTitle}
                                            CarTwoBody={CarTwoBody}
                                            handleClickOnPaymentMode={
                                              handleClickOnPaymentMode
                                            }
                                            firstArgument={"cancelSilver"}
                                            secondArgument={"updateSilver"}
                                          />
                                        ) : (
                                          <Card
                                            CardDisable={
                                              checkkSilver
                                                ? true
                                                : checkForBtnDisableFree
                                                ? true
                                                : checkForBtnDisableFree2
                                                ? true
                                                : checkkSilverOne
                                                ? true
                                                : false
                                            }
                                            Active={
                                              paymentMode === "Silver"
                                                ? true
                                                : false
                                            }
                                            ActiveSecond={
                                              paymentMode ===
                                              "subscriptionSilver"
                                                ? true
                                                : false
                                            }
                                            CarOneTitle={Card3Title}
                                            CardOneBody={Card3Body}
                                            CarTwoTitle={Card4Title}
                                            CarTwoBody={Card4Title}
                                            handleClickOnPaymentMode={
                                              handleClickOnPaymentMode
                                            }
                                            firstArgument={"Silver"}
                                            secondArgument={
                                              "subscriptionSilver"
                                            }
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <span
                                        style={{
                                          color: "white",
                                          fontSize: "12px",
                                        }}
                                        className="btn btn-primary ml-5"
                                        onClick={() => navigate("/")}
                                      >
                                        Buy{" "}
                                      </span>
                                    )}

                                    {paymentMode === "Silver" &&
                                    LoginObject &&
                                    LoginObject?.username ? (
                                      <>
                                        {LoginObject &&
                                        LoginObject?.username ? (
                                          <div
                                            onClick={() =>
                                              handleOneTimePayment()
                                            }
                                          >
                                            <StripeButton
                                              continueDispatch={
                                                continueDispatch
                                              }
                                              subscription={subscription}
                                              callSaveTrans={callSaveTrans}
                                              lastStripeTrasactionObject={
                                                lastStripeTrasactionObject
                                              }
                                              stripeLatestObject={
                                                stripeLatestObject
                                              }
                                              buttonLabel={"payment"} //Hard coded if dowgrade and upgrade not involve
                                              price={100}
                                              packageName={"silver"}
                                              userEmail={
                                                LoginObject &&
                                                LoginObject?.email
                                              }
                                              setIsLoading={setIsLoading}
                                              disableBtn={
                                                checkForBtnDisableMetalOneTime ||
                                                checkForBtnDisableSilverOneTime ||
                                                checkFrBtnDisableOneTimeGold
                                                  ? false
                                                  : checkForBtnDisableSilver
                                                  ? false
                                                  : checkForBtnDisableFree
                                                  ? false
                                                  : checkForBtnDisableFree2
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <span
                                            style={{ color: "white" }}
                                            className="btn stripeButtonDesignCustom d-flex w-100 justify-content-center align-items-center"
                                            onClick={() => navigate("/")}
                                          >
                                            Buy{" "}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {LoginObject &&
                                    LoginObject?.username &&
                                    stepperSilver === 9 ? (
                                      <>
                                        {" "}
                                        {/* <SubscriptionScreen
                        BackSubScreen={"++"}
                        handleStepperButton={handleStepperButton}
                        radioBtnValue={radioBtnValue}
                        subscription={subscription}
                      /> */}
                                        <div
                                          style={{ width: "100%" }}
                                          className="d-flex "
                                        >
                                          {" "}
                                          {/* <StripeButton
                          subscription={subscription}
                          callSaveTrans={callSaveTrans}
                          lastStripeTrasactionObject={
                            lastStripeTrasactionObject
                          }
                          stripeLatestObject={stripeLatestObject}
                          buttonLabel={"subscribe"}
                          price={10}
                          packageName="silver"
                          userEmail={LoginObject.user.email}
                          setIsLoading={setIsLoading}
                        /> */}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {LoginObject &&
                                        LoginObject?.jwt &&
                                        stepperSilver === 7 ? (
                                          <>
                                            {" "}
                                            {/* <CancelSubScreen
                                        BackSubScreen={"++"}
                                        handleStepperButton={
                                          handleStepperButton
                                        }
                                        subscriptionLists={
                                          subscriptionListsSilver
                                        }
                                        deleteSub={deleteSub}
                                        deletedSubObj={deletedSubObj}
                                        DeleteSub={DeleteSub}
                                        metalPackagesName={silverPackagesName}
                                      /> */}
                                          </>
                                        ) : stepperSilver === 8 ? (
                                          <>
                                            <Stepper
                                              amount={"$10"}
                                              getMonth={getMonth}
                                              List={silverPackagesName}
                                              BackSubScreen={"++"}
                                              handleStepperButton={
                                                handleStepperButton
                                              }
                                              updateSub={updateSub}
                                            />
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                  </>
                                )}
                                {true > 0 ? (
                                  <>
                                    <Button
                                      continueDispatch={continueDispatch}
                                      onupgradeAndDowngrade={
                                        onupgradeAndDowngrade
                                      }
                                      buttonLabelDownUp={buttonLabelSilver}
                                      SubscriptionList={SubscriptionList}
                                      paymentMode={paymentMode}
                                      payFirst={"cancelSilver"}
                                      paySecond={"updateSilver"}
                                      payThird={"subscriptionSilver"}
                                      handleStepperButton={handleStepperButton}
                                      disableBtn={
                                        checkForBtnDisableMetalOneTime ||
                                        checkForBtnDisableSilverOneTime ||
                                        checkFrBtnDisableOneTimeGold
                                          ? false
                                          : checkForBtnDisableSilver
                                          ? false
                                          : checkForBtnDisableFree
                                          ? false
                                          : checkForBtnDisableFree2
                                          ? false
                                          : true
                                      }
                                      subscription={subscription}
                                      callSaveTrans={callSaveTrans}
                                      lastStripeTrasactionObject={
                                        lastStripeTrasactionObject
                                      }
                                      stripeLatestObject={stripeLatestObject}
                                      buttonLabel={"subscribe"}
                                      price={10}
                                      packageName={"silver"}
                                      userEmail={
                                        LoginObject && LoginObject?.email
                                      }
                                      setIsLoading={setIsLoading}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {LoginObject &&
                                    LoginObject?.jwt &&
                                    stepperSilver === 9 ? (
                                      <>
                                        {" "}
                                        {/* <SubscriptionScreen
                                handleStepperButton={handleStepperButton}
                                radioBtnValue={radioBtnValue}
                                subscription={subscription}
                                BackSubScreen={"++"}
                              /> */}
                                        <div
                                          style={{ width: "100%" }}
                                          className="d-flex "
                                        >
                                          {" "}
                                          <StripeButton
                                            continueDispatch={continueDispatch}
                                            subscription={subscription}
                                            callSaveTrans={callSaveTrans}
                                            lastStripeTrasactionObject={
                                              lastStripeTrasactionObject
                                            }
                                            stripeLatestObject={
                                              stripeLatestObject
                                            }
                                            buttonLabel={"subscribe"}
                                            price={10}
                                            packageName={"silver"}
                                            userEmail={
                                              LoginObject && LoginObject?.email
                                            }
                                            setIsLoading={setIsLoading}
                                            disableBtn={
                                              checkForBtnDisableMetalOneTime ||
                                              checkForBtnDisableSilverOneTime ||
                                              checkFrBtnDisableOneTimeGold
                                                ? false
                                                : checkForBtnDisableSilver
                                                ? false
                                                : checkForBtnDisableFree
                                                ? false
                                                : checkForBtnDisableFree2
                                                ? false
                                                : true
                                            }
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {LoginObject &&
                                        LoginObject?.jwt &&
                                        stepperSilver === 7 ? (
                                          <>
                                            {/* <CancelSubScreen
                                        BackSubScreen={"++"}
                                        handleStepperButton={
                                          handleStepperButton
                                        }
                                        subscriptionLists={
                                          subscriptionListsSilver
                                        }
                                        deleteSub={deleteSub}
                                        deletedSubObj={deletedSubObj}
                                        DeleteSub={DeleteSub}
                                        metalPackagesName={silverPackagesName}
                                      /> */}
                                          </>
                                        ) : stepperSilver === 8 ? (
                                          <>
                                            <Stepper
                                              amount={"$10"}
                                              getMonth={getMonth}
                                              List={silverPackagesName}
                                              BackSubScreen={"++"}
                                              handleStepperButton={
                                                handleStepperButton
                                              }
                                              updateSub={updateSub}
                                            />
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                              <div className="pricing-features">
                                <h4>
                                  Includes
                                  <span id="premium-includes-text">
                                    {" "}
                                    everything in Standard, plus
                                  </span>
                                  :
                                </h4>
                                <ul style={{ textAlign: "left" }}>
                                  {PackageFeatureList?.silverActive.map(
                                    (pkg, index) => {
                                      return (
                                        <div key={index}>
                                          {pkg.silverActive ? (
                                            <li
                                              className="checkedPackage"
                                              data-icon="🗸"
                                              data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                              data-position="benchmark"
                                            ></li>
                                          ) : (
                                            <li
                                              data-icon="x"
                                              className="text-danger"
                                              data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                              data-position="benchmark"
                                            ></li>
                                          )}
                                          <li
                                            data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                            data-position="benchmark"
                                          >
                                            <span>{pkg.name}</span>
                                          </li>
                                        </div>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            packageNamee === "metal"
                              ? "pricing-box has-discount activated"
                              : "pricing-box has-discount"
                          }
                          id="pricing-premium"
                        >
                          <h3>Metal</h3>

                          <div className={"pricing-box-content"}>
                            <div className="pricing-cta">
                              <span id="premium-message-icon"></span>

                              <div className="price">
                                <span className="default-price">
                                  {/* <span className="default-price-amount">20</span> */}
                                </span>
                                <span className="promo-price">
                                  <sup>$</sup>
                                  <span className="promo-price-amount">50</span>
                                  <sub>/mo</sub>
                                </span>
                              </div>
                              <p className="price-subtext">
                                <span className="price-toggle-text">
                                  Pay monthly
                                </span>
                              </p>
                              <>
                                {true ? (
                                  <>
                                    <Button
                                      continueDispatch={continueDispatch}
                                      onupgradeAndDowngrade={
                                        onupgradeAndDowngrade
                                      }
                                      SubscriptionList={SubscriptionList}
                                      buttonLabelDownUp={buttonLabelMetal}
                                      paymentMode={paymentMode}
                                      payFirst={"cancelMetal"}
                                      paySecond={"updateMetal"}
                                      payThird={"subScriptionMetal"}
                                      handleStepperButton={handleStepperButton}
                                      disableBtn={
                                        checkForBtnDisableMetalOneTime ||
                                        checkForBtnDisableSilverOneTime ||
                                        checkFrBtnDisableOneTimeGold
                                          ? false
                                          : checkForBtnDisableMetal
                                          ? false
                                          : checkForBtnDisableFree
                                          ? false
                                          : checkForBtnDisableFree2
                                          ? false
                                          : true
                                      }
                                      subscription={subscription}
                                      callSaveTrans={callSaveTrans}
                                      lastStripeTrasactionObject={
                                        lastStripeTrasactionObject
                                      }
                                      stripeLatestObject={stripeLatestObject}
                                      buttonLabel={"subscribe"}
                                      price={50}
                                      packageName={"metal"}
                                      userEmail={
                                        LoginObject && LoginObject?.email
                                      }
                                      setIsLoading={setIsLoading}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {LoginObject &&
                                    LoginObject?.jwt &&
                                    stepperMetal === 6 ? (
                                      <>
                                        {" "}
                                        <div
                                          style={{ width: "100%" }}
                                          className="d-flex "
                                        >
                                          {" "}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {LoginObject &&
                                        LoginObject?.jwt &&
                                        stepperMetal === 4 ? (
                                          <>
                                            {" "}
                                            {/* <CancelSubScreen
                                        BackSubScreen={"--"}
                                        handleStepperButton={
                                          handleStepperButton
                                        }
                                        subscriptionLists={
                                          subscriptionListsMetal
                                        }
                                        deleteSub={deleteSub}
                                        deletedSubObj={deletedSubObj}
                                        DeleteSub={DeleteSub}
                                        metalPackagesName={metalPackagesName}
                                      /> */}
                                          </>
                                        ) : stepperMetal === 5 ? (
                                          <>
                                            <Stepper
                                              amount={"$50"}
                                              getMonth={getMonth}
                                              List={metalPackagesName}
                                              BackSubScreen={"--"}
                                              handleStepperButton={
                                                handleStepperButton
                                              }
                                              updateSub={updateSub}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                {stepperMetal === 10 ? (
                                  <>
                                    {" "}
                                    {LoginObject && LoginObject?.jwt ? (
                                      <div className="d-flex ">
                                        {subscriptionListsMetal.length > 0 ? (
                                          <Card
                                            CardDisable={
                                              checkkMetal
                                                ? true
                                                : checkForBtnDisableFree
                                                ? true
                                                : checkForBtnDisableFree2
                                                ? true
                                                : checkkGoldOne ||
                                                  checkkMetalOne ||
                                                  checkkSilverOne
                                                ? true
                                                : false
                                            }
                                            Active={
                                              paymentMode === "cancelMetal"
                                                ? true
                                                : false
                                            }
                                            ActiveSecond={
                                              paymentMode === "updateMetal"
                                                ? true
                                                : false
                                            }
                                            CarOneTitle={CarOneTitle}
                                            CardOneBody={CardOneBody}
                                            handleClickOnPaymentMode={
                                              handleClickOnPaymentMode
                                            }
                                            CarTwoTitle={CarTwoTitle}
                                            CarTwoBody={CarTwoBody}
                                            firstArgument={"cancelMetal"}
                                            secondArgument={"updateMetal"}
                                          />
                                        ) : (
                                          <Card
                                            CardDisable={
                                              checkkMetal
                                                ? true
                                                : checkForBtnDisableFree
                                                ? true
                                                : checkForBtnDisableFree2
                                                ? true
                                                : checkkGoldOne ||
                                                  checkkMetalOne ||
                                                  checkkSilverOne
                                                ? true
                                                : false
                                            }
                                            Active={
                                              paymentMode === "paymentMetal"
                                                ? true
                                                : false
                                            }
                                            ActiveSecond={
                                              paymentMode ===
                                              "subscriptionMetal"
                                                ? true
                                                : false
                                            }
                                            CarOneTitle={Card3Title}
                                            CardOneBody={Card3Body}
                                            handleClickOnPaymentMode={
                                              handleClickOnPaymentMode
                                            }
                                            CarTwoTitle={Card4Title}
                                            CarTwoBody={Card4Title}
                                            firstArgument={"paymentMetal"}
                                            secondArgument={"subscriptionMetal"}
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <span
                                        style={{
                                          color: "white",
                                          fontSize: "12px",
                                        }}
                                        className="btn btn-primary ml-5"
                                        onClick={() => navigate("/")}
                                      >
                                        Buy{" "}
                                      </span>
                                    )}
                                    {paymentMode === "paymentMetal" &&
                                    LoginObject &&
                                    LoginObject?.jwt ? (
                                      <>
                                        {LoginObject && LoginObject?.jwt ? (
                                          <div
                                            onClick={() =>
                                              handleOneTimePayment()
                                            }
                                          >
                                            <StripeButton
                                              disableBtn={
                                                checkForBtnDisableMetalOneTime ||
                                                checkForBtnDisableSilverOneTime ||
                                                checkFrBtnDisableOneTimeGold
                                                  ? false
                                                  : checkForBtnDisableSilver
                                                  ? false
                                                  : checkForBtnDisableFree
                                                  ? false
                                                  : checkForBtnDisableFree2
                                                  ? false
                                                  : true
                                              }
                                              subscription={subscription}
                                              callSaveTrans={callSaveTrans}
                                              lastStripeTrasactionObject={
                                                lastStripeTrasactionObject
                                              }
                                              stripeLatestObject={
                                                stripeLatestObject
                                              }
                                              buttonLabel={"payment"}
                                              price={100}
                                              packageName="metal"
                                              userEmail={
                                                LoginObject &&
                                                LoginObject?.email
                                              }
                                              setIsLoading={setIsLoading}
                                            />
                                          </div>
                                        ) : (
                                          <span
                                            style={{
                                              color: "white",
                                              fontSize: "12px",
                                            }}
                                            className="btn btn-primary ml-5"
                                            onClick={() => navigate("/")}
                                          >
                                            Buy{" "}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {LoginObject &&
                                    LoginObject?.jwt &&
                                    stepperMetal === 6 ? (
                                      <>
                                        {" "}
                                        <SubscriptionScreen
                                          handleStepperButton={
                                            handleStepperButton
                                          }
                                          radioBtnValue={radioBtnValue}
                                          subscription={subscription}
                                          BackSubScreen={"--"}
                                        />
                                        <div
                                          style={{ width: "100%" }}
                                          className="d-flex "
                                        >
                                          {" "}
                                          <StripeButton
                                            // continueDispatch={continueDispatch}
                                            subscription={subscription}
                                            callSaveTrans={callSaveTrans}
                                            lastStripeTrasactionObject={
                                              lastStripeTrasactionObject
                                            }
                                            stripeLatestObject={
                                              stripeLatestObject
                                            }
                                            buttonLabel={"subscribe"}
                                            price={100}
                                            packageName="metal"
                                            userEmail={
                                              LoginObject && LoginObject?.email
                                            }
                                            setIsLoading={setIsLoading}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {LoginObject &&
                                        LoginObject?.jwt &&
                                        stepperMetal === 4 ? (
                                          <>
                                            {" "}
                                            {/* <CancelSubScreen
                                        BackSubScreen={"--"}
                                        handleStepperButton={
                                          handleStepperButton
                                        }
                                        subscriptionLists={
                                          subscriptionListsMetal
                                        }
                                        deleteSub={deleteSub}
                                        deletedSubObj={deletedSubObj}
                                        DeleteSub={DeleteSub}
                                        metalPackagesName={metalPackagesName}
                                      /> */}
                                          </>
                                        ) : stepperMetal === 5 ? (
                                          <>
                                            <Stepper
                                              amount={"$50"}
                                              getMonth={getMonth}
                                              List={metalPackagesName}
                                              BackSubScreen={"--"}
                                              handleStepperButton={
                                                handleStepperButton
                                              }
                                              updateSub={updateSub}
                                            />
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                              <div className="pricing-features">
                                <h4>
                                  Includes
                                  <span id="premium-includes-text">
                                    {" "}
                                    everything in Standard, plus
                                  </span>
                                  :
                                </h4>
                                <ul style={{ textAlign: "left" }}>
                                  {PackageFeatureList.metalActive.map(
                                    (pkg, index) => {
                                      return (
                                        <div key={index}>
                                          {pkg.metalActive ? (
                                            <li
                                              className="checkedPackage"
                                              data-icon="🗸"
                                              data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                              data-position="benchmark"
                                            ></li>
                                          ) : (
                                            <li
                                              data-icon="x"
                                              className="text-danger"
                                              data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                              data-position="benchmark"
                                            ></li>
                                          )}
                                          <li
                                            data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                            data-position="benchmark"
                                          >
                                            <span>{pkg.name}</span>
                                          </li>
                                        </div>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            packageNamee === "gold"
                              ? "pricing-box has-discount activated"
                              : "pricing-box has-discount"
                          }
                          id="pricing-premium"
                        >
                          <h3>Gold</h3>

                          <div className="pricing-box-content">
                            <div className="pricing-cta">
                              <span id="premium-message-icon"></span>

                              <div className="price">
                                <span className="default-price">
                                  {/* <span className="default-price-amount">20</span> */}
                                </span>
                                <span className="promo-price">
                                  <sup>$</sup>
                                  <span className="promo-price-amount">
                                    150
                                  </span>
                                  <sub>/mo</sub>
                                </span>
                              </div>
                              <p className="price-subtext">
                                <span className="price-toggle-text">
                                  Pay monthly
                                </span>
                              </p>
                              <>
                                {stepper === 0 ? (
                                  <>
                                    {LoginObject && LoginObject?.jwt ? (
                                      <div className="d-flex ">
                                        {subscriptionLists.length > 0 ? (
                                          <>
                                            {" "}
                                            {/* <div
                            className={paymentMode==='cancelSubscription'?"card ml-2 active":"card ml-2 border border-grey "}
                    
                            onClick={() =>
                              handleClickOnPaymentMode("cancelSubscription")
                            }
                            style={paymentMode!='cancelSubscription'?{
                              width: "100%",
                              backgroundColor: "#f4f7f9",
                            }:{}}
                          >
                            <div className="card-body">
                              <p>Subscription Cancel</p>
                              
                            </div>
                          </div>
                          <div
                             className={paymentMode==='updateSubscription'?"card ml-2  active":"card ml-2 border border-grey "}
                    
                            onClick={() =>
                              handleClickOnPaymentMode("updateSubscription")
                            }
                            style={paymentMode!='updateSubscription'?{
                              width: "100%",
                              backgroundColor: "#f4f7f9",
                            }:{}}
                          >
                            <div className="card-body">
                              <p>Update Subscription</p>
                             
                            </div>
                          </div> */}
                                          </>
                                        ) : (
                                          <> </>
                                        )}
                                      </div>
                                    ) : null}
                                    {paymentMode === "payment" &&
                                    LoginObject &&
                                    LoginObject?.jwt ? (
                                      <>
                                        {LoginObject && LoginObject?.jwt ? (
                                          <div
                                            onClick={() =>
                                              handleOneTimePayment()
                                            }
                                          >
                                            <StripeButton
                                              subscription={subscription}
                                              callSaveTrans={callSaveTrans}
                                              lastStripeTrasactionObject={
                                                lastStripeTrasactionObject
                                              }
                                              stripeLatestObject={
                                                stripeLatestObject
                                              }
                                              buttonLabel={"payment"}
                                              price={100}
                                              packageName="gold"
                                              userEmail={
                                                LoginObject &&
                                                LoginObject?.email
                                              }
                                              setIsLoading={setIsLoading}
                                              disableBtn={
                                                checkForBtnDisableMetalOneTime ||
                                                checkForBtnDisableSilverOneTime ||
                                                checkFrBtnDisableOneTimeGold
                                                  ? false
                                                  : checkForBtnDisableGold
                                                  ? false
                                                  : checkForBtnDisableFree
                                                  ? false
                                                  : checkForBtnDisableFree2
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <span
                                            style={{
                                              color: "white",
                                              fontSize: "12px",
                                            }}
                                            className="btn btn-primary ml-5"
                                            onClick={() => navigate("/")}
                                          >
                                            Buy{" "}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {SubscriptionList.length > 0 &&
                                        paymentMode === "cancelSubscription" ? (
                                          <>
                                            {/* <button
                                        // disabled={
                                        //   checkForBtnDisableMetalOneTime ||
                                        //   checkForBtnDisableSilverOneTime ||
                                        //   checkFrBtnDisableOneTimeGold
                                        //     ? false
                                        //     : checkForBtnDisableGold
                                        //     ? false
                                        //     : checkForBtnDisableFree
                                        //     ? false
                                        //     : checkForBtnDisableFree2
                                        //     ? false
                                        //     : true
                                        // }
                                        // onClick={() =>
                                        //   handleStepperButton("cancelSub")
                                        // }
                                        className="btn btn-primary ml-4 "
                                        style={{ fontSize: "12px" }}
                                      >
                                        Activated
                                      </button> */}
                                          </>
                                        ) : SubscriptionList.length > 0 &&
                                          paymentMode ===
                                            "updateSubscription" ? (
                                          <>
                                            <button
                                              disabled={
                                                checkForBtnDisableMetalOneTime ||
                                                checkForBtnDisableSilverOneTime ||
                                                checkFrBtnDisableOneTimeGold
                                                  ? false
                                                  : checkForBtnDisableGold
                                                  ? false
                                                  : checkForBtnDisableFree
                                                  ? false
                                                  : checkForBtnDisableFree2
                                                  ? false
                                                  : true
                                              }
                                              onClick={() =>
                                                handleStepperButton("updtSub")
                                              }
                                              className="btn btn-lg stripeButtonDesignCustom theme-btn d-flex  justify-content-center align-items-center "
                                            >
                                              Update Subscription
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            {LoginObject && LoginObject?.jwt ? (
                                              // <button
                                              //   disabled={
                                              //     checkForBtnDisableMetalOneTime ||
                                              //     checkForBtnDisableSilverOneTime ||
                                              //     checkFrBtnDisableOneTimeGold
                                              //       ? false
                                              //       : checkForBtnDisableGold
                                              //       ? false
                                              //       : checkForBtnDisableFree
                                              //       ? false
                                              //       : checkForBtnDisableFree2
                                              //       ? false
                                              //       : true
                                              //   }
                                              //   onClick={() => handleStepperButton("+")}
                                              //   className="btn btn-lg stripeButtonDesignCustom theme-btn d-flex  justify-content-center align-items-center "
                                              // >
                                              //   Subscription
                                              // </button>
                                              <StripeButton
                                                continueDispatch={
                                                  continueDispatch
                                                }
                                                subscription={subscription}
                                                onupgradeAndDowngrade={
                                                  onupgradeAndDowngrade
                                                }
                                                buttonLabelDownUp={
                                                  buttonLabelGold
                                                }
                                                callSaveTrans={callSaveTrans}
                                                lastStripeTrasactionObject={
                                                  lastStripeTrasactionObject
                                                }
                                                stripeLatestObject={
                                                  stripeLatestObject
                                                }
                                                buttonLabel={"subscribe"}
                                                price={100}
                                                packageName={"gold"}
                                                userEmail={
                                                  LoginObject &&
                                                  LoginObject?.email
                                                }
                                                setIsLoading={setIsLoading}
                                                disableBtn={
                                                  checkForBtnDisableMetalOneTime ||
                                                  checkForBtnDisableSilverOneTime ||
                                                  checkFrBtnDisableOneTimeGold
                                                    ? false
                                                    : checkForBtnDisableGold
                                                    ? false
                                                    : checkForBtnDisableFree
                                                    ? false
                                                    : checkForBtnDisableFree2
                                                    ? false
                                                    : true
                                                }
                                              />
                                            ) : (
                                              <span
                                                style={{
                                                  color: "white",
                                                  fontSize: "12px",
                                                }}
                                                className="btn btn-primary ml-5"
                                                onClick={() => navigate("/")}
                                              >
                                                Buy{" "}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {LoginObject &&
                                    LoginObject?.username &&
                                    stepper === 1 ? null : (
                                      <>
                                        {LoginObject &&
                                        LoginObject?.username &&
                                        stepper === 3 ? (
                                          {}
                                        ) : stepper === 2 ? (
                                          <>
                                            <Stepper
                                              amount={"$100"}
                                              getMonth={getMonth}
                                              List={goldPackagesName}
                                              BackSubScreen={"-"}
                                              handleStepperButton={
                                                handleStepperButton
                                              }
                                              updateSub={updateSub}
                                            />
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                              <div className="pricing-features">
                                <h4>
                                  Includes
                                  <span id="premium-includes-text">
                                    {" "}
                                    everything in Standard, plus
                                  </span>
                                  :
                                </h4>
                                <ul style={{ textAlign: "left" }}>
                                  {PackageFeatureList.goldActive.map(
                                    (pkg, index) => {
                                      return (
                                        <div key={index}>
                                          {pkg.goldActive ? (
                                            <li
                                              className="checkedPackage"
                                              data-icon="🗸"
                                              data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                              data-position="benchmark"
                                            ></li>
                                          ) : (
                                            <li
                                              data-icon="x"
                                              className="text-danger"
                                              data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                              data-position="benchmark"
                                            ></li>
                                          )}
                                          <li
                                            data-tooltip-text="Compare your key metrics (like net profit margin and revenue per employee) to businesses that are similar to yours."
                                            data-position="benchmark"
                                          >
                                            <span>{pkg.name}</span>
                                          </li>
                                        </div>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div></div>
                  <div></div>

                  <div></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Index;
