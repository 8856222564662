import React, { useState } from "react";
import "./ToggleSwitch.css";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { AutoRenwal, webhookUpdateRenwal } from "../../../Actions/Actions";
import { recurringSubscription } from "../../../Utils/ApiManager";

export default function RecurringSubscription() {
  const dispatch = useDispatch();
  const { SubscriptionList, UserInfo, SessionID, AutoRenewalField } =
    useSelector((state) => state);

  const [checked, setChecked] = useState(AutoRenewalField);
  const toggleChecked = () => {
    setChecked((value) => !value);
    if (!checked) {
      let subid = SubscriptionList[0] && SubscriptionList[0]?.id;
      const now = new Date();
      let z = addOneYear(now);
      let a = z.toLocaleString().split(",");
      recurringSubForYear(a, subid);
    } else {
      let subid = SubscriptionList && SubscriptionList[0]?.id;
      const now = new Date();
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      let date = lastDay.toLocaleString().split(",");
      recurringSub(date, subid);
    }
  };

  const recurringSubForYear = async (date, subid) => {
    try {
      let obj = {
        endDate: date[0],
        subid: subid,
      };
      recurringSubscription(obj)
        .then((response) => {
          if (response && response.data) {
            toast.info(`Auto Renwal Procedure Active `);
            //  webhook();
            saveTransactionDownGrade(response.data, 1);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try later");
          toast.error(err);
        });
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
    return;
  };

  const addOneYear = (date) => {
    date.setFullYear(date.getFullYear() + 1);
    return date;
  };

  const webhook = async () => {
    try {
      let obj = {
        // endDate:date[0],
        // subid:subid
      };
      let res = await axios
        .post(
          process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + `/webhookDataFetch`,
          obj
        )
        .then((response) => {
          if (response && response.data) {
            dispatch(webhookUpdateRenwal(response.data));
            //  saveTransactionDownGrade(response.data);
            // toast.info(`Subscription End at the month`);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try later");
        });
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
    return;
  };
  const recurringSub = async (date, subid) => {
    try {
      let obj = {
        endDate: date[0],
        subid: subid,
      };

      recurringSubscription(obj)
        .then((response) => {
          if (response && response.data) {
            toast.info(`Auto Renwal Procedure Stopped`);
            //   webhook();
            saveTransactionDownGrade(response.data, 0);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try later");
          toast.error(err.response.data.message);
        });
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
    return;
  };
  const conversion = (arg, arg2, arg3) => {
    if (arg) {
      var date = new Date(arg * 1000).toLocaleString("en-US").split(",");
      date = date[0].split("/");
      let formmatedDate = date[1] + "/" + date[0] + "/" + date[2];
      return formmatedDate;
    }
    if (arg2) {
      let amount = arg2;
      return amount;
    }
    if (arg3) {
      if (arg3 === 14900) {
        return "gold";
      } else if (arg3 === 5000) {
        return "metal";
      } else if (arg3 === 1000) {
        return "silver";
      }
    }
  };
  const saveTransactionDownGrade = async (data, val) => {
    let oldStartDate =
      SubscriptionList && SubscriptionList[0]?.current_period_start;

    let check = data.subscriptionUpdt;
    if (check) {
      let expireDate = conversion(check.cancel_at, null, null);
      let creationDate = conversion(oldStartDate, null, null);
      let amount = conversion(null, check.plan.amount, null);
      let packageName = conversion(null, null, check.plan.amount);

      let formData = new FormData();
      formData.append("created_at", creationDate);
      formData.append("expiryDate", expireDate);
      formData.append("currency", check.plan.currency);
      formData.append("packageName", packageName);
      formData.append("amount", amount);
      formData.append("recieptUrl", SessionID);
      formData.append("customerId", check.customer);
      formData.append("transactionId", SessionID);
      formData.append("userId", UserInfo.user.id);
      try {
        await axios
          .post(process.env.REACT_APP_APIS + "save-transaction=1", formData)
          .then(function (response) {
            toast.info("Package updated");
            AutoRenewalActive(val);
          });
      } catch (err) {}
    } else {
      toast.info("Webhook Not working so cant process save-Transaction ");
    }
  };
  const AutoRenewalActive = async (val) => {
    try {
      await axios
        .post(
          process.env.REACT_APP_APIS +
            `autorenew=${val}&userId=${UserInfo.user.id}`
        )
        .then(function (response) {
          dispatch(AutoRenwal(val));
        });
    } catch (err) {}
  };

  return (
    <>
      <div className="social-settings">
        <div className="settings-header">
          <h5>Recurring Subscription</h5>
          <p>This toggle will handle your recurring status of subscription.</p>
          <p>Auto renew every month?</p>
          <div className="d-flex mt-3 ml-2">
            <label className=" d-flex toggle mt-0 ml-2 " htmlFor="myToggle">
              {" "}
              No{" "}
            </label>
            <label className=" d-flex toggle mt-0 ml-2 " htmlFor="myToggle">
              <input
                className="toggle__input  ml-5"
                name=""
                type="checkbox"
                checked={checked == 0 ? false : true}
                onChange={toggleChecked}
                id="myToggle"
              />
              <div className="toggle__fill"></div>
            </label>
            <label className=" d-flex toggle mt-0 ml-2 " htmlFor="myToggle">
              {" "}
              Yes
            </label>
          </div>
        </div>
      </div>
    </>
  );
}