import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Tooltip,
  Legend
);
export const options = {
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
  },
  chartArea: {
    setBackgroundColor: "rgba(255, 255, 255, 1)",
  },

  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};
export const options0 = {
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
  },

  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};
const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,

  datasets: [
    {
      label: "Offline Sales",
      data: [3, 5, 54, 62, 6, 25, 6, 26, 3],

      backgroundColor: "rgb(152, 189, 255)",
      lineTension: 0.5,
    },
    {
      label: "Online Sales",
      data: [3, 15, 34, 2, 6, 2, 6, 26, 3],
      backgroundColor: "rgb(75, 73, 172)",
      lineTension: 0.5,
    },
  ],
};
