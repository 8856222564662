import { ApiCall, PublicApiCall } from "./ApiCall";

const REACT_APP_APIS = process.env.REACT_APP_APIS;
const REACT_APP_APIS_GLOBAL_LOCALHOST =
  process.env.REACT_APP_APIS_GLOBAL_LOCALHOST;

export const LoginUser = ({ username, password }) => {
  const LOGIN_PATH = `login=&username=${username}&password=${password}`;
  let path = REACT_APP_APIS + LOGIN_PATH;
  return PublicApiCall(path, "post", null);
};
export const LoginUserFromEhr = (username, password) => {
  const LOGIN_PATH = `login=&${username}&${password}`;
  let path = REACT_APP_APIS + LOGIN_PATH;
  return ApiCall(path, "post", null);
};

export const updateRoomName = (payload) => {
  const queryParam = "update-room=1";
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", payload);
};

export const getRoomHistory = (username) => {
  const queryParam = `get-room-history=1&conversationSid=${username}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "get", null);
};

export const getRoomParticipents = (roomId) => {
  const queryParam = `get-room-participants=1&conversationSid=${roomId}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "get", null);
};

export const checkIfRoomExistInDBApi = (roomName) => {
  const queryParam = `check-if-room-exist=1&roomid=${roomName}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "get", null);
};

export const createRoom = (roomName) => {
  const queryParam = `usernameg=${roomName}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "get", null);
};

export const getPatientList = (roomName) => {
  const queryParam = `getPatientList=1&roomid=${roomName}`;
  let path = REACT_APP_APIS + queryParam;
  return PublicApiCall(path, "get", null);
};

export const onAcceptCall = ({ status, id }) => {
  const queryParam = `updatePatientStatusToJoinstatus=1&roomid=${status}&status=accepted&userid=${id}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", null);
};

export const getPatientJoiningStatusApi = (RoomInfo) => {
  const queryParam = `gjr=1&roomid=${RoomInfo}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", null);
};
export const patientJoinRoomApi = (RoomInfo, patientName) => {
  const queryParam = `usernamen=${RoomInfo}&visitor=${patientName}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "get", null);
};
export const deletedSelectedUserFromList = (patientId, userRoomName) => {
  const queryParam = `removePatientFromList=1&userid=${patientId}&roomid=${userRoomName}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "get", null);
};
export const UserStatusPendingApi = ({ username }, { id }) => {
  const queryParam = `updatePatientStatusToJoinstatus=1&roomid=${username}&status=pending&userid=${id}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", null);
};
export const UserStatusPendingApiPatient = ({ roomName, patId }) => {
  const queryParam = `updatePatientStatusToJoinstatus=1&roomid=${roomName}&status=pending&userid=${patId}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", null);
};

export const changePaitentStatus = (roomName, status, patId) => {
  const queryParam = `updatePatientStatusToJoinstatus=1&roomid=${roomName}&status=${status}&userid=${patId}`;
  let path = REACT_APP_APIS + queryParam;
  return PublicApiCall(path, "post", null);
};

export const DeleteSubscription = (obj) => {
  const queryParam = "/Delete-Subscription";
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const saveTransaction = (formData) => {
  const queryParam = "save-transaction=1";
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", formData);
};
export const recurringSubscription = (obj) => {
  const queryParam = "/RecurringSubscription";
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const getAllRecordingsOfSpecificRoom = (Roomobj) => {
  const queryParam = "/GetAllRecordingOfSpecificRoom";
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", Roomobj);
};
export const retrievetheRecoridngInstance = (recordingSid) => {
  const queryParam = "/retrievetheSelectedRecoridngInstance";
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", recordingSid);
};
export const getCardDetailsApi = (obj) => {
  const queryParam = "/getAllCards";
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const updateProviderStatus = (username, status) => {
  const queryParam = `updateproviderStatus=1&roomName=${username}&history=${status}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", null);
};
export const getProviderStatus = (username) => {
  const queryParam = `providerStatus=1&roomName=${username}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "get", null);
};
export const AddPaymentEventsToDb = (obj, username) => {
  const queryParam = `addstripe=1&roomName=${username}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const createCardUpdateToken = (obj) => {
  const queryParam = "/update-card-token";
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const saveCreditCard = (obj) => {
  const queryParam = `save-creditcardDetails=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const getCreditCardFromDb = (userId) => {
  const queryParam = `get-creditcardDetails=1&userId=${userId}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post");
};
export const updateCreditCard = (userId, obj) => {
  const queryParam = `update-creditcardDetails=1&userId=${userId}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const dominantSpeakerUpdate = (obj) => {
  const queryParam = "/dominantSpeakerUpdate";
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const updateProfileImage = (obj) => {
  const queryParam = `updateProfileImage=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const RefundApiCall = (obj) => {
  const queryParam = `/refundOneTimeAmount`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const get_Transaction_Api = (id) => {
  const queryParam = `get-transaction=1&userId=${id}`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", null);
};
export const paymentIntentApi = (obj) => {
  const queryParam = `/retreive_Payment_Intent_For_one_Time_card`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const getPaymentIntentFromSubId = (obj) => {
  const queryParam = `/get_paymentIntentFromSubId`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const get_Latest_Invoice = (obj) => {
  const queryParam = `/get_Latest_Invoice`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const get_Prorations = (formData) => {
  const queryParam = `get-Prorations-detail=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", formData);
};
export const get_Payment_List = (obj) => {
  const queryParam = `/getPaymentList`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const next_Month_Invoice = (obj) => {
  const queryParam = `/nextMonthUpcomingInvoice`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
//--------------------For indicating twilio that person is removed----------------------
export const removeParticpant = (obj) => {
  const queryParam = `/RemoveParticpant`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
//-------------------Room Creation---------------
export const roomCreation = (obj) => {
  const queryParam = `/roomCreation`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
//-----------------room destroy----
export const roomDestroy = (obj) => {
  const queryParam = `/roomDestroy`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
//-----------------Call RECORDINGS rOOM----
export const RecordingRules = (obj) => {
  const queryParam = `/recordingRules`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const RetreiveRecordingRules = (obj) => {
  const queryParam = `/retrieveRecordings`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const getAllRecordingList = (obj) => {
  const queryParam = `/getAllRecordingLists`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
//-------------get All Room

export const getAllRooms = (obj) => {
  const queryParam = `/getAllRoomsList`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const createCompositionss = (obj) => {
  const queryParam = `/createCompositions`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const fetchCompositions = (obj) => {
  const queryParam = `/fetchComposition`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const viewMediaRecording = (obj) => {
  const queryParam = `/viewMediaRec`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const deleteRecording = (obj) => {
  const queryParam = `/deleteRecording`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const RecordingAudioRules = (obj) => {
  const queryParam = `/RecordingAudioRules`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const StopRecording = (obj) => {
  const queryParam = `/StopRecording`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const PatientLimitations = (obj) => {
  const queryParam = `PatientLimitations=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const getPatientLimitations = (obj) => {
  const queryParam = `get-Patient-Limitations-Attempts=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const updatePatientLimitations = (obj) => {
  const queryParam = `updatePatientLimitations=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const insertTotalMin = (obj) => {
  const queryParam = `ConsumedMinutes=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const getParticpantMin = () => {
  const queryParam = `getConsumedMinutes=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", null);
};
export const updateConsumedMinutesApi = (obj) => {
  const queryParam = `updateConsumedMinutes=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const intiateChat = (obj) => {
  const queryParam = `intiate-Conversation=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};
export const getIntiatedChat = (obj) => {
  const queryParam = `get-Conversation=1`;
  let path = REACT_APP_APIS + queryParam;
  return ApiCall(path, "post", obj);
};

export const generateToken = (obj) => {
  const queryParam = `/generatingAccessToken`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
export const getConversationBySid = (obj) => {
  const queryParam = `/getConversationBySid`;
  let path = REACT_APP_APIS_GLOBAL_LOCALHOST + queryParam;
  return ApiCall(path, "post", obj);
};
