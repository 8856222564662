import React, { useState, useEffect } from "react";
import Loader from "../../../Common/Spinner";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Pagination from "../Pagination";
import ReactPaginate from "react-paginate";

export const Transactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [transactionList2, setTransactionList2] = useState([]);

  const state = useSelector((state) => state);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [pkgName, setPkgName] = useState();
  const userId = state.LoginObject.id;

  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  let itemsPerPage = 7;
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % transactionList.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    setIsLoading(true);
    getTransactionsList();
  }, []);
  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      console.log(startDate, "startDate");
      console.log(endDate, "endDate");
      console.log(transactionList, "transactionList");
      setTransactionList(
        transactionList.filter((obj) => {
          return (
            // new Date(obj.created_at.substring(0, 9)).getTime() >=
            //   new Date(startDate.substring(0, 9)).getTime() &&
            // new Date(obj.created_at.substring(0, 9)).getTime() <=
            //   new Date(endDate.substring(0, 9)).getTime()

            moment(obj.created_at).isBetween(startDate, endDate)
          );
        })
      );
    }
  }, [endDate]);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(transactionList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(transactionList.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, transactionList]);
  useEffect(() => {
    if (pkgName != null) {
      let pkg = pkgName.toLowerCase();
      setTransactionList(
        transactionList2.filter((i) => {
          console.log(pkgName, "==", i.packageName);
          return i.packageName.match(pkg.replace(/\s+/g, ""));
        })
      );
    }
  }, [pkgName]);

  const getTransactionsList = async () => {
    try {
      let transactionList;
      transactionList = await axios.get(
        process.env.REACT_APP_APIS + `get-transaction=1&userId=${userId}`
      );
      let data = transactionList.data.reverse();
      setIsLoading(false);
      if (data && data.length > 0) {
        setTransactionList(data);
        setTransactionList2(data);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const setDate = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (name == "startDate") {
      setStartDate(value);
      return;
    }
    if (name == "endDate") {
      setEndDate(value);
      return;
    }
    if (name == "pkgName") {
      setPkgName(value);
      return;
    }
  };
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  console.log(transactionList, "transactionList");
  return (
    <div className="row">
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <p className="card-title">Transactions History</p>
            {isLoading ? (
              <Loader small={false} large={true} />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                    justifyContent: "flex-end",
                    marginRight: "18px",
                  }}
                >
                  <div>
                    <div className=" ">
                      <input
                        style={{ height: "37px", width: "230px" }}
                        type="text"
                        className="form-control "
                        name="pkgName"
                        onChange={setDate}
                        placeholder="Search  Package Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive container-fluid">
                      <div
                        id="example_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6"></div>
                          <div className="col-sm-12 col-md-6"></div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="example"
                              className="display expandable-table dataTable no-footer"
                              // style={{ width: "100px" }}
                              role="grid"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className="select-checkbox "
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Quote#"
                                    style={{ width: "136px" }}
                                  >
                                    Operation
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    style={{ width: "186px" }}
                                  >
                                    Package Name
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    style={{ width: "186px" }}
                                  >
                                    Amount
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    style={{ width: "186px" }}
                                  >
                                    Amount Status
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    style={{ width: "176px" }}
                                  >
                                    Purchased at
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Premium: activate to sort column ascending"
                                    style={{ width: "190px" }}
                                  >
                                    Period End
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems &&
                                  currentItems.length > 0 &&
                                  currentItems.map((el, key) => {
                                    return (
                                      <tr className="odd" key={key}>
                                        <td className=" select-checkbox">
                                          <span
                                            className={
                                              el.customerid === "Downgrade"
                                                ? "badge badge-warning-blue badge-pill px-25"
                                                : el.customerid === "upgrade"
                                                ? "badge badge-warning-green badge-pill px-25"
                                                : "badge badge-warning badge-pill px-25"
                                            }
                                          >
                                            {capitalizeFirst(el.customerid)}
                                          </span>
                                        </td>
                                        <td className="sorting_1">
                                          {capitalizeFirst(el.packageName)}
                                        </td>
                                        <td>
                                          $
                                          {el.amount && el.amount > 0
                                            ? el.amount / 100
                                            : "0"}
                                        </td>
                                        <td>
                                          <span className="badge badge-warning badge-pill px-25">
                                            {el.amount && el.amount / 100 == 0
                                              ? "Proration Adjustments"
                                              : "Paid"}
                                          </span>
                                        </td>

                                        <td>
                                          {el.created_at ? el.created_at : ""}
                                        </td>
                                        <td>
                                          {el.expiryDate ? el.expiryDate : ""}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                            {transactionList &&
                              transactionList.length === 0 && (
                                <div
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                  }}
                                >
                                  <p className="mt-3">No data found</p>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5"></div>
                          <div className="col-sm-12 col-md-7"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              marginRight: "30px",
            }}
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
