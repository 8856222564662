import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import icon from "../../../Assets/images/usageicon.png";
import { getPatientLimitations } from "../../../Utils/ApiManager";
function RemianingMinutes() {
  const [noOFAttempts, setNoOfAttempts] = useState();
  const { LoginObject } = useSelector((state) => state);
  useEffect(() => {
    getPatLimiatation();
  }, []);
  const getPatLimiatation = async () => {
    let res = await getPatientLimitations();
    if (res && res.data) {
      const filteredArr = res.data.filter(
        (item) => item.user_id === LoginObject?.id && item.noOFAttempts != 0
      );
      if (filteredArr && filteredArr.length > 0) {
        setNoOfAttempts(filteredArr[0]?.noOFAttempts);
      } else {
        setNoOfAttempts(0);
      }
    }
  };

  return (
    <div>
      <div className="card-body">
        <div className="d-flex">
          <div className="minutesMain" style={{ backgroundColor: "#12b8c3" }}>
            <img src={icon} />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <h5 className="minutesMainH5">
              {" "}
              {false ? (
                <>
                  <div
                    className="spinner-border  spinner-border-sm"
                    role="status"
                    style={{ color: "#12b8c3" }}
                  ></div>
                </>
              ) : (
                <h5 className="minutesMainH5" style={{ color: "#12b8c3" }}>
               <h4>  {noOFAttempts}</h4> 
                </h5>
              )}
            </h5>
            <p className="minutesMainp">Total Calls Left</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemianingMinutes;
