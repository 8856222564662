import React, { useEffect, useState, useLayoutEffect } from "react";
import { MinGraph } from "./MinutesGraph";
import axios from "axios";
import moment from "moment";

import {
  dateCheck,
  FilterArray,
  FilterArray2,
  getTomorrowDate,
  searchDataBetweenDates,
} from "../../../Utils/commonFunctions";
import { useSelector } from "react-redux";

export default function MinuteGraphCalc() {
  let LoginUser = useSelector((state) => state.LoginObject);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [result, setResult] = useState();
  const [days, setDays] = useState();
  const [dataSet, setDataSet] = useState();
  const [keyValue, setKeyValue] = useState();
  const [totalRooms, setTotalRooms] = useState();
  const [totalMinutes, setTotalMin] = useState();
  const [durSec, setMinuteGraphDuration] = useState();

  const [
    dataFilteredBetweenStartDateAndEndDate,
    setDataFilteredBetweenStartDateAndEndDate,
  ] = useState();
  const [filterDatesLabelForBarGraph, setFilterDatesLabelForBarGraph] =
    useState();
  const [roooms, setRooms] = useState();
  const [onlyDatee, setonlyDate] = useState();

  useLayoutEffect(() => {
    const getTommorwwDatee = async () => {
      const { startDefaultValue, defaultValue } = await getTomorrowDate();
      setStartDate(startDefaultValue);
      setEndDate(defaultValue);
    };
    getTommorwwDatee();
  });
  useEffect(() => {
    if (roooms) {
      const dateCheckEffect = async () => {
        let res = await dateCheck(startDate, endDate, roooms);
        if (res) compareArray(res);
      };
      dateCheckEffect();
    }
  }, [roooms]);

  useEffect(() => {
    if (endDate && startDate) {
      const searchDataBetweenDatesFiler = async () => {
        let onlyDateee = await searchDataBetweenDates(startDate, endDate);

        setFilterDatesLabelForBarGraph(onlyDateee);
        setonlyDate(onlyDateee);
      };
      searchDataBetweenDatesFiler();
      getAllRoomDates();
    }
  }, [startDate, endDate]);
  useEffect(() => {
    MinutesGraph();
    // partciptantsCalculation();
  }, [result, keyValue]);

  const dateCheck = (from, to, check) => {
    let dates = [];
    const startdate = new Date(from);
    const enddate = new Date(to);

    while (startdate <= enddate) {
      dates.push(new Date(startdate));
      startdate.setDate(startdate.getDate() + 1);
    }
    return dates;
  };
  const compareArray = async (resdate) => {
    const { dupes, result } = await FilterArray(roooms, resdate);
    setDataFilteredBetweenStartDateAndEndDate(dupes);

    setResult(result);
    const { keyVaue, pushInDataSetArray, sum, days } = await FilterArray2(
      filterDatesLabelForBarGraph,
      dupes
    );

    setDays(days);
    setKeyValue(keyVaue);
    setTotalRooms(sum);
    setDataSet(pushInDataSetArray);
  };

  const getAllRoomDates = async () => {
    let res = await axios.get(
      process.env.REACT_APP_APIS +
        `get-room-history=1&conversationSid=${LoginUser?.username}`
    );
    const results =
      res &&
      res.data &&
      res.data.rooms.filter((element) => {
        // 👇️ using AND (&&) operator
        return element.room_name === LoginUser?.username;
      });

    setRooms(results);
  };

  const MinutesGraph = () => {
    let makingObject =
      result &&
      result.map((el) => {
        return {
          partLabel: new Date(el?.create_time).toDateString(),
          totalRecordingDurationSec: el.totalRecordingDurationSec,
          totalParticipantDurationSec: el.totalParticipantDurationSec,
          durationSec: el.duration_sec,
        };
      });

    let test = [];
    makingObject &&
      makingObject.map((participant) => {
        let oldFound = test.findIndex(
          (oldParticipant) => oldParticipant.partLabel === participant.partLabel
        );
        if (oldFound !== -1) {
          test[oldFound] = {
            ...test[oldFound],
            durationSec: +test[oldFound].durationSec + +participant.durationSec,
          };
        } else {
          test.push(participant);
        }
      });

    let t = test.map((el) => ({
      label: moment(el.partLabel).format("MM-DD-YYYY"),
      totalRecordingDurationSec: el.totalRecordingDurationSec,
      totalParticipantDurationSec: el.totalParticipantDurationSec,
      durationSec: el.durationSec,
    }));

    let map = {};

    let pushInMinutesGraphArrayDurationSec = [];
    let pushInMinutesGraphArrayParticiptantDurationSec = [];
    let pushInMinutesGraphArrayRecodingDurationSec = [];

    keyValue && keyValue.forEach((item) => (map[item.LabelKey] = item));
    t.forEach((item) => (map[item.label] = item));
    const resultff = Object.values(map);

    resultff.map((a) => {
      if (a.durationSec !== undefined || null) {
        pushInMinutesGraphArrayDurationSec.push(a.durationSec);
      } else {
        pushInMinutesGraphArrayDurationSec.push(0);
      }
      if (a.totalRecordingDurationSec !== undefined || null) {
        pushInMinutesGraphArrayRecodingDurationSec.push(
          a.totalRecordingDurationSec
        );
      } else {
        pushInMinutesGraphArrayRecodingDurationSec.push(0);
      }
      if (a.totalParticipantDurationSec !== undefined || null) {
        pushInMinutesGraphArrayParticiptantDurationSec.push(
          a.totalParticipantDurationSec
        );
      } else {
        pushInMinutesGraphArrayParticiptantDurationSec.push(0);
      }
    });

    var minutesDuration;
    let MinuteDataset = [];
    pushInMinutesGraphArrayDurationSec.map((el) => {
      minutesDuration = Math.floor(el / 60);
      MinuteDataset.push(minutesDuration);
    });
    // var minutesDuration = Math.floor(pushInMinutesGraphArrayDurationSec / 60);

    const sum = MinuteDataset.reduce((partialSum, a) => partialSum + a, 0);

    setTotalMin(sum);

    setMinuteGraphDuration(MinuteDataset);
  };

  const setDate = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (name == "startDate") {
      setStartDate(value);
      return;
    }
    if (name == "endDate") {
      setEndDate(value);
      return;
    }
  };
  return (
    <div className="col-md-6  stretch-card">
      <div className="card">
        <div className="card-body">
          <div className="chartjs-size-monitor">
            <div className="font-weight-bold">
              Total Meetings time
              <hr></hr>
            </div>
            <div className="chartjs-size-monitor-shrink">
              <div className=""></div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group mr-2 ">
                <label htmlFor="start Date">Start Date</label>
                <input
                  type="date"
                  className="form-control "
                  name="startDate"
                  onChange={setDate}
                  defaultValue={startDate}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group mr-2 ">
                <label htmlFor="start Date">End Date</label>
                <input
                  // value={endDate}
                  //min={endDate}
                  type="date"
                  className="form-control "
                  name={"endDate"}
                  onChange={setDate}
                  defaultValue={endDate}
                />
              </div>
            </div>
            <div className="col-sm-2 align-self-center">
              <button
                style={{
                  paddingtop: "8px!important",
                  paddingbottom: "8px!important",
                  paddingleft: "14px!important",
                }}
                className="btn  btn-primary search-btn-filter"
                onClick={() => searchDataBetweenDates(startDate, endDate)}
              >
                <i className="fas fa-search primary fa-2x	"></i>
              </button>
            </div>
          </div>
          {/* <div style={{ textAlign: "end", width: "100%" }}>
            <div className="mb-5">
              <button
                className="btn  btn-primary"
                onClick={() => searchDataBetweenDates(startDate, endDate)}
              >
                Search
              </button>
            </div>
          </div> */}

          <div
            style={{
              position: "relative",
              height: "auto",
              minHeight: "18rem",
              width: "calc(100% - 1vw)",
            }}
          >
            <MinGraph
              height={470}
              dataSet={durSec}
              filterDatesLabelForBarGraph={filterDatesLabelForBarGraph}
              dataFilteredBetweenStartDateAndEndDate={
                dataFilteredBetweenStartDateAndEndDate
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
