import axios from "axios";
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

// import {
//   CreateStripeDetail,
//   packagePurchaseDetailObject,
//   stripePackageType,
// } from "../Redux/Actions";
import { useSelector } from "react-redux";
import { Packages } from "../../../Common/Packages";
import NewCustomStripe from "./NewCustomStriep";
import {
  CreateStripeDetail,
  packagePurchaseDetailObject,
  stripePackageType,
} from "../../../Actions/Actions";

const StripeButton = ({
  continueDispatch,
  onupgradeAndDowngrade,
  buttonLabelDownUp,
  disableBtn,
  callSaveTrans,
  subscription,
  lastStripeTrasactionObject,
  stripeLatestObject,
  price,
  packageName,
  userEmail,
  setIsLoading,
  setStripe,
  buttonLabel,
}) => {
  let dispatch = useDispatch();
  const priceForStripe = price * 100;

  const publishableKey =
    "pk_test_51LT7kzKz7EPltLjwdd4Q4IpLM1sBEMKqv37fTNR2sC9gwJJkslCTaCYsvJwanfUiKOvbtJem2a4oVRJixhQ1ccPJ00N6DOYzAF";

  const [stripe, setStripee] = useState();
  const [customStripeFlag, setCustomStripeFlag] = useState(false);
  const [newUpgradedAmount, setnewUpgradedAmount] = useState(null);
  const [alertEnabler, setAlertEnable] = useState();

  const { LoginObject, StripePackagePurchaseDetailObject } = useSelector(
    (state) => state
  );

  const saveTransaction = async (formData) => {
    try {
      await axios
        .post(process.env.REACT_APP_APIS + "save-transaction=1", formData)
        .then(function (response) {
          setAlertEnable(true);
          setStripee(response);

          dispatch(stripePackageType(packageName));

          toast.success("Your package activated");
        });
    } catch (err) {}
  };
  let totalDays = 0;

  const amountUpgrader = () => {
    let packageAmm;
    let a = Date.now();
    let z = new Date(a);
    let yyyy = z.getFullYear();
    let mm = z.getMonth() + 1;
    let dd = z.getDate();
    let formattedToday = dd + "/" + mm + "/" + yyyy;
    if (buttonLabel === "downgrade" || buttonLabel === "upgrade") {
      //totalDays = getDifferenceInDays(formattedToday);
    }
    if (buttonLabel === "upgrade" || buttonLabel === "payment") {
      let previousPackageAmt = priceForStripe;
      packageAmm = splitDate(
        formattedToday,
        totalDays,
        previousPackageAmt,
        previousPackageAmt
      );
    }
    return packageAmm;
  };
  const StripeRenwal = (stripe) => {
    let amountStripe;
    if (stripe) {
      let formattedToday;
      const {
        currency,
        description,
        amount,
        balance_transaction,
        id,
        customer,
        receipt_url,
        billing_details: { name },
      } = stripe.data;
      amountStripe = amount / 10;

      var dt = new Date(Date.now());
      dt.setMonth(dt.getMonth() + 1);
      let dtyyyy = dt.getFullYear();
      let dtmm = dt.getMonth() + 1;

      let dtdd = dt.getDate();
      const format = dtdd + "/" + dtmm + "/" + dtyyyy;
      let transactionObj = {};
      transactionObj["packageCreationDate"] = formattedToday;
      transactionObj["packageExpirationDate"] = format;

      transactionObj["currency"] = currency;
      transactionObj["packageName"] = description;
      transactionObj["amount"] = amount;
      transactionObj["receiptUrl"] = receipt_url;
      transactionObj["userEmail"] = name;
      transactionObj["customerId"] = id;
      transactionObj["transactionId"] = balance_transaction;
      transactionObj["userId"] = LoginObject.id;
      transactionObj["transactionRole"] = buttonLabel;

      let formData = new FormData();
      formData.append("created_at", formattedToday);
      formData.append("expiryDate", format);
      formData.append("currency", currency);
      formData.append("packageName", description);
      formData.append("amount", amount);
      formData.append("recieptUrl", receipt_url);
      formData.append("customerId", id);
      formData.append("transactionId", balance_transaction);
      formData.append("userId", LoginObject && LoginObject.id);
      // formData.append("upgradeStatus",'false');
      // formData.append("activationDate", '12/34/22');
      //----------Case1 Upgrade Case------------------
      if (
        // buttonLabel === "Active" ||
        // buttonLabel === "buy" ||
        buttonLabel === "upgrade"
      ) {
        let returnedVal = amountUpgrader();
        if (
          typeof returnedVal === typeof undefined ||
          returnedVal === null ||
          isNaN(parseFloat(returnedVal))
        ) {
          returnedVal = amount;
        } else {
          formData.append("amount", returnedVal);
        }

        saveTransaction(formData);
        let packageArr = Packages;
        let selectedPackage = packageArr.find(
          (el) => el.packageName === description
        );
        const {
          currency,
          amount,
          receiptUrl,
          userEmail,
          userId,
          customerId,
          transactionId,
          ...reduxObj
        } = transactionObj;
        dispatch(packagePurchaseDetailObject(selectedPackage));
        dispatch(CreateStripeDetail(transactionObj));
        setCustomStripeFlag(true);
      }
      //need change

      if (typeof totalDays === typeof undefined) {
        return;
      }

      if (Math.sign(totalDays) === -1 || totalDays === 0) {
        saveTransaction(formData);
        let packageArr = Packages;
        let selectedPackage = packageArr.find(
          (el) => el.packageName === description
        );

        const {
          currency,
          amount,
          receiptUrl,
          userEmail,
          userId,
          customerId,
          transactionId,
          ...reduxObj
        } = transactionObj;
        dispatch(packagePurchaseDetailObject(selectedPackage));
        dispatch(CreateStripeDetail(transactionObj));
      } else {
        saveTransaction(formData);
        toast.info(`Package will  be downgraded after ${totalDays} days`);
      }
    }
  };
  const getDays = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const splitDate = (
    formmattedDate,
    totalDays,
    previousPackageAmt,
    amountStripe
  ) => {
    const [day, month, year] = formmattedDate.split("/");
    const daysInMonth = getDays(year, month);
    let newAmm = amountStripe / 100;

    let newCauclatedAmount = getDeductedMoney(
      daysInMonth,
      totalDays,
      previousPackageAmt,
      newAmm
    );
    let result = newCauclatedAmount;

    price = result;
    setnewUpgradedAmount(result);
    return result;
  };
  const getDeductedMoney = (
    daysInMonth,
    totalDays,
    previousPackageAmt,
    amount
  ) => {
    let prevAmout = 50;
    let result = prevAmout / daysInMonth;
    let amountFinal = result * totalDays;
    let newValue = amount - amountFinal;

    return newValue;
  };

  const getDifferenceInDays = (formattedToday) => {
    let created_at = formattedToday;
    let expiryDatee =
      lastStripeTrasactionObject && lastStripeTrasactionObject?.expiryDate;

    const [day, month, year] = created_at.split("/");
    const [exp_day, exp_month, exp_year] = expiryDatee.split("/");

    let expiryDate = new Date(+exp_year, exp_month - 1, +exp_day);
    let todayDate = new Date(+year, month - 1, +day);
    let TotalDays;
    TotalDays = Math.ceil((expiryDate - todayDate) / (1000 * 3600 * 24));
    if (
      typeof TotalDays === typeof undefined ||
      TotalDays === null ||
      isNaN(parseFloat(TotalDays))
    ) {
      TotalDays = 0;
    }
    return TotalDays;
  };
  const customStripeRenwal = () => {
    if (buttonLabel === "upgrade" || buttonLabel === "payment") {
      let returnedVal = amountUpgrader();
      if (
        typeof returnedVal === typeof undefined ||
        returnedVal === null ||
        isNaN(parseFloat(returnedVal))
      ) {
      } else {
        return returnedVal;
        // formData.append("amount", returnedVal);
      }
    }
  };

  const onToken = async (token) => {
    setAlertEnable(false);
    if (token) {
      setIsLoading(true);
      let productObject = {
        email: userEmail,
        amount: priceForStripe,
        description: packageName,
      };
      try {
        let response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/stripe-payment",
          { token: token, product: productObject }
        );
        setIsLoading(false);
        StripeRenwal(response);

        // saveTransaction(response)
      } catch (Err) {
        setIsLoading(false);
      }
    } else {
      toast.error("Something went wrong please try later");
    }
  };

  return (
    <>
      {buttonLabel === "upgrade" ||
      buttonLabel === "subscribe" ||
      buttonLabel === "payment" ? (
        <>
          <NewCustomStripe
          continueDispatch={continueDispatch}
            onupgradeAndDowngrade={onupgradeAndDowngrade}
            buttonLabelDownUp={buttonLabelDownUp}
            disableBtn={disableBtn}
            price={price}
            callSaveTrans={callSaveTrans}
            subscription={subscription}
            customStripeRenwal={customStripeRenwal}
            setIsLoading={setIsLoading}
            label={buttonLabel}
            packageName={packageName}
          />
        </>
      ) : (
        <>
          {/* <StripeCheckout
            name="Tele Health"
            stripeKey={publishableKey}
            label={buttonLabel}
            opened={amountUpgrader}
            image={window.location.origin + "/patient-sense_log-blue.png"}
            description={`Total: $${buttonLabel === "upgrade" ? newUpgradedAmount : price
              }`}
            amount={
              buttonLabel === "upgrade"
                ? newUpgradedAmount * 100
                : priceForStripe
            }
            panelLabel="Pay"
            token={(token) => onToken(token)}
            exp_month="2/23/2022"
            email={userEmail}
          /> */}
          null
        </>
      )}
    </>
  );
};

export default StripeButton;