import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "./Loader/Loader";
import { toast } from "react-toastify";
export default function EmailModal({ setToggleModal, RoomUrl }) {
  const [patientEmail, setPatientEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setToggleModal(false);
  const topCategory = ["Category 1"];
  const [error, setError] = useState(null);
  const state = useSelector((state) => state);
  const userName = state.LoginObject.username;
  let body = `Hello,${
    userName && userName
  } has invited you to join a secure video call:
  ${RoomUrl}
  Please review the Patient Guide (telexy guide) before the call to help ensure your telehealth appointment goes smoothly.
  `;
  const nodeMaileSendMail = async () => {
    let formData = new FormData();
    formData.append("mailsentto", patientEmail);
    formData.append("subject", "Invitation Email");
    formData.append("msg", body);
    formData.append("mailpass", "ug40jdxoflbqm");
    try {
      setIsLoading(true);
      let res = await axios
        .post(process.env.REACT_APP_APIS, formData)
        .then((response) => {
          if (response && response.data) {
            setIsLoading(false);
            handleClose();
            toast.info(response.data);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleClose();
          toast.error("Something went wrong please try later");
        });
      setIsLoading(false);
      handleClose();
      // let res = await axios.post(`http://localhost:3001/create`, { friendlyName: loginUser })
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  useEffect(() => {
    if (patientEmail) {
    } else {
      setError("");
    }
  }, [patientEmail]);
  const isValidEmail = () => {
    let isEmailValid;
    if (patientEmail) {
      isEmailValid =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          patientEmail
        );
      if (isEmailValid) {
        setError("");
      } else {
        setError("Email Invalid");
      }
    } else {
      setError("");
    }

    if (patientEmail === null) {
      setError("");
    }
  };
  const handleChange = (e) => {
    // if (!isValidEmail(e.target.value)) {
    //   setError("Email is invalid");
    //   setPatientEmail(null);
    // } else {
    const value = e.target.value;
    //   //const {name}=e.target
    //   setError(null);
    setPatientEmail(value);
  };
  const navList = topCategory.map((navList , index) => {
    return (
      <button
        className="btn btn-primary dropdown-toggle mr-1"
        data-toggle="dropdown"
        type="button"
        key={index}
      >
        {navList}
      </button>
    );
  });

  return (
    <>
      <div
        className="modal fade show d-block small"
        // id="encounterfieldadd"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ background: "#121212ad" }}
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Invite Via Email
              </h5>
              <button
                style={{
                  padding: "5px",
                  width: "26px",
                  marginTop:'5px'

                }}
                type="button"
                className="btn btn-light"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                ×
              </button>
            </div>

            <div className="modal-body">
              {isLoading ? (
                <Loader small={false} large={true} />
              ) : (
                <>
                  {" "}
                  <div className="form-group">
                    <label htmlFor="Patinet email" className="font-weight-bold">
                      Patient email{" "}
                    </label>
                    <input
                      style={{ borderRadius: "10px" }}
                      type="email"
                      placeholder="Patient Email"
                      className="form-control"
                      id="Patinet email"
                      name="patientEmail"
                      aria-describedby="PatinetName"
                      onChange={handleChange}
                      onBlur={() => {
                        if (patientEmail) isValidEmail();
                      }}
                    />
                    <p className="text-danger ">{error}</p>
                  </div>
                  <hr />
                  <div className="d-flex  justify-content-between">
                    <div>
                      <h3>Invitation</h3>
                    </div>
                    
                  </div>
                  <div
                    style={{ overflow: "auto", height: "150px" }}
                    className="mh-20 mt-3 lead"
                  >
                    <p className="p">
                      {" "}
                      Hi, {userName} has invited you to join a Telxy call.
                      Please review the Patient Guide (telexy guide) before the
                      call to help ensure your telehealth appointment goes
                      smoothly. To join call click on below link
                      <br></br>
                      <br></br>
                      {RoomUrl}
                      <br></br>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={nodeMaileSendMail}
                disabled={error}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
