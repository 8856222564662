import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import Participant from "./Participants";
import GroupCallList from "./GroupList";
import CallActionsButton from "./CallActions";
import { useSelector, useDispatch } from "react-redux";
import { connect, LocalVideoTrack } from "twilio-video";
import {
  PatientEndMsgCounter,
  ScreenShareDetaills,
  AllAcceptedPatients,
} from "../../../Actions/Actions";
import DefaultAudio from "../../../Common/DefaultAudio";
import { SocketContext } from "../../../context/socket";
import GroupParticpant from "./GroupParticipant/GroupParticipant";
import { dominantSpeakerUpdate } from "../../../Utils/ApiManager";
import capitalizeFirst from "../../../Common/CaptilizeFirstLetter";
import store from "../../../Store/Store";
import GroupParticpantAudio from "./GroupParticipant/GroupParticipantAudio";
import Draggable from "react-draggable";

// import { dominantSpeakerUpdate } from "../../../Utils/ApiManager";

const Index = ({
  toggleNoiseCacellationIcon,
  EnableDisableNoiseCancellation,
  isRecrodingOn,
  isRecordingOn,
  isShowCallActionsButton,
  backToGlobalRoom,

  showMessagesSection,
  cameraDevFound,
  videoDomDisable,
  handlePhoneCallEndOnPatientSide,
  providerRoom,
  thiswillHandleLogoutAndStatusOfUser,
  setProviderRoomFN,
  // setCountcountRemoteParticipants
  getParticipantDetails,
}) => {
  let PatientDataDetailss = store && store.getState()?.PatientDataDetails;
  let dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const {
    PatientDataDetails,

    LoginObject,
    ProviderRoomInfo,

    ScreenShareDetails,
    AllPendingPat,
    IsProviderSystmCamEnable,
    IsPatSystmCamEnable,
    AllPatientsAccepted,
  } = useSelector((state) => state);
  // Set draggable the initial position to the center of the screen
  const initialPosition = { x: -250 , y: -400 } 

  let screenTrack;

  const [participants, setParticipants] = useState([]);

  const [toogleFullScreen, settoogleFullScreen] = useState(false);
  const [isVideoDisable, setIsVideoDisable] = useState(false);
  const [isScreenShareEnable, setIsScreenShareEnable] = useState(false);
  const [audioDisable, setAudioDisable] = useState(false);
  const [activeParticipant, setActiveParticipant] = useState(null);
  const [handleSpecificCallEndEffect, sethandleSpecificCallEndEffect] =
    useState(false);

  const [whenPatEndCallInfo, setWhenPatEndCallInfo] = useState(null);
  const [PatReloadObj, setPatReloadObj] = useState(null);
  const [partipantInCaseForScreenShare, setParticpantInCaseForScreenShare] =
    useState(null);
  const [isScreenShare, setIsScreenShare] = useState(false);

  const [selectedParticipant, setSelectedParticipant] = useState(0);
  const [selectedPartInCaseScreenShare, setSelectedPartInCaseScreenShare] =
    useState(false);
  const [screenShareButtonToggle, setScreenShareButtonToggle] = useState(false);
  const [reconnectingParticpant, setReconnectingParticpant] = useState(null);
  const [isReconnectedPart, setIsReconnectedPart] = useState(null);
  const [networkLevelForGroup, setNetworkLevelForGroup] = useState([]);
  const [groupReconnectingScreenDisplay, setGroupReconnectingScreenDisplay] =
    useState(false);
  const [groupReconnectingPart, setGroupReconnectingPart] = useState(null);

  console.log("groupReconnectingScreenDisplay", groupReconnectingScreenDisplay);
  // For Recooneted in group-------------------------------------
  useEffect(() => {
    socket.on("enable-reconnected-on-other-side-in-group", (data) => {
      console.log("For Recconected 1", data, PatientDataDetails, LoginObject);
      if (
        LoginObject.username &&
        data.reconnectingMark === LoginObject.fullname
      ) {
        setGroupReconnectingScreenDisplay(false);
      } else if (
        !LoginObject.username &&
        data.reconnectingMark === PatientDataDetails.joiningUser
      ) {
        setGroupReconnectingScreenDisplay(false);
      }
    });
  }, []);
  useEffect(() => {
    if (isReconnectedPart === undefined) {
      return;
    }
    if (isReconnectedPart !== null) {
      if (!LoginObject.username) {
        toast.info(
          `Reconnected ${isReconnectedPart.localParticipant.identity}`
        );

        participants &&
          participants.map((el) => {
            let obj = {};

            obj.reconnectingMark = el.identity;
            obj.connectionLossPartipant =
              isReconnectedPart.localParticipant.identity;

            socket.emit("enable-reconnected-on-other-side-in-group", obj);
          });

        setGroupReconnectingScreenDisplay(false);
        setProviderRoomFN(isReconnectedPart);
      }
      if (
        LoginObject.username &&
        LoginObject.fullname == isReconnectedPart.localParticipant.identity
      ) {
        toast.info(
          `Reconnected ${isReconnectedPart.localParticipant.identity} `
        );
        let obj = {};
        AllPatientsAccepted &&
          AllPatientsAccepted.map((el) => {
            obj.patId = el.id;
            obj.reconnectingMark = el.lname + el.fname;
            obj.providerId = LoginObject.id;
            obj.connectionLossPartipant =
              isReconnectedPart.localParticipant.identity;
            socket.emit("enable-reconnected-on-other-side-in-group", obj);
          });

        setGroupReconnectingScreenDisplay(false);
        setProviderRoomFN(isReconnectedPart);
      }
    }
  }, [isReconnectedPart]);

  //-----------------------For Reconnecting in group---------------------
  useEffect(() => {
    if (reconnectingParticpant === undefined) {
      return;
    }

    if (reconnectingParticpant !== null) {
      if (
        !LoginObject.username &&
        PatientDataDetailss.joiningUser == reconnectingParticpant.identity
      ) {
        toast.info(`Reconnecting ${reconnectingParticpant.identity}`);

        participants &&
          participants.map((el) => {
            let obj = {};

            obj.reconnectingMark = el.identity;
            obj.connectionLossPartipant = reconnectingParticpant.identity;

            socket.emit("enable-reconnecting-on-other-side-in-group", obj);
          });

        setGroupReconnectingScreenDisplay(true);
        setIsReconnectedPart(null);
      }
      if (
        LoginObject.username &&
        LoginObject.fullname == reconnectingParticpant.identity
      ) {
        toast.info(`Reconnecting ${reconnectingParticpant.identity} `);

        AllPatientsAccepted &&
          AllPatientsAccepted.map((el) => {
            let obj = {};
            obj.patId = el.id;
            obj.reconnectingMark = el.lname + el.fname;
            obj.providerId = LoginObject.id;
            obj.connectionLossPartipant = reconnectingParticpant.identity;
            socket.emit("enable-reconnecting-on-other-side-in-group", obj);
          });

        setGroupReconnectingScreenDisplay(true);
        setIsReconnectedPart(null);
      }
    }
  }, [reconnectingParticpant]);

  useEffect(() => {
    socket.on("enable-reconnecting-on-other-side-in-group", (data) => {
      if (
        LoginObject.username &&
        data.reconnectingMark === LoginObject.fullname
      ) {
        setGroupReconnectingPart(data.connectionLossPartipant);
        setGroupReconnectingScreenDisplay(true);
      } else if (
        !LoginObject.username &&
        data.reconnectingMark === PatientDataDetails.joiningUser
      ) {
        setGroupReconnectingPart(data.connectionLossPartipant);
        setGroupReconnectingScreenDisplay(true);
      }
    });
  }, []);

  const participantConnected = (participant) => {
    let ifExist = participants.find(
      (el) => el.identity === participant.identity
    );

    console.log("showMessagesSection", showMessagesSection);
    if (ifExist && ifExist.identity) {
      let temp = [...participants];
      temp.map((el) => {
        if (el.identity === participant.identity) {
          el = participant;
        }
      });
      setParticipants(temp);
    } else {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    }
  };
  const participantNetworkQualityIndicator = (participant) => {
    console.log("this thing trigger good", participant);
    participant.on(
      "networkQualityLevelChanged",
      (networkQualityLevel, networkQualityStats) => {
        console.log(
          "networkQualityLevel networkQualityStats",
          networkQualityLevel,
          networkQualityStats
        );
        printNetworkQualityStats(networkQualityLevel, participant);
      }
    );
  };
  const printNetworkQualityStats = (networkQualityLevel, participant) => {
    let obj = {
      participantIdentity: participant.identity,
      levels: networkQualityLevel,
    };

    setNetworkLevelForGroup((prevState) => {
      let foundIndex = prevState.findIndex(
        (stateObj) => stateObj.participantIdentity === obj.participantIdentity
      );
      if (foundIndex === -1) {
        return [...prevState, obj];
      } else if (prevState[foundIndex].levels !== obj.levels) {
        prevState[foundIndex].levels = obj.levels;
        return [...prevState];
      } else {
        return prevState;
      }
    });
  };
  console.log("networkLevelForGroup", networkLevelForGroup);

  useEffect(() => {
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };
    if (providerRoom) {
      providerRoom.on("participantConnected", participantConnected);
      // ----------------------For Reconnecting ------------------
      providerRoom.on("reconnecting", (error) => {
        console.log(providerRoom.state, "room state");

        console.log(providerRoom, "reconncting Provider Room");
        // getReconnectingParticipant(providerRoom.localParticipant);
        if (error.code === 53001) {
          console.log("Reconnecting your signaling connection!", error.message);
        } else if (error.code === 53405) {
          console.log("Reconnecting your media connection!", error.message);
        }
      });
      providerRoom.on("reconnected", () => {
        console.log(providerRoom.state, "room state Reconnected");
        console.log("room state value", providerRoom);
        // getReconnectedParticipant(providerRoom);

        console.log("Reconnected your signaling and media connections!");
      });

      //---------------------------For Disconeecting--------------------------
      // providerRoom.on('disconnected', (room, error) => {
      //   console.log('room Disconenction',room)
      //    if (error.code === 20104) {
      //      console.log('Signaling reconnection failed due to expired AccessToken!');
      //    } else if (error.code === 53000) {
      //      console.log('Signaling reconnection attempts exhausted!');
      //    } else if (error.code === 53002) {
      //      console.log('Signaling reconnection took too long!');
      //    }
      //  });
      providerRoom.on("participantDisconnected", participantDisconnected);
      providerRoom &&
        providerRoom.participants.forEach(participantNetworkQualityIndicator);

      providerRoom && providerRoom.participants.forEach(participantConnected);

      providerRoom.on("participantConnected", (participant) => {
        toast.info(`${participant.identity} joined you`);
      });

      providerRoom.on("participantDisconnected", (participant) => {
        toast.info(`${participant.identity} leave conversation`);
      });

      providerRoom.on("dominantSpeakerChanged", onDominantSpeaker);
    }

    return () => {
      if (providerRoom) {
        providerRoom.off("participantConnected", participantConnected);
        providerRoom.off("participantDisconnected", participantDisconnected);
      }
    };
  }, [providerRoom]);

  const onDominantSpeaker = (participant) => {
    gettingActiveCallerInTheRoom(participant);
  };
  // useEffect(() => {

  //   if (providerRoom && !isScreenShareEnable) {

  //     providerRoom.on("dominantSpeakerChanged", (participant) => {
  //       gettingActiveCallerInTheRoom(participant);
  //     });
  //   }
  //   return () => {
  //     if (providerRoom && isScreenShareEnable) {
  //       providerRoom.off("dominantSpeakerChanged", gettingActiveCallerInTheRoom);
  //     }
  //   };

  // }, [providerRoom, isScreenShareEnable]);

  useEffect(() => {
    if (participants) {
      // testing for dominnat speaking functionality stops
      // participants.map((participant, index) => {
      //   let tracks = participant.videoTracks.size
      //   if (tracks > 1) {
      //     providerRoom.off('dominantSpeakerChanged', onDominantSpeaker);
      //   }
      // })

      socket.on("End-Call-With-Specific-Patient", (obj) => {
        sethandleSpecificCallEndEffect(obj);
      });
      socket.on("When-Patient-End-Call", (obj) => {
        console.log("When-Patient-End-Call", obj);
        if (LoginObject.username) {
          removeAcceptedPatList(obj);
        }

        setWhenPatEndCallInfo(obj);
      });
      // let remoteUsers = participants.map((participant, index) => {
      //   let tracks = participant.videoTracks.size
      //   if (tracks > 1) {
      // }
      // })
      providerRoom &&
        providerRoom.participants.forEach(participantNetworkQualityIndicator);
      getParticipantDetails(participants);
      // const partipant = participants.filter((element) => {
      //   let el=element.videoTracks
      //   let res=Object.keys(el).length

      //        return  res > 1;
      // });

      // if (partipant.length > 0) {
      //   setIsScreenShare(true);
      // } else {
      //   setIsScreenShare(false);
      // }
      // setParticpantInCaseForScreenShare(partipant);

      // setCountcountRemoteParticipants(participants)
    }
  }, [participants]);
  useEffect(() => {
    if (participants.length > 1 && providerRoom) {
      socket.emit("toggle_message_section_on_groupCall", true);
      // toast.info('you are entering in group call')
    }
  }, [participants]);
  const removeAcceptedPatList = (obj) => {
    const { patId } = obj;

    let filteredArr =
      AllPatientsAccepted &&
      AllPatientsAccepted.filter((obj) => obj.id == patId);
    dispatch(AllAcceptedPatients(filteredArr));
  };

  useEffect(() => {
    if (handleSpecificCallEndEffect && participants) {
      let shortObj = handleSpecificCallEndEffect.patientObj;
      let name = shortObj.fname + shortObj.lname;
      let participant = participants.filter((element) => {
        return element.identity === name;
      });
      if (participant) {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p.identity !== name)
        );
        sethandleSpecificCallEndEffect(null);
      }
    }
  }, [handleSpecificCallEndEffect]);
  useEffect(() => {
    if (whenPatEndCallInfo && participants) {
      let shortObj = whenPatEndCallInfo.patientObj;
      let name = shortObj.joiningUser;
      let participant = participants.filter((element) => {
        return element.identity === name;
      });
      if (participant && participant.length > 0) {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p.identity !== name)
        );
        setWhenPatEndCallInfo(null);
      }
    }
  }, [whenPatEndCallInfo]);

  useEffect(() => {
    if (activeParticipant && participants && participants.length > 0) {
      //-------------------------------------------------------------------//
      let currentParticipantInCaseScrenShare = participants.findIndex(
        (part) => part.identity === activeParticipant
      );
      if (currentParticipantInCaseScrenShare >= 0) {
        setSelectedPartInCaseScreenShare(currentParticipantInCaseScrenShare);
      } else {
        setSelectedPartInCaseScreenShare(0);
      }
      //----------------------------------------------------------------------
      let isScreenShareExist = null;
      let currentIdentity = null;
      let currentIndex = null;
      participants.map((part, index) => {
        if (part.videoTracks.size == 1) {
          const tracks = Array.from(part.videoTracks.values()).map(
            (publication) => publication.track
          );
          if (tracks[0]?.name == "screen") {
            //it means screen is shared in audio
            currentIdentity = part.identity;
            // screenshared true and identity matched with dominant participant
            isScreenShareExist = true;
            currentIndex = index;
          }
        }

        if (part.videoTracks.size > 1) {
          //it means screen is shared
          currentIdentity = part.identity;
          // screenshared true and identity matched with dominant participant
          isScreenShareExist = true;
          currentIndex = index;
        }
      });
      if (isScreenShareExist) {
        setSelectedParticipant(currentIndex);
      } else {
        // without screenshare it run dominant speaker
        let currentParticipant = participants.findIndex(
          (part) => part.identity === activeParticipant
        );
        if (currentParticipant >= 0) {
          setSelectedParticipant(currentParticipant);
        } else {
          setSelectedParticipant(0);
        }
      }
    }
  }, [activeParticipant]);

  useEffect(() => {
    console.log("running effect due to pending");
    if (AllPendingPat && participants) {
      const difference = [
        //...getDifference(participants, AllPendingPat),
        ...getDifference(AllPendingPat, participants),
      ];

      let obj = Object.assign({}, ...difference);

      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p.identity !== obj?.fname + obj?.lname)
      );
    }
  }, [AllPendingPat]);

  const getDifference = (array1, array2) => {
    return array1.filter((object1) => {
      return array2.some((object2) => {
        return object1.fname + object1.lname === object2.identity;
      });
    });
  };

  const gettingActiveCallerInTheRoom = (participant) => {
    if (participant && participant.identity) {
      setActiveParticipant(participant.identity);
    }
  };

  // it will toggle particpant to main screen from smaller screen
  const toggleSelectedParticipant = (index) => {
    if (index) {
      setSelectedParticipant(index);
    }
  };

  // it will disable or enable my video in room call
  const onClickDisableVideo = (check) => {
    if (check) {
      if (providerRoom.localParticipant.videoTracks.size > 1) {
        let videoTrack = Array.from(
          providerRoom.localParticipant.videoTracks.values()
        )[0];
        videoTrack.track.disable();
      } else {
        providerRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.disable();
        });
      }
    } else {
      providerRoom.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
      });
    }
    setIsVideoDisable(check);
  };

  // it will disable or enable my audio in room call
  const onClickDisableAudio = (check) => {
    if (check) {
      if (providerRoom.localParticipant.videoTracks.size > 1) {
        let audioTrack = Array.from(
          providerRoom.localParticipant.audioTracks.values()
        )[0];
        audioTrack.track.disable();
      } else {
        providerRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });
      }
    } else {
      providerRoom.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
      });
    }
    setAudioDisable(check);
  };

  const shareScreenToTheRoom = async (shareScreen) => {
    let obj = {};
    // For Proivder Side Checking
    if (ProviderRoomInfo) {
      obj.token = ProviderRoomInfo.usertoken;
      obj.RoomName = ProviderRoomInfo.roomname;
    } else {
      //For Patient Side Checking
      obj.token = PatientDataDetailss.token;
      obj.RoomName = PatientDataDetailss.roomName;
    }

    if (shareScreen) {
      navigator.mediaDevices
        .getDisplayMedia()
        .then((stream) => {
          screenTrack = new LocalVideoTrack(stream.getTracks()[0], {
            name: "screen",
          });
          providerRoom.localParticipant.publishTrack(screenTrack);
          screenTrack.mediaStreamTrack.onended = () => {
            shareScreenToTheRoom(false);
          };
          setIsScreenShareEnable(true);
          setProviderRoomFN(providerRoom);
          let screenShareobj = {};
          screenShareobj.screenLocalTrack = screenTrack;
          dispatch(ScreenShareDetaills(screenShareobj));
        })
        .catch(() => {
          alert("Could not share the screen.");
        });
    } else {
      const { room, screenLocalTrack } = ScreenShareDetails;
      providerRoom.localParticipant.unpublishTrack(screenLocalTrack);
      screenLocalTrack.stop();
      setIsScreenShareEnable(false);
      setProviderRoomFN(providerRoom);
    }
  };

  const stopScreenAndJoinWithVideo = async () => {
    let resumeCallobj = {};

    // For Proivder Side Checking
    if (ProviderRoomInfo) {
      resumeCallobj.token = ProviderRoomInfo.usertoken;
      resumeCallobj.RoomName = ProviderRoomInfo.roomname;
    } else {
      //For Patient Side Checking
      resumeCallobj.token = PatientDataDetailss.token;
      resumeCallobj.RoomName = PatientDataDetailss.roomName;
    }
    let videoRoom = await connect(resumeCallobj.token, {
      name: resumeCallobj.RoomName,
    });
    setProviderRoomFN(videoRoom);
    let clearReduxScreenShareDetails = {};
    clearReduxScreenShareDetails.room = null;
    clearReduxScreenShareDetails.setscreenLocalTrack = null;
    clearReduxScreenShareDetails.shareScreen = false;
    dispatch(ScreenShareDetaills(clearReduxScreenShareDetails));

    // setIsScreenShareEnable(false);

    // setscreenLocalTrack(null);
  };

  const stopVideoRoom = () => {
    setProviderRoomFN((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
        // setIsAudioCallModal(false);
        // setAudioConnecting(true);
      }
      return null;
    });
  };

  const toggleScreenShareButton = (screenCheck) => {
    setScreenShareButtonToggle(screenCheck);
  };
  const getReconnectingParticipant = (reconnectingParticpants) => {
    setReconnectingParticpant(reconnectingParticpants);
  };
  const getReconnectedParticipant = (reconnectedParticpants) => {
    setIsReconnectedPart(reconnectedParticpants);
  };

  let remoteParticipant = null;
  let groupParticipantAudio = null;
  if (participants.length > 0 && participants.length === 1 && providerRoom) {
    remoteParticipant = (
      <Participant
        screenShareButtonToggle={screenShareButtonToggle}
        isShowCallActionsButton={isShowCallActionsButton}
        groupReconnectingPart={groupReconnectingPart}
        groupReconnectingScreenDisplay={groupReconnectingScreenDisplay}
        setIsReconnectedPart={setIsReconnectedPart}
        setProviderRoomFN={setProviderRoomFN}
        isReconnectedPart={isReconnectedPart}
        isReconnectingParticpant={reconnectingParticpant}
        getReconnectingParticipant={getReconnectingParticipant}
        room={providerRoom}
        index={0}
        part={participants}
        key={participants[0]?.sid}
        participant={participants[0]}
        isGroupCall={participants.length > 1}
        localScreen={false}
        toggleScreenShareButton={toggleScreenShareButton}
      />
    );
  } else if (participants.length > 1 && providerRoom) {
    // let screenSharedIndex = null
    // participants.map((part, index) => {
    //   if (part.videoTracks.size > 1) {
    //     screenSharedIndex = index
    //   }
    // })
    remoteParticipant = (
      <GroupParticpant
        isShowCallActionsButton={isShowCallActionsButton}
        groupReconnectingPart={groupReconnectingPart}
        groupReconnectingScreenDisplay={groupReconnectingScreenDisplay}
        localScreen={false}
        room={providerRoom}
        index={false ? selectedParticipant : null}
        part={participants}
        key={participants[selectedParticipant]?.sid}
        participant={participants[selectedParticipant]}
        isGroupCall={participants.length > 1}
        toggleScreenShareButton={toggleScreenShareButton}
      />
    );
    // Group particpatn audio saperate
    groupParticipantAudio = participants.map((participant, index) => {
      return (
        <GroupParticpantAudio
          index={index}
          key={participant.sid}
          participant={participant}
        />
      );
    });
  }

  let provider = LoginObject && LoginObject.username;
  // ****************************For Patient ***************
  let patVideoCall = null;
  if (!provider) {
    if (IsPatSystmCamEnable) {
      patVideoCall = true;
    } else {
      patVideoCall = false;
    }
  }

  // ******************************For Provider *********************
  let providerVideoCall = null;
  if (provider) {
    if (IsProviderSystmCamEnable) {
      providerVideoCall = true;
    } else {
      providerVideoCall = false;
    }
  }
  console.log("provider Room", providerRoom);

  return (
    <>
      {/* <ul>{remoteParticipants}</ul> */}
      {!isShowCallActionsButton ? (
        <div className="chat-body pt-4 pb-0">
          <div
            className={
              toogleFullScreen
                ? "video-screen-inner blur-bg FullScreen"
                : "video-screen-inner blur-bg"
            }
          >
            {/* Not involve in Audio */}
            {!remoteParticipant ? (
              providerRoom && providerVideoCall ? (
                <DefaultAudio
                  circle={false}
                  loader={true}
                  text={
                    LoginObject && LoginObject.username
                      ? "Accept patient call to join you"
                      : "Please Wait "
                  }
                />
              ) : (
                <DefaultAudio
                  circle={
                    providerVideoCall
                      ? false
                      : participants.length == 0
                      ? false
                      : true
                  }
                  loader={provider ? providerVideoCall : patVideoCall}
                  text={
                    LoginObject && LoginObject.username
                      ? "Accept patient call to join you"
                      : "Please Wait "
                  }
                />
              )
            ) : (
              <>
                {remoteParticipant}
                {groupParticipantAudio}
              </>
            )}
            {/* ------------------ ---------------*/}
            <div className="call-user-avatar">
              {/* <>
                  {
                    providerRoom.localParticipant && providerRoom.localParticipant.map((local, k) => {
                      return (
                        <Participant
                          key={local.sid}
                          participant={local}
                        />
                      )
                    })
                  }
                </> */}
              {providerRoom ? (
                <Participant
                  screenShareButtonToggle={screenShareButtonToggle}
                  localScreen={true}
                  isVideoDisable={isVideoDisable}
                  key={providerRoom.localParticipant.sid}
                  participant={providerRoom.localParticipant}
                  toggleScreenShareButton={toggleScreenShareButton}
                />
              ) : (
                <DefaultAudio
                  circle={providerVideoCall ? false : true}
                  loader={IsProviderSystmCamEnable ? true : false}
                  AudioName={
                    provider
                      ? capitalizeFirst(provider[0]) +
                        capitalizeFirst(provider[1])
                      : null
                  }
                />
              )}
            </div>
            {/* <div className="record-time">
                        <span>40:12</span>
                    </div> */}
            {providerRoom && participants && participants.length > 1 ? (
              <GroupCallList
                groupReconnectingPart={groupReconnectingPart}
                groupReconnectingScreenDisplay={groupReconnectingScreenDisplay}
                networkLevelForGroup={networkLevelForGroup}
                selectedParticipant={selectedPartInCaseScreenShare}
                toggleSelectedParticipant={toggleSelectedParticipant}
                providerRoom={providerRoom}
                remoteParticipants={participants}
                participant={participants[selectedPartInCaseScreenShare]}
                isScreenShareEnable={isScreenShareEnable}
              />
            ) : undefined}
            {/* NOt involve in Audio */}
            <CallActionsButton
              toggleNoiseCacellationIcon={toggleNoiseCacellationIcon}
              EnableDisableNoiseCancellation={EnableDisableNoiseCancellation}
              callActionCircle={false}
              isRecrodingOn={isRecrodingOn}
              isRecordingOn={isRecordingOn}
              backToGlobalRoom={backToGlobalRoom}
              isShowCallActionsButton={false}
              cameraDevFound={cameraDevFound}
              handlePhoneCallEndOnPatientSide={handlePhoneCallEndOnPatientSide}
              thiswillHandleLogoutAndStatusOfUser={
                thiswillHandleLogoutAndStatusOfUser
              }
              settoogleFullScreen={settoogleFullScreen}
              toogleFullScreen={toogleFullScreen}
              onClickDisableVideo={onClickDisableVideo}
              isVideoDisable={isVideoDisable}
              onClickDisableAudio={onClickDisableAudio}
              audioDisable={audioDisable}
              isScreenShared={isScreenShareEnable}
              shareScreenToTheRoom={shareScreenToTheRoom}
              screenShareButtonToggle={screenShareButtonToggle}
            />
          </div>
        </div>
      ) : (
        <>
          {" "}
          {!remoteParticipant ? null : (
            <>
              {remoteParticipant}
              {groupParticipantAudio}
            </>
          )}
          <Draggable  defaultPosition={initialPosition}>
            <div>
              <div className="d-flex flex-column">
                <div className="mb-auto">
                  {" "}
                  <CallActionsButton
                    callActionCircle={true}
                    isRecrodingOn={isRecrodingOn}
                    isRecordingOn={isRecordingOn}
                    backToGlobalRoom={backToGlobalRoom}
                    isShowCallActionsButton={isShowCallActionsButton}
                    cameraDevFound={cameraDevFound}
                    handlePhoneCallEndOnPatientSide={
                      handlePhoneCallEndOnPatientSide
                    }
                    thiswillHandleLogoutAndStatusOfUser={
                      thiswillHandleLogoutAndStatusOfUser
                    }
                    settoogleFullScreen={settoogleFullScreen}
                    toogleFullScreen={toogleFullScreen}
                    onClickDisableVideo={onClickDisableVideo}
                    isVideoDisable={isVideoDisable}
                    onClickDisableAudio={onClickDisableAudio}
                    audioDisable={audioDisable}
                    isScreenShared={isScreenShareEnable}
                    shareScreenToTheRoom={shareScreenToTheRoom}
                    screenShareButtonToggle={screenShareButtonToggle}
                  />
                </div>
              </div>
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

export default Index;
