import React, { useEffect, useState, useContext } from "react";
import Loader from "../../Common/Spinner";
import {
  createCompositionss,
  deleteRecording,
  getAllRecordingList,
  getAllRooms,
  getRoomHistory,
} from "../../Utils/ApiManager";
import { useSelector } from "react-redux";
import { SocketContext } from "../../context/socket";
import CompositionRecordings from "./compositionRecordings";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import moment from "moment/moment";
import { secondsToHms } from "../../Utils/commonFunctions";
import ReactPaginate from "react-paginate";
export default function Recordings() {
  const today = new Date().toISOString().slice(0, 10);
  const socket = useContext(SocketContext);
  const [roomWithRecoridngs, setRoomWithRecordings] = useState(null);
  const [finalRecordings, setFinalRecordings] = useState(null);
  const [recordingStatus, setRecordingStatus] = useState(null);
  const [composeButton, setComposeButtonDisabled] = useState(null);
  const [isViewMediaOnScreenset, setIsViewMediaOnScreen] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [compositions, setCompositions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onDeleteRender, setOnDeleteRender] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const { StripePackagePurchaseDetailObject, LoginObject } = useSelector(
    (state) => state
  );
  const optionsForVideoFormates = ["Audio", "Video", "Defaults"];

  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  let itemsPerPage = 7;
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % finalRecordings.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  const onOptionChangeHandlerForFormate = (event) => {
    console.log("User Selected Value - ", event.target.value);
  };
  useEffect(() => {
    if (recordingStatus) {
      toast.info(recordingStatus);
    }
  }, [recordingStatus]);
  useEffect(() => {
    DifferentiateComposedOrNotComposed(compositions, roomWithRecoridngs);
  }, [compositions, roomWithRecoridngs, onDeleteRender]);

  useEffect(() => {
    socket.on("status-update", async (status) => {
      let statusText;

      switch (status) {
        case "composition-request":
          statusText = "Sent request for composition.";
          setRecordingStatus("Composition Started");

          break;

        case "composition-started":
          statusText = "Composition has begun! ";
          break;

        case "composition-available":
          statusText =
            "Your composition is now available! Reloading in 3 seconds...";
          break;

        case "composition-progress":
          statusText = `Working... `;
          break;

        case "composition-failed":
          statusText = "Composition has failed.";
          break;

        case "composition-deleted":
          statusText = "Composition deleted. ✅ Reloading in 3 seconds...";
          break;

        default:
          statusText = "";
          break;
      }
    });
  }, []);

  useEffect(() => {
    // callCompostionApi();
    getRoomAHistory();
  }, []);

  const setDate = (event) => {
    const { name } = event.target;
    if (name == "startDate") {
      let date = new Date(event.target.value);
      let isoDate = date.toISOString();
      setSelectedStartDate(isoDate);
      setSelectedEndDate(null);
      return;
    }
    if (name == "endDate") {
      let date = new Date(event.target.value);
      let isoDate = date.toISOString();
      setSelectedEndDate(isoDate);
      setSelectedStartDate(null);
      return;
    }
  };

  const DifferentiateComposedOrNotComposed = (
    compositions,
    roomWithRecoridngs
  ) => {
    if (
      compositions &&
      compositions.length == 0 &&
      roomWithRecoridngs &&
      roomWithRecoridngs.length > 0
    ) {
      let matchingObjs = roomWithRecoridngs.map((zObj) => {
        let statuss = "NotComposed";

        return { sid: zObj, statuss: statuss };
      });

      console.log("matchingObjs", matchingObjs);
      setFinalRecordings(matchingObjs);
      return;
    }
    if (
      compositions &&
      compositions.length > 0 &&
      roomWithRecoridngs &&
      roomWithRecoridngs.length > 0
    ) {
      let matchingObjs = roomWithRecoridngs.map((zObj) => {
        let statuss = "NotComposed";
        let matchingCompositions = compositions.filter((compObj) => {
          return zObj.sid === compObj.sid;
        });
        if (matchingCompositions.length > 0) {
          statuss = "Composed";
        }
        return { sid: zObj, statuss: statuss };
      });

      console.log("matchingObjs", matchingObjs);
      setFinalRecordings(matchingObjs);
    }
  };
  const getRoomAHistory = () => {
    setIsLoading(true);
    let obj = {
      dateCreatedAfter: selectedStartDate ? selectedStartDate : null,
      dateCreatedBefore: selectedEndDate ? selectedEndDate : null,
      roomName: LoginObject.username,
    };
    getAllRooms(obj)
      .then((res) => {
        if (res && res.data) {
          setIsLoading(false);
          setRoomWithRecordings(res.data.roomSidsWithRecordings);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const createComposition = async (el, index) => {
    let sid = el.sid.sid;
    let obj = { roomSid: sid };
    let res = await createCompositionss(obj);
    if (res && res.data) {
      setComposeButtonDisabled(index);
    }
  };
  const isViewMediaCallBack = (val, url) => {
    setMediaUrl(url);
    setIsViewMediaOnScreen(val);
  };
  const BackToMainScreen = (val) => {
    setIsViewMediaOnScreen(val);
    setMediaUrl(null);
  };
  const IsComposedButton = (AllRoomSid) => {
    let arr = [];
    AllRoomSid &&
      AllRoomSid.map((ele) => {
        if (ele.compositions.length > 0) {
          arr.push(ele);
        }
      });
    setCompositions(arr);
  };
  console.log("compositions", compositions);
  const getCompositions = (val) => {
    deleteRecording().then((res) => { });
  };
  const clearFilters = () => {
    setSelectedEndDate(null);
    setSelectedStartDate(null);
  };
  //paginate
  useEffect(() => {
    if (finalRecordings && finalRecordings != null) {
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(finalRecordings.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(finalRecordings.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, finalRecordings]);
  return (
    <>
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <>
              {isViewMediaOnScreenset ? (
                <>
                  <p className="card-title  w-100 text-center">Video</p>
                  <button
                    className=" p-2 "
                    style={{ border: 'none', background: 'none', position: 'absolute', left: '4px', top: '6px' }}
                    onClick={() => BackToMainScreen(false)}
                  >
                    <i className="fas fa-arrow-alt-circle-left text-primary " style={{ fontSize: '25px' }}> </i>
                  </button>
                  <div className=" player-wrapper">
                    <ReactPlayer
                      url={mediaUrl}
                      playing={true}
                      className="react-player"
                      controls={true}
                      loop={true}
                      width="100%"
                      height="81%"
                    />
                  </div>
                </>
              ) : (
                <>
                  {false ? (
                    <Loader small={false} large={true} />
                  ) : (
                    <>
                      <p className="card-title">Recent Room with Recording </p>
                      <div
                      className="meetinghistory"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "10px",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* <div style={{ marginRight: "10px" }}>
                          <div>
                            {" "}
                            <label htmlFor="media type"> Media Type</label>
                          </div>
                          <select
                            className="form-control"
                            class="fas fa-angle-down  fw-light "
                            style={{ height: "34px", width: "200px" }}
                            onChange={onOptionChangeHandlerForFormate}
                          >
                            <option>Choose Formate</option>
                            {optionsForVideoFormates.map((option, index) => {
                              return <option key={index}>{option}</option>;
                            })}
                          </select>
                        </div> */}
                        <div>
                          <div className=" " style={{ marginRight: "10px" }}>
                            <label htmlFor="start Date" className=" ">
                              Date Created After
                            </label>
                            <input
                              style={{ height: "35px", width: "240px" }}
                              type="date"
                              className="form-control "
                              name="startDate"
                              onChange={setDate}
                              max={today}
                              value={
                                selectedStartDate
                                  ? new Date(selectedStartDate)
                                    .toISOString()
                                    .substr(0, 10)
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="">
                            <label htmlFor="start Date">
                              {" "}
                              Date Created Before
                            </label>
                            <input
                              style={{ height: "35px", width: "240px" }}
                              value={
                                selectedEndDate
                                  ? new Date(selectedEndDate)
                                    .toISOString()
                                    .substr(0, 10)
                                  : ""
                              }
                              type="date"
                              className="form-control"
                              name={"endDate"}
                              onChange={setDate}
                              max={today}
                            // defaultValue={endDate}
                            />
                          </div>
                        </div>

                        <div className="d-flex ">
                        <div
                          className=" align-self-center"
                          style={{ textAlign: "end" }}
                        >
                          <button
                            style={{
                              marginLeft: "10px",
                              marginTop: "25px",
                            }}
                            className="btn  btn-primary "
                            onClick={() => clearFilters()}
                          >
                            Clear
                          </button>
                        </div>
                        <div
                          className=" align-self-center"
                          style={{ textAlign: "end" }}
                        >
                          <button
                            style={{
                              marginRight: "20px",
                              marginLeft: "10px",
                              marginTop: "25px",
                            }}
                            className="btn  btn-primary "
                            onClick={getRoomAHistory}
                          >
                            Search
                          </button>
                        </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive container-fluid">
                            <div
                              id="example_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12 col-md-6"></div>
                                <div className="col-sm-12 col-md-6"></div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    id="example"
                                    className="display expandable-table responsivee-table dataTable no-footer w-100"
                                   
                                    role="grid"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="select-checkbox "
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Quote#"
                                          style={{ width: "176px" }}
                                        >
                                          Room Name
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="example"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          style={{ width: "210px" }}
                                        >
                                          Start Time
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="example"
                                          rowSpan="1"
                                          colSpan="1"
                                          style={{ width: "210px" }}
                                        >
                                          End Time
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="example"
                                          rowSpan="1"
                                          colSpan="1"
                                          style={{ width: "210px" }}
                                        >
                                          Call Duration
                                        </th>

                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="example"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Status: activate to sort column ascending"
                                          style={{ width: "196px" }}
                                        >
                                          Action{" "}
                                        </th>
                                        {/* <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Updated at: activate to sort column ascending"
                          style={{ width: "155px" }}
                        >
                          Updated at
                        </th> */}
                                        <th
                                          className="details-control sorting_disabled"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label=""
                                          style={{ width: "53" }}
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {isLoading ? (
                                        <Loader small={false} large={true} />
                                      ) : (
                                        <>
                                          {" "}
                                          {currentItems &&
                                            currentItems.length > 0 ? (
                                            currentItems.map((el, index) => {
                                              return (
                                                <>
                                                  <tr
                                                    className="odd"
                                                    key={index}
                                                  >
                                                    <td>{el.sid.uniqueName}</td>

                                                    <td>
                                                      {moment(
                                                        el.sid.dateCreated
                                                      ).format(
                                                        "MM-DD-YYYY, h:mm a"
                                                      )}
                                                    </td>
                                                    <td>
                                                      {moment(
                                                        el.sid.dateUpdated
                                                      ).format(
                                                        "MM-DD-YYYY, h:mm a"
                                                      )}
                                                    </td>
                                                    <td>
                                                      {secondsToHms(
                                                        el.sid.duration
                                                      )}
                                                    </td>
                                                    {el.statuss ==
                                                      "NotComposed" ? (
                                                      <td>
                                                        {
                                                          <span
                                                            onClick={() =>
                                                              createComposition(
                                                                el
                                                              )
                                                            }
                                                            className="badge badge-warning-blue badge-pill px-25"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            Start Compose
                                                          </span>
                                                        }
                                                      </td>
                                                    ) : (
                                                      <td>
                                                        <span className="badge badge-warning-green badge-pill px-25">
                                                          Composed
                                                        </span>
                                                      </td>
                                                    )}
                                                  </tr>
                                                </>
                                              );
                                            })
                                          ) : (
                                            <tr
                                              className="odd"
                                            // key={index}
                                            >
                                              <td></td>

                                              <br></br>
                                              <td>
                                                <span className="badge badge-warning-grey badge-pill px-25">
                                                  <p style={{ color: "Black" }}>
                                                    {" "}
                                                    No Records to show
                                                  </p>
                                                </span>
                                              </td>
                                              <td></td>
                                              <br></br>
                                            </tr>
                                          )}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5"></div>
                                <div className="col-sm-12 col-md-7"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginTop: "10px",
                              paddingRight: "25px",
                              paddingTop: "10px",
                            }}
                          >
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel="next >"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageCount}
                              previousLabel="< previous"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "10px",
                            paddingRight: "25px",
                            paddingTop: "10px",
                          }}
                        ></div>
                      </div>
                      <CompositionRecordings
                        onDeleteRender={onDeleteRender}
                        setOnDeleteRender={setOnDeleteRender}
                        getRoomAHistory={getRoomAHistory}
                        getCompositions={getCompositions}
                        IsComposedButton={IsComposedButton}
                        isViewMediaCallBack={isViewMediaCallBack}
                        roomWithRecoridngs={roomWithRecoridngs}
                      />
                    </>
                  )}
                </>
              )}
            </>

            {/* //old Table Design */}
          </div>
        </div>
      </div>
    </>
  );
}
