const Package = {
  freeActive: [],
  silverActive: [
    {
      name: "60 Minutes ",

      silverActive: true,

      featureName: "Call Features",
    },

    {
      name: "Patients limititation on chat /call upto three patients",

      silverActive: true,

      featureName: "Call Features",
    },

    {
      name: "Waiting Room",

      silverActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Patient Queue",

      silverActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Text Chat",

      silverActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Meeting History",

      silverActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Browser Notifications",

      silverActive: true,

      featureName: "Workflow Features",
    },
    {
      name: "Secure Data Center",

      silverActive: true,

      featureName: "Security",
    },

    {
      name: "EndToEnd Encryption",

      silverActive: true,

      featureName: "Security",
    },

    {
      name: "Monthly Usage Report",

      silverActive: true,

      featureName: "Success",
    },
    {
      name: "Personalized RoomURL",

      silverActive: false,

      featureName: "Call Features",
    },

    {
      name: "Email Notifications",

      silverActive: false,

      featureName: "Workflow Features",
    },
    {
      name: "Group Calling",

      silverActive: false,

      featureName: "Extensions",
    },
    {
      name: "Screen Share",

      silverActive: false,

      featureName: "Extensions",
    },
    {
      name: "Network Quality Indicator",

      silverActive: false,

      featureName: "Extensions",
    },

    {
      name: "File Transfer and Attachments In Chat",

      silverActive: false,

      featureName: "Extensions",
    },
    {
      name: "Recordings",

      metalActive: false,

      featureName: "Support",
    },
  ],

  metalActive: [
    {
      name: "120 Minutes ",

      metalActive: true,

      featureName: "Call Features",
    },

    {
      name: "Patients limititation on chat /call upto six patients",

      metalActive: true,

      featureName: "Call Features",
    },

    {
      name: "Waiting Room",

      metalActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Patient Queue",

      metalActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Text Chat",

      metalActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Meeting History",

      metalActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Browser Notifications",

      metalActive: true,

      featureName: "Workflow Features",
    },

    {
      name: "Monthly Usage Report",

      metalActive: true,

      featureName: "Success",
    },

    {
      name: "Secure Data Center",

      metalActive: true,

      featureName: "Security",
    },

    {
      name: "EndToEnd Encryption",

      metalActive: true,

      featureName: "Security",
    },
    {
      name: "Group Calling",

      metalActive: false,

      featureName: "Extensions",
    },
    {
      name: "Personalized RoomURL",

      metalActive: false,

      featureName: "Call Features",
    },

    {
      name: "Group Calling",

      metalActive: false,

      featureName: "Support",
    },

    {
      name: "Screen Share",

      metalActive: false,

      featureName: "Extensions",
    },
    {
      name: "Email Notifications",

      metalActive: false,

      featureName: "Workflow Features",
    },

    {
      name: "File Transfer and Attachments In Chat",

      metalActive: false,

      featureName: "Extensions",
    },
    {
      name: "Network Quality Indicator",

      metalActive: false,

      featureName: "Extensions",
    },

    {
      name: "Recordings",

      metalActive: false,

      featureName: "Support",
    },
  ],
  goldActive: [
    {
      name: "Unlimited Minutes ",

      goldActive: true,
      featureName: "Call Features",
    },
    {
      name: "Unlimited Patients  on chat /call ",

      goldActive: true,
      featureName: "Call Features",
    },
    {
      name: "Network Quality Indicator",

      goldActive: true,

      featureName: "Extensions",
    },

    {
      name: "Personalized RoomURL",

      goldActive: true,
      featureName: "Call Features",
    },

    {
      name: "Waiting Room",

      goldActive: true,
      featureName: "Workflow Features",
    },

    {
      name: "Patient Queue",

      goldActive: true,
      featureName: "Workflow Features",
    },

    {
      name: "Text Chat",

      goldActive: true,
      featureName: "Workflow Features",
    },

    {
      name: "Meeting History",

      goldActive: true,
      featureName: "Workflow Features",
    },

    {
      name: "Browser Notifications",

      goldActive: true,
      featureName: "Workflow Features",
    },

    {
      name: "Text and Email Notifications",

      goldActive: true,
      featureName: "Workflow Features",
    },
    {
      name: "Group Calling",

      goldActive: true,
      featureName: "Extensions",
    },
    {
      name: "Dominant Speaker",

      goldActive: true,
      featureName: "Extensions",
    },
    {
      name: "Screen Share",

      goldActive: true,
      featureName: "Extensions",
    },

    {
      name: "File Transfer And Attachments In Chat",

      goldActive: true,
      featureName: "Extensions",
    },

    {
      name: "Email Support",

      goldActive: true,
      featureName: "Support",
    },

    {
      name: "Monthly Usage Report",

      goldActive: true,
      featureName: "Success",
    },

    {
      name: "Secure Data Center",

      goldActive: true,
      featureName: "Security",
    },

    {
      name: "EndToEnd Encryption",

      goldActive: true,
      featureName: "Security",
    },
    {
      name: "Recordings",

      goldActive: true,

      featureName: "Support",
    },
  ],
};
export default Package;
