import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Client } from "@twilio/conversations";
import Conversation from "./Messages/Conversation";
import Loader from "../../../../Common/Spinner";
import { isMessageImageShownOnFileViewer } from "../../../../Actions/Actions";
import { SocketContext } from "../../../../context/socket";
import {
  generateToken,
  getConversationBySid,
  intiateChat,
  LoginUser,
} from "../../../../Utils/ApiManager";

const Index = ({
  patientObject,
  joinedParticipants,
  showMessagesSection,
  showMessageSection,
}) => {
  let onClickPatientObj = patientObject && patientObject.id;

  onClickPatientObj = onClickPatientObj && onClickPatientObj.toString();
  const [joinedUser, setJoinedUser] = useState(0);
  const [selectedConversationSid, setSelectedConversationSid] = useState(null);
  const LoginUserr = useSelector((state) => state.LoginObject);
  const PatJoiningUser = useSelector(
    (state) => state.PatientDataDetails?.joiningUser
  );
  let AllPatientsAccepted = useSelector((state) => state.AllPatientsAccepted);
  let GroupChatUniqueIdentifierr = useSelector(
    (state) => state.GroupChatUniqueIdentifierr
  );

  let StripePackagePurchaseDetailObject = useSelector(
    (state) => state.StripePackagePurchaseDetailObject
  );

  const loginUser = LoginUserr?.username && LoginUserr.username;
  let identity = loginUser ? loginUser : PatJoiningUser;
  const socket = useContext(SocketContext);

  const [state, setState] = useState({
    delectionEffect: false,
    isTypingPart: null,
    conversationsClientSdk: null,
    name: loginUser,
    token: null,
    statusString: null,
    conversationsReady: false,
    conversations: [],
    status: "success",
    // conversationSid: null,
    newMessage: "",
  });
  useEffect(() => {
    if (joinedParticipants > 1 && !LoginUserr.username) {
      showMessageSection(!showMessageSection);
    } else if (joinedParticipants == 1 && !LoginUserr.username) {
      showMessageSection(!showMessageSection);
    }
  }, [joinedParticipants]);

  useEffect(() => {
    if (showMessagesSection && !LoginUserr.username) {
      getToken();
    }
  }, [showMessagesSection]);

  const { PatientDataDetails, RoomInfo } = useSelector((state) => state);
  let dispatch = useDispatch();

  const toggleImage = (check, url, toggleImage, fileType) => {
    let imageHastobeViewObj = {};
    imageHastobeViewObj.url = url;
    imageHastobeViewObj.toggleImage = toggleImage;
    imageHastobeViewObj.fileType = fileType;
    imageHastobeViewObj.check = check;
    dispatch(isMessageImageShownOnFileViewer(imageHastobeViewObj));
  };

  const createUniqueNameProviderPatientName = () => {
    let arr;
    let conversationUniqueName;

    if (joinedParticipants > 1) {
      if (LoginUserr && LoginUserr?.username) {
        let identifier = [];

        AllPatientsAccepted &&
          AllPatientsAccepted.map((el) => {
            identifier.push(el.id);
          });
        identifier.push(LoginUserr.id.toString());
        let AllAcceptedIndeitfier = identifier.join("");

        conversationUniqueName = AllAcceptedIndeitfier;
      } else if (PatientDataDetails && PatientDataDetails?.joiningUser) {
        let identifier = [];

        GroupChatUniqueIdentifierr &&
          GroupChatUniqueIdentifierr.map((el) => {
            identifier.push(el.id);
          });
        identifier.push(PatientDataDetails.providerId.toString());
        let AllAcceptedIndeitfier = identifier.join("");

        arr = [];
        // loginUser=PatientData.patientData.joiningUser
        conversationUniqueName = AllAcceptedIndeitfier;
      }
    } else {
      if (LoginUserr && LoginUserr?.username) {
        let patName =
          PatientDataDetails &&
          PatientDataDetails?.fname + PatientDataDetails?.lname;
        arr = [LoginUserr.id, onClickPatientObj].sort();
        conversationUniqueName = arr.join("");
      } else {
        arr = [
          PatientDataDetails?.providerId,
          PatientDataDetails?.patId,
        ].sort();
        conversationUniqueName = arr.join("");
      }
    }
    return conversationUniqueName;
  };
  let conversationName = createUniqueNameProviderPatientName();

  let conversationsClient;

  useEffect(() => {
    if (joinedParticipants) {
      setJoinedUser(joinedParticipants);
    }
  }, [joinedParticipants]);

  useEffect(() => {
    if (JSON.stringify(patientObject) === "{}" && LoginUserr.username) {
      getToken();
    } else if (JSON.stringify(patientObject) != "{}" && LoginUserr.username) {
      getToken();
    }
  }, [patientObject]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      name: PatientDataDetails?.joiningUser,
    }));
  }, [PatientDataDetails]);

  useEffect(() => {
    localStorage.clear();
    localStorage.setItem("name", state.name);
    if (LoginUserr?.username) {
      //getToken();
    }

    setState((prev) => ({ ...prev, statusString: "Fetching credentials…" }));
    return () => {
      localStorage.clear();
      sessionStorage.clear();
    };
  }, []);

  useEffect(() => {
    if (state.token) initConversations();
  }, [state.token]);

  const getToken = async () => {
    let sid = await flowOfToken();
    let obj = { identityUser: identity.toLowerCase() };
    //  let chatToken = await getTokenFrombackend();
    let res = await generateToken(obj);
    if (res && res?.data) {
      setState((prev) => ({
        ...prev,
        // conversations: conversations,
        token: res.data?.token,
        //  token: chatToken.token
      }));
      setSelectedConversationSid(sid);
    } else {
      toast.info("invalid Token");
    }
  };
  const intiateChatToDb = async (val) => {
    let formData = new FormData();
    formData.append("account_sid", val.accountSid);
    formData.append("bindings", val.bindings);
    formData.append("chat_service_sid", val.chatServiceSid);
    formData.append("date_created", val.dateCreated);
    formData.append("date_updated", val.dateUpdated);
    formData.append("friendly_name", val.friendlyName);
    formData.append("sid", val.sid);
    formData.append("messaging_service_sid", val.messagingServiceSid);
    formData.append("userName", LoginUserr.id);
    await intiateChat(formData);
  };

  const flowOfToken = async () => {
    let selectedConversationID = null;
    let conversationsList = await getConversations();

    if (conversationsList.data) {
      let array = conversationsList.data.conversations;

      if (array) {
        let isConversationCreated = array.some(
          (el) => el.friendly_name === conversationName
        );
        let myConversation = array.find(
          (el) => el.friendly_name === conversationName
        );
        if (PatientDataDetails && PatientDataDetails?.joiningUser) {
          // patient case

          if (isConversationCreated) {
            let res = await createConversationParticipant(
              PatientDataDetails?.joiningUser,
              myConversation.sid
            );

            console.log(res);
            selectedConversationID = myConversation.sid;
          } else {
            let conversationSid = await createConversations(conversationName);

            await createConversationParticipant(
              PatientDataDetails?.joiningUser,
              conversationSid
            );
            selectedConversationID = conversationSid;
          }
        } else {
          // provider case
          // console.log(
          //   "provider convo OK TESTED",
          //   isConversationCreated,
          //   myConversation.sid
          // );
          if (isConversationCreated) {
            await createConversationParticipant(state.name, myConversation.sid);
            selectedConversationID = myConversation.sid;
          } else {
            // console.log("provider convo IF NOT", conversationName);
            let conversationSid = await createConversations(conversationName);
            let result = await getConversationBySid({
              conversationSid: conversationSid,
            });
            debugger;
            let check = result && result?.data && LoginUserr?.username;
            let pkg = StripePackagePurchaseDetailObject?.packageName;
            // if package is silver or metal then intiate chat to db
            if (check && (pkg == "silver" || pkg == "metal")) {
              intiateChatToDb(result?.data.conversation);
              // intiate Chat
            }
            await createConversationParticipant(state.name, conversationSid);
            selectedConversationID = conversationSid;
          }
        }
      } else {
        let conversationSid = await createConversations(conversationName);
        await createConversationParticipant(state.name, conversationSid);
        selectedConversationID = conversationSid;
      }
    }
    return selectedConversationID;
  };

  const getTokenFrombackend = async () => {
    let identity;
    if (LoginUserr && LoginUserr.username) {
      identity = loginUser;
    } else {
      identity = PatientDataDetails?.joiningUser;
    }
    let response;
    //let patietnName=newState?.PatientData?.patientData?.fname+newState?.PatientData?.patientData?.lname
    try {
      response = await axios.post(
        process.env.REACT_APP_APIS + `create-chat-token=1&identity=${identity}`
      );
      // response = await axios.post(`http://localhost:3001/token`, { identity: identity })
    } catch (err) {}
    //  let response = await axios.post (process.env.REACT_APP_BACKEND_URL+`/token`, { identity: identity })
    return response.data;
  };

  const createConversations = async (loginUser) => {
    if (loginUser) {
      let res = await axios.post(
        process.env.REACT_APP_APIS +
          `create-conversation=1&friendlyName=${loginUser}`
      );
      // let res = await axios.post(`http://localhost:3001/create`, { friendlyName: loginUser })
      return res.data.sid;
      // return res.data.message
    } else {
      toast.error("No Login User");
    }
  };

  const createConversationParticipant = async (name, conversationSid) => {
    if (name && conversationSid) {
      await axios.post(
        process.env.REACT_APP_APIS +
          `add-participant-to-conversation=1&conversationSid=${conversationSid}&identity=${name}`
      );
    }
    // let response = await axios.post(`http://localhost:3001/chat`, { identity: name, conversationSid: conversationSid })
  };

  const deleteConversation = () => {
    return axios.post(process.env.REACT_APP_BACKEND_URL + `/delete`, {
      conversationId: "",
    });
  };

  const getConversations = () => {
    return axios.post(process.env.REACT_APP_APIS + `get-conversations=1`);
    // return axios.post(`http://localhost:3001/get-conversations`)
  };
  //----- For Typing Indicator----------
  const typingStarted = (part, val) => {
    if (part.isTyping && part.identity) {
      setState((prevState) => ({
        ...prevState,
        isTypingPart: true,
      }));
    }
  };
  const typingEnded = (part, val) => {
    if (!part.isTyping && part.identity) {
      setState((prevState) => ({
        ...prevState,
        isTypingPart: false,
      }));
    }
  };

  // ---------------End Typing Indicator---------------

  const initConversations = async () => {
    conversationsClient = await new Client(state.token);
    setState((prev) => ({
      ...prev,
      conversationsClientSdk: conversationsClient,
    }));
    setState((prev) => ({ ...prev, statusString: "Connecting to Twilio…" }));

    conversationsClient.on("connectionStateChanged", (stateResp, err) => {
      if (stateResp === "connecting") console.log("connecting");
      setState((prev) => ({
        ...prev,
        statusString: "Connecting to Twilio…",
        status: "default",
      }));
      if (stateResp === "connected") {
        console.log("connected");
        setState((prev) => ({
          ...prev,
          statusString: "You are connected.",
          status: "success",
        }));
      }
      if (stateResp === "disconnecting") console.log("disconnecting");
      setState((prev) => ({
        ...prev,
        statusString: "Disconnecting from Twilio…",
        conversationsReady: false,
        status: "default",
      }));
      if (stateResp === "disconnected") console.log("disconnected");
      setState((prev) => ({
        ...prev,
        statusString: "Disconnected.",
        conversationsReady: false,
        status: "warning",
      }));
      if (stateResp === "denied") console.log("denied");

      setState((prev) => ({
        ...prev,
        statusString: "Failed to connect.",
        conversationsReady: false,
        status: "error",
      }));
    });

    conversationsClient.on("conversationJoined", (conversation) => {
      setState((prev) => ({
        ...prev,
        conversations: [...prev.conversations, conversation],
      }));
    });
    conversationsClient.on("typingStarted", (participant) => {
      typingStarted(participant, true);
    });

    conversationsClient.on("typingEnded", (participant) => {
      typingEnded(participant, true);
    });

    conversationsClient.on("conversationLeft", (Conversation) => {
      setState((prev) => ({
        ...prev,
        conversations: [
          ...prev.conversations.filter((it) => it !== Conversation),
        ],
      }));
    });
  };

  const { conversations, status, conversationsClientSdk, isTypingPart } = state;

  const selectedConversation = conversations.find(
    (it) => it.sid === selectedConversationSid
  );
  let conversationContent;
  console.log("identity");
  if (selectedConversation) {
    conversationContent = (
      <Conversation
        delectionEffectState={state.delectionEffect}
        setState={setState}
        getConversations={getConversations}
        joinedParticipants={joinedParticipants}
        isTypingPart={isTypingPart}
        conversationsClientSdk={conversationsClientSdk}
        toggleImage={toggleImage}
        conversationProxy={selectedConversation}
        myIdentity={identity}
        conversationName={conversationName}
      />
    );
  } else if (status !== "success") {
    conversationContent = <Loader small={true} large={false} />;
  } else {
    conversationContent = "";
  }
  return <>{conversationContent}</>;
};
export default Index;
