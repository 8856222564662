import React, { Component, PureComponent } from "react";
import styles from "./assets/MessageBubble.module.css";
import PropTypes from "prop-types";
// import { Spin } from "antd";
import WhatsappIcon from "./WhatsappIcon";
import ChatIcon from "./ChatIcon";
import userAvatar from "../../../../../Assets/images/userAvatar.svg";
import userAvatar2 from "../../../../../Assets/images/messagesAvatar.svg";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import Loader from "../../../../../Common/Loader/Loader";
class MessageBubble extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.message.state, "step 1 check ");
    console.log(this.props.message, "yellow check");
    let fileType = null;
    if (
      this.props.message &&
      this.props.message.media &&
      this.props.message.media.state &&
      this.props.message.media.state.contentType
    ) {
      let fileMedia = this.props.message.media.state.contentType;
      fileMedia = fileMedia.split("/");
      fileType = fileMedia[fileMedia.length - 1];
    }

    this.state = {
      direction: this.props.direction,
      rplyMessage: this.props.message,
      uiRender: false,
      hasMedia: this.props.message.type === "media",
      mediaDownloadFailed: false,
      mediaUrl: null,
      fileType: fileType,
    };
  }

  componentDidMount = async () => {
    if (this.props.direction == "incoming") {
      console.log("check Attribute", await this.props.message);
    }
    this.setState({
      ...this.state,

      type: (await this.props.message.getParticipant()).type,
    });
    if (this.state.hasMedia) {
      this.props.message.media

        .getContentTemporaryUrl()
        .then((url) => {
          this.setState({ mediaUrl: url });
        })
        .catch((e) => this.setState({ mediaDownloadFailed: true }));
    }
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  }

  componentDidUpdate(prevProps) {
    console.log("prevProps.rplyMsgUiUpdate", prevProps.rplyMsgUiUpdate);

    if (prevProps.rplyMsgUiUpdate !== this.props.rplyMsgUiUpdate) {
      // this.setState({ uiRender: !this.state.uiRender });
      this.setState((prevState) => ({
        uiRender: !this.state.uiRender,
      }));
      console.log("Data has been updated in the parent component");
      // Perform any necessary updates here
    }
  }

  render = () => {
    console.log("has Media or not", this.state.hasMedia);
    const { itemStyle, divStyle } =
      this.state.direction === "incoming"
        ? {
            itemStyle: styles.received_msg,
            divStyle: styles.received_withd_msg,
          }
        : { itemStyle: styles.outgoing_msg, divStyle: styles.sent_msg };

    const m = this.props.message;
    console.log("THIS CHECK ", m);

    let onDeleteHideRplydel = null;
    if (m.state && m.state.attributes && m.state.attributes.isDeleted) {
      onDeleteHideRplydel = true;
    }
    let rplied_To =
      m.state && m.state.attributes && m.state.attributes.replied_to;
    let rplyTo = m?.state?.attributes?.replied_to;
    let bodyRply = m.state && m.state.attributes && m.state.attributes.body;
    let isHaveMedia =
      m.state && m.state.attributes && m.state.attributes?.mediaUrl;
    let bodyMsg = m?.state?.attributes?.body;
    let fileTypeMedia =
      m.state &&
      m.state.attributes &&
      m.state.attributes?.fileType &&
      m.state.attributes?.fileType.split("/");

    const type = this.state.type;

    console.log("isHaveMedia", this.props.direction);
    // let imageAvatar
    // if (typeof this.props.updateProfileImagee === "string") {
    //   imageAvatar = process.env.REACT_APP_IMAGE_BASE_PATH + this.props.updateProfileImagee
    // } else {
    //   if (this.props.ProivderImage) {
    //     imageAvatar = process.env.REACT_APP_IMAGE_BASE_PATH + this.props.ProivderImage
    //   } else {
    //     imageAvatar = userAvatar
    //   }
    // }

    return (
      <>
        {/* <div id={m.sid} className={itemStyle}>
          <div className={divStyle}>
            <div style={{ overflowWrap: "anywhere" }}>
              <strong>
                {type === "whatsapp" && <WhatsappIcon />}
                {type === "chat" && <ChatIcon />}
              
                {` ${m.author}`}
              </strong>
              <br />
              <div className={styles.medias}>
                {this.state.hasMedia && (
                  <Media
                    toggleImage={this.props?.toggleImage}
                    hasFailed={this.state.mediaDownloadFailed}
                    url={this.state.mediaUrl}
                    fileType={this.state.fileType}
                  />
                )}
              </div>
              {m.body}
            </div>
            <span className={styles.time_date}>
              {m.state.timestamp.toLocaleString()}
            </span>
          </div>
        </div> */}

        {/* lop09090909099 */}

        <div
          id={m.sid}
          className={
            this.props.direction === "incoming" ? "chats" : "chats chats-right"
          }
        >
          <div className="chat-avatar">
            {this.state.direction === "incoming" ? (
              <>
                {" "}
                <img
                  src={userAvatar}
                  className="rounded-circle dreams_chat"
                  alt="image"
                />
              </>
            ) : (
              <>
                <img
                  src={userAvatar2}
                  className="rounded-circle dreams_chat"
                  alt="image"
                />
              </>
            )}
          </div>

          <div className="chat-content">
            <div className="message-content">
              {this.state.direction === "incoming" ? (
                <>
                  {isHaveMedia ? (
                    <div
                      class={
                        isHaveMedia
                          ? "talk-bubble tri-right round right-in"
                          : null
                      }
                    >
                      <div class={isHaveMedia ? "talktextMedia" : null}>
                        <span className="RplyMessageAuthorMedia">
                          {rplied_To}
                        </span>
                        <p>
                          <span style={{ color: "black" }}>
                            <Media
                              toggleImage={this.props?.toggleImage}
                              hasFailed={false}
                              url={isHaveMedia}
                              fileType={fileTypeMedia[1]}
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                  <div
                    class={
                      bodyRply && rplied_To
                        ? "talk-bubble tri-right round right-in"
                        : null
                    }
                  >
                    <div class={rplied_To && bodyRply ? "talktext" : null}>
                      <p>
                        {rplied_To && !isHaveMedia ? (
                          <b style={{ color: "#4b49ac" }}>{rplyTo}</b>
                        ) : null}
                        {rplied_To ? (
                          <>
                            {" "}
                            <br></br>
                          </>
                        ) : null}

                        <span style={{ color: "black" }}>
                          {" "}
                          {bodyRply ? bodyMsg : null}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {isHaveMedia ? (
                    <div
                      class={
                        isHaveMedia
                          ? "talk-bubble tri-right round right-in"
                          : null
                      }
                    >
                      <div class={isHaveMedia ? "talktextMedia" : null}>
                        <span className="RplyMessageAuthorMedia">
                          {rplied_To}
                        </span>
                        <p>
                          <span style={{ color: "black" }}>
                            <Media
                              toggleImage={this.props?.toggleImage}
                              hasFailed={false}
                              url={isHaveMedia}
                              fileType={fileTypeMedia[1]}
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {rplied_To && bodyRply ? (
                    <div
                      class={
                        rplied_To && bodyRply
                          ? "talk-bubble tri-right round right-in"
                          : null
                      }
                    >
                      <div class={rplied_To && bodyRply ? "talktext" : null}>
                        {}{" "}
                        <p>
                          {rplied_To ? (
                            <b style={{ color: "#4b49ac" }}>{rplyTo}</b>
                          ) : null}
                          {rplied_To ? (
                            <>
                              {" "}
                              <br></br>
                            </>
                          ) : null}

                          <span
                            style={{ color: "black", whiteSpace: "pre-wrap" }}
                          >
                            {bodyRply ? bodyMsg : null}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </>
              )}

              <div className={styles.medias}>
                {this.state.hasMedia && (
                  <Media
                    //  loaderForMediaSent={this.props.loaderForMediaSent}
                    toggleImage={this.props?.toggleImage}
                    hasFailed={this.state.mediaDownloadFailed}
                    url={this.state.mediaUrl}
                    fileType={this.state.fileType}
                  />
                )}
              </div>
              <p class="p" style={{ whiteSpace: "pre-wrap" }}>
                {" "}
                <br></br>
                {m.body}
              </p>
              <div className="chat-time">
                {/* <div>
                  <div className="time">
                    <i className="fas fa-clock"></i>

                    {m.state.timestamp.toLocaleString()}{this.props.message.media.state.size}
                  </div> */}
                <div>
                  <div className="time">
                    <i className="fas fa-clock"></i>

                    <span style={{ paddingLeft: "10px" }}>
                      {m?.state?.timestamp.toLocaleString()}
                    </span>
                    <span style={{ paddingLeft: "10px" }}>
                      {this.props.message &&
                        this.props.message.media &&
                        this.props.message.media.state.size / 1000 + " KB"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.direction === "incoming"
                  ? "chat-profile-name d-flex "
                  : "chat-profile-name text-right d-flex justify-content-end"
              }
            >
              {this.state.direction === "incoming" ? (
                <>
                  {" "}
                  <h6> {m.author}</h6>
                  {onDeleteHideRplydel ||
                  this.state.direction != "outgoing" ||
                  m.body === null ? null : (
                    <i
                      className="fas fa-pencil-alt"
                      title="Reply"
                      onClick={() => this.props.messageEdit(m)}
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        marginRight: "5px",
                      }}
                    ></i>
                  )}
                  {onDeleteHideRplydel ? null : (
                    <i
                      className="fas fa-reply fa-flip-horizontal"
                      title="Reply"
                      onClick={() => this.props.onReplyMessage(m)}
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    ></i>
                  )}{" "}
                  {onDeleteHideRplydel ||
                  this.state.direction != "outgoing" ? null : (
                    <i
                      className="fa fa-trash"
                      title="Delete"
                      onClick={() => this.props.deleteMessage(m)}
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    ></i>
                  )}
                </>
              ) : (
                <>
                  {onDeleteHideRplydel ||
                  this.state.direction != "outgoing" || m.body===null? null : (
                    <i
                      className="fas fa-pencil-alt"
                      title="Reply"
                      onClick={() => this.props.messageEdit(m)}
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        marginRight: "5px",
                      }}
                    ></i>
                  )}
                  {onDeleteHideRplydel ? null : (
                    <i
                      className="fas fa-reply "
                      title="Reply"
                      onClick={() => this.props.onReplyMessage(m)}
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        marginRight: "5px",
                      }}
                    ></i>
                  )}{" "}
                  {onDeleteHideRplydel ||
                  this.state.direction != "outgoing" ? null : (
                    <i
                      className="fa fa-trash"
                      title="Delete"
                      onClick={() => this.props.deleteMessage(m)}
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    ></i>
                  )}
                  <h6> {m.author}</h6>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
}

class Media extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showImage: false,
    };
  }

  render = () => {
    const { hasFailed, url, fileType, mediaSize } = this.props;
    console.log(
      "have media",
      hasFailed,
      url,
      fileType,
      styles.media,
      styles.placeholder
    );
    return (
      <>
        <div
          className={`${styles.media}${!url ? " " + styles.placeholder : ""}`}
          onClick={() => this.props?.toggleImage(true, url, true, fileType)}
        >
          {/* {!url && !hasFailed && <Spin />} */}

          {hasFailed && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p>Failed to load media</p>
            </div>
          )}
          {!hasFailed && url && (
            <div className={styles.media_icon}>
              <div style={{ zIndex: 123, position: "absolute" }}>
                <span
                  className="fa fa-eye"
                  style={{ fontSize: "3em", opacity: 0.3 }}
                />
              </div>
              <div
                className={styles.picture_preview}
                style={{ backgroundImage: `url(${url})`, zIndex: 122 }}
              ></div>
            </div>
          )}
        </div>
      </>
    );
  };
}

Media.propTypes = {
  hasFailed: PropTypes.bool.isRequired,
  url: PropTypes.string,
};

const mapStateToProps = (state) => ({
  updateProfileImagee: state.updateProfileImagee,
  ProivderImage: state.ProivderImage,
});

export default connect(mapStateToProps, null)(MessageBubble);
