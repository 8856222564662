import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteSubscription,
  updateConsumedMinutesApi,
  updatePatientLimitations,
} from "../../../Utils/ApiManager";
import { Packages } from "../../../Common/Packages";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  packagePurchaseDetailObject,
  totalDays,
  subscriptionList,
  stripePackageType,
  NotificationForCardExpire,
} from "../../../Actions/Actions";
import {
  formmattedDateIntoDDMMYYY,
  swalConfirmationforAccept,
} from "../../../Utils/commonFunctions";
import { Obj } from "../../../Common/PatientLimitations";

export default function CancelSubscription() {
  let Navigate = useNavigate();
  let dispatch = useDispatch();
  const { SubscriptionList, LoginObject } = useSelector((state) => state);
  const updatePatientsLimitations = () => {
    let limitations = Obj?.FREE_PATIENT_LIMITATIONS;
    let formData = new FormData();
    formData.append("id", LoginObject?.id);
    formData.append("noOFAttempts", limitations);
    formData.append("userName", null);
    updatePatientLimitations(formData);
  };
  const DeleteSub = () => {
    if (SubscriptionList.length === 0) {
      return;
    }
    let obj = {
      subId: SubscriptionList[0].id,
    };

    DeleteSubscription(obj).then((response) => {
      let deletedRecord = response?.data?.deletedSub;

      let selectedPackage = Packages.find((ele) => ele.packageName === "free");
      updatePatientsLimitations();
      dispatch(packagePurchaseDetailObject(selectedPackage));
      saveTransaction();
      dispatch(NotificationForCardExpire([]));
      clearMinAndLimiatations();
      toast.warning("Subscription Deleted ,Free version is activated");
      // setFreeVersionBanner(true);

      dispatch(subscriptionList([]));
      dispatch(totalDays("free"));
      Navigate("/dashboard");
    });
  };
  const clearMinAndLimiatations = () => {
    let limitation = 0;
    let formData = new FormData();
    formData.append("id", LoginObject?.id);
    formData.append("noOFAttempts", limitation);
    formData.append("userName", null);
    updatePatientLimitations(formData);
    let formData1 = new FormData();
    formData1.append("userid", LoginObject?.id);
    formData1.append("id", LoginObject?.id);
    formData1.append("totalMinConsumed", 0);
    updateConsumedMinutesApi(formData1);
  };
  const confirmationForPatientAcceptCall = async () => {
    let swalObj = {};
    swalObj.text = "Are you sure that you want to end current subscription?";
    swalObj.title = "Are you sure?";
    swalObj.className = "warning";
    swalObj.icon = "warning";
    swalObj.confirmButtonColor = "#FF0000";
    swalObj.buttons = ["No ", "Yes"];
    let willEndCall = await swalConfirmationforAccept(swalObj);

    if (willEndCall) {
      await DeleteSub();
    }
  };

  const saveTransaction = async () => {
    let formattedToday = formmattedDateIntoDDMMYYY();

    let formData = new FormData();
    formData.append("created_at", formattedToday);
    formData.append("expiryDate", "Free");
    formData.append("currency", "none");
    formData.append("packageName", "free");
    formData.append("amount", "0$");
    formData.append("recieptUrl", "reciept_urlFree");
    formData.append("customerId", "Free");
    formData.append("transactionId", "trans-Free");
    formData.append("userId", LoginObject?.id);
    try {
      await axios
        .post(process.env.REACT_APP_APIS + "save-transaction=1", formData)
        .then((response) => {
          dispatch(stripePackageType("free"));
        });
    } catch (err) {
      toast.info("Something Went Wrong");
    }
  };

  return (
    <div>
      <div className="social-settings">
        <div className="settings-header">
          <h5>Cancel Subscription</h5>
          <p>You can cancel stripe subscription from this button.</p>
        </div>

        <div className="form-col form-body">
          <form action="/">
            <div className="row">
              <div className="col-md-12 col-xl-12">
                <div className="form-group" style={{ width: "28%" }}></div>
              </div>

              <div className="col-md-6 col-xl-4 ">
                <button
                  type="button"
                  className="btn btn-delete btn-primary"
                  onClick={confirmationForPatientAcceptCall}
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
