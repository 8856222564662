import React, { useState, useEffect } from "react";
import "./StripePayments.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../../Common/Spinner";
import {
  get_Payment_List,
  get_Prorations,
  next_Month_Invoice,
} from "../../../Utils/ApiManager";
import UpComingInvoice from "./upComingInvoice";

export default function StripePayments() {
  let { SubscriptionList, LoginObject } = useSelector((state) => state);

  const [paymentEventss, setPaymentEventss] = useState([]);
  const [invoiceDetailss, setinvoiceDetailss] = useState([]);
  // const [isLoadings, setIsLoading] = useState(false);
  // const [isLoadingPaymentsEvents, setIsLoadingPaymentsEvents] = useState(false);
  const [paymentEvents, setPaymentEvents] = useState([]);
  const [invoiceDetails, setinvoiceDetails] = useState([]);

  const [nextAttempt, setNextPaymentAttempt] = useState([]);
  const [created, setCreated] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPaymentsEvents, setIsLoadingPaymentsEvents] = useState(false);
  const [RemainingAmount, setRemainingAmount] = useState(0);
  const [upComingNextInvoice, setUpcomingInvoice] = useState();
  const [upcomingLoading, setUpcomingInvoiceLoading] = useState();
  useEffect(() => {
    let obj = {};
    obj.customer = SubscriptionList[0].customer;
    getPaymentList(obj);
    get_next_month_invoice(obj);
  }, []);

  useEffect(() => {
    getProrations();

    getInvoiceRecord();

    // getInvoiceLines();
  }, []);
  const getProrations = () => {
    let formData = new FormData();
    formData.append("userId", LoginObject.id);
    get_Prorations(formData).then((res) => {
      if (res && res.data) {
        let subId = SubscriptionList[0].id;

        let results = res.data.filter((element) => {
          return element.subscriptionId === subId;
        });
        let val = [];
        results.map((el) => {
          val.push(el.amount);
        });
        let sum = val.reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        }, 0);
        let roundedNumber = sum.toFixed(2);
        setRemainingAmount(roundedNumber);

        let h = [];
        for (let i = 0; i < results.length; i += 2) {
          h.push(results.slice(i, i + 2));
        }

        console.log("result", h);
        setPaymentEvents(h);
      }
    });
  };
  const getPaymentList = (obj) => {
    setIsLoading(true);
    get_Payment_List(obj).then((res) => {
      setIsLoading(false);
      if (res && res.data) {
        setPaymentEventss(res.data.AllCustomerPayment);
        setinvoiceDetailss(res.data.AllCustomerPayment);
      }
    });
  };

  const getInvoiceRecord = async () => {
    setIsLoading(true);
    try {
      let obj = {
        customerId: SubscriptionList[0].customer,
      };
      let url =
        process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoicePayments";
      axios.post(url, obj).then((res) => {
        setinvoiceDetails(res.data.invoiceDetails);
        let result = timeStampIntoDate(res.data.invoiceDetails.period_start);

        //  let invoiceDetailEvents=PaymentEvents(res.data.invoiceDetails.lines)
        let currentperiod = timeStampIntoDate(
          res.data.invoiceDetails.period_end
        );
        setNextPaymentAttempt(currentperiod);
        // setPaymentEvents(invoiceDetailEvents)

        setCreated(result);
        setIsLoading(false);
      });
    } catch (err) {}
  };

  const getInvoiceLines = async () => {
    setIsLoadingPaymentsEvents(true);
    try {
      let obj = {
        customerId: SubscriptionList[0].customer,
      };
      let url = process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoiceLines";
      axios.post(url, obj).then((res) => {
        let invoiceDetailEvents = PaymentEvents(res.data && res.data.lines);

        setPaymentEvents(invoiceDetailEvents);
        setIsLoadingPaymentsEvents(false);
        // setCreated(result);
      });
    } catch (err) {}
  };
  const PaymentEvents = (events) => {
    let res = events.data.map((el) => {
      let obj = {
        description: el.description,
        Qty: el.plan.interval_count,
        amount: el.amount / 100,
      };
      return obj;
    });
    return res;
  };
  const timeStampIntoDate = (createdTimeStamp) => {
    let date = new Date(createdTimeStamp * 1000);

    return date.toLocaleString();
  };
  const replaceTerms = (str) => {
    let strTerm = str;
    if (strTerm && strTerm.includes("Unused time")) {
      strTerm = strTerm.replace("Unused time", "Remaining amount left");
    }
    if (strTerm.includes("Remaining time")) {
      strTerm = strTerm.replace("Remaining time", "total amount charge");
    }
    if (strTerm.includes("1 ×")) {
      strTerm = strTerm.replace("1 ×", "Next Month charge on");
    }

    return strTerm;
  };
  const get_next_month_invoice = (obj) => {
    setUpcomingInvoiceLoading(true);
    next_Month_Invoice(obj)
      .then((res) => {
        setUpcomingInvoiceLoading(false);
        if (res && res.data) setUpcomingInvoice(res.data.upComingLines.data);
      })
      .catch((err) => {
        setUpcomingInvoiceLoading(false);
      });
  };

  return (
    <>
      <div
        className="content-wrapper"
        style={{
          height: "calc(100vh - 16rem)",
          //   height: "54vh",
          //   overflowX: "hidden",
          backgroundColor: "#fff",
        }}
      >
        {isLoading ? (
          <Loader large={true} />
        ) : (
          <>
            <div className="row ">
              <div className="col-sm-12">
                <div>
                  {isLoading ? (
                    <Loader large={true} />
                  ) : (
                    <>
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <div class="settings-header">
                            <h5> Payment</h5>
                          </div>
                          <table
                            id="example"
                            className="display expandable-table dataTable no-footer"
                            style={{ width: "100%" }}
                            role="grid"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="select-checkbox sorting_disabled"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Quote#"
                                  style={{ width: "246px" }}
                                >
                                  Amount
                                </th>
                                <th
                                  className=""
                                  tabIndex="0"
                                  aria-controls="example"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Quote#"
                                  aria-sort="ascending"
                                  style={{ width: "186px" }}
                                >
                                  Name
                                </th>
                                <th
                                  className=""
                                  tabIndex="0"
                                  aria-controls="example"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Quote#"
                                  aria-sort="ascending"
                                  style={{ width: "186px" }}
                                >
                                  Status
                                </th>
                                <th
                                  className=""
                                  tabIndex="0"
                                  aria-controls="example"
                                  rowSpan="1"
                                  colSpan="1"
                                  style={{ width: "186px" }}
                                >
                                  Description
                                </th>

                                <th
                                  className=""
                                  tabIndex="0"
                                  aria-controls="example"
                                  rowSpan="1"
                                  colSpan="1"
                                  style={{ width: "176px" }}
                                >
                                  CustomerId
                                </th>
                                <th
                                  className=""
                                  tabIndex="0"
                                  aria-controls="example"
                                  rowSpan="1"
                                  colSpan="1"
                                  style={{ width: "176px" }}
                                >
                                  Date
                                </th>
                                <th
                                  className=""
                                  tabIndex="0"
                                  aria-controls="example"
                                  rowSpan="1"
                                  colSpan="1"
                                  style={{ width: "176px" }}
                                >
                                  View Receipt
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoadingPaymentsEvents ? (
                                <>
                                  <td>
                                    <Loader />{" "}
                                  </td>
                                </>
                              ) : (
                                <>
                                  {paymentEventss &&
                                    paymentEventss.map((innerArray, i) => (
                                      <tr className="odd" key={i}>
                                        {innerArray.charges.data.map(
                                          (item, j) => (
                                            <>
                                              <td
                                                className=" select-checkbox"
                                                key={j}
                                              >
                                                <i class="fas fa-dollar-sign text-primary"></i>
                                                {item.amount / 100}
                                              </td>
                                              <td
                                                className=" select-checkbox"
                                                key={j}
                                              >
                                                {item.billing_details.name}
                                              </td>
                                              <td
                                                className=" select-checkbox"
                                                key={j}
                                              >
                                                <div className="success-box-custom">
                                                  <div className="Box-root Flex-flex">
                                                    <div className="Box-root Flex-flex">
                                                      <div className="Badge Badge--variant--status Box-root Box-background--green100 Flex-inlineFlex Flex-alignItems--center Flex-direction--row">
                                                        <span className="Badge-text Text-color--green600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline">
                                                          <span>
                                                            {item.status}
                                                          </span>
                                                        </span>
                                                        <div className="Badge-icon--container Box-root Padding-left--4">
                                                          <div
                                                            aria-hidden="true"
                                                            className="SVGInline SVGInline--cleaned SVG Icon Icon--check Icon-color Icon-color--green500 Box-root Flex-flex"
                                                          >
                                                            <svg
                                                              aria-hidden="true"
                                                              className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--check-svg Icon-color-svg Icon-color--green500-svg"
                                                              height="12"
                                                              width="12"
                                                              viewBox="0 0 16 16"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M5.297 13.213.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z"
                                                                fill-rule="evenodd"
                                                              ></path>
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td
                                                className=" select-checkbox"
                                                key={j}
                                              >
                                                {item.description}
                                              </td>
                                              <td
                                                className=" select-checkbox"
                                                key={j}
                                              >
                                                {item.billing_details.email}
                                              </td>

                                              <td
                                                className=" select-checkbox"
                                                key={j}
                                              >
                                                {timeStampIntoDate(
                                                  item.created
                                                )}
                                              </td>
                                              <td
                                                className=" select-checkbox"
                                                key={j}
                                              >
                                                <a
                                                  href={item.receipt_url}
                                                  target="_blank"
                                                >
                                                  View Receipt
                                                </a>
                                              </td>
                                            </>
                                          )
                                        )}
                                      </tr>
                                    ))}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}

                  {/* <div className="card p-3 scroll" style={{ marginTop: "5px" }}>
          <div>
            <DataGrid
              height={"80vh"}
              columns={Paymentcolumns}
              rows={paymentEvents}
              //  rowClass={(row) => (row.packageName.includes('GOLD') ? highlightClassname : undefined)}
            />
          </div>
        </div> */}

                  {/* **********total bill **************** */}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="settings-header">
                  <h5> Stripe Upgrade/downgrade details</h5>
                </div>
                <table
                  id="example"
                  className="display expandable-table dataTable no-footer"
                  style={{ width: "100%" }}
                  role="grid"
                >
                  <thead>
                    <tr role="row">
                      <th
                        className="select-checkbox sorting_disabled"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Quote#"
                        style={{ width: "246px" }}
                      >
                        Previous Package
                      </th>
                      <th
                        className=""
                        tabIndex="0"
                        aria-controls="example"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Quote#"
                        aria-sort="ascending"
                        style={{ width: "186px" }}
                      >
                        Description of previous package
                      </th>
                      <th
                        className=""
                        tabIndex="0"
                        aria-controls="example"
                        rowSpan="1"
                        colSpan="1"
                        style={{ width: "186px" }}
                      >
                        Amount left on previous package(usd)
                      </th>
                      <th
                        className=""
                        tabIndex="0"
                        aria-controls="example"
                        rowSpan="1"
                        colSpan="1"
                        style={{ width: "176px" }}
                      >
                        Current Package
                      </th>
                      <th
                        className=""
                        tabIndex="0"
                        aria-controls="example"
                        rowSpan="1"
                        colSpan="1"
                        style={{ width: "176px" }}
                      >
                        Description of current package(usd)
                      </th>
                      <th
                        className=""
                        tabIndex="0"
                        aria-controls="example"
                        rowSpan="1"
                        colSpan="1"
                        style={{ width: "176px" }}
                      >
                        Total price of current package
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingPaymentsEvents ? (
                      <>
                        <td>
                          <Loader />{" "}
                        </td>
                      </>
                    ) : (
                      <>
                        {paymentEvents &&
                          paymentEvents.map((innerArray, i) => (
                            <tr className="odd" key={i}>
                              {innerArray.map((item, j) => (
                                <>
                                  <td className=" select-checkbox" key={j}>
                                    {item.prorationSenario}
                                  </td>
                                  <td className=" select-checkbox" key={j}>
                                    {replaceTerms(item.description)}
                                  </td>
                                  <td className=" select-checkbox" key={j}>
                                    <i class="fas fa-dollar-sign text-primary"></i>
                                    {item.amount < 0
                                      ? Math.abs(item.amount)
                                      : Math.abs(item.amount)}
                                  </td>
                                </>
                              ))}
                            </tr>
                          ))}
                      </>
                    )}
                  </tbody>
                </table>
                <div className="page-content container">
                  <div className="container px-0">
                    <div className="row mt-4">
                      <div className="col-12 col-lg-12">
                        <hr className="row brc-default-l1 mx-n1 mb-4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-content container">
                <div className="container px-0">
                  <div className="row mt-4">
                    <div className="col-12 col-lg-12">
                      <div className="row">
                        <div className="col-sm-6"></div>

                        <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                          <hr className="d-sm-none" />
                          <div className="text-grey-m2">
                            {/* <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                Total
                              </div> */}

                            <div className="my-2  font-weight-bolder">
                              <i className=" text-blue-m2 text-xs mr-1 font-weight-bolder"></i>{" "}
                              Remaining Amount :{" "}
                              <i class="fas fa-dollar-sign text-primary"></i>
                              {Math.abs(invoiceDetails.starting_balance) / 100}
                            </div>

                            <div className="my-2">
                              <div className="my-2 d-flex">
                                <i className=" text-blue-m2 text-xs mr-1"></i>{" "}
                                <span className=" text-90 font-weight-bolder">
                                  Current Period:
                                </span>{" "}
                                <div
                                  style={{
                                    paddingBottom: "5px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  <span className="badge badge-warning badge-pill px-25">
                                    {created.slice(0, 10)}
                                  </span>
                                  <span>
                                    <i class="fas fa-arrow-alt-circle-right ml-2 mr-2 text-primary"></i>
                                  </span>
                                  <span className="badge badge-warning badge-pill px-25">
                                    {nextAttempt.slice(0, 10)}
                                  </span>
                                </div>
                              </div>
                              {/* <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "} */}

                              {/* <span className="text-600 text-90">Remaining balance will be applied to upcoming invoice,it may change if you perform further upgradation/downgradation in this month</span>  */}
                            </div>

                            {/* <div className="my-2">
                                <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                                <span className="text-600 text-90">
                                  Amount due:
                                </span>{" "}
                                <span className="badge badge-warning badge-pill px-25">
                                  ${invoiceDetails.amount_due / 100}
                                </span>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ----------------------------------------------------- */}
              <UpComingInvoice
                upcomingLoading={upcomingLoading}
                previousBalance={invoiceDetails.starting_balance}
                timeStampIntoDate={timeStampIntoDate}
                upComingNextInvoice={upComingNextInvoice}
              />
            </div>
            {/* <div className="page-content container">
              <div className="container px-0">
                <div className="row mt-4">
                  <div className="col-12 col-lg-12">
                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <span className="text-sm text-grey-m2 align-middle">
                            CustomerId:{" "}
                          </span>
                          <span className="text-600 text-110 text-blue align-middle">
                            {SubscriptionList && SubscriptionList[0].customer}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm text-grey-m2 align-middle">
                            Customer:{" "}
                          </span>
                          <span className="text-600 text-110 text-blue align-middle">
                            {invoiceDetails.customer_name}
                          </span>
                        </div>
                        <div className="text-grey-m2">
                          <div>
                            <span className="text-sm text-grey-m2 align-middle">
                              Customer Email:{" "}
                            </span>
                            <span className="text-600 text-110 text-blue align-middle">
                              {invoiceDetails.customer_email}
                            </span>
                          </div>
                          <div>
                            <span className="text-sm text-grey-m2 align-middle">
                              Country:{" "}
                            </span>
                            <span className="text-600 text-110 text-blue align-middle">
                              {invoiceDetails.account_country}
                            </span>
                          </div>
                          <div>
                            <span className="text-sm text-grey-m2 align-middle">
                              Payment method:{" "}
                            </span>
                            <span className="text-600 text-110 text-blue align-middle">
                              {invoiceDetails.collection_method ===
                              "charge_automatically"
                                ? "Stripe will automatically charge the when downgradation/upgradation happens "
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                        <hr className="d-sm-none" />
                        <div className="text-grey-m2">
                          <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                            Invoice
                          </div>

                          <div className="my-2">
                            <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                            <span className="text-600 text-90">subId:</span>{" "}
                            {invoiceDetails.subscription}
                          </div>

                          <div className="my-2">
                            <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                            <span className="text-600 text-90">Created:</span>{" "}
                            {created}
                          </div>

                          <div className="my-2 d-flex">
                            <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                            <span className="text-600 text-90">
                              Current Period:
                            </span>{" "}
                            <div
                              style={{
                                paddingBottom: "5px",
                                marginTop: "-5px",
                              }}
                            >
                              <span className="badge badge-warning badge-pill px-25">
                                {created.slice(0, 10)}
                              </span>
                              <span>to</span>
                              <span className="badge badge-warning badge-pill px-25">
                                {nextAttempt.slice(0, 10)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}

        {/* <div className="card p-3 scroll" style={{ marginTop: "5px" }}>
          <div>
            <DataGrid
              height={"80vh"}
              columns={Paymentcolumns}
              rows={paymentEvents}
              //  rowClass={(row) => (row.packageName.includes('GOLD') ? highlightClassname : undefined)}
            />
          </div>
        </div> */}

        {/* **********total bill **************** */}
      </div>
      {/* ___________________________________________other Section___________________________ */}
    </>
  );
}
