import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import EmailModal from "./Forgotpassword";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  AutoRenwal,
  LoginObject,
  packagePurchaseDetailObject,
  totalDays,
  sessionID,
  subscriptionList,
  NotificationForCardExpire,
  profilePicUpdate,
  paymentIntentInCase1TPayment,
} from "../../Actions/Actions";
import Loader from "../../Common/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../Assets/images/telxyLogo.svg";
import {
  getCreditCardFromDb,
  LoginUser,
  LoginUserFromEhr,
  updateProviderStatus,
  getProviderStatus,
} from "../../Utils/ApiManager";
import { Packages } from "../../Common/Packages";
import Success from "../Success/Success";
import "../../Assets/css/login.css";
import { SocketContext } from "../../../src/context/socket";
import Forgotpassword from "./Forgotpassword";

function Login({ getPatientLists, setStripeLatestObject, SuccessUrlState }) {
  //-------------- All Global variable Section-----------
  let navigate = useNavigate();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { IsUrlsCameFromEhrr, RoomInfo } = useSelector((state) => state);
  //----------Alll States -------------------
  const [username, setUsername] = useState("");
  const [NavbarToogle, setNavbarToogle] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingg, setLoadingg] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const openModal = () => {
    setToggleModal(true);
  };
  // let visitingPath = window.location.href;
  let lastPart = null;

  useEffect(() => {
    if (IsUrlsCameFromEhrr && IsUrlsCameFromEhrr.includes("/?")) {
      setLoadingg(true);
      lastPart = IsUrlsCameFromEhrr.split("/?").pop();
      lastPart = lastPart.split("&&");
      let username = lastPart[0];
      let password = lastPart[1];

      LoginUserFromEhr(username, password).then(async (res) => {
        setLoadingg(false);

        if (res && res.data && res.data.username) {
          let statusObj = {};
          statusObj.status = "online";
          socket.emit("show_provider_online", statusObj);
          await getPatientLists(res.data.username);
          dispatch(LoginObject(res.data));
          console.log(res.data, "res.data");

          dispatch(profilePicUpdate(res.data.profileimage));
          dispatch(AutoRenwal(res.data.autorenew));
          await getTransactionStripe(res.data);

          navigate("/Dashboard");
        } else {
          toast.error("Invalid username/password");
          setLoadingg(false);
        }
      });
    }
  }, [IsUrlsCameFromEhrr]);
  useEffect(() => {
    if (username) {
    } else {
      setError(false);
    }
  }, [username]);

  const loginUserr = async () => {
    setLoading(true);
    if (username === "") {
      setUserNameError(true);
      setLoading(false);
      return;
    } else {
      setUserNameError(false);
    }
    if (password === "") {
      setPasswordError(true);
      setLoading(false);
      return;
    } else {
      setPasswordError(false);
    }
    try {
      let obj = {
        username: username,
        password: password,
      };
      LoginUser(obj).then(async (res) => {
        // if (res && res.data && res.data.history === "online") {
        //   toast.warn("You are already loggedin on other device")
        //   setLoading(false);
        //   return
        // } else {
        setLoading(false);
        let statusObj = {};
        statusObj.status = "online";
        statusObj.username = res.data.username;
        if (res && res.data && res.data.username) {
          socket.emit("show_provider_online", statusObj);
          dispatch(profilePicUpdate(res.data.profileimage));
          updateProviderStatuss(res.data.username);
          await getTransactionStripe(res.data);
          await getPatientLists(res.data.username);
          dispatch(LoginObject(res.data));
          dispatch(AutoRenwal(res.data.autorenew));
          navigate("/Dashboard");
        } else {
          toast.error("Invalid username/password");
        }
        // }
      });
    } catch (err) {
      setLoading(false);
    }
  };

  const updateProviderStatuss = (username) => {
    try {
      updateProviderStatus(username, "online").then(async (res) => {
        console.log(res, "res555555555555");
      });
    } catch (err) {}
  };

  const isValidName = () => {
    if (username) {
      let isnameValidName = /^[a-zA-Z0-9_@]+$/.test(username);
      if (isnameValidName) {
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(false);
    }
    if (username === null) {
      setError(false);
    }
  };
  const CalculateFun = (lastTransactionObject) => {
    let created = lastTransactionObject?.created_at;
    let result = created.split("/");
    let n = result[2] + "-" + result[1] + "-" + result[0];
    const date = new Date(n);
    let expiry = lastTransactionObject?.expiryDate;
    //let z=expiry.toLocaleDateString("en-US")
    let res = created.split("/");
    let re = res[2] + "-" + res[1] + "-" + res[0];
    let ress = expiry.split("/");
    let re2 = ress[2] + "-" + ress[1] + "-" + ress[0];
    let resultFinal = getDayDiff(new Date(re), new Date(re2));
    return resultFinal;
  };
  const getDayDiff = (startDate, endDate) => {
    const msInDay = 24 * 60 * 60 * 1000;

    return Math.round(Math.abs(endDate - startDate) / msInDay);
  };
  const doSomeIdmatchForSubscription = (metaData, SubscriptionList, value) => {
    //this thing hAPPEN aFTER SOME cORRECTION

    let obj = {};
    let userInfo = value;
    obj.id = userInfo?.id;
    obj.role = userInfo?.role;

    let result =
      SubscriptionList &&
      SubscriptionList.map((el) => {
        let checkForSubscription =
          el.id === metaData.subId &&
          obj.id == metaData.id &&
          metaData.role === obj.role;

        if (checkForSubscription) {
          return el;
        }
        return;
      });

    return result;
  };

  const getSubscriptionList = (transactionId, value) => {
    try {
      let url =
        process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/get-Subscription-list";
      axios.post(url, { transactionId: transactionId }).then((res) => {
        let ress = res.data && res.data;

        let metaData = ress?.metadata;
        let SubscriptionList = ress?.subscriptions?.data;

        let result = doSomeIdmatchForSubscription(
          metaData,
          SubscriptionList,
          value
        );

        let results = result.filter((element) => {
          return element !== undefined;
        });

        if (results) {
          getCard(results, value);
        }

        dispatch(subscriptionList(results));
      });
    } catch (err) {}
  };

  const addMonths = (numOfMonths, date = new Date()) => {
    date.setMonth(date.getMonth() + numOfMonths);

    return date;
  };
  const getCard = (SubscriptionList, value) => {
    getCreditCardFromDb(value && value.id)
      .then((res) => {
        let cusIdd = SubscriptionList[0].customer;

        const results =
          res &&
          res.data.filter((element) => {
            return element.cusId === cusIdd;
          });
        const object = Object.assign({}, ...results);

        if (object) {
          const date = new Date();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          let date2 = object.exp_year + "-" + object.exp_month + "-" + "01";
          let date1 = year + "/" + month + "/" + "01";

          let diffMonth = getMonthDifference(new Date(date1), new Date(date2));

          if (
            diffMonth === 0 ||
            diffMonth === 1 ||
            (diffMonth > 0 && diffMonth < 1)
          ) {
            let arr = [];
            let obj = {};

            obj.msg = ` Your  Credit Card will expire on ${object.exp_year}-${object.exp_month}`;
            arr.push(obj);
            dispatch(NotificationForCardExpire(obj));
          }
        }

        // setCardDetails(object)
      })
      .catch((err) => {});
  };
  const getMonthDifference = (startDate, endDate) => {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  };

  const getTransactionStripe = async (value) => {
    let transactionList = await axios.get(
      process.env.REACT_APP_APIS + `get-transaction=1&userId=${value.id}`
    );

    let data = transactionList.data;
    if (data && data.length === 0) {
      dispatch(packagePurchaseDetailObject(Packages[0]));
    } else if (data && data.length > 0) {
      var lastTransactionObject = data[data.length - 1];

      let trans = lastTransactionObject?.packageName;

      if (
        trans === "metalOneTime" ||
        trans === "silverOneTime" ||
        trans === "goldOneTime"
      ) {
        let created = lastTransactionObject?.created_at;

        let result = created.split("/");
        let n = result[2] + "-" + result[1] + "-" + result[0];
        const date = new Date(n);
        let expiry = addMonths(1, date);
        let z = expiry.toLocaleDateString("en-US");
        //let noOfDays=datediff(parseDate(created), parseDate(expiry.toLocaleDateString("en-US")))
        let res = created.split("/");
        let re = res[2] + "-" + res[1] + "-" + res[0];
        let ress = z.split("/");
        let re2 = ress[2] + "-" + ress[0] + "-" + ress[1];
        let resultFinal = getDayDiff(new Date(re), new Date(re2));
        dispatch(
          paymentIntentInCase1TPayment(lastTransactionObject?.customerid)
        );
        dispatch(totalDays(resultFinal));
      }
      if (trans === "free") {
        dispatch(totalDays("free"));
      }
      if (trans === "gold" || trans === "silver" || trans === "metal") {
        let res = CalculateFun(lastTransactionObject);

        dispatch(totalDays(res));
      }

      setStripeLatestObject(lastTransactionObject);

      let lastTransactionId = lastTransactionObject?.transactionId;

      dispatch(sessionID(lastTransactionId));

      let selectedPackage = Packages.find(
        (ele) => ele.packageName === lastTransactionObject.packageName
      );

      dispatch(packagePurchaseDetailObject(selectedPackage));
      if (lastTransactionId) {
        await getSubscriptionList(lastTransactionId, value);
      }
    }
  };

  const handleUserName = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    value = value.toLowerCase();
    if (name === "username") {
      // if (!isValidName(e.target.value)) {
      //   setError(true);
      // } else {
      setError(false);
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  const onEnterLogin = (e) => {
    if (e.keyCode === 13) {
      loginUserr();
    }
  };

  return (
    <>
      {<Success SuccessUrlState={SuccessUrlState} />}
      {IsUrlsCameFromEhrr && IsUrlsCameFromEhrr && loadingg ? (
        <Loader small={false} large={true} forButton={true} />
      ) : (
        <>
          <div className="LoginPagePublic">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="navbar-brand">
                <img width="50" src={logo} alt="no image" />
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setNavbarToogle(!NavbarToogle)}
              >
                <span className="icon-menu fas fa-bars text-white"></span>
              </button>

              <div
                className={
                  NavbarToogle
                    ? "collapse navbar-collapse show"
                    : "collapse navbar-collapse"
                }
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link font-weight-bold" : "nav-link"
                      }
                      to="/login"
                    >
                      SignIn
                    </NavLink>
                  </li>
                  <li className="nav-item active">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link font-weight-bold" : "nav-link"
                      }
                      to="/join-doctor"
                    >
                      Join Provider
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
            <div className=" main ">
              <div className="logininLeft">
                <div
                  style={{ height: "92vh" }}
                  className="d-flex  justify-content-center align-items-start mt-5"
                >
                  <div className="w-75">
                    <div className="containter">
                      <div
                        id="carouselExampleFade"
                        className="carousel slide carousel-fade"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner p-3">
                          <div className="carousel-item active">
                            <h5>Reporting</h5>
                            <p>Detail Reports of your Calls </p>
                          </div>
                          <div className="carousel-item">
                            <h5>Video Consulation</h5>
                            <p>Best Video Consulation Experience </p>
                          </div>
                          <div className="carousel-item">
                            <h5>Online Payment </h5>
                            <p>Change Package of your Choice anytime </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {toggleModal ? (
                <EmailModal setToggleModal={setToggleModal} />
              ) : null}
              <div className="logininRight">
                <div
                  style={{ height: "calc(100vh - 59px)" }}
                  className="d-flex justify-content-center 
             align-items-center"
                >
                  <div className="border p-5 rounded loginForm">
                    <h2 className="form-title  fw-bolder text-center   text-primary">
                      Log in{" "}
                    </h2>

                    <div className="mt-5">
                      <div>
                        <label>Email/Username</label>
                        <input
                          className="form-group"
                          type="text"
                          name="username"
                          id="username"
                          placeholder="Email "
                          onChange={handleUserName}
                          onKeyDown={onEnterLogin}
                          aria-describedby={userNameError ? "invalid" : "valid"}
                          onBlur={() => {
                            if (username) isValidName();
                          }}
                        />
                        {error ? (
                          <p className="text-danger">
                            Special characters are not allowed except '@' and
                            '_'
                          </p>
                        ) : (
                          ""
                        )}
                        {/* {userNameError ? (
                          <p className="text-danger">Please enter username</p>
                        ) : undefined} */}
                      </div>

                      <div className=" pb-0 mb-0">
                        <label>Password</label>
                        <input
                          className="form-group "
                          type="password"
                          name="password"
                          id="your_pass"
                          placeholder="Password"
                          onChange={handleUserName}
                          onKeyDown={onEnterLogin}
                          aria-describedby={passwordError ? "invalid" : "valid"}
                        />
                        {/* {passwordError ? (
                          <p className="text-danger">Please enter password</p>
                        ) : undefined} */}
                      </div>
                      <div
                        style={{
                          dispaly: "flex",
                          justifyContent: "end",
                          alignContent: "end",
                          alignItems: "end",
                          textAlign: "end",
                          color: "#420ba1",
                          marginTop: "-12px",
                        }}
                      >
                        <a
                          onClick={openModal}
                          style={{
                            dispaly: "flex",
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Forgot password?
                        </a>
                      </div>
                      <div className="bg-orange d-flex justify-content-center ">
                        <button
                          style={{
                            width: "140px",
                            marginTop: "10px",
                            borderRadius: "5px",
                            height: "42px",
                          }}
                          className="btn btn-SignIn pl-3 pr-3 "
                          onClick={loginUserr}
                          disabled={isLoading}
                        >
                          Log in
                          {isLoading ? (
                            <Loader
                              small={true}
                              large={false}
                              forButton={true}
                            />
                          ) : undefined}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>

    // <>
    //   {<Success SuccessUrlState={SuccessUrlState} />}
    //   <div className="SignInformPublic">
    //     {" "}
    //     <div className="container rounded">
    //       <div className="signin-content d-flex">
    //         <div className="signin-image">
    //           <figure>
    //           </figure>
    //         </div>
    //         <div className="signin-form">
    //           <h2 className="form-title p-3 fw-bolder">Sign In</h2>
    //           <div>
    //             <div className="form-group">
    //               <label htmlFor="your_name">
    //                 <i className="fas fa-user"></i>
    //               </label>
    //               <input
    //                 type="text"
    //                 name="username"
    //                 id="username"
    //                 placeholder="Username"
    //                 onChange={handleUserName}
    //                 onKeyDown={onEnterLogin}
    //               />
    //               {error ? (
    //                 <p className="text-danger">
    //                   Special characters are not allowed except '@' and '_'
    //                 </p>
    //               ) : (
    //                 ""
    //               )}
    //               {userNameError ? (
    //                 <p className="text-danger">Please enter username</p>
    //               ) : undefined}
    //             </div>
    //             <div className="form-group">
    //               <label htmlFor="your_pass">
    //                 <i className="fas fa-lock"></i>
    //               </label>
    //               <input
    //                 type="password"
    //                 name="password"
    //                 id="your_pass"
    //                 placeholder="Password"
    //                 onChange={handleUserName}
    //                 onKeyDown={onEnterLogin}
    //               />
    //               {passwordError ? (
    //                 <p className="text-danger">Please enter password</p>
    //               ) : undefined}
    //             </div>

    //             <div className="form-group form-button">
    //               <button
    //                 className="btn btn-primary"
    //                 onClick={loginUserr}
    //                 disabled={isLoading}
    //               >
    //                 Login
    //                 {isLoading ? (
    //                   <Loader small={true} large={false} forButton={true} />
    //                 ) : undefined}
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
}

export default Login;
