import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import icon from "../../../Assets/images/icons8-chat-bubble-80.png";
import { Obj } from "../../../Common/PatientLimitations";
import axios from "axios";

function ParticpantLimit() {
  const { LoginObject, StripePackagePurchaseDetailObject } = useSelector(
    (state) => state
  );
  let pkg = StripePackagePurchaseDetailObject?.packageName;
  let check = 0;
  if (pkg == "silver") {
    check = Obj?.Silver_Unique_Chat_Particpant;
  } else if (pkg == "metal") {
    check = Obj?.Metal_Unique_Chat_Particpant;
  } else if (pkg == "gold") {
    check = Obj?.Gold_Unique_Chat_Particpant;
  }
  const [noOFAttempts, setNoOfAttempts] = useState(check);
  const [noOFAttemptsLeft, setNoOfAttemptsLeft] = useState();

  useEffect(() => {
    getPatLimiatation();
  }, []);
  const getPatLimiatation = async () => {
    let res = await axios.post(
      process.env.REACT_APP_APIS + `get-conversations=1`
    );
    if (res && res?.data && res?.data?.conversations) {
      let result = res.data.conversations.filter(
        (el) =>
          el.friendly_name
            .toString()
            .slice(-3)
            .indexOf(LoginObject?.id.toString()) !== -1
      );
      if (result && result.length > 0) {
        if (noOFAttempts === "UNLIMITED") {
          setNoOfAttemptsLeft("UNLIMITED");
        } else {
          setNoOfAttemptsLeft(noOFAttempts - result.length);
        }
      }

      //2 array
    }
  };

  return (
    <div>
      <div className="card-body">
        <div className="d-flex">
          <div className="minutesMain" style={{ backgroundColor: "#ffff" }}>
            <img src={icon} />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <h5 className="minutesMainH5">
              {" "}
              {false ? (
                <>
                  <div
                    className="spinner-border  spinner-border-sm"
                    role="status"
                    style={{ color: "#12b8c3" }}
                  ></div>
                </>
              ) : (
                <h5 className="minutesMainH5" style={{ color: "#12b8c3" }}>
                  {noOFAttemptsLeft < 0 ? 0 : <h4>{noOFAttemptsLeft}</h4>}
                </h5>
              )}
            </h5>
            <p className="minutesMainp">Chats Left Each Particpant</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParticpantLimit;
