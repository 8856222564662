import "./Emoji.css";
import EmojiPicker from "emoji-picker-react";
import { useEffect } from "react";
import {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";
import { useState } from "react";
export default function EmojisPicker({ onClickEmoji }) {
  const [emojiPickerHeight, setEmojiPickerHeight] = useState(null);
  const [emojiPickerWidth, setEmojiPickerWidth] = useState(null);

  useEffect(() => {
    function handleResize() {
      if (window.matchMedia("(max-width: 768px)").matches) {
        // set your mobile breakpoint
        setEmojiPickerHeight("20em");
        setEmojiPickerWidth(300);
        // assign 20em height for mobile view
      } else {
        setEmojiPickerWidth(350);
        setEmojiPickerHeight("28em"); // assign default height for desktop view
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // call the function on page load

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [selectedEmoji, setSelectedEmoji] = useState("");

  const onClick = (emojiData, event) => {
    onClickEmoji(emojiData);
  };

  return (
    <div className="Emoji">
      <div className="show-emoji"></div>

      <EmojiPicker
        onEmojiClick={onClick}
        autoFocusSearch={false}
        height={emojiPickerHeight}
        width={emojiPickerWidth}
        // theme={Theme.AUTO}
        searchDisabled
        lazyLoadEmojis={true}
        previewConfig={{
          showPreview: false,
        }}
      />
    </div>
  );
}
