export const Packages = [
  {
    packageName: "free",
    callFeatures: {
      minutes: false,
      sessions: false,
      personalizedRoomURL: false,
      hdVideo: false,
      audioCall: true,
    },
    workflowFeatures: {
      waitingRoom: false,
      patientQueue: true,
      textChatAudioCall: true,
      textChatVideoCall: false,
      meetingHistory: false,
      browserNotifications: false,
      roomPasscode: false,
      textAndEmailNotifications: false,
      transferPatient: false,
    },
    extensions: {
      photoCapture: false,
      groupCalling: false,
      screenshare: false,
      fileTransfer: false,
      payments: false,
    },
    support: {
      onlinHelpPages: false,
      emailSupport: false,
      onDemandChat: false,
    },
    success: {
      monthlyUsageReport: false,
    },
    security: {
      secureDataCenter: false,
      endToEndEncryption: false,
      noPatientDataStored: false,
    },
    groups: {
      forTwoPlusUsers: false,
      clinicSubdomain: false,
      adminControls: false,
    },
  },
  {
    packageName: "metal",
    callFeatures: {
      minutes: 120,
      Patientslimititation: 6,
      personalizedRoomURL: false,
    },
    workflowFeatures: {
      waitingRoom: true,
      patientQueue: true,
      textChat: true,

      meetingHistory: true,
      browserNotifications: true,

      EmailNotifications: false,
    },
    recordings: {
      recordingEnbled: false,
    },
    extensions: {
      groupCalling: false,
      screenshare: false,
      fileTransferAndAttachments: false,
    },

    success: {
      monthlyUsageReport: false,
    },
    security: {
      secureDataCenter: false,
      endToEndEncryption: false,
    },
    groups: {
      adminControls: false,
    },
  },
  {
    packageName: "silver",
    callFeatures: {
      minutes: 60,
      Patientslimititation: 3,
      personalizedRoomURL: false,
    },
    workflowFeatures: {
      meetingHistory: true,
      browserNotifications: true,
      waitingRoom: true,
      patientQueue: true,
      textChat: true,

      EmailNotifications: false,
    },
    extensions: {
      groupCalling: false,
      screenshare: false,
      fileTransferAndAttachments: false,
    },

    success: {
      monthlyUsageReport: true,
    },
    recordings: {
      recordingEnbled: false,
    },
    security: {
      secureDataCenter: true,
      endToEndEncryption: true,
    },
    groups: {},
  },
  {
    packageName: "gold",
    callFeatures: {
      minutes: -1,
      Patientslimititation: -1,
      personalizedRoomURL: true,
    },
    workflowFeatures: {
      meetingHistory: true,
      browserNotifications: true,
      waitingRoom: true,
      patientQueue: true,
      textChat: true,

      EmailNotifications: true,
    },
    extensions: {
      groupCalling: true,
      screenshare: true,
      fileTransferAndAttachments: true,
    },

    success: {
      monthlyUsageReport: true,
    },
    recordings: {
      recordingEnbled: true,
    },
    dominantSpeaker: {
      dominantEnabled: true,
    },
    security: {
      secureDataCenter: true,
      endToEndEncryption: true,
    },
    groups: {},
  },
];
