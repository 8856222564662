import React, { useState, useEffect } from "react";
import { getRoomHistory } from "../../../../Utils/ApiManager";
import { useSelector } from "react-redux";
import icon from "../../../../Assets/images/icon2dashboard.png";
function TotalParticipants() {
  const [isLoading, setIsLoading] = useState(false);
  const [countParticipants, setCountParticipants] = useState(0);
  const state = useSelector((state) => state);
  const userName = state.LoginObject.username;
  useEffect(() => {
    setIsLoading(true);
    try {
      getRoomHistory(userName).then((res) => {
        var count = 0;
        res.data.rooms.map((el) => {
          if (el.room_name === userName) {
            count +=
              el.unique_participant_identities === 0 ||
              el.unique_participant_identities === null
                ? 0
                : el.unique_participant_identities - 1;
            setCountParticipants(count);
          }
        });

        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
    }
  }, []);
  return (
    <div>
      <div className="card-body " style={{ height: "130px" }}>
        {/* <p className="mb-4">Total Participants </p>

        {isLoading ? (
          <>
            <div className="spinner-border text-light spinner-border-sm" role="status"></div>
          </>
        ) : (
          <>
            <p className="fs-30 mb-2">{countParticipants}</p>
          </>
        )} */}

        <div className="d-flex">
          <div className="OperayionMainDashboard">
            <img src={icon} />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <h5 className="OperayionMainDashboardH5">
              {" "}
              {isLoading ? (
                <>
                  <div
                    className="spinner-border text-light spinner-border-sm"
                    role="status"
                  ></div>
                </>
              ) : (
                <>{countParticipants}</>
              )}{" "}
            </h5>
            <p className="OperayionMainDashboardp">Total Appointments</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalParticipants;
