function LoginAction(payload) {
  return {
    type: "Login",
    payload: payload,
  };
}

function CreateProviderRoom(payload) {
  return {
    type: "ProviderRoomCreated",
    payload: payload,
  };
}

function PatientAction(payload) {
  return {
    type: "Patient",
    payload: payload,
  };
}

function PatientData(payload) {
  return {
    type: "PatientData",
    payload: payload,
  };
}
function RoomForPatient(payload) {
  return {
    type: "RoomForPatient",
    payload: payload,
  };
}
function RoomStatusFN(payload) {
  return {
    type: "RoomStatus",
    payload: payload,
  };
}
function RoomsScreenView(payload) {
  return {
    type: "largeScreen",
    payload: payload,
  };
}
function LoginObject(payload) {
  return {
    type: "loginObject",
    payload: payload,
  };
}
function CreateStripeDetail(payload) {
  return {
    type: "StripeDetail",
    payload: payload,
  };
}
function packagePurchaseDetailObject(payload) {
  return {
    type: "intialPackageDetailObject",
    payload: payload,
  };
}
function intialRoomBeforeScreenShared(payload) {
  return {
    type: "roomBeforeShare",
    payload: payload,
  };
}

function stripePackageType(payload) {
  return {
    type: "packageType",
    payload: payload,
  };
}
function subscriptionList(payload) {
  return {
    type: "subscription",
    payload: payload,
  };
}
function sessionID(payload) {
  return {
    type: "sessionId",
    payload: payload,
  };
}
function totalDays(payload) {
  return {
    type: "totalDay",
    payload: payload,
  };
}
function AllAcceptedPatients(payload) {
  return {
    type: "AllAcceptedPatients",
    payload: payload,
  };
}
function webhookUpdateRenwal(payload) {
  return {
    type: "WebhookUpdateRenwal",
    payload: payload,
  };
}
function AutoRenwal(payload) {
  return {
    type: "autoRenwal",
    payload: payload,
  };
}
function getInvoiceDetailonAction(payload) {
  return {
    type: "getInvoiceAction",
    payload: payload,
  };
}
function saveRoomInfoInRedux(payload) {
  return {
    type: "ROOM_INFO",
    payload: payload,
  };
}

function AllPatientList(payload) {
  return {
    type: "AllPatientListss",
    payload: payload,
  };
}

function ScreenShareDetaills(payload) {
  return {
    type: "ScreenShareDetail",
    payload: payload,
  };
}

function getOngoingCallState(payload) {
  return {
    type: "ONGOING_CALL_STATE",
    payload: payload,
  };
}
function isMessageImageShownOnFileViewer(payload) {
  return {
    type: "FILE_VIEWER_IMAGE",
    payload: payload,
  };
}

function logoutPatientOnProviderEndCall(payload) {
  return {
    type: "PROVIDER_END_PATIENT_CALL",
    payload: payload,
  };
}
function AllPendingStatus(payload) {
  return {
    type: "pendingStatus",
    payload: payload,
  };
}
function IsUrlCameFromEhr(payload) {
  return {
    type: "IsUrlCameFromUrl",
    payload: payload,
  };
}
function NavigateDasboardIssue(payload) {
  return {
    type: "DasboardIssue",
    payload: payload,
  };
}
function NotificationForCardExpire(payload) {
  return {
    type: "NotificationCardExpire",
    payload: payload,
  };
}
function isProviderSystemCamEnable(payload) {
  return {
    type: "providerSystemCamm",
    payload: payload,
  };
}
function isPatSystemCamEnable(payload) {
  return {
    type: "patSystemCamm",
    payload: payload,
  };
}
function getEhrDimensions(payload) {
  return {
    type: "ehrDimensions",
    payload: payload,
  };
}
function isProviderCameFromEhr(payload) {
  return {
    type: "isProviderCameFromEhr",
    payload: payload,
  };
}
function isPatCameFromEhrOrTabs(payload) {
  return {
    type: "isPatCameFromEhrOrTab",
    payload: payload,
  };
}
function profilePicUpdate(payload) {
  return {
    type: "profilepicupdate",
    payload: payload,
  };
}
function popupPurchase(payload) {
  return {
    type: "popTypePurchase",
    payload: payload,
  };
}
function continesBuying(payload) {
  return {
    type: "continuebuyPackage",
    payload: payload,
  };
}
function removeItemFromNotification(payload) {
  return {
    type: "REMOVE_ITEM",
    payload: payload,
  };
}

function paymentIntentInCase1TPayment(payload) {
  return {
    type: "paymentIntent",
    payload: payload,
  };
}
function Latest_Invoice_Stripe(payload) {
  return {
    type: "latestInvoice",
    payload: payload,
  };
}
function packgNameChossen(payload) {
  return {
    type: "packageChoose",
    payload: payload,
  };
}

function groupChatUniqueIdentifier(payload) {
  return {
    type: "groupChat",
    payload: payload,
  };
}

function MySystemInfo(payload) {
  return {
    type: "MySystemInformation",
    payload: payload,
  };
}

function patientEndProviderImage(payload) {
  return {
    type: "PatientEndProviderImage",
    payload: payload,
  };
}
function GlobalRoomEnd(payload) {
  return {
    type: "globalRoomEnd",
    payload: payload,
  };
}
function isProviderMsgOpen(payload) {
  return {
    type: "isProviderMessagesOpen",
    payload: payload,
  };
}
function roomsSid(payload) {
  return {
    type: "roomSid",
    payload: payload,
  };
}
function roomrecordingRuless(payload) {
  return {
    type: "recordingRules",
    payload: payload,
  };
}
function IzRecordingOn(payload) {
  return {
    type: "isRecordingOn",
    payload: payload,
  };
}
function startCounterRemianing(payload) {
  return {
    type: "startCounter",
    payload: payload,
  };
}
function stopCounterRemaining(payload) {
  return {
    type: "stopCounter",
    payload: payload,
  };
}
function onCompleteAction(payload) {
  return {
    type: "onComplete",
    payload: payload,
  };
}

export {
  onCompleteAction,
  stopCounterRemaining,
  startCounterRemianing,
  IzRecordingOn,
  roomrecordingRuless,
  roomsSid,
  isProviderMsgOpen,
  GlobalRoomEnd,
  removeItemFromNotification,
  groupChatUniqueIdentifier,
  packgNameChossen,
  Latest_Invoice_Stripe,
  paymentIntentInCase1TPayment,
  continesBuying,
  popupPurchase,
  profilePicUpdate,
  isProviderCameFromEhr,
  getEhrDimensions,
  NavigateDasboardIssue,
  AllPendingStatus,
  ScreenShareDetaills,
  AllPatientList,
  LoginAction,
  LoginObject,
  CreateProviderRoom,
  PatientAction,
  RoomForPatient,
  PatientData,
  RoomStatusFN,
  RoomsScreenView,
  CreateStripeDetail,
  packagePurchaseDetailObject,
  intialRoomBeforeScreenShared,
  stripePackageType,
  subscriptionList,
  sessionID,
  totalDays,
  AllAcceptedPatients,
  webhookUpdateRenwal,
  AutoRenwal,
  getInvoiceDetailonAction,
  saveRoomInfoInRedux,
  getOngoingCallState,
  isMessageImageShownOnFileViewer,
  logoutPatientOnProviderEndCall,
  IsUrlCameFromEhr,
  NotificationForCardExpire,
  isProviderSystemCamEnable,
  isPatSystemCamEnable,
  isPatCameFromEhrOrTabs,
  MySystemInfo,
  patientEndProviderImage,
};
