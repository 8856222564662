import React from 'react'

export default function ListFeatures({List,price}) {
  return (
   <>
    <ul className="pricing-card-list">
                    {List && List.map((el , index) => {
                      return (
                        <li className="pricing-card-list-item icon-yes" key={index}>
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                  <p className="pricing-card-price">
                    {price}<span className="pricing-card-price-text">/mo</span>
                  </p>
   </>
  )
}
