import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import RoomHeader from "./RoomHeader";
import RoomBody from "./RoomBody";
import RoomRightSidenav from "./RoomRightSidenav";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../../src/context/socket";

import store from "../../Store/Store";
import {
  createRoom,
  deletedSelectedUserFromList,
  getParticpantMin,
  getPatientJoiningStatusApi,
  getPatientList,
  RecordingRules,
  removeParticpant,
  roomCreation,
  roomDestroy,
  StopRecording,
  updateConsumedMinutesApi,
  UserStatusPendingApi,
  UserStatusPendingApiPatient,
} from "../../Utils/ApiManager";
import {
  saveRoomInfoInRedux,
  getOngoingCallState,
  isMessageImageShownOnFileViewer,
  isProviderSystemCamEnable,
  NavigateDasboardIssue,
  groupChatUniqueIdentifier,
  AllAcceptedPatients,
  isPatSystemCamEnable,
  GlobalRoomEnd,
  roomsSid,
  IzRecordingOn,
  onReloadDeductMinute,
  startCounterRemianing,
} from "../../Actions/Actions";
import { toast } from "react-toastify";
import { LocalAudioTrackPublication } from "twilio-video";
import {
  connect,
  Video,
  createLocalVideoTrack,
  createLocalAudioTrack,
  createLocalTracks,
} from "twilio-video";

import { useNavigate, useLocation } from "react-router-dom";
import ImageComponenet from "./RoomRightSidenav/RoomMessages/Messages/ImageViewerForMessagePic/ImageComponent";
import {
  isSystemCameraAvaialble,
  swalConfirmationforAccept,
} from "../../Utils/commonFunctions";
import { Obj } from "../../Common/PatientLimitations";
function RoomNew({
  backToGlobalRoom,
  isShowCallActionsButton,
  callEndWhenProviderSwitchesTab,
  setCallEndWhenProviderSwitchesTab,
}) {
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };
  const [RightBar, setRightBar] = useState(false);

  const [providerRoom, setProviderRoom] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isRecrodingOn, setIsRecordingOn] = useState(false);

  const [joinedParticipants, setJoinedParticpant] = useState(0);
  const [showMessagesSection, setShowMessagesSection] = useState(false);
  const [whenProviderEndCallWithPat, setwhenProviderEndCallWithPat] =
    useState(true);
  const [whenPatientEndCallWithProvider, setPatientEndCallWithProvider] =
    useState(false);
  const [toggleNoiseCacellationIcon, setToggleNoiseCancellationIcon] =
    useState(true);
  const [handleCallWhenPatEndCall, sethandleCallWhenPatEndCall] =
    useState(false);

  const [handleCallWhenPatEndCallInfo, sethandleCallWhenPatEndCallInfo] =
    useState([]);
  const [patWhoPerformRefresh, setpatWhoPerformRefresh] = useState([]);
  const [patMessageCounter, setPatMessageCounter] = useState(false);
  const [cameraDevFound, setCameraDevFound] = useState(false);
  let objNoise = {
    noiseCancellation: null,
    room: null,
    systemCamAviable: null,
    token: null,
  };
  const [noiseCancellationState, setNoiseCancellation] = useState(objNoise);

  const socket = useContext(SocketContext);
  let LoginUser = useSelector((state) => state.LoginObject);
  const {
    EndCallWhenPatSwitchTabs,
    AllPatientsAccepted,
    RoomSid,
    StripePackagePurchaseDetailObject,
    RecordingRulesUpdates,
  } = useSelector((state) => state);
  useEffect(() => {
    if (EndCallWhenPatSwitchTabs) {
      console.log("EndCallWhenPatSwitchTabs", EndCallWhenPatSwitchTabs);
    }
  }, [EndCallWhenPatSwitchTabs]);

  const {
    PatientDataDetails,
    RetreiveAllPatientList,
    LoginObject,
    isMessageShownOnFileViewer,
    RoomInfo,
    PatientInfo,
    IsProviderSystmCamEnable,
    IsPatSystmCamEnable,
    isProviderCameFromEhrProv,
    SystemInfo,
  } = useSelector((state) => state);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  console.log("IsPatSystmCamEnable", IsPatSystmCamEnable);

  const [toggleImageUrl, settoggleImageUrl] = useState(false);
  const [fileType, setfileType] = useState(false);
  const [tooggleImage, settooggleImage] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [tickVal, setTickVal] = useState(0);
  const [whenProviderEndGroupCall, setwhenProviderEndGroupCall] =
    useState(false);
  const [handleProviderReload, setHandleProviderReload] = useState(false);
  const [cameraFound, setCameraFound] = useState(true);

  const [MediaLabel, setMediaLabel] = useState();
  const [countdownMin, setCountDownMin] = useState(0);
  console.log(countdownMin, "countdownMin");
  //------------------For reconnection event-------------------------------
  const [videoAvaialbel, setVideoAvaialble] = useState(false);
  const [roomNameReconnect, setRoomNameRecoent] = useState(null);
  const [reconnectToken, setToken] = useState(null);
  const [signalRetry, setSignalRetry] = useState(false);

  useEffect(() => {
    dispatch(NavigateDasboardIssue(false));
    if (!LoginObject.username) {
      intialgetPatientList();
    }
  }, []);
  // useEffect(() => {
  //   debugger;
  //   if (SystemInfo.name === "chrome") {
  //     navigator.mediaDevices
  //       .enumerateDevices()
  //       .then((data) => {
  //         console.log("data", data);
  //       })
  //       .catch((err) => {
  //         console.log("error getting MediaDeviceInfo list", err);
  //       });
  //   } else {
  //     navigator.mediaDevices
  //       .getUserMedia()
  //       .then((data) => {
  //         console.log("data", data);
  //       })
  //       .catch((err) => {
  //         console.log("error getting MediaDeviceInfo list", err);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    if (LoginUser && LoginUser?.username) {
      isSystemCameraAvaialble();
    }
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (
      !whenProviderEndCallWithPat &&
      !LoginObject.username &&
      !handleProviderReload
    ) {
      window.addEventListener("beforeunload", handleTabClosing);
      // window.addEventListener("unload", ()=>handleTabClosing());
      return () => {
        window.removeEventListener("beforeunload", handleTabClosing);
        // window.removeEventListener("unload", ()=>handleTabClosing());
      };
    }
  }, [whenProviderEndCallWithPat]);
  useEffect(() => {
    if (callEndWhenProviderSwitchesTab) {
      confirmationForCallEnd();
    }
  }, [callEndWhenProviderSwitchesTab]);

  //  --------------------Case1------------------
  useEffect(() => {
    if (LoginObject.username && !whenProviderEndGroupCall) {
      window.addEventListener(
        "beforeunload",
        thiswillHandleLogoutAndStatusOfUser
      );
      // window.addEventListener("unload", );
      return () => {
        window.removeEventListener(
          "beforeunload",
          thiswillHandleLogoutAndStatusOfUser
        );
        // window.removeEventListener(
        //   "unload",
        //   ()=>thiswillHandleLogoutAndStatusOfUser()
        // );
      };
    }

    if (
      !LoginObject.username &&
      !whenPatientEndCallWithProvider &&
      !handleProviderReload
    ) {
      window.addEventListener("beforeunload", blank);

      return () => {
        window.removeEventListener("beforeunload", blank);
      };
    }
  });
  const updateMinApi = async (obj) => {
    let minutes = obj?.total / 1000 / 60;
    //After UpdateMinApi call
    let formData = new FormData();
    formData.append("userid", LoginUser?.id);
    formData.append("id", LoginUser?.id);
    formData.append("totalMinConsumed", minutes);
    let res = await updateConsumedMinutesApi(formData);
    setCountDownMin(0);
  };
  const checkDeduct = async (check, obj) => {
    if (check) {
    } else {
      setTickVal(obj?.total / 1000 / 60);
    }
  };

  const handleReloadwithRoom = async (e, tickVal, RoomSid) => {
    const pkg = StripePackagePurchaseDetailObject?.packageName;
    if (pkg == "silver" || pkg == "metal") {
      let formData = new FormData();
      formData.append("userid", LoginUser?.id);
      formData.append("id", LoginUser?.id);
      formData.append("totalMinConsumed", tickVal);
      let res = await updateConsumedMinutesApi(formData);
      setCountDownMin(0);
    }

    if (RoomSid) {
      let obj = { roomSid: RoomSid };
      await roomDestroy(obj);
    }
  };

  useEffect(() => {
    if (true) {
      window.onbeforeunload = (e) => {
        handleReloadwithRoom(
          e,
          tickVal,
          RoomSid,
          StripePackagePurchaseDetailObject?.packageName
        );
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [tickVal, RoomSid]);

  useEffect(() => {
    if (isMessageShownOnFileViewer) {
      settoggleImageUrl(isMessageShownOnFileViewer.url);
      setfileType(isMessageShownOnFileViewer.fileType);
      settooggleImage(isMessageShownOnFileViewer.toggleImage);

      setShowImage(isMessageShownOnFileViewer.check);
    }
  }, [isMessageShownOnFileViewer]);
  let intervalForTime;
  useEffect(() => {
    if (!LoginObject.username) {
      intervalForTime = setInterval(
        () => getPatientJoiningStatus(RoomInfo),
        5000
      );
      return () => {
        clearInterval(intervalForTime);
      };
    }
  }, []);

  useEffect(() => {
    if (LoginUser && LoginUser.username) {
      setShowMessagesSection(false);
      createRoomCall(LoginUser.username);
    }
  }, []);
  const dispatchPatientCamera = (PatientDataDetails, val) => {
    console.log("dispatch camera", val, PatientDataDetails);
    creatingVideoChatOn(
      PatientDataDetails.roomName,
      PatientDataDetails.token,
      val,
      null
    );
    dispatch(isPatSystemCamEnable(val));
  };

  useEffect(() => {
    if (PatientDataDetails && PatientDataDetails.roomName) {
      setCameraDevFound(IsPatSystmCamEnable);
      const isSystemCameraAvaialble = async (SystemInfo) => {
        console.log(SystemInfo, "os IN ROOM ");
        if (SystemInfo.name === "chrome") {
          return new Promise((resolve, reject) => {
            navigator.mediaDevices
              .enumerateDevices()
              .then((devices) => {
                let cameraFound = false;
                devices.forEach(function (device) {
                  if (device.kind === "videoinput" && device.deviceId != "") {
                    cameraFound = true;
                  }
                });
                console.log("For Cmar redux", cameraFound);

                resolve(cameraFound);
              })
              .catch(function (error) {
                reject(error);
              });
          });
        } else {
          navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((data) => {
              console.log("devices Fetch from user Media", data);
            });
          return new Promise((resolve, reject) => {
            navigator.mediaDevices
              .getUserMedia()
              .then((devices) => {
                console.log("devices mobile 34", devices);
                let cameraFound = false;
                devices.forEach(function (device) {
                  if (device.kind === "videoinput" && device.deviceId != "") {
                    cameraFound = true;
                  }
                });
                console.log("For Cmar redux devices mobile", cameraFound);

                resolve(cameraFound);
              })
              .catch(function (error) {
                reject(error);
              });
          });
        }
      };
      isSystemCameraAvaialble(SystemInfo).then((cameraFound) => {
        console.log("pat Camera Found", cameraFound);
        dispatchPatientCamera(PatientDataDetails, cameraFound);
      });
    }
  }, [PatientDataDetails]);

  const proivderEndPatientCallLogoutPatient = useCallback(() => {
    setProviderRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      // window.location.href = '/'
      return null;
    });
  }, []);
  const confirmationForCallEnd = async () => {
    let swalObj = {};
    swalObj.text = "Are you sure that you want to end Call";
    swalObj.title = "Are you sure?";
    swalObj.className = "warning";
    swalObj.icon = "warning";
    swalObj.confirmButtonColor = "#FF0000";
    swalObj.buttons = ["No ", "Yes"];
    let willEndCall = await swalConfirmationforAccept(swalObj);

    if (willEndCall) {
      setCallEndWhenProviderSwitchesTab(false);
      thiswillHandleLogoutAndStatusOfUser();
      // await DeleteSub()
    } else {
      setCallEndWhenProviderSwitchesTab(false);
    }
  };
  const blank = async () => {
    handleLogout();
  };
  const videoDomDisable = () => {
    Video.enableDominantSpeaker(false);
  };

  const handlePhoneCallEndOnPatientSide = async (patientObj) => {
    if (!LoginObject.username) {
      sethandleCallWhenPatEndCallInfo(patientObj);
      setPatientEndCallWithProvider(true);
      await UserStatusPendingApiPatient(patientObj).then(async (res) => {
        let reso = res.data;
        if (reso === "user successfully updated!") {
          await removeParticpant(patientObj).then((res) => {});
          socket.emit("When-Patient-End-Call", patientObj);
        }
      });
    } else {
    }
    //   await deleteSelectedPatient(patientObj);
  };
  const deleteSelectedPatient = (el) => {
    const { roomName, patId } = el;
    deletedSelectedUserFromList(patId, roomName)
      .then((res) => {})
      .catch((err) => {});
  };

  const handleTabClosing = async () => {
    if (
      !LoginObject.username &&
      PatientDataDetails &&
      PatientDataDetails.roomName
    ) {
      await proivderEndPatientCallLogoutPatient();

      UserStatusPendingApiPatient(
        PatientDataDetails.patId,
        PatientDataDetails.roomName
      )
        .then((el) => {})
        .catch((err) => {});
      // await removePatientFromList(
      //   PatientDataDetails.patId,
      //   PatientDataDetails.roomName
      // );
    }
  };

  const removePatientFromList = async (id, romname) => {
    await deletedSelectedUserFromList(id, romname).then((res) => {});
  };
  // const alertUser = (event) => {

  //   event.preventDefault();
  //   event.returnValue = "Are you sure want to close the tab";
  // };
  const intialgetPatientList = () => {
    getPatientList(RoomInfo).then((res) => {
      let arr = res && res.data;
      const results = arr.filter((element) => {
        return element.active === "accepted";
      });
      dispatch(groupChatUniqueIdentifier(results));
    });
  };

  const getPatientJoiningStatus = async (RoomInfo) => {
    // try {
    //   getPatientJoiningStatusApi(RoomInfo).then((res) => {
    //     let data = res.data;

    //     if (data && data.length > 0) {
    //       let currentPatient = data.find((el) => el.id === PatientDataDetails.patId);
    //       if (currentPatient) {

    //       }
    //       else {
    //         proivderEndPatientCallLogoutPatient()
    //         window.location.href = '/'
    //       }

    //     }
    //     else {
    //       proivderEndPatientCallLogoutPatient()
    //       window.location.href = '/'

    //     }
    //   });
    // } catch (err) { }

    getPatientList(RoomInfo).then((res) => {
      let arr = res && res.data;
      const results = arr.filter((element) => {
        return element.active === "accepted";
      });
      let reduxPatAcceptedList =
        store && store.getState()?.GroupChatUniqueIdentifierr;
      console.log(
        "reduxPatAcceptedList",
        reduxPatAcceptedList.length,
        results.length
      );
      if (reduxPatAcceptedList.length != results.length) {
        dispatch(groupChatUniqueIdentifier(results));
      }
      //  dispatch(groupChatUniqueIdentifier(results))

      let currentPatient;

      if (results && results.length > 0) {
        currentPatient = results.find(
          (el) => el.id === PatientDataDetails.patId
        );

        if (currentPatient) {
        } else {
        }
      } else {
        if (!LoginObject.username) {
          arr.map((el) => {
            if (el.id === PatientInfo && el.active === "pending") {
              //  window.location.reload();
            }
          });
        }
      }
    });
  };

  // useEffect(() => {
  //   if(handleCallWhenPatEndCall && handleCallWhenPatEndCallInfo )

  //   {
  //     socket.emit("When-Patient-End-Call",handleCallWhenPatEndCallInfo)
  //   }

  // }, [handleCallWhenPatEndCall,handleCallWhenPatEndCallInfo])

  const CloseImageViewerForMsg = () => {
    let imageHastobeViewObj = {};
    imageHastobeViewObj.url = null;
    imageHastobeViewObj.toggleImage = null;
    imageHastobeViewObj.fileType = null;
    imageHastobeViewObj.check = false;
    dispatch(isMessageImageShownOnFileViewer(imageHastobeViewObj));
  };

  const getParticipantDetails = (particpant) => {
    setJoinedParticpant(particpant && particpant.length);
  };
  const showMessageSection = (val) => {
    socket.emit("trigger-chat", val);
    setPatMessageCounter(val);

    setShowMessagesSection(val);
  };
  const getDeviceId = (deviceId) => {
    const localParticipant = providerRoom.localParticipant;
    if (deviceId) {
      createLocalVideoTrack &&
        createLocalVideoTrack({
          deviceId: { exact: deviceId },
        }).then(function (localVideoTrack) {
          console.log(localVideoTrack, "localVideoTrack");

          const tracks = Array.from(localParticipant.videoTracks.values()).map(
            (publication) => publication.track
          );
          localParticipant.unpublishTracks(tracks);
          console.log(
            localParticipant.identity + " removed track: " + tracks[0].kind
          );
          // detachTracks(tracks)

          localParticipant.publishTrack(localVideoTrack);
          console.log(
            localParticipant.identity + " added track: " + localVideoTrack.kind
          );
          //detachTracks(tracks)
          // attachTracks([localVideoTrack]);
          // tracks.attachTracks()
        });
    }
  };
  const getParticipantMin = async () => {
    let res = await getParticpantMin();
    if (res && res.data) {
      const filteredArr = res.data.filter(
        (item) => item.user_id === LoginObject?.id
      );
      let minutes;
      if (filteredArr && filteredArr.length > 0) {
        minutes = filteredArr[0]?.totalMinConsumed;
      }
      return minutes * 60;
    }
  };
  //MaxParticpantDuration
  const roomCreate = async (userName) => {
    const pkg = StripePackagePurchaseDetailObject?.packageName;
    let limitations = await getParticipantMin();

    let obj = {
      type: "go",
      userName: userName,
      totalParticipantMin: 60,
    };

    if (pkg === "silver" || pkg === "metal") {
      roomCreation(obj).then((res) => {
        if (res && res.data) {
          dispatch(roomsSid(res.data.room.sid));
        }
      });
    }
    if (pkg === "gold") {
      let obj1 = {
        type: "group",
        userName: userName,
        totalParticipantMin: limitations,
      };
      roomCreation(obj1).then((res) => {
        if (res && res.data) {
          //callRecordingRules(res.data.room.sid);
          dispatch(roomsSid(res.data.room.sid));
        }
      });
    }
  };
  const callRecordingRules = async (sid) => {
    let obj = { roomSid: sid };
    let res = await RecordingRules(obj);
  };
  const createRoomCall = async (userName) => {
    createRoom(userName)
      .then(async (response) => {
        if (response && response.data) {
          roomCreate(userName);
          const camerFound = await isSystemCameraAvaialble(SystemInfo);

          setCameraDevFound(camerFound);
          dispatch(isProviderSystemCamEnable(camerFound));

          dispatch(saveRoomInfoInRedux(response.data));
          const pkg = StripePackagePurchaseDetailObject?.packageName;

          //if noise Cancellation is not present
          if (pkg === "silver" || pkg === "metal") {
            await creatingVideoChatOn(
              response.data.roomname,
              response.data.usertoken,
              camerFound,
              null
            );
          } else {
            let noiseObj = await enableDisableNoise(true, camerFound, null);

            let newState = {
              noiseCancellation: noiseObj,
              token: response.data.usertoken,
              room: response.data.roomname,
              systemCamAviable: camerFound,
            };
            setNoiseCancellation((prevState) => {
              const updatedState = { ...prevState, ...newState };
              return updatedState;
            });
          }
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    let a = noiseCancellationState;
    if (a.noiseCancellation && a.token && a.room && a.systemCamAviable) {
      creatingVideoChatOn(
        a.room,
        a.token,
        a.systemCamAviable,
        a.noiseCancellation
      );
    }
  }, [
    noiseCancellationState.noiseCancellation,
    noiseCancellationState.token,
    noiseCancellationState.room,
    noiseCancellationState.systemCamAviable,
  ]);

  const gotDevices = (mediaDevices) => {
    let mediaLabel = [];

    mediaDevices.forEach((mediaDevice) => {
      if (mediaDevice.kind === "videoinput") {
        mediaLabel.push({
          deviceId: mediaDevice.deviceId,
          deviceLabel: mediaDevice.label,
        });
      }
    });

    setMediaLabel(mediaLabel);
  };
  const maxRoomParitcipantLimitationExceded = () => {
    console.log(PatientDataDetails, "PatientDataDetails");
    handlePhoneCallEndOnPatientSide(PatientDataDetails);
  };

  const enableDisableNoise = async (check, systemCamAviable, checked) => {
    let tracksNoise;
    if (check) {
      tracksNoise = await createLocalTracks({
        audio: {
          noiseCancellationOptions: {
            sdkAssetsPath: process.env.REACT_APP_APIS_GLOBAL_LOCALHOST,
            vendor: "krisp",
          },
        },
        video: systemCamAviable ? { name: "camera" } : false,
      });

      return tracksNoise;
    } else {
      if (checked == true) {
        setToggleNoiseCancellationIcon(true);
        await noiseCancellationState.noiseCancellation[0].noiseCancellation.enable();
        toast.info("Noise Cancellation Active");
      }
      if (checked == false) {
        setToggleNoiseCancellationIcon(false);
        await noiseCancellationState.noiseCancellation[0].noiseCancellation.disable();
        toast.info("Noise Cancellation Disable");
      }
    }
  };
  let noiseObj;
  const creatingVideoChatOn = useCallback(
    async (room, token, systemCamAviable, isNoise) => {
      if (room && token) {
        setVideoAvaialble(systemCamAviable);
        setRoomNameRecoent(room);
        setToken(token);

        console.log(noiseCancellationState);
        let objWithoutNoise = {
          name: room,
          // tracks: noiseObj,
          audio: { name: "microphone" },
          _useTwilioConnection: true,

          video: systemCamAviable ? { name: "camera" } : false,
          dominantSpeaker: true,
          networkQuality: {
            local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 2, // RemoteParticipants' Network Quality verbosity [0 - 3]
          },
        };
        let objWithNoise = {
          name: room,
          tracks: isNoise,
          // audio: { name: "microphone" },
          _useTwilioConnection: true,

          // video: systemCamAviable ? { name: "camera" } : false,
          dominantSpeaker: true,
          networkQuality: {
            local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 2, // RemoteParticipants' Network Quality verbosity [0 - 3]
          },
        };
        let finalObj = isNoise != null ? objWithNoise : objWithoutNoise;
        // delay state latency

        connect(token, finalObj)
          .then(async (room) => {
            if (room) {
              setProviderRoom(room);

              dispatch(getOngoingCallState(true));
            } else {
              dispatch(getOngoingCallState(false));
            }
          })
          .catch((err) => {
            console.log("error code ", err.code, err);
            if (err && err.code === 53105) {
              if (err.message == "Room contains too many Participants") {
                toast.warn(
                  "Room contains too many Participants due to package Limitations"
                );
                maxRoomParitcipantLimitationExceded();
              }
            }

            if (err && err.code === 20103) {
              toast.warn("Something went wrong please contact support");
            } else {
              // toast.warn(
              //   "Webcam is not available. You are joining with audio only"
              // );
            }
            if (err && err.code === 53000) {
              console.log("we in here");
              toast.warn("Signal lost ,Retrying");
              setSignalRetry(true);
            }
          });
      }
    },
    []
  );

  useEffect(() => {
    if (signalRetry) {
      console.log("we out ");
      creatingVideoChatOn(
        roomNameReconnect,
        reconnectToken,
        videoAvaialbel,
        null
      );
      setSignalRetry(false);
    }
  }, [signalRetry]);

  useEffect(() => {
    if (LoginObject && LoginObject.username && isProviderCameFromEhrProv) {
      setShowMessagesSection(false);
      setRightBar(!RightBar);
    }
  }, [isProviderCameFromEhrProv]);

  const toggleRightSidenav = () => {
    setShowMessagesSection(false);
    if (LoginObject.username) {
      setRightBar(!RightBar);
    }
  };

  const handleLogout = useCallback(() => {
    setProviderRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      if (LoginUser && LoginUser.username) {
        navigate("/dashboard");
      } else {
        if (PatientDataDetails && PatientDataDetails.roomName) {
          let obj = {};
          obj.patId = PatientDataDetails.patId;
          obj.roomName = PatientDataDetails.roomName;
          UserStatusPendingApiPatient(obj);
        }
      }
      return null;
    });
  }, []);

  const deletePaientWhenLeaveCall = async (id, romname) => {
    await deletedSelectedUserFromList(id, romname).then((res) => {});
    window.location.href = "/";
  };

  const onDestroyRoomDeductMin = async () => {
    const pkg = StripePackagePurchaseDetailObject?.packageName;
    if (pkg == "silver" || pkg == "metal") {
      setCountDownMin(0);
      dispatch(startCounterRemianing(false));
      let formData = new FormData();
      formData.append("userid", LoginUser?.id);
      formData.append("id", LoginUser?.id);
      formData.append("totalMinConsumed", tickVal);

      await updateConsumedMinutesApi(formData);
    }
  };
  const thiswillHandleLogoutAndStatusOfUser = async () => {
    let obj = { roomSid: RoomSid };
    onDestroyRoomDeductMin();
    roomDestroy(obj);

    dispatch(GlobalRoomEnd(true));
    await thiswillConvertStatustoPending();
    await handleLogout();
    dispatch(AllAcceptedPatients([]));

    if (LoginObject.username) {
      setwhenProviderEndGroupCall(true);
    }
  };

  const thiswillConvertStatustoPending = () => {
    RetreiveAllPatientList &&
      RetreiveAllPatientList.map((el) => {
        UserStatusPendingApi(LoginObject, el)
          .then((el) => {})
          .catch((err) => {});
      });
  };

  const setProviderRoomFN = (room) => {
    setProviderRoom(room);
  };
  const isRecordingOn = (val) => {
    dispatch(IzRecordingOn(val));
    setIsRecordingOn(val);
    let obj = { roomSid: RoomSid, RecSenario: RecordingRulesUpdates };
    if (val) {
      RecordingRules(obj)
        .then((res) => {
          if (res && res.data) {
            toast.info("Recording Started");
          }
        })
        .catch((err) => {
          toast.info("Something Went Wrong");
        });
    } else {
      StopRecording(obj)
        .then((res) => {
          if (res && res.data) {
            toast.info("Recording Stoped");
          }
        })
        .catch((err) => {
          toast.info("Something Went Wrong");
        });
    }
  };

  let roomName;
  if (LoginUser && LoginUser.username) {
    roomName = LoginUser.username;
  } else {
    if (PatientDataDetails && PatientDataDetails.roomName) {
      roomName = PatientDataDetails.roomName;
    }
  }

  let mobileResponsiveCheck = LoginObject.username
    ? RightBar
      ? "chat video-screen "
      : "chat video-screen hide-chatbar "
    : !LoginObject.username
    ? showMessagesSection
      ? "chat video-screen hide-chatbar"
      : windowSize.innerWidth < 992
      ? "chat video-screen "
      : "chat video-screen hide-chatbar"
    : "chat video-screen";

  return (
    <>
      {showImage ? (
        <ImageComponenet
          url={toggleImageUrl}
          toggleImage={CloseImageViewerForMsg}
          fileType={fileType}
        />
      ) : null}
      <div className="d-flex content main_content">
        <div className={mobileResponsiveCheck} id="middle">
          {!isShowCallActionsButton ? (
            <RoomHeader
              EnableDisableNoiseCancellation={enableDisableNoise}
              checkDeduct={checkDeduct}
              setCountDownMin={setCountDownMin}
              countdownMin={countdownMin}
              updateMinApi={updateMinApi}
              getDeviceId={getDeviceId}
              MediaLabel={MediaLabel}
              setPatMessageCounter={setPatMessageCounter}
              patMessageCounter={patMessageCounter}
              toggleRightSidenav={toggleRightSidenav}
              showMessagesSection={showMessagesSection}
              showMessageSection={showMessageSection}
              countRemoteParticipants={joinedParticipants}
              roomName={roomName}
            />
          ) : null}

          <RoomBody
            toggleNoiseCacellationIcon={toggleNoiseCacellationIcon}
            EnableDisableNoiseCancellation={enableDisableNoise}
            isRecrodingOn={isRecrodingOn}
            isRecordingOn={isRecordingOn}
            backToGlobalRoom={backToGlobalRoom}
            isShowCallActionsButton={isShowCallActionsButton}
            showMessageSection={showMessagesSection}
            cameraDevFound={cameraDevFound}
            videoDomDisable={videoDomDisable}
            handlePhoneCallEndOnPatientSide={handlePhoneCallEndOnPatientSide}
            toggleRightSidenav={toggleRightSidenav}
            getParticipantDetails={getParticipantDetails}
            setProviderRoomFN={setProviderRoomFN}
            providerRoom={providerRoom}
            thiswillHandleLogoutAndStatusOfUser={
              thiswillHandleLogoutAndStatusOfUser
            }
          />
        </div>
        {!isShowCallActionsButton ? (
          <RoomRightSidenav
            setCountDownMin={setCountDownMin}
            showMessageSection={showMessageSection}
            showMessagesSection={showMessagesSection}
            joinedParticipants={joinedParticipants}
            getParticipantDetails={getParticipantDetails}
            toggleRightSidenav={toggleRightSidenav}
            RightBar={RightBar}
          />
        ) : null}
      </div>
    </>
  );
}

export default RoomNew;
