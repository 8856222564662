import Webcam from "react-webcam";
import React from "react";

export default function WebComponentWebCompon({ onJoinsSmallScreenClass }) {
  const videoConstraints = {
    width: 280,
    height: 250,
    facingMode: "user",
  };
  return (
    <div
      className={
        onJoinsSmallScreenClass
          ? "flex-fill modal-video joined downdivmainpagecustom"
          : "flex-fill modal-video  downdivmainpagecustom"
      }
    >
      <Webcam
        id="preVideoPatientEnd"
        audio={false}
        height={"100%"}
        screenshotFormat="image/jpeg"
        width={"100%"}
        videoConstraints={videoConstraints}
      />
    </div>
  );
}
