import React, { useEffect } from "react";
import {
  getCardDetailsApi,
  getCreditCardFromDb,
} from "../../../Utils/ApiManager";
import { toast } from "react-toastify";
import "./CardDetail.css";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import CardComp from "./CardComp";
import { NotificationForCardExpire } from "../../../Actions/Actions";

export default function CardDetails() {
  let dispatch = useDispatch();
  const {
    SubscriptionList,
    LoginObject,
    StripePackagePurchaseDetailObject,
    paymentIntentInCaseOfTime,
  } = useSelector((state) => state);
  const [FirstcardDetails, setCardDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCard();
  }, []);
  const getCard = () => {
   
    setIsLoading(true);
    getCreditCardFromDb(LoginObject && LoginObject.id)
      .then((res) => {
      
        let check =
        StripePackagePurchaseDetailObject.packageName === "silverOneTime" ||
        StripePackagePurchaseDetailObject.packageName === "goldOneTime" ||
        StripePackagePurchaseDetailObject.packageName === "metalOneTime";
        setIsLoading(false);
       
        let results;
       
        if (check) {
           results =
            res &&
            res.data.filter((element) => {
              return element.cusId === paymentIntentInCaseOfTime;
            });
        }else{
          let cusIdd = SubscriptionList && SubscriptionList[0].customer;
           results =
          res &&
          res.data.filter((element) => {
            return element.cusId === cusIdd;
          });

        }
       
        const object = Object.assign({}, ...results);
        if (object) {
          const date = new Date();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          let date2 = object.exp_year + "-" + object.exp_month + "-" + "01";
          let date1 = year + "/" + month + "/" + "01";

          let diffMonth = getMonthDifference(new Date(date1), new Date(date2));

          if (
            diffMonth === 0 ||
            diffMonth === 1 ||
            (diffMonth > 0 && diffMonth < 1)
          ) {
            let arr = [];
            let obj = {};

            obj.msg = ` Your  Credit Card will expire on ${object.exp_year}-${object.exp_month}`;
            arr.push(obj);
            dispatch(NotificationForCardExpire(obj));
          }
        }

        setCardDetails(object);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getMonthDifference = (startDate, endDate) => {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  };

  return (
    <div className="social-settings">
      <div className="">
        <div className="d-flex mt-3 ml-2">
          <div className="container-fluid  mx-auto">
            <div className="row d-flex justify-content-center">
              <CardComp
                getCard={getCard}
                cardDetails={FirstcardDetails}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}