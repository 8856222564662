import React from "react";

import { useState } from "react";
import { RecordingAudioRules, RecordingRules } from "../../../Utils/ApiManager";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { roomrecordingRuless } from "../../../Actions/Actions";
export default function RecModal({ onClose }) {
  let dispatch = useDispatch();

  const { RoomSid, IsRecordingOn, RecordingRulesUpdates } = useSelector(
    (state) => state
  );
  const [selectedRadio, setSelectedRadio] = useState(RecordingRulesUpdates);

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setSelectedRadio(value);
    //setRulesStatus(value === "AllParAudioVideo");
  };
  const updateRecordingRules = () => {
    let obj = {
      roomSid: RoomSid,
    };

    if (selectedRadio == "AllParAudioVideo") {
      dispatch(roomrecordingRuless("AllParAudioVideo"));
      if (IsRecordingOn) {
        RecordingRules(obj)
          .then((res) => {
            if (res && res.data) {
              toast.info("Recording Rules Updated");
              onClose();
            }
          })
          .catch((err) => {
            toast.info("SomeThing Went Wrong");
          });

        return;
      }
      toast.info("Recording Rules Updated");
      onClose();
      return;
    }
    if (selectedRadio == "AllParAudio") {
      dispatch(roomrecordingRuless("AllParAudio"));
      if (IsRecordingOn) {
        RecordingAudioRules(obj)
          .then((res) => {
            if (res && res.data) {
              toast.info("Recording  Rules Updated");
              onClose();
            }
          })
          .catch((err) => {
            toast.info("SomeThing Went Wrong");
          });
        return;
      }
      toast.info("Recording Rules Updated");
      onClose();

      return;
    }
  };

  return (
    <>
      <div
        className="modal fade show d-block small"
        // id="encounterfieldadd"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ background: "#121212ad" }}
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Recording Rules
              </h5>
              <button
                style={{
                  padding: "5px",
                  width: "26px",
                  marginTop: "5px",
                }}
                type="button"
                className="btn btn-light"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                ×
              </button>
            </div>

            <div className="modal-body">
              {false ? null : ( // <Loader small={false} large={true} />
                <>
                  {" "}
                  <div className="form-group">
                    <div class="form-check ">
                      <input
                        class="form-check-input ml-1"
                        type="radio"
                        name="AllParAudioVideo"
                        id="flexRadioDefault1"
                        value="AllParAudioVideo"
                        onChange={handleRadioChange}
                        checked={selectedRadio === "AllParAudioVideo"}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Record All Participants Audio/Video
                      </label>
                    </div>
                    <div class="form-check ">
                      <input
                        class="form-check-input ml-1"
                        type="radio"
                        name="AllParAudio"
                        id="flexRadioDefault2"
                        value="AllParAudio"
                        onChange={handleRadioChange}
                        checked={selectedRadio === "AllParAudio"}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Record All Participants Audio
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex  justify-content-between">
                    <div>
                      <h3>Instructions</h3>
                    </div>
                  </div>
                  <div
                    style={{ overflow: "auto", height: "150px" }}
                    className="mh-20 mt-3 lead"
                  >
                    <p className="p">
                      {" "}
                      {/* Hi, {userName} has invited you to join a Telxy call. */}
                      Recording Rules can be updated througout the Room at any
                      time
                      <br></br>
                      <br></br>
                      {/* {RoomUrl} */}
                      <br></br>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={updateRecordingRules}
                //disabled={error}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
