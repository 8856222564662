import React, { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import { data, options, options0 } from "../../Common/Charts";
import { Bar } from "react-chartjs-2";
import TotalRoomsCard from "./DashbordCards/TotalRoomscard/Index";
import TotalParticipants from "./DashbordCards/TotalParticipantscard/Index";
import TotalMinutes from "./DashbordCards/TotalMinutescard/Index";
import { useSelector } from "react-redux";
import TransactionCard from "./DashbordCards/TransactionCard/Index";
import RoomGraph from "./Dashboard Graph/RoomGraph";
import ParticipantGraph from "./Dashboard Graph/ParticipantGraph";
import MinuteGraphCalc from "./Dashboard Graph/MinuteGraphCalc";
import { useDispatch } from "react-redux";
import {
  isProviderCameFromEhr,
  NavigateDasboardIssue,
} from "../../Actions/Actions";
import RemianingMinutes from "./RemainingMinutes";
import { roomDestroy } from "../../Utils/ApiManager";
import ConsumedMinutes from "./DashbordCards/RemainingConsumedMin/ConsumedMin";
import ParticpantLimit from "./ParticpantLimit/ParticipantLimit";
function Dashboard() {
  let dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [ehrWidth, setEhrWidth] = useState(1000);
  const userName = state.LoginObject.fullname;
  const RoomSid = state.LoginObject.RoomSid;
  useEffect(() => {
    const updateDimensions = () => {
      setEhrWidth(window.innerWidth);
      // setHeight(window.innerHeight)
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    dispatch(NavigateDasboardIssue(true));
    if (userName && ehrWidth < 992) {
      dispatch(isProviderCameFromEhr(true));
    }
  }, [ehrWidth]);
  const handleReloadwithRoom = async () => {
    if (RoomSid) {
      let obj = { roomSid: RoomSid };
      await roomDestroy(obj);
    }
  };
  useEffect(() => {
    if (true) {
      window.onbeforeunload = function () {
        handleReloadwithRoom();
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      {/* //test */}
      <div className="row dashboard">
        <div className="col-md-12 ">
          <div className="row">
            <div className="col-12 col-xl-8 mb-2 mb-xl-0">
              <h3 className="font-weight-bold">Welcome {userName}</h3>
              {/* <h6 className="font-weight-normal mb-0">
                All systems are running smoothly! You have{" "}
                <span className="text-primary">3 unread alerts!</span>
              </h6> */}
            </div>
            <div className="col-12 col-xl-4">
              <div className="justify-content-end d-flex">
                <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                  <button
                    className="btn btn-sm btn-light bg-white dropdown-toggle"
                    type="button"
                    id="dropdownMenuDate2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <i className="mdi mdi-calendar "></i>{" "}
                    {new Date().toDateString()}
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuDate2"
                  >
                    <a className="dropdown-item">January - March</a>
                    <a className="dropdown-item">March - June</a>
                    <a className="dropdown-itemRoomGraph">June - August</a>
                    <a className="dropdown-item">August - November</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3 dashboard">
        {/* <div className="col-md-6  stretch-card">
          <div className="card tale-bg">
            <div className="card-people mt-auto">
              <img
                src="https://technext.github.io/skydash/images/dashboard/people.svg"
                alt="people"
              />
              <div className="weather-info">
                <div className="d-flex">
                  <div>
                    <h2 className="mb-0 font-weight-normal">
                      <i className="icon-sun mr-2"></i>31<sup>C</sup>
                    </h2>
                  </div>
                  <div className="ml-2">
                    <h4 className="location font-weight-normal">Rawalpindi</h4>
                    <h6 className="font-weight-normal">Pakistan</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-md-12  transparent">
          <div className="row">
            <div className="col-lg-3 col-md-6  mb-2 stretch-card transparent">
              {/* <div className="card card-tale">
                <div className="card-body">
                  <p className="mb-2">Today’s Bookings</p>
                  <p className="fs-30 mb-2">4006</p>
                  <p>10.00% (30 days)</p>
                </div>
              </div> */}
              <TotalRoomsCard></TotalRoomsCard>
            </div>
            <div className="col-lg-3 col-md-6  mb-2 stretch-card transparent">
              <div className="card ">
                <TotalParticipants></TotalParticipants>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mb-2 mb-2 stretch-card transparent">
              <div className="card">
                {/* <div className="card-body">
                  <p className="mb-2">Number of Meetings</p>
                  <p className="fs-30 mb-2">34040</p>
                  <p>2.00% (30 days)</p>
                </div> */}
                <TotalMinutes></TotalMinutes>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mb-2 stretch-card transparent">
              <div className="card ">
                <TransactionCard />
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mb-2 mb-2 stretch-card transparent">
              <div className="card">
                {/* <div className="card-body">
                  <p className="mb-2">Number of Meetings</p>
                  <p className="fs-30 mb-2">34040</p>
                  <p>2.00% (30 days)</p>
                </div> */}
                <RemianingMinutes></RemianingMinutes>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mb-2 mb-2 stretch-card transparent">
              <div className="card">
                {/* <div className="card-body">
                  <p className="mb-2">Number of Meetings</p>
                  <p className="fs-30 mb-2">34040</p>
                  <p>2.00% (30 days)</p>
                </div> */}
                <ConsumedMinutes></ConsumedMinutes>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mb-2 mb-2 stretch-card transparent">
              <div className="card">
                {/* <div className="card-body">
                  <p className="mb-2">Number of Meetings</p>
                  <p className="fs-30 mb-2">34040</p>
                  <p>2.00% (30 days)</p>
                </div> */}
                <ParticpantLimit></ParticpantLimit>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3 dashboard">
        {/* <div className="col-md-6  stretch-card">
          <div className="card">
           <ParticipantGraph />
          </div>
        </div> */}
        <ParticipantGraph />
        {/* <RoomGraph /> */}
        <MinuteGraphCalc />
      </div>
    </>
  );
}

export default Dashboard;
