import React from 'react'
import ListFeatures from './ListFeatures'
// import OnlyMonthPickor from './onlyMonthPickor'

export default function Stepper({ handleStepperButton, updateSub, BackSubScreen, List, getMonth, amount }) {
  return (
    <>
      <div className="p-2">
        <button
          className="btn  d-flex"
          onClick={() => handleStepperButton(BackSubScreen)}
        >
          <i className="fa fa-arrow-left mr-2"></i>
        </button>
        <p className="p-2">Update Subscription</p>
        <hr />
        <ListFeatures List={List} price={amount} />
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div
              style={{ alignSelf: "center" }}
              className="mr-2"
            >
             {/* <OnlyMonthPickor getMonth={getMonth} /> */}
            </div>
            <div
              style={{ lineHeight: "1.5" }}
              className="d-flex flex-row "
            >

              <p className="font-weight-bolder">
                Expire Date
              </p>
            </div>
          </div>

          <div> </div>
          <div> </div>
        </div>
        <div style={{ width: "100%" }} className="d-flex ">

        </div>
      </div>
      <div
        style={{ width: "100%" }}
        className="d-flex flex-row ml-4 pl-4"
      >
        <div className="mr-4">
        </div>
        <button
          onClick={updateSub}
          className="btn btn-lg stripeButtonDesignCustom theme-btn d-flex  justify-content-center align-items-center"
        >
          Change subscription
        </button>
      </div>
    </>
  )
}
