import React, { Component } from "react";
import "./assets/Conversation.css";
// import MessageBubble from './MessageBubble'
import Dropzone from "react-dropzone";
import styles from "./assets/Conversation.module.css";
import ConversationsMessages from "./ConversationsMessages";
import PropTypes from "prop-types";
import axios from "axios";
import sendButton from "../../../../../Assets/images/sendButton.svg";
import { connect } from "react-redux";
import { SocketContext } from "../../../../../context/socket";
import { LoginObject } from "../../../../../Actions/Actions";
import { toast } from "react-toastify";
import Loader from "../../../../../Common/Loader/Loader";
import { makeShortString } from "../../../../../Utils/commonFunctions";
import EmojiPicker from "../../../../../Common/Emojispicker";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import { TimeScale } from "chart.js";
const PatientDataDetails = (state) => {
  return state.PatientDataDetails;
};
const ProviderData = (state) => {
  return state.AllPatientsAccepted;
};
const LoginObjects = (state) => {
  return state.LoginObject;
};
const AllPatientsAccepteds = (state) => {
  return state.AllPatientsAccepted;
};
const stripe = (state) => {
  return state.StripePackagePurchaseDetailObject;
};

class Conversation extends Component {
  static contextType = SocketContext;
  constructor(props) {
    super(props);

    this.state = {
      messageObjectEdit: null,
      isEditable: false,
      rplyMediaMsg: null,
      rplyMessageAttributes: {},
      uiEffect: null,
      rplyMessage: null,
      loaderForMediaSent: false,
      acceptedFileType: null,
      acceptedFileTarget: null,
      openEmoji: false,
      fileName: "",
      newMessage: "",
      conversationProxy: props.conversationProxy,
      messages: [],
      loadingState: "initializing",
      boundConversations: new Set(),
    };
    this.inputOpenFileRef = React.createRef(null);
    this.bottomRef = React.createRef(null);
  }
  static socket = SocketContext;

  loadMessagesFor = (Conversation) => {
    if (this.state.conversationProxy === Conversation) {
      Conversation.getMessages()
        .then((messagePaginator) => {
          if (this.state.conversationProxy === Conversation) {
            this.setState({
              messages: messagePaginator.items,
              loadingState: "ready",
            });
          }
        })
        .catch((err) => {
          this.setState({ loadingState: "failed" });
        });
    }
  };
  componentWillUnmount() {
    this.props.isProviderClose();
  }
  deleteMessage = async (m) => {
    // m.remove();
    let updateObjForDelete = {
      isDeleted: true,
      // isEditableMsg: false,
    };
    await m.updateAttributes(updateObjForDelete);

    if (m && m?.media && m?.media?.state) {
      this.props.setState((prev) => ({
        ...prev,
        delectionEffect: !this.props.delectionEffectState,
      }));
      m.remove();

      return;
    } else {
      m &&
        m.updateBody("Deleted message").then((res) => {
          //  this.loadMessagesFor(this.state.conversationProxy);
        });
    }
  };
  messageRemoved = (part) => {
    this.props.setState((prev) => ({
      ...prev,
      delectionEffect: !this.props.delectionEffectState,
    }));
    this.loadMessagesFor(this.state.conversationProxy);
  };
  messageEdit = async (message) => {
    this.setState({
      newMessage: message.body,
      isEditable: true,
      messageObjectEdit: message,
    });
    // await message.updateBody("bar");
  };

  componentDidMount = () => {
    this.props.isProviderSideOpen();

    if (this.state.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;

        newConversation.on("messageAdded", (m) =>
          this.messageAdded(m, newConversation)
        );
        newConversation.on("messageRemoved", (m) =>
          this.messageRemoved(m, newConversation)
        );

        newConversation.on("messageUpdated", ({ message, updateReasons }) => {
          // Fired when data of a message has been updated.

          // if (
          //   message &&
          //   message.attributes &&
          //   message.attributes.isEditableMsg
          // ) {
          //   this.props.setState((prev) => ({
          //     ...prev,
          //     delectionEffect: !this.props.delectionEffectState,
          //   }));
          //   this.loadMessagesFor(this.state.conversationProxy);
          // }
          if (message && message.attributes && message.attributes.isDeleted) {
            this.props.setState((prev) => ({
              ...prev,
              delectionEffect: !this.props.delectionEffectState,
            }));
            this.loadMessagesFor(this.state.conversationProxy);
          }
          this.loadMessagesFor(this.state.conversationProxy);

          this.updatedMessagesNonSender(message);
        });

        this.setState({
          boundConversations: new Set([
            ...this.state.boundConversations,
            newConversation,
          ]),
        });
      }
    }
  };

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.conversationProxy !== oldState.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on("messageAdded", (m) =>
          this.messageAdded(m, newConversation)
        );
        this.setState({
          boundConversations: new Set([
            ...this.state.boundConversations,
            newConversation,
          ]),
        });
      }
    }
  };

  static getDerivedStateFromProps(newProps, oldState) {
    let logic =
      oldState.loadingState === "initializing" ||
      oldState.conversationProxy !== newProps.conversationProxy;
    if (logic) {
      return {
        loadingState: "loading messages",
        conversationProxy: newProps.conversationProxy,
      };
    } else {
      return null;
    }
  }

  //----This function set non-sending person messages to the updated rply Messages
  updatedMessagesNonSender = (messagess) => {
    // this.setState((prevState, props) => ({
    //   messages: [...prevState.messages, messagess],
    // }));

    this.setState((prevState) => {
      // Check if messages array in prevState already contains an object with the same sid

      const isSidExists = prevState.messages.some(
        (message) => message.sid === messagess.sid
      );
      const checkAttributes = messagess.state.attributes;

      if (!isSidExists) {
        // If sid does not exist, add the new message to messages array
        return {
          messages: [...prevState.messages, messagess],
        };
      } else if (isSidExists && checkAttributes) {
        const newMessages = prevState.messages.map((message) => {
          if (message.sid === messagess.sid) {
            return messagess;
          } else {
            return message;
          }
        });

        // If sid exists, return the previous state without updating messages
        return {
          messages: [...prevState.messages, newMessages],
        };
      } else {
        return prevState;
      }
    });
  };
  onClickemoji = () => {
    this.setState({ openEmoji: !this.state.openEmoji });
  };
  updateMediaAttributes = (m, url, rplyMessage) => {
    let author = rplyMessage?.author;
    let size = rplyMessage?.media?.state?.size;
    let rplyMediaUrl = url;

    m.updateAttributes({
      replied_to: author,
      mediaSize: size,
      mediaUrl: rplyMediaUrl,
      fileType: rplyMessage.state.media.contentType,
      // isEditableMsg: false,
    }).then((res) => {
      this.setState((prevState) => ({
        rplyMessage: null,
        uiEffect: false,
      }));
    });
  };
  //--- This Function set new Message attribute with rply message body------
  handleAttribute = async (m, proxy) => {
    /// This code change attributes of messages
    if (proxy === this.state.conversationProxy) {
      if (this.state.rplyMessage != null) {
        if (this.state.rplyMessage?.state?.type == "media") {
          let z = this.state.rplyMessage;
          let url = await this.state.rplyMessage.media.getContentTemporaryUrl();

          this.updateMediaAttributes(m, url, z);
          return;
        }

        let author = this.state.rplyMessage.state.author;
        let sid = this.state.rplyMessage.state.sid;
        let body = this.state.rplyMessage.state.body;
        await m
          .updateAttributes({
            replied_to: author,
            messageSid: sid,
            body: body,
            // isEditableMsg: false,
          })
          .then((res) => {
            this.setState((prevState) => ({
              rplyMessageAttributes: res.state.updateAttributes,
            }));
            // this.setState((prevState, props) => ({
            //   messages: [...prevState.messages, res],
            // }));
            //-----No rply state left after setting it to new message-----

            this.setState((prevState) => ({
              rplyMessage: null,
              uiEffect: false,
            }));
          });
      } else {
        this.setState((prevState) => ({
          uiEffect: !this.state.uiEffect,
        }));
      }
    }
  };
  updatedMessage = async () => {
    await (this.state.messageObjectEdit &&
      this.state.messageObjectEdit.updateBody(this.state.newMessage));
    // (await this.state.messageObjectEdit) &&
    //   this.state.messageObjectEdit.updateAttributes({ isEditableMsg: true });
    this.props.setState((prev) => ({
      ...prev,
      delectionEffect: !this.props.delectionEffectState,
    }));
    this.loadMessagesFor(this.state.conversationProxy);

    this.setState((prevState) => ({
      isEditable: false,
      newMessage: "",
      messageObjectEdit: null,
    }));
  };

  handleKeyPress = (e) => {
    if (e.keyCode === 16 && e.shiftKey) {
      if (e.type == "keypress") {
        this.setState({ newMessage: this.state.newMessage + "\n" });
      }
    } else if (!e.shiftKey && e.keyCode === 13) {
      if (this.state.fileName != "") {
        this.onUploadSend();
        this.setState({ fileName: "" });
        return;
      }
      {
        this.state.isEditable ? this.updatedMessage() : this.sendMessage(e);
      }
    }
  };

  messageAdded = async (message, targetConversation) => {
    // This will trigger when msg being send without reply

    if (
      this.state.rplyMessage == null &&
      Object.keys(message.state.attributes).length === 0
    ) {
      if (targetConversation === this.state.conversationProxy)
        this.setState((prevState, props) => ({
          messages: [...prevState.messages, message],
        }));
    } else {
      //This will trigger when msg being send with rply
      await this.handleAttribute(message, targetConversation);
    }
  };

  onMessageChanged = (event) => {
    // if (this.props.LoginObject.username) {
    //   this.state.conversationProxy.updateAttributes({
    //     typingPerson: this.props.LoginObject.username,
    //   });
    // }

    this.state.conversationProxy.typing();

    //this.props.conversationsClientSdk.typing();
    this.setState({ newMessage: event.target.value });
  };
  sendMessage = (event) => {
    event.preventDefault();
    const message = this.state.newMessage;
    if (message === "") {
      return;
    } else if (message.trim() === "") {
      return;
    } else {
      if (this.props.LoginObject.username) {
        this.props.ProData.map((el) => {
          let obj1 = {};
          obj1.id = el.id;
          obj1.PatName = el.fname + el.lname;
          obj1.providerId = this.props.LoginObject.id;
          this.context.emit("messageReceiveNotification", obj1);
        });
      } else {
        let obj = {};

        obj.id = this.props.Patdata.patId;
        obj.PatName = this.props.Patdata.joiningUser;
        obj.providerId = this.props.Patdata.providerId;
        this.context.emit("messageReceiveNotificationPro", obj);
      }

      this.setState({ newMessage: "" });

      this.state.conversationProxy.sendMessage(message).then((res) => {
        //response only Count 0-1-2

        this.setState((prevState) => ({
          rplyMessage: null,
          uiEffect: true,
        }));
      });

      this.saveMessageInDatabase();
    }
  };

  saveMessageInDatabase = async () => {
    let provider = this.props.Provider?.user?.fullname;
    let patient = this.props.PatientData?.patientData?.joiningUser;
    let payload = {
      messageId: 0,
      message: this.state.newMessage,
      fromId: provider || patient,
      fromName: provider || patient,
      toId:
        this.props &&
        this.props.conversationName &&
        this.props.conversationName,
      toName:
        this.props &&
        this.props.conversationName &&
        this.props.conversationName,
      createdOn: new Date(),
      isRead: false,
      isDeleted: false,
    };

    let response = await axios.post(
      process.env.REACT_APP_APIS + `savemsg=1`,
      payload
    );
    return response.data;
  };
  onUploadSend = () => {
    this.setState({ loaderForMediaSent: true });

    this.state.conversationProxy
      .sendMessage({
        contentType: this.state.acceptedFileType,
        media: this.state.acceptedFileTarget,
      })
      .then((res) => {
        this.setState({
          acceptedFileType: null,
          acceptedFileTarget: null,
          fileName: "",
        });
        this.clearRef();
      })
      .catch((e) => {
        if (e.code === 413) {
          toast.info("File size is too large. Maximum file size is 150MB.");
          //  setFileSendError('File size is too large. Maximum file size is 150MB.');
        } else {
          toast.info(
            "There was a problem uploading the file. Please try again."
          );
          // setFileSendError('There was a problem uploading the file. Please try again.');
        }
      })
      .finally(() => {
        this.setState({ loaderForMediaSent: false });
        this.setState({ fileName: "" });
      });
  };
  onDragDrop = (acceptedFiles) => {
    // this.state.conversationProxy.sendMessage({
    //     contentType: acceptedFiles[0].type,
    //     media: acceptedFiles[0],
    //   });
    this.setState({
      acceptedFileType: acceptedFiles[0].type,
      acceptedFileTarget: acceptedFiles[0],
      fileName: acceptedFiles[0].name,
    });
  };
  clearRef = () => {
    this.inputOpenFileRef.current.value = null;
  };
  onClickEmoji = (emojiData, event) => {
    let sym = emojiData.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    this.setState((prevState) => ({
      newMessage: prevState.newMessage + emoji,
    }));
  };

  onDrop = (acceptedFiles, uploadBtn) => {
    if (acceptedFiles.target.files) {
      const fileName = acceptedFiles.target.files[0].name;
      this.setState({ fileName });
    }
    this.setState({
      acceptedFileType: acceptedFiles.target.files[0].type,
      acceptedFileTarget: acceptedFiles.target.files[0],
    });
    if (uploadBtn === true) {
      this.setState({ loaderForMediaSent: true });

      this.state.conversationProxy
        .sendMessage({
          contentType: acceptedFiles.target.files[0].type,
          media: acceptedFiles.target.files[0],
        })
        .then((res) => {
          this.setState({
            acceptedFileType: null,
            acceptedFileTarget: null,
            fileName: "",
          });
          this.clearRef();
        })
        .catch((e) => {
          if (e.code === 413) {
            toast.info("File size is too large. Maximum file size is 150MB.");
            //  setFileSendError('File size is too large. Maximum file size is 150MB.');
          } else {
            toast.info(
              "There was a problem uploading the file. Please try again."
            );
            // setFileSendError('There was a problem uploading the file. Please try again.');
          }
        })
        .finally(() => {
          this.setState({ loaderForMediaSent: false });
          this.setState({ fileName: "" });
        });
    } else {
    }
  };
  onCrossButton = () => {
    this.setState({ fileName: "" });
    this.clearRef();
  };
  //---- This set Rply Message entire body--
  onReplyMessage = async (messageObject) => {
    this.setState({ rplyMessage: messageObject });
  };
  clearReplyMsg = () => {
    this.setState({ rplyMessage: null });
  };

  render = () => {
    console.log("this.props.isTypingPart", this.props.isTypingPart);
    return (
      <>
        <Dropzone onDrop={this.onDragDrop} accept="*">
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps()} onClick={() => {}}>
              {isDragActive && (
                <div className={styles.drop}>
                  <span style={{ fontSize: "5em", color: "#fefefe" }}>
                    <li className="fa fa-upload" />
                  </span>
                  <h3 style={{ color: "#fefefe" }}>Release to Upload</h3>
                </div>
              )}
              <div
                style={{
                  filter: `blur(${isDragActive ? 4 : 0}px)`,
                }}
              >
                <input id="files" {...getInputProps()} />
                <ConversationsMessages
                  messageEdit={this.messageEdit}
                  deleteMessage={this.deleteMessage}
                  loaderForMediaSent={this.state.loaderForMediaSent}
                  rplyMessageAttributes={this.state.rplyMessageAttributes}
                  onReplyMessage={this.onReplyMessage}
                  toggleImage={this.props?.toggleImage}
                  identity={this.props.myIdentity}
                  messages={this.state.messages}
                  rplyMsguiUpdate={this.state.uiEffect}
                />
              </div>
            </div>
          )}
        </Dropzone>
        {this.state.rplyMessage !== null ? (
          <div
            className=" align-items-center w-73Per "
            style={
              this.props.LoginObject.username
                ? { zIndex: "99999", marginTop: "-7%" }
                : { zIndex: "99999", marginTop: "-2%" }
            }
          >
            <div
              class=" reply-message  "
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                marginBottom: "5px",
                backgroundColor: "#e9e9e9",
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              <span
                class="reply-sender"
                // style={{ fontWeight: "bold", marginright: "5px" }}
              ></span>
              <span
                class="reply-text"
                // style={{ flexGrow: "1" }}
              >
                {this.state.rplyMessage.state.body != null
                  ? makeShortString(this.state.rplyMessage.state.body, 20)
                  : this.state.rplyMessage.state.type == "media" &&
                    this.state.rplyMessage?.state?.media?.state?.contentType}
              </span>
              <span style={{ marginLeft: "auto" }}>
                <i
                  className="fas fa-times"
                  style={{ fontSize: "17px" }}
                  onClick={this.clearReplyMsg}
                ></i>
              </span>
            </div>
          </div>
        ) : null}
        {this.props.isTypingPart ? (
          <>
            {this.props.joinedParticipants.length > 1 ? (
              <span>Someone is Typing...</span>
            ) : (
              <span>Typing...</span>
            )}
          </>
        ) : null}
        {this.state.openEmoji ? (
          <div
            style={{
              paddingTop: "15px",

              display: "flex",
            }}
          >
            <EmojiPicker onClickEmoji={this.onClickEmoji} />{" "}
          </div>
        ) : (
          <></>
        )}

        <div className="d-flex align-items-center w-90Per">
          {/* <div className="smile-col p-2">
            <a   htmlFor="file-input">
              <i className="far fa-smile"></i>
            </a>
          </div> */}

          <textarea
            type="text"
            className="form-control chat_form"
            placeholder="Enter Message....."
            style={{ overflow: "hidden" }}
            id="messageInput"
            value={
              this.state.fileName != ""
                ? this.state.fileName
                : this.state.newMessage
            }
            autoComplete="off"
            onKeyDown={(e) => {
              this.handleKeyPress(e);
            }}
            onChange={this.onMessageChanged}
            disabled={this.state.loaderForMediaSent}
          />
          {this.state.fileName != "" && (
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.onCrossButton}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "37px",
                  width: "37px",
                }}
              >
                &times;
              </button>
            </div>
          )}
          {/* {this.state.openEmoji ? (
            <div
              style={{
                paddingTop: "15px",
                marginTop: "25px",
                marginRight: "31px",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                height: "434px",
                width: "319px",
                display: "flex",
              }}
            >
              <EmojiPicker />{" "}
            </div>
          ) : (
            <></>
          )} */}
          {this.state.fileName != "" ? null : (
            <i
              onClick={this.onClickemoji}
              class="fas fa-smile text-primary ml-2"
            ></i>
          )}

          {/* <div className="specker-col p-2">
            <a  >
              <span className="material-icons">
                <i className="fas fa-microphone"></i>
              </span>
            </a>
          </div> */}

          <div className="attach-col rounded  ml-2">
            <label className="mb-0">
              {this.props &&
              this.props?.StripePackagePurchaseDetailObject &&
              this.props?.StripePackagePurchaseDetailObject?.extensions
                ?.fileTransferAndAttachments ? (
                <>
                  <i className="fas fa-paperclip  text-primary "></i>
                  <input
                    id="file-input"
                    type={"file"}
                    style={{ display: "none" }}
                    ref={this.inputOpenFileRef}
                    onChange={(e) => this.onDrop(e, false)}
                  />
                </>
              ) : (
                <></>
              )}
            </label>
          </div>
          {this.state.fileName != "" ? (
            <div className="send-col p-2  rounded" onClick={this.onUploadSend}>
              <a>
                <span className="material-icons ">
                  {this.state.loaderForMediaSent ? (
                    <div
                      style={{
                        width: "31px",
                        height: "31px",
                        background: "#4b49ac",
                        borderRadius: "50%",
                        paddingRight: "4px",
                      }}
                    >
                      <Loader small={true} large={false} />
                    </div>
                  ) : (
                    <img
                      className="sendButtonMessege"
                      src={sendButton}
                      width="31px"
                      alt="send"
                    />
                  )}
                </span>
              </a>
            </div>
          ) : (
            <div
              className="send-col p-2  rounded"
              onClick={
                this.state.isEditable
                  ? () => this.updatedMessage()
                  : (e) => this.sendMessage(e)
              }
            >
              <a>
                <span className="material-icons ">
                  <div style={{ width: "31px" }}></div>

                  <img
                    className="sendButtonMessege"
                    src={sendButton}
                    width="31px"
                    alt="send"
                  />
                </span>
              </a>
            </div>
          )}
        </div>
      </>
    );
  };
}

Conversation.propTypes = {
  myIdentity: PropTypes.string.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return {
    isProviderSideOpen: () =>
      dispatch({ type: "isProviderMessagesOpen", payload: true }),
    isProviderClose: () =>
      dispatch({ type: "isProviderMessagesOpen", payload: false }),
  };
};

const mapStateToProps = (state) => ({
  PatientData: state.PatientData,
  Provider: state.UserInfo,
  Patdata: PatientDataDetails(state),
  ProData: ProviderData(state),
  LoginObject: LoginObjects(state),
  AllPatientsAccepted: AllPatientsAccepteds(state),
  StripePackagePurchaseDetailObject: stripe(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
