import React from 'react'
import './AudiCircle.css'

export default function AudioCircle({AudioName}) {
  return (
    <div className="circle"><div className='textCircle'>{AudioName}</div></div>
   
   

  )
}
