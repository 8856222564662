import React, { useState, useEffect } from "react";
import { NavigateDasboardIssue } from "../../Actions/Actions";
import SettingNav from "../Settings/SetingLayout/SettingNav";
import { Meeting } from "./Meeting/index";
import { Transactions } from "./Transactions/index";
import { useDispatch, useSelector } from "react-redux";
import { roomDestroy } from "../../Utils/ApiManager";

function History() {
  let dispatch = useDispatch();
  const { RoomSid } = useSelector((state) => state);
  const [tab, setTabs] = useState("Meeting History");
  const handleSetTabs = (val) => {
    setTabs(val);
  };
  const tabs = [
    { id: "1", name: "Meeting History" },
    { id: "2", name: "Transactions History" },
  ];
  useEffect(() => {
    dispatch(NavigateDasboardIssue(false));
  }, []);
  const handleReloadwithRoom = async () => {
    if (RoomSid) {
      let obj = { roomSid: RoomSid };
      await roomDestroy(obj);
    }
  };
  useEffect(() => {
    if (true) {
      window.onbeforeunload = function () {
        handleReloadwithRoom();
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <div className="settings-tab my-4">
        <SettingNav handleSetTabs={handleSetTabs} tab={tab} tabs={tabs} />
        {tab === "Meeting History" && <Meeting />}
        {tab === "Transactions History" && <Transactions />}
      </div>
    </>
  );
}

export default History;
