import React from "react";
import { useSelector } from "react-redux";
import StripeButton from "./Stripemethod/Stripe";
import "./index.css";

export default function Button({
  onupgradeAndDowngrade,
  buttonLabelDownUp,
  SubscriptionList,
  paymentMode,
  handleStepperButton,
  disableBtn,
  payFirst,
  paySecond,
  payThird,
  subscription,
  callSaveTrans,
  lastStripeTrasactionObject,
  stripeLatestObject,
  buttonLabel,
  price,
  userEmail,
  setIsLoading,
  packageName,
  //  disableBtn
}) {
  const { LoginObject } = useSelector((state) => state);


  return (
    <>
      {SubscriptionList.length > 0 && paymentMode === payFirst ? (
        <>
          {/* <button
            //  disabled={disableBtn}
            onClick={() => handleStepperButton(payFirst)}
            className={"btn btn-primary buttonFixx ml-4"}
            style={{
              fontSize: "11px",
            }}
          >
            Activated
          </button> */}
        </>
      ) : SubscriptionList.length > 0 && paymentMode === paySecond ? (
        <>
          <button
            disabled={disableBtn}
            onClick={() => handleStepperButton(paySecond)}
            className="btn btn-lg stripeButtonDesignCustom theme-btn d-flex  justify-content-center align-items-center "
          >
            Update Subscription
          </button>
        </>
      ) : (
        <>
          {LoginObject && LoginObject?.jwt ? (
            // <button
            //   disabled={disableBtn}
            //   onClick={() => handleStepperButton(payThird)}
            //   className="btn btn-lg stripeButtonDesignCustom theme-btn d-flex  justify-content-center align-items-center "
            // >
            //   Subscription
            // </button>
            <StripeButton
              onupgradeAndDowngrade={onupgradeAndDowngrade}
              buttonLabelDownUp={buttonLabelDownUp}
              subscription={subscription}
              callSaveTrans={callSaveTrans}
              lastStripeTrasactionObject={lastStripeTrasactionObject}
              stripeLatestObject={stripeLatestObject}
              buttonLabel={"subscribe"}
              price={50}
              packageName={packageName}
              userEmail={LoginObject && LoginObject?.email}
              setIsLoading={setIsLoading}
              disableBtn={disableBtn}
            />
          ) : null}
        </>
      )}
    </>
  );
}
