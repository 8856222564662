import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import userAvatar from "../../../../src/Assets/images/userAvatar.svg";
import { EmptyCheck, ValidateEmail } from "../../../Utils/ValidationsFunctions";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader/Loader";
import { updateProfileImage } from "../../../Utils/ApiManager";
import { profilePicUpdate } from "../../../Actions/Actions";
export default function PersonalInfo() {
  const [profileImage, setProfileImage] = useState("");
  const { LoginObject, updateProfileImagee } = useSelector((state) => state);

  const [profileUploadButtonEnable, setProfileUploadButtonEnable] =
    useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [reduxProfileImagee, setReduxProfileImagee] =
    useState(updateProfileImagee);

  console.log(state.updateProfileImagee, "llll");
  console.log(updateProfileImagee, "updateProfileImagee");
  useEffect(() => {
    if (profileImage) setProfileUploadButtonEnable(true);
  }, [profileImage]);
  // useEffect(() => {
  //   if (updateProfileImagee) {
  //     setReduxProfileImagee(updateProfileImagee);
  //   }
  // }, [updateProfileImagee]);

  useEffect(() => {
    setName(LoginObject && LoginObject?.username);
    setEmail(LoginObject && LoginObject?.email);
    if (updateProfileImagee) {
      setReduxProfileImagee(updateProfileImagee);
    }
  }, [LoginObject, updateProfileImagee]);

  const imagehandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImage([reader.result]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    setImageFile(e.target.files[0]);
  };

  const displayimage = () => {
    if (profileImage !== []) {
      return (
        <div className="imageholder">
          <img
            style={{
              width: "7em",
              height: "7em",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={profileImage}
            alt=""
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const uploadProfileImage = async () => {
    let formData = new FormData();
    formData.append("image", imageFile);
    formData.append("userId", LoginObject.id);
    try {
      setLoading(true);
      updateProfileImage(formData).then((res) => {
        console.log(res, "iamge_responce");
        if (res) {
          setLoading(false);
          toast.success("Profile Image updated successfully");
          setProfileUploadButtonEnable(false);
          dispatch(profilePicUpdate(res.data.imageURL));
        }
      });
    } catch (error) { }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name == "name") {
      setName(value);
      setNameError("");

      return;
    }
    if (name == "password") {
      setPassword(value);
      setPasswordError("");

      return;
    }
    if (name == "email") {
      setEmailError("");
      setEmail(value);

      return;
    }
  };
  const callSaveApi = async () => {
    let isEmailValid;
    let isNameError = await EmptyCheck(name);
    let isEmailError = await EmptyCheck(email);
    let isPassError = await EmptyCheck(password);

    if (isNameError) {
      setNameError("Enter Name");
    }
    if (isEmailError) {
      setEmailError("Enter Email");
    } else {
      isEmailValid = await ValidateEmail(email);
    }
    if (isPassError) {
      setPasswordError("Enter Password");
    }
    if (isEmailValid) {
      setEmailError("Email not Valid");
    }
  };

  let isImageSelected = displayimage().props.children.props.src;
  return (
    <>
      <div className="form-col form-body">
        <div
          className="slimscroll"
          style={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <div className="settings-option">
            <a href="#" className="user-list-item">
              Edit Settings
            </a>
          </div>
          <div className="">
            <div className="profile-cover text-center mb-3">
              <label className="profile-cover-avatar" htmlFor="avatar_upload">
                {reduxProfileImagee ? (
                  <>
                    {isImageSelected && displayimage()}
                    {!isImageSelected && (
                      <img
                        className="avatar-img"
                        src={
                          "https://dashboard.telxy.com/" + reduxProfileImagee
                        }
                        alt="Profile Image"
                      />
                    )}
                  </>
                ) : (
                  <>
                    {isImageSelected && displayimage()}
                    {!isImageSelected && (
                      <img
                        className="avatar-img"
                        src={userAvatar}
                        alt="Profile Image"
                      />
                    )}
                  </>
                )}
                <input type="file" id="avatar_upload" onChange={imagehandler} />
                <span className="avatar-edit">
                  <i className="fas fa-pen"></i>
                </span>
              </label>
              <div className="d-flex justify-content-center">
                {profileUploadButtonEnable && (
                  <button
                    style={{
                      marginTop: "5px",
                      display: 'flex'
                    }}
                    type="button"
                    className="btn btn-primary btn-primary"
                    onClick={uploadProfileImage}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Loader forButton={true} small={true} large={false} />{" "}
                      </>
                    ) : (
                      <>
                        <li className="fa fa-upload"></li>
                      </>
                    )}
                  </button>
                )}
              </div>
              <h5 className="mt-3 profile-name mb-1">
                {LoginObject?.fullname}
              </h5>
              <p className="profile-email mb-1">{LoginObject?.email}</p>
            </div>
          </div>
        </div>

        <div
          className=""
          style={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <form action="/">
            <div className="row">
              <div className="col-md-6 col-xl-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    value={name}
                    name="name"
                    onChange={handleOnChange}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby={nameError ? "invalid" : "valid"}
                    placeholder="Enter Name"
                  />{" "}
                </div>
              </div>
              <div className="col-md-6 col-xl-6">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    name="email"
                    value={email}
                    className="form-control  group_formcontrol"
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby={emailError ? "invalid" : "valid"}
                    placeholder="Enter Email"
                    onChange={handleOnChange}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    {/* We'll never share your email with anyone else. */}
                  </small>
                </div>
              </div>
              <div className="col-md-6 col-xl-6">
                <div className="form-group">
                  <label>Password</label>
                  <input
                    className="form-control group_formcontrol"
                    name="password"
                    type="text"
                    onChange={handleOnChange}
                    value={password}
                    aria-describedby={passwordError ? "invalid" : "valid"}
                    placeholder="*****"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="form-group form-check ">
                  <button
                    type="button"
                    className="btn btn-delete btn-primary"
                    onClick={callSaveApi}
                  >
                    Save
                  </button>
                </div>
                <div></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
