import moment from "moment";
import React, { useState, useEffect, useMemo, useContext } from "react";
import Loader from "../../../Common/Spinner";
import Modal from "../../../Common/Modal";
import { useSelector } from "react-redux";
import {
  getRoomHistory,
  getRoomParticipents,
  RetreiveRecordingRules,
  RetreiveRecordings,
} from "../../../Utils/ApiManager";
import isNull from "../../../Common/Datagrid/Null-checking";
import Pagination from "../Pagination";
import ReactPaginate from "react-paginate";
import { SocketContext } from "../../../context/socket";

export const Meeting = () => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [roomParticipantHistory, setRoomParticipantsHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roomHistory, setRoomHistory] = useState([]);
  const [roomHistory2, setRoomHistory2] = useState([]);

  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const state = useSelector((state) => state);
  const userName = state.LoginObject.username;
  const fullName = state.LoginObject.fullname;
  const today = new Date().toISOString().slice(0, 10);

  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  let itemsPerPage = 7;
  const socket = useContext(SocketContext);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % roomHistory.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(roomHistory.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(roomHistory.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, roomHistory]);

  useEffect(() => {
    setIsLoading(true);
    try {
      getRoomHistory(userName).then((res) => {
        let filteredArr = res.data.rooms.filter((item) => {
          return item.room_name === userName;
        });
        setRoomHistory(filteredArr);
        setRoomHistory2(filteredArr);
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      console.log(startDate, "startDate");
      console.log(endDate, "endDate");
      console.log(roomHistory, "room");
      setRoomHistory(
        roomHistory2.filter((obj) => {
          return (
            new Intl.DateTimeFormat("en-US").format(
              new Date(obj.create_time)
            ) >= new Intl.DateTimeFormat("en-US").format(new Date(startDate)) &&
            new Intl.DateTimeFormat("en-US").format(
              new Date(obj.create_time)
            ) <= new Intl.DateTimeFormat("en-US").format(new Date(endDate))
          );
        })
      );
    }
  }, [endDate]);

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
    return hDisplay + mDisplay + sDisplay;
  };

  const toggleModel = (roomId) => {
    setModal(true);
    gridForParticipants(roomId);
  };

  const gridForParticipants = (roomId) => {
    setIsLoadingModal(true);
    getRoomParticipents(roomId)
      .then((res) => {
        let filteredArr = res.data.participants.filter((item) => {
          return item.participant_identity != fullName;
        });
        setRoomParticipantsHistory(filteredArr);
        setIsLoadingModal(false);
      })
      .catch((err) => {
        setIsLoadingModal(false);
      });
  };

  const clostModal = () => {
    setModal(false);
  };
  const setDate = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (name == "startDate") {
      setStartDate(value);
      return
    }
    if (name == "endDate") {
      setEndDate(value);
      return

    }
  };

  const clearFilters = () => {
    setStartDate('')
    setEndDate('')
    setRoomHistory(roomHistory2);
  };
  const handleRecording = (el) => {
    let obj = { roomSid: el.room_sid };
    RetreiveRecordingRules(obj);
  };
  
 


  return (
    <div className="row">
      {modal ? (
        <Modal title={"Patients"} close={clostModal}>
          {isLoadingModal ? (
            <Loader small={false} large={true} />
          ) : (
            <div className="table-responsive">
              <div
                id="example_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6"></div>
                  <div className="col-sm-12 col-md-6"></div>
                </div>
                <div
                  className="row"
                  style={{
                    minHeight: "200px",
                  }}
                >
                  <div className="col-sm-12">
                    <table
                      id="example"
                      className="display expandable-table dataTable no-footer"
                      style={{ width: "100%" }}
                      role="grid"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="select-checkbox sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Quote#"
                            style={{ width: "136px" }}
                          >
                            Name
                          </th>
                          <th
                            className=""
                            tabIndex="0"
                            aria-controls="example"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Quote#"
                            aria-sort="ascending"
                            style={{ width: "186px" }}
                          >
                            Joining Date & Time
                          </th>
                          <th
                            className=""
                            tabIndex="0"
                            aria-controls="example"
                            rowSpan="1"
                            colSpan="1"
                            style={{ width: "186px" }}
                          >
                            Leave Date & Time
                          </th>
                          <th
                            className=""
                            tabIndex="0"
                            aria-controls="example"
                            rowSpan="1"
                            colSpan="1"
                            style={{ width: "176px" }}
                          >
                            Call Duration
                          </th>

                          <th
                            className="details-control sorting_disabled"
                            rowSpan="1"
                            aria-label=""
                            style={{ width: "53" }}
                          ></th>
                        </tr>
                      </thead>

                      <tbody>
                        {roomParticipantHistory ? (
                          <>
                            {roomParticipantHistory.map((el, index) => {
                              return (
                                <tr className="odd" key={index}>
                                  <td className=" select-checkbox">
                                    {capitalizeFirst(el.participant_identity)}
                                  </td>
                                  <td className="sorting_1">
                                    {moment(el.join_time).format(
                                      "MM-DD-YYYY, h:mm a"
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {moment(el.leave_time).format(
                                      "MM-DD-YYYY, h:mm a"
                                    )}
                                  </td>
                                  <td>
                                    {el.duration_sec &&
                                      secondsToHms(el.duration_sec)}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5"></div>
                  {roomParticipantHistory == "" && (
                    <div
                      className=" d-flex"
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <tr>
                        <th>No patients record to show</th>
                      </tr>
                    </div>
                  )}
                  <div className="col-sm-12 col-md-7"></div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      ) : undefined}
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <p className="card-title">Meeting History</p>
            {isLoading ? (
              <Loader small={false} large={true} />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                    justifyContent: "flex-end",
                  }}
                  className="meetinghistory"
                >
                  <div>
                    <div className="" style={{ marginRight: "10px" }}>
                      <label htmlFor="start Date" className=" ">
                        Start Date
                      </label>
                      <input
                        style={{ height: "35px", width: "240px" }}
                        type="date"
                        className="form-control "
                        name="startDate"
                        onChange={setDate}
                        value={startDate}
                        max={today}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <label className="EndDatespace" htmlFor="start Date">End Date</label>
                      <input
                        style={{ height: "35px", width: "240px" }}
                        value={endDate}
                        type="date"
                        className="form-control"
                        name="endDate"
                        onChange={setDate}
                        defaultValue={endDate}
                        max={today}
                      />
                    </div>
                  </div>
                  <div
                    className=" align-self-center"
                    style={{ textAlign: "end" }}
                  >
                    <button
                      style={{
                        marginRight: "20px",
                        marginLeft: "10px",
                        marginTop: "25px",
                      }}
                      className="btn  btn-primary "
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive container-fluid">
                      <div
                        id="example_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6"></div>
                          <div className="col-sm-12 col-md-6"></div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="example"
                              className="display expandable-table dataTable no-footer"
                              style={{ width: "100%" }}
                              role="grid"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className="select-checkbox "
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Quote#"
                                    style={{ width: "176px" }}
                                  >
                                    Room Name
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    style={{ width: "210px" }}
                                  >
                                    Start Time
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    style={{ width: "210px" }}
                                  >
                                    End Time
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    style={{ width: "210px" }}
                                  >
                                    Call Duration
                                  </th>
                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Premium: activate to sort column ascending"
                                    style={{ width: "190px" }}
                                  >
                                    Joined Patients
                                  </th>

                                  <th
                                    className=""
                                    tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Status: activate to sort column ascending"
                                    style={{ width: "196px" }}
                                  >
                                    Action{" "}
                                  </th>
                                  {/* <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Updated at: activate to sort column ascending"
                          style={{ width: "155px" }}
                        >
                          Updated at
                        </th> */}
                                  <th
                                    className="details-control sorting_disabled"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label=""
                                    style={{ width: "53" }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems &&
                                  currentItems.map((el, key) => {
                                    return (
                                      <tr className="odd" key={key}>
                                        <td className=" select-checkbox">
                                          {capitalizeFirst(el.room_name)}
                                        </td>
                                        <td className="sorting_1">
                                          {moment(el.create_time).format(
                                            "MM-DD-YYYY, h:mm a"
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          {moment(el.end_time).format(
                                            "MM-DD-YYYY, h:mm a"
                                          )}
                                        </td>
                                        <td>
                                          {el.duration_sec &&
                                            secondsToHms(el.duration_sec)}
                                        </td>
                                        <td>
                                          {el.unique_participant_identities ===
                                            0 ? (
                                            <>0</>
                                          ) : (
                                            <>
                                              {el.unique_participant_identities ===
                                                null
                                                ? el.concurrent_participants - 1
                                                : el.unique_participant_identities -
                                                1}{" "}
                                            </>
                                          )}
                                        </td>

                                        <td>
                                          {el.concurrent_participants > 1 &&
                                            el.concurrent_participants != null ? (
                                            <>
                                              {" "}
                                              <a
                                                className=""
                                                style={{ cursor: "default" }}
                                                onClick={() =>
                                                  toggleModel(el.room_sid)
                                                }
                                              >
                                                View Patients
                                              </a>{" "}
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <a
                                                className=""
                                                style={{
                                                  color: "grey",
                                                  cursor: "not-allowed",
                                                }}
                                              >
                                                View Patients
                                              </a>{" "}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5"></div>
                          <div className="col-sm-12 col-md-7"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                      paddingRight: "25px",
                      paddingTop: "10px",
                    }}
                  >
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                    />
                  </div>
                </div>
              </>
            )}

            {/* //old Table Design */}
          </div>
        </div>
      </div>
    </div>
  );
};
