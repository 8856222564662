import React, { useState, useEffect, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { roomDestroy, updateProviderStatus } from "../../../Utils/ApiManager";
import { SocketContext } from "../../../context/socket";
import { useContext } from "react";

export const Sidenav = ({
  instantEffectOnRoom,
  providerInCallSettings,
  toggleSidenavBar,
  toogleSideNav,
  callEndWhenProviderSwitchesTab,
  detectSideNavChange,
  setProviderInCallSettings,
  getClickOnTab,
}) => {
  const {
    NavigateDasboardsIssue,
    AllPatientsAccepted,
    LoginObject,
    RoomSid,
    StripePackagePurchaseDetailObject,
  } = useSelector((state) => state);
  const [Active, setActive] = useState("Dashboard");
  const [ToogleOnMobile, setToogleOnMobile] = useState(false);
  const [detectCall, setDetectCall] = useState();
  const socket = useContext(SocketContext);
  useLayoutEffect(() => {
    const width = window.innerWidth;
    if (width < 992) {
      setToogleOnMobile(true);
    }
  }, []);
  useEffect(() => {
    if (NavigateDasboardsIssue) {
      setActive("Dashboard");
    }
  }, [NavigateDasboardsIssue]);
  useEffect(() => {
    if (instantEffectOnRoom === "Room") {
      setActive("Room");
    }
  }, [instantEffectOnRoom]);

  const handleClick = (value) => {
    getClickOnTab(value);
    if (ToogleOnMobile === true && toogleSideNav == true) {
      toggleSidenavBar();
    }
    setActive(value);
  };
  const handleDetectClickWhenInCall = (val) => {
    detectSideNavChange();
  };
  useEffect(() => {
    if (providerInCallSettings) {
      detectSideNavChange();
      setProviderInCallSettings(false);
    }
  }, [providerInCallSettings]);

  // useEffect(() => {
  //   if (AllPatientsAccepted.length === 0) {
  //     setDetectCall(AllPatientsAccepted.length > 0);
  //     return;
  //   }

  //   if (AllPatientsAccepted.length > 0) {
  //     setDetectCall(AllPatientsAccepted.length > 0);
  //     return;
  //   }
  // }, [AllPatientsAccepted]);

  console.log(
    "AllPatientsAccepted",
    AllPatientsAccepted,
    Active,
    callEndWhenProviderSwitchesTab
  );
  const updateProviderStatuss = () => {
    socket.emit("show_provider_online", "offline");

    try {
      updateProviderStatus(LoginObject.username, "offline").then(
        async (res) => {}
      );
    } catch (err) {}
  };

  const handleReloadwithRoom = async () => {
    if (RoomSid) {
      let obj = { roomSid: RoomSid };
      await roomDestroy(obj);
    }
  };

  const handleLogout = () => {
    handleReloadwithRoom();

    updateProviderStatuss();

    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };
  let recordingCheck =
    StripePackagePurchaseDetailObject &&
    StripePackagePurchaseDetailObject?.recordings &&
    StripePackagePurchaseDetailObject?.recordings?.recordingEnbled;

  return (
    <>
      <nav
        className={
          toogleSideNav
            ? "sidebar sidebar-offcanvas active"
            : "sidebar sidebar-offcanvas"
        }
        id="sidebar"
      >
        <ul className="nav" style={{ position: "fixed" }}>
          <li
            className={
              Active === "Dashboard" ? " nav-item  active" : " nav-item  "
            }
          >
            <NavLink
              onClick={
                // detectCall
                //   ? () => handleDetectClickWhenInCall()
                // :
                () => handleClick("Dashboard")
              }
              className={({ isActive }) =>
                isActive ? "nav-link nav-item active" : "nav-link nav-item"
              }
              to={"/dashboard"}
            >
              <i className="fas fa-th menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </NavLink>
          </li>
          <li
            className={Active === "Room" ? " nav-item  active" : " nav-item  "}
          >
            <NavLink
              onClick={
                detectCall
                  ? () => handleDetectClickWhenInCall()
                  : () => handleClick("Room")
              }
              className={({ isActive }) =>
                isActive && detectCall
                  ? "nav-link nav-item  active"
                  : "nav-link nav-item"
              }
              to="/room"
            >
              <i className="fas fa-video menu-icon"></i>
              <span className="menu-title">Room</span>
            </NavLink>
          </li>
          <li
            className={Active === "upgrade" ? " nav-item active" : " nav-item "}
          >
            <NavLink
              onClick={
                detectCall
                  ? () => handleDetectClickWhenInCall()
                  : () => handleClick("upgrade")
              }
              className={({ isActive }) =>
                isActive && detectCall
                  ? "nav-link nav-item "
                  : "nav-link nav-item "
              }
              to={detectCall ? "/room" : "/upgrade"}
            >
              <i className="fas fa-chart-line menu-icon"></i>
              <span className="menu-title">Update Package</span>
            </NavLink>
          </li>
          <li
            className={Active === "history" ? " nav-item active" : " nav-item "}
          >
            <NavLink
              onClick={
                detectCall
                  ? () => handleDetectClickWhenInCall()
                  : () => handleClick("history")
              }
              className={({ isActive }) =>
                isActive && detectCall
                  ? "nav-link nav-item "
                  : "nav-link nav-item "
              }
              to={detectCall ? "/room" : "/history"}
            >
              <i className="fas fa-chart-bar menu-icon"></i>
              <span className="menu-title">History</span>
            </NavLink>
          </li>
          {recordingCheck ? (
            <li
              className={Active === "Recordings" ? " nav-item " : " nav-item "}
            >
              <NavLink
                onClick={() => handleClick("Recordings")}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item " : "nav-link nav-item"
                }
                to="/Recordings"
              >
                <i class="fas fa-record-vinyl"></i>
                <span className="menu-title" style={{ paddingLeft: "17px" }}>
                  {"  "} Recordings
                </span>
              </NavLink>
            </li>
          ) : null}

          <li className={Active === "settings" ? " nav-item " : " nav-item "}>
            <NavLink
              onClick={() => handleLogout("logout")}
              className={({ isActive }) =>
                isActive ? "nav-link nav-item " : "nav-link nav-item"
              }
              to="/"
            >
              <i className="fas fa-sign-out-alt  "></i>
              <span className="menu-title" style={{ paddingLeft: "17px" }}>
                {"  "} Logout
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};
