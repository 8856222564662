import { combineReducers } from "redux";
import { Packages } from "../Common/Packages";

const loginReducer = (state = null, action) => {
  switch (action.type) {
    case "Login":
      return { user: action.payload };
    default:
      return state;
  }
};

const createProviderRoom = (state = { isRoomCreated: false }, action) => {
  switch (action.type) {
    case "ProviderRoomCreated":
      return { isRoomCreated: action.payload };
    default:
      return state;
  }
};

const patientReducer = (state = null, action) => {
  switch (action.type) {
    case "Patient":
      return action.payload;
    default:
      return state;
  }
};

const saveRoomForPatientEnd = (state = null, action) => {
  switch (action.type) {
    case "RoomForPatient":
      return action.payload;
    default:
      return state;
  }
};

const savePatientData = (state = null, action) => {
  switch (action.type) {
    case "PatientData":
      return action.payload;
    default:
      return state;
  }
};

const roomStatus = (state = { roomStatus: null }, action) => {
  switch (action.type) {
    case "RoomStatus":
      return { roomStatus: action.payload };
    default:
      return state;
  }
};
const largeScreenView = (state = { largeScreen: true }, action) => {
  switch (action.type) {
    case "largeScreen":
      return { largeScreen: action.payload };
    default:
      return state;
  }
};
const loginObject = (state = true, action) => {
  switch (action.type) {
    case "loginObject":
      return action.payload;
    default:
      return state;
  }
};

const StripeDetail = (state = { StripeDetail: {} }, action) => {
  switch (action.type) {
    case "StripeDetail":
      return { StripeDetail: action.payload };
    default:
      return state;
  }
};
const StripePurchasedPackageDetails = (
  state = { StripePurchasedPackageDetails: Packages[0] },
  action
) => {
  switch (action.type) {
    case "intialPackageDetailObject":
      return action.payload;
    default:
      return state;
  }
};
const RoomBeforeScreenShare = (
  state = { RoomBeforeScreenShare: true },
  action
) => {
  switch (action.type) {
    case "roomBeforeShare":
      return action.payload;
    default:
      return state;
  }
};
const stripeePackageType = (state = { stripeePackageType: true }, action) => {
  switch (action.type) {
    case "packageType":
      return action.payload;
    default:
      return state;
  }
};
const subscriptionLists = (state = { subscriptionLists: [] }, action) => {
  switch (action.type) {
    case "subscription":
      return action.payload;
    default:
      return state;
  }
};
const SessionId = (state = null, action) => {
  switch (action.type) {
    case "sessionId":
      return action.payload;
    default:
      return state;
  }
};
const totalDayss = (state = null, action) => {
  switch (action.type) {
    case "totalDay":
      return action.payload;
    default:
      return state;
  }
};
const allAcceptedPaitents = (state = [], action) => {
  switch (action.type) {
    case "AllAcceptedPatients":
      if (state.length === action.payload.length) {
        return state;
      }
      return action.payload;
    default:
      return state;
  }
};
const webhookInvoiceRenewal = (state = [], action) => {
  switch (action.type) {
    case "WebhookUpdateRenwal":
      return action.payload;
    default:
      return state;
  }
};
const autoRenewalFild = (state = [], action) => {
  switch (action.type) {
    case "autoRenwal":
      return action.payload;
    default:
      return state;
  }
};
const GetInvoiceDetailonAction = (state = false, action) => {
  switch (action.type) {
    case "getInvoiceAction":
      return action.payload;
    default:
      return state;
  }
};
const ProviderRoomInfo = (state = false, action) => {
  switch (action.type) {
    case "ROOM_INFO":
      return action.payload;
    default:
      return state;
  }
};
const AllPatientLists = (state = [], action) => {
  switch (action.type) {
    case "AllPatientListss":
      if (state.length === action.payload.length) {
        return state;
      }
      return action.payload;
    default:
      return state;
  }
};
const ScreenShareDetail = (state = null, action) => {
  switch (action.type) {
    case "ScreenShareDetail":
      return action.payload;
    default:
      return state;
  }
};

const getOngoingCallState = (state = true, action) => {
  switch (action.type) {
    case "ONGOING_CALL_STATE":
      return action.payload;
    default:
      return state;
  }
};
const isMessageShownOnFileViewer = (state = false, action) => {
  switch (action.type) {
    case "FILE_VIEWER_IMAGE":
      return action.payload;
    default:
      return state;
  }
};

const logoutPatientOnProviderEndCall = (state = false, action) => {
  switch (action.type) {
    case "PROVIDER_END_PATIENT_CALL":
      return action.payload;
    default:
      return state;
  }
};
const AllPendingPats = (state = [], action) => {
  switch (action.type) {
    case "pendingStatus":
      console.log("action.payload", action.payload);
      return action.payload;
    default:
      return state;
  }
};
const IsUrlsCameFromEhr = (state = false, action) => {
  switch (action.type) {
    case "IsUrlCameFromUrl":
      return action.payload;
    default:
      return state;
  }
};
const NavigateDasboardIssuee = (state = false, action) => {
  switch (action.type) {
    case "DasboardIssue":
      return action.payload;
    default:
      return state;
  }
};
const NotificationForCardExpires = (state = [], action) => {
  switch (action.type) {
    case "NotificationCardExpire":
      if ("msg" in action.payload) {
        state.push(action.payload);
      } else {
        state = [];
        return state;
      }
    case "REMOVE_ITEM":
      console.log(" Rmeove Item", action.payload);
      return state.filter((item, index) => index !== action.payload);

    default:
      return state;
  }
};
const isProviderSystmCamEnable = (state = true, action) => {
  switch (action.type) {
    case "providerSystemCamm":
      return action.payload;
    default:
      return state;
  }
};
const isPatSystmCamEnable = (state = true, action) => {
  switch (action.type) {
    case "patSystemCamm":
      return action.payload;
    default:
      return state;
  }
};

const getEhrDimensionsSmallScreen = (state = {}, action) => {
  switch (action.type) {
    case "ehrDimensions":
      return action.payload;
    default:
      return state;
  }
};

const isProviderCameFromEhrPro = (state = false, action) => {
  switch (action.type) {
    case "isProviderCameFromEhr":
      return action.payload;
    default:
      return state;
  }
};
const isPatCameFromEhrorTab = (state = false, action) => {
  switch (action.type) {
    case "isPatCameFromEhrOrTab":
      return action.payload;
    default:
      return state;
  }
};

const updateProfileImage = (state = {}, action) => {
  switch (action.type) {
    case "profilepicupdate":
      return action.payload;
    default:
      return state;
  }
};
const popUpOpenForStripePurchase = (state = false, action) => {
  switch (action.type) {
    case "popTypePurchase":
      return action.payload;
    default:
      return state;
  }
};
const continuePurchasing = (state = false, action) => {
  switch (action.type) {
    case "continuebuyPackage":
      return action.payload;
    default:
      return state;
  }
};
const paymentIntentInCaseOfTime = (state = null, action) => {
  switch (action.type) {
    case "paymentIntent":
      return action.payload;
    default:
      return state;
  }
};
const Latest_Invoice = (state = null, action) => {
  switch (action.type) {
    case "latestInvoice":
      return action.payload;
    default:
      return state;
  }
};
const poackage_Choosen = (state = null, action) => {
  switch (action.type) {
    case "packageChoose":
      return action.payload;
    default:
      return state;
  }
};
const GroupChatUniqueIdentifier = (state = null, action) => {
  switch (action.type) {
    case "groupChat":
      return action.payload;
    default:
      return state;
  }
};
const mySystemInfo = (state = null, action) => {
  switch (action.type) {
    case "MySystemInformation":
      return action.payload;
    default:
      return state;
  }
};

function PatientEndProviderImage(state = null, action) {
  switch (action.type) {
    case "PatientEndProviderImage":
      return action.payload;
    default:
      return state;
  }
}
function globalRoomEnd(state = false, action) {
  switch (action.type) {
    case "globalRoomEnd":
      return action.payload;
    default:
      return state;
  }
}
function isProviderMessagesOpen(state = false, action) {
  switch (action.type) {
    case "isProviderMessagesOpen":
      return action.payload;
    default:
      return state;
  }
}
function roomSid(state = false, action) {
  switch (action.type) {
    case "roomSid":
      return action.payload;
    default:
      return state;
  }
}
function RecordingRulesUpdate(state = "AllParAudioVideo", action) {
  switch (action.type) {
    case "recordingRules":
      return action.payload;
    default:
      return state;
  }
}
function isRecordingOn(state = false, action) {
  switch (action.type) {
    case "isRecordingOn":
      return action.payload;
    default:
      return state;
  }
}
function StartCounter(state = false, action) {
  switch (action.type) {
    case "startCounter":
      return action.payload;
    default:
      return state;
  }
}
function StopCounter(state = false, action) {
  switch (action.type) {
    case "stopCounter":
      return action.payload;
    default:
      return state;
  }
}
function onCompletion(state = false, action) {
  switch (action.type) {
    case "onComplete":
      return action.payload;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  onCompletions: onCompletion,
  StopCounters: StopCounter,
  StartCounters: StartCounter,
  IsRecordingOn: isRecordingOn,
  RecordingRulesUpdates: RecordingRulesUpdate,
  RoomSid: roomSid,
  isProviderMessagesOpens: isProviderMessagesOpen,
  globalRoomEnds: globalRoomEnd,
  GroupChatUniqueIdentifierr: GroupChatUniqueIdentifier,

  poackage_Choosen: poackage_Choosen,
  Latest_Invoice: Latest_Invoice,
  paymentIntentInCaseOfTime: paymentIntentInCaseOfTime,
  ContinuePurchasing: continuePurchasing,

  updateProfileImagee: updateProfileImage,
  UserInfo: loginReducer,
  PatientInfo: patientReducer,
  RoomInfo: saveRoomForPatientEnd,
  PatientDataDetails: savePatientData,
  RoomStatus: roomStatus,
  RoomCreated: createProviderRoom,
  ScreenView: largeScreenView,
  LoginObject: loginObject,
  StripePackageDetail: StripeDetail,
  StripePackagePurchaseDetailObject: StripePurchasedPackageDetails,
  RoomBeforeScreenShared: RoomBeforeScreenShare,
  StripeePackageType: stripeePackageType,
  SubscriptionList: subscriptionLists,
  SessionID: SessionId,
  TotalDayss: totalDayss,
  ProviderRoomInfo: ProviderRoomInfo,
  RetreiveAllPatientList: AllPatientLists,
  AllPatientsAccepted: allAcceptedPaitents,
  WebhookInvoiceRenewal: webhookInvoiceRenewal,
  AutoRenewalField: autoRenewalFild,
  GetInvoiceDetailonaction: GetInvoiceDetailonAction,
  ScreenShareDetails: ScreenShareDetail,
  OnGoingCallState: getOngoingCallState,
  isMessageShownOnFileViewer: isMessageShownOnFileViewer,
  ProviderEndPatCall: logoutPatientOnProviderEndCall,
  AllPendingPat: AllPendingPats,
  IsUrlsCameFromEhrr: IsUrlsCameFromEhr,
  NavigateDasboardsIssue: NavigateDasboardIssuee,
  NotifyForCardExpires: NotificationForCardExpires,
  IsProviderSystmCamEnable: isProviderSystmCamEnable,
  IsPatSystmCamEnable: isPatSystmCamEnable,
  GetEhrDimensions: getEhrDimensionsSmallScreen,
  isProviderCameFromEhrProv: isProviderCameFromEhrPro,
  isPatiCameFromEhrorTab: isPatCameFromEhrorTab,
  PopUpOpenForStripePurchase: popUpOpenForStripePurchase,
  SystemInfo: mySystemInfo,
  ProivderImage: PatientEndProviderImage,
});

export default rootReducer;
