import React from "react";
import { useState } from "react";
import {
  createCardUpdateToken,
  getCreditCardFromDb,
} from "../../../Utils/ApiManager";
import { useSelector } from "react-redux";
import { checkDate, minMaxLength } from "../../../Utils/ValidationsFunctions";
import { toast } from "react-toastify";
import ReceiptCardUpdate from "./receiptCardUpdate";
import Loader from "../../../Common/Spinner";

export default function UpdateCard({ handleModel, getCard }) {
  const { SubscriptionList, LoginObject } = useSelector((state) => state);
  const [cardNo, setCardNo] = useState(null);
  const [cardError, setCardError] = useState(null);
  const [cvc, setcvc] = useState(null);
  const [cvcError, setcvcError] = useState(null);
  const [expiryDate, setExpiryDate] = useState("mm/dd/yyyy");
  const [expiryDateError, setExpiryDateError] = useState(null);
  const [receiptShow, setReceiptShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedCardDetail, setUpdateCardDetail] = useState(null);

  const handleonCahnge = (e) => {
    const { name, value } = e.target;
    if (name === "card-num") {
      setCardError(null);
      setCardNo(value);
      return;
    }
    if (name === "cvv") {
      setcvcError(null);
      setcvc(value);
      return;
    }
    if (name === "expiry") {
      console.log(value);
      setExpiryDateError(null);
      setExpiryDate(value);
      return;
    }
  };
  const updateCard = async () => {
    const cardLength = await minMaxLength(cardNo, 16, 16);
    const invalidDate = await checkDate(expiryDate);
    const cvcLength = await minMaxLength(cvc, 3, 3);
    if (cardLength) {
      setCardError("Card Length Should be 16 digit");
    }
    if (invalidDate) {
      setExpiryDateError("Invalid Date");
    }
    if (cvcLength) {
      setcvcError("Cvc should be 3 digit");
    }

    if (!cardLength && !invalidDate && !cvcLength) {
      setIsLoading(true);
      let obj = {};
      obj.cardNum = cardNo;
      obj.cvc = cvc;
      obj.expiryDate = expiryDate;
      obj.cusId = SubscriptionList[0].customer;

      createCardUpdateToken(obj)
        .then((res) => {
          if (res.data) {
            //  handleModel(false);
            setUpdateCardDetail(res.data);
            setReceiptShow(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response.data) {
            toast.info(err.response.data.error);
            handleModel(true);
            setReceiptShow(false);
            setIsLoading(false);
          }
        });
    }
  };
  const closeHandle = (val) => {
    getCard();
    setReceiptShow(false);
    handleModel(val);
  };

  return (
    <div>
      <div
        id="exampleModalCenter"
        className="modal fade show fadeCustom"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div className="modal-dialog modal-dialog" role="document">
          <div className="modal-content">
            {isLoading ? (
              <Loader small={false} large={true} />
            ) : (
              <>
                <div className="modal-body mt-3">
                  {receiptShow ? (
                    <>
                      <ReceiptCardUpdate
                        updatedCardDetail={updatedCardDetail}
                      />
                    </>
                  ) : (
                    <>
                      <div className="align-items-start justify-content-between d-flex w-100 border-bottom">
                        <div>
                          <h5 className="fw-bolder text-primary">
                            Update Card
                          </h5>
                        </div>
                        <div>
                          {" "}
                          <img
                            className="p-2"
                            width="40"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Visa_Logo.png/640px-Visa_Logo.png"
                            alt="Visa"
                          />
                          <img
                            className="p-2"
                            width="35"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/772px-Mastercard-logo.svg.png?20210817144358"
                            alt="Master"
                          />
                          <img
                            className="p-2"
                            width="40"
                            src="https://download.logo.wine/logo/American_Express/American_Express-Logo.wine.png"
                            alt="American Express"
                          />
                        </div>
                      </div>

                      <div className="w-100 pt-2 pl-5 pr-5 pb-0">
                        <div className="form-group ">
                          <label htmlFor="exampleFormControlSelect1">
                            Card Number
                          </label>

                          <input
                            onChange={handleonCahnge}
                            type="text"
                            className="form-control"
                            name="card-num"
                            placeholder="1234 5678 9012 3457"
                            size="17"
                            id="cno"
                            minLength="16"
                            maxLength="16"
                          />
                          <span style={{ color: "red" }}>{cardError}</span>
                        </div>

                        <div className="form-group ">
                          <label htmlFor="exampleFormControlSelect1">
                            Expiration
                          </label>
                          <input
                            onChange={handleonCahnge}
                            placeholder="MM/YYYY"
                            type="month"
                            id="datepicker"
                            name="expiry"
                            class="form-control month-picker"
                          ></input>
                          {/* <input
                            onChange={handleonCahnge}
                            type="date"
                            className="form-control"
                            name="expiry"
                            placeholder="MM/YYYY"
                            size="7"
                            id="exp"
                            minLength="7"
                            maxLength="7"
                          /> */}
                          <span style={{ color: "red" }}>
                            {expiryDateError}
                          </span>
                        </div>

                        <div className="form-group ">
                          <label htmlFor="exampleFormControlSelect1">Cvc</label>
                          <input
                            onChange={handleonCahnge}
                            type="password"
                            className="form-control"
                            name="cvv"
                            placeholder="&#9679;&#9679;&#9679;"
                            size="1"
                            minLength="3"
                            maxLength="3"
                          />
                          <span style={{ color: "red" }}>{cvcError}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => closeHandle(false)}
              >
                Close
              </button>
              {receiptShow ? (
                <></>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={updateCard}
                  >
                    Update
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
