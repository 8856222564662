
import React from "react";
import ReactNotifications from "react-browser-notifications";


class BrowserNotification extends React.Component {
  constructor(props) {
    super();
    this.showNotifications = this.showNotifications.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount(){
    this.showNotifications()
  }

  showNotifications() {
    if (this.n.supported()) this.n.show();
  }

  handleClick(event) {
    window.focus();
    this.n.close(event.target.tag);
  }

  render() {
    return (
      <div>
        <ReactNotifications
          onRef={(ref) => (this.n = ref)} // Required
          title={this.props.title} // Required
          body={this.props.body}
          icon={  this.props.icon }    
          tag={this.props.tag}
          onClick={(event) => this.handleClick(event)}
        />
      

        
      </div>
    );
  }
}
export default BrowserNotification;
