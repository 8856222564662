import HashLoader from "react-spinners/HashLoader";
function Loader({}) {
  return (
    <div
      className="text-center"
      style={{
        justifyContent: "center",
        display: "flex",
        padding: "50px",
      }}
    >
      <div className="spinner-border  text-primary" role="status"></div>{" "}
    </div>
  );
}

export default Loader;
