import React from "react";

export default function RefundPopup({ paymentDetail, close }) {
  return (
    <div>
      <div
        className={"modal fade show"}
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-modal={"true"}
        aria-labelledby="staticBackdropLabel"
        aria-hidden={"true"}
        style={{
          display: "block",
          paddingRight: "17px ",
          background: "rgba(18, 18, 18, 0.68)",
        }}
        role={"dialog"}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Payment Details
              </h5>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-between align-items-center switchflex">
                <div className="modal-width-60">
                  <div className="row">
                    <div className="col-sm-12 mobileModal">
                      <div className="m-3">
                        {
                          <div style={{ textAlign: "center" }}>
                            <h5
                              className="modal-title pb-4 boldcenter"
                              id="exampleModalLabel"
                            ></h5>
                          </div>
                        }
                        {paymentDetail.status == "succeeded" ? (
                          <>
                            {" "}
                            <div style={{ textAlign: "center" }}>
                              <div className="d-flex justify-content-between">
                                <h6 className="font-weight-bold">Id: </h6>
                                <h6 className="text-muted">
                                  {" "}
                                  {paymentDetail.payment_intent}
                                </h6>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h6 className="font-weight-bold">
                                  Payment Status:
                                </h6>
                                <h6 className="text-muted">
                                  {paymentDetail.object}
                                </h6>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h6 className="font-weight-bold">
                                  Amount Refund:
                                </h6>
                                <h6 className="text-muted">
                                  ${paymentDetail.amount}.00
                                </h6>
                              </div>

                              <div className="d-flex justify-content-between">
                                <h6 className="font-weight-bold">Currency:</h6>
                                <h6>{paymentDetail.currency}</h6>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="d-flex justify-content-between">
                            <h6 className="font-weight-bold">Message: </h6>
                            <h6 className="text-muted">
                              {" "}
                              Payment not Refunded
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {false ? undefined : (
                <>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-primary mt-2"
                      onClick={() => close()}
                    >
                      Close{" "}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}