import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isPatCameFromEhrOrTabs, RoomForPatient } from "../../../Actions/Actions";
import { checkIfRoomExistInDB } from "../../../Utils/commonFunctions";
import PatientPopup from "../../PatientPopup/PatientPopup";
import { reactLocalStorage } from "reactjs-localstorage";
import { NavLink } from "react-router-dom";
import Loader from "../../../Common/Loader/Loader";
import logo from '../../../Assets/images/telxyLogo.svg'
function PatientJoin() {
  const { RoomInfo } = useSelector((state) => state);
  let dispatch = useDispatch();
  const [roomName, setRoomName] = useState("");
  const [toolgeNav, settoolgeNav] = useState(false);
  const [patientJoiningPopup, setPatientJoiningPopup] = useState(false);
  const [togglePopupScreen, setTogglePopupScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ehrWidth, setEhrWidth] = useState(window.innerWidth);
  const [onJoinsSmallScreenClass, setonJoinsSmallScreenClass] = useState(false)
  useEffect(() => {
    if (RoomInfo) {
      setPatientJoiningPopup(true);
      setRoomName(RoomInfo);
    }
  }, [RoomInfo]);

  const onChange = (e) => {
    setRoomName(e.target.value)
    setError(false)
  }

  useEffect(() => {
    const updateDimensions = () => {
      setEhrWidth(window.innerWidth);
      // setHeight(window.innerHeight)
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    console.log('ehrWidth Pat', ehrWidth)

    if (ehrWidth < 992) {
      dispatch(isPatCameFromEhrOrTabs(true));
    }
  }, [ehrWidth]);


  const joinFromJoinDoctorComponent = async (roomPath) => {
    if (roomPath === "") {
      setError(true);
      return;
    } else {
      // check for patient if url includes 'appointment'
      setLoading(true);
      if (roomPath && roomPath.includes("appointment") && RoomInfo === null) {
        let splitURL = roomPath.split("appointment/");
        let roomNameFromURL = splitURL[splitURL.length - 1];

        // this will check fisrt if room exist in DB
        let roomExist = await checkIfRoomExistInDB(roomNameFromURL);

        if (roomExist) {
          reactLocalStorage.set("Room", roomNameFromURL);
          setPatientJoiningPopup(true);
          dispatch(RoomForPatient(roomNameFromURL));
          setLoading(false);
        }
        setLoading(false);
      } else if (roomPath) {
        let roomExist = await checkIfRoomExistInDB(roomPath);
        setLoading(false);

        if (roomExist) {
          dispatch(RoomForPatient(roomExist));
          setPatientJoiningPopup(true);
          setLoading(false);
        }
      }
    }
  };
  const closeModel = (val) => {
    setPatientJoiningPopup(val);
    setonJoinsSmallScreenClass(val)

    togglePopupScreen(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !loading) {
      joinFromJoinDoctorComponent(roomName);
    }
  };
  const handleSmallScreen = (val) => {
    setonJoinsSmallScreenClass(val)

  }
  return (
    <>
      <div className="LoginPagePublic">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="navbar-brand">
            <img
              width="50"
              src={logo}
              alt="no image"
            />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => settoolgeNav(!toolgeNav)}
          >
            <span className="icon-menu fas fa-bars text-white"></span>
          </button>

          <div
            className={
              toolgeNav
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link font-weight-bold" : "nav-link"
                  }
                  to="/login"
                >
                  SignIn
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link font-weight-bold" : "nav-link"
                  }
                  to="/join-doctor"
                >
                  Join Provider
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <div className="main">
          <div className="logininLeft">
            <div
              style={{ height: "92vh" }}
              className="d-flex justify-content-center align-items-start mt-5"
            >
              <div className="w-75">
                <div className="containter">
                  <div
                    id="carouselExampleFade"
                    className="carousel slide carousel-fade"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner p-3">
                      <div className="carousel-item active">
                        <h5>Reporting</h5>
                        <p>Detail Reports of your Calls </p>
                      </div>
                      <div className="carousel-item">
                        <h5>Video Consulation</h5>
                        <p>Best Video Consulation Experience </p>
                      </div>
                      <div className="carousel-item">
                        <h5>Online Payment </h5>
                        <p>Change Package of your Choice anytime </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="logininRight">
            <div
              style={{ height: "calc(100vh - 59px)" }}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="main justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="signin-content rounded">
                    <section id="hero" className="d-flex align-items-center">
                      <div className="container text-center position-relative">
                        <h3 className="text-primary">
                          Paste URL or Enter Provider's Room Name
                        </h3>
                        <h6 className="text-primary mt-0 pt-0 mb-3">
                          As a patient, you don’t need an account!
                        </h6>
                        <div className="w-100">
                          <div className="input-group mb-2">
                            <input
                              type="text"
                              value={roomName}
                              className="form-control"
                              name="roomName"
                              placeholder="Paste URL or Enter Room Name"
                              aria-label="room"
                              onChange={onChange}
                              aria-describedby={error ? 'invalid' : "valid"}
                              onKeyPress={handleKeyPress}
                              disabled={loading}
                            />
                          </div>

                        </div>
                        <button
                          style={{
                            display: "inline-flex",
                            height:'36px',
                            width:'180px',
                            justifyContent:'center',
                            
                          }}
                          className="main-btn mt-3"
                          onClick={() => joinFromJoinDoctorComponent(roomName)}
                          disabled={loading}
                        >
                          Join Provider
                          {loading ? (
                            <>
                              <Loader small={true} />{" "}
                            </>
                          ) : (
                            <></>
                          )}
                        </button>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="SignInformPublic">
        {" "}
        <div className="container rounded">
          <div className="signin-content d-flex">
            <section id="hero" className="d-flex align-items-center">
              <div className="container text-center position-relative">
                <h3>Paste URL or Enter Provider's Room Name </h3>
                <h6 className="text-white mt-0 pt-0 mb-3">
                  As a patient, you don’t need an account!
                </h6>
                <div className="" style={{ width: "50%", marginLeft: "25%" }}>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      value={roomName}
                      className="form-control"
                      name="roomName"
                      placeholder="Paste URL or Enter Room Name"
                      aria-label="room"
                      onChange={(e) => setRoomName(e.target.value)}
                      aria-describedby="basic-addon2"
                    />
                  </div>
                </div>

                <a
                  className="main-btn"
                  onClick={() => joinFromJoinDoctorComponent(roomName)}
                >
                  Join Provider
                </a>
              </div>
            </section>
          </div>
        </div>
      </div> */}

      {patientJoiningPopup ? (
        <PatientPopup
          handleSmallScreen={handleSmallScreen}
          onJoinsSmallScreenClass={onJoinsSmallScreenClass}
          room_Name={roomName}
          setTogglePopupScreen={setTogglePopupScreen}
          togglePopupScreen={togglePopupScreen}
          patientJoiningPopup={patientJoiningPopup}
          closeModel={closeModel}
        />
      ) : undefined}
    </>
  );
}

export default PatientJoin;
