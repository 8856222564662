import React, { useRef, useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import Countdown from "react-countdown";
import { onCompleteAction } from "../../../Actions/Actions";

function CountdownApiExample({
  checkDeduct,
  stateEmptyOnCompletion,
  updateMinApi,
  stopCounter,
  counterMilliseconds,
}) {
  const countdownApiRef = useRef(null);
  let dispatch = useDispatch();

  const [date, setDate] = useState(Date.now() + counterMilliseconds);

  useEffect(() => {
    if (counterMilliseconds != 0) {
      handleStartClick();
    }
  }, [counterMilliseconds]);
  useEffect(() => {
    console.log("bug here", stopCounter);
    if (stopCounter) {
      handlePauseClick();
      //   handleResetClick();
    }
  }, [stopCounter]);

  const handleStartClick = () => {
    countdownApiRef.current && countdownApiRef.current.start();
  };

  const handlePauseClick = async () => {
    await (countdownApiRef.current && countdownApiRef.current.pause());
    setTimeout(() => {
      console.log("DUM DUM");
    }, 7000);
  };

  const handleResetClick = async () => {
    await setDate(Date.now() + counterMilliseconds);
  };

  const handleStop = () => {
    countdownApiRef.current && countdownApiRef.current.stop();
  };

  const handleStopClick = (obj) => {
    console.log("stop Seconds", obj);

    updateMinApi(obj);
    //Call Back Function
  };
  const handleUpdate = (obj) => {
    //Callback on completion
    dispatch(onCompleteAction(true));
    stateEmptyOnCompletion();
  };

  const isPaused = () => {
    return !!(countdownApiRef.current && countdownApiRef.current.isPaused());
  };

  const handleUpdatemount = (obj) => {
    console.log("obj", obj);
    checkDeduct(false, obj);
  };
  const isCompleted = () => {
    return !!(countdownApiRef.current && countdownApiRef.current.isCompleted());
  };

  return (
    <>
      <Countdown
        key={date}
        ref={countdownApiRef}
        date={date}
        // onMount={handleUpdatemount}
        // onStop={handleUpdatemount}
        onTick={handleUpdatemount}
        // onStart={handleUpdate}
        onPause={handleStopClick}
        onComplete={handleUpdate}
        autoStart={false}
      />
      {/* <div>
        <button
          type="button"
          onClick={handleStartClick}
          disabled={!isPaused() || isCompleted()}
        >
          Start
        </button>{" "}
        <button
          type="button"
          onClick={handlePauseClick}
          disabled={isPaused() || isCompleted()}
        >
          Pause
        </button>{" "}
        <button type="button" onClick={handleResetClick}>
          Reset
        </button>
        <button onClick={handleStop}>Stop</button>
      </div> */}
    </>
  );
}

export default CountdownApiExample;
