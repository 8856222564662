import React, { useState, useEffect } from "react";
import "./previousInvoice.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../Common/Spinner";

export default function ConfirmationUpgradationAndDowngradation({
  closeConfirmation,
  updateDowngradeSub,
  name,
}) {
  console.log("name madni", name);
  let { SubscriptionList } = useSelector((state) => state);

  const [paymentEvents, setPaymentEvents] = useState([]);
  const [invoiceDetails, setinvoiceDetails] = useState([]);

  const [nextAttempt, setNextPaymentAttempt] = useState();
  const [created, setCreated] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPaymentsEvents, setIsLoadingPaymentsEvents] = useState(false);
  const [previewLines, setPreviewLines] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    previewBill();

    // getInvoiceRecord();

    // getInvoiceLines();
  }, []);

  const replaceTerms = (str) => {
    let strTerm = str;
    if (strTerm.includes("Unused time")) {
      strTerm = strTerm.replace("Unused time", "Remaining amount left");
    }
    if (strTerm.includes("Remaining time")) {
      strTerm = strTerm.replace("Remaining time", "total amount charge");
    }
    if (strTerm.includes("1 ×")) {
      strTerm = strTerm.replace("1 ×", "Next Month charge on");
    }

    return strTerm;
  };

  const getInvoiceRecord = async () => {
    try {
      let obj = {
        customerId: SubscriptionList[0].customer,
      };
      let url =
        process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoicePayments";
      axios.post(url, obj).then((res) => {
        setinvoiceDetails(res.data.invoiceDetails);
        let result = timeStampIntoDate(res.data.invoiceDetails.period_start);

        //  let invoiceDetailEvents=PaymentEvents(res.data.invoiceDetails.lines)
        let currentperiod = timeStampIntoDate(res.data.invoice.period_end);
        setNextPaymentAttempt(currentperiod);
        // setPaymentEvents(invoiceDetailEvents)

        setCreated(result);
        setIsLoading(false);
      });
    } catch (err) {}
  };

  const getInvoiceLines = async () => {
    setIsLoadingPaymentsEvents(true);
    try {
      let obj = {
        customerId: SubscriptionList[0].customer,
      };
      let url = process.env.REACT_APP_APIS_GLOBAL_LOCALHOST + "/invoiceLines";
      axios.post(url, obj).then((res) => {
        let invoiceDetailEvents = PaymentEvents(res.data && res.data.lines);

        setPaymentEvents(invoiceDetailEvents);
        setIsLoadingPaymentsEvents(false);
        // setCreated(result);
      });
    } catch (err) {
      setIsLoadingPaymentsEvents(false);
    }
  };
  const previewBill = () => {
    setIsLoadingPaymentsEvents(true);

    try {
      let obj = {
        subId: SubscriptionList[0].id,
        packageName: name,
      };
      let url =
        process.env.REACT_APP_APIS_GLOBAL_LOCALHOST +
        "/PreviewSubscriptionBill";
      axios.post(url, obj).then((res) => {
        console.log("res.data.lines", res.data.invoiceLine);
        // let invoiceDetailEvents = PaymentEvents(res.data && res.data.lines);
        setinvoiceDetails(res && res.data.invoice);
        let invoiceDetailEvents = PaymentEvents(
          res.data && res.data.invoiceLine
        );
        setPaymentEvents(invoiceDetailEvents);
        let result = timeStampIntoDate(res.data.invoice.created);
        let currentperiod = timeStampIntoDate(res.data.invoice.period_end);
        setNextPaymentAttempt(currentperiod);

        setCreated(result);
        setIsLoadingPaymentsEvents(false);
      });
    } catch (err) {
      setIsLoadingPaymentsEvents(false);
    }
  };
  const PaymentEvents = (events) => {
    console.log(events, "events");
    let res =
      events &&
      events.map((el) => {
        console.log("el", el);
        let obj = {
          description: el.description,
          Qty: el.plan.interval_count,
          amount: el.amount / 100,
        };
        return obj;
      });
    return res;
  };
  const timeStampIntoDate = (createdTimeStamp) => {
    let date = new Date(createdTimeStamp * 1000);

    return date.toLocaleString();
  };

  return (
    <>
      <>
        <div>
          <div
            id="exampleModalCenter"
            className="modal fade show fadeCustom"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            style={{ display: "block", paddingRight: "17px" }}
          >
            <div className="modal-dialog  " role="document">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "#4b49ac",
                  }}
                >
                  <h5 className="modal-title" id="exampleModalLabel">
                    Comfirmations
                  </h5>
                </div>

                <div className="modal-body">
                  <div className="col-md-12 p-0">
                    <div className="row">
                      <div className="col-xs-10 col-sm-10 col-md-12 col-xs-offset-1 col-sm-offset-1 col-md-offset-3">
                        <div className="row">
                          <div className="receipt-header">
                            {/* <div className="col-xs-6 col-sm-6 col-md-6">
                              <div className="receipt-left">
                                <img
                                  className="img-responsive"
                                  alt="iamgurdeeposahan"
                                  src="https://bootdey.com/img/Content/avatar/avatar6.png"
                                  style={{
                                    width: "71px",
                                    borderRadius: "43px",
                                  }}
                                />
                              </div>
                            </div> */}
                            {/* <div
                              className="col-xs-12 col-sm-12 col-md-12 text-right"
                              style={{ top: "90px" }}
                            >
                              <div className="receipt-right">
                                <h5>Customer Id :  {invoiceDetails.customer}</h5>
                                <h5>Created :  {created}</h5>
                              
                                <div className="my-2">
                            <i className="text-blue-m2 text-xs mr-1"></i>{" "}
                          
                          </div>
                               
                               
                               
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="row p-0">
                          <div className="receipt-header receipt-header-mid">
                            <div className="col-xs-8 col-sm-8 col-md-8 text-left">
                              {/* <div className="receipt-right">
                                <h5>Custome Name: {invoiceDetails.customer_name} </h5>
                                <p>
                                  <b>Customer Email :</b>  {invoiceDetails.customer_email}
                                </p>
                                <p>
                                  <b>Country:</b>  {invoiceDetails.account_country}
                                </p>
                                <p>
                                  <b>Payment Method :</b>  {invoiceDetails.collection_method==='charge_automatically'?'Stripe will automatically charge amount when upgradation/downgradation happens':''}
                                </p>
                              </div> */}
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4">
                              <div className="receipt-left">
                                <h3></h3>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          {isLoadingPaymentsEvents ? (
                            <>
                              <Loader />{" "}
                            </>
                          ) : (
                            <>
                              <table className="table table-bordered table-responsive">
                                <thead>
                                  <tr>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Unit Price</th>

                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {" "}
                                  <>
                                    <>
                                      {paymentEvents &&
                                      paymentEvents.length > 0 ? (
                                        <>
                                          {paymentEvents.map((el, index) => {
                                            return (
                                              <tr className="odd" key={index}>
                                                <td
                                                  style={{
                                                    whiteSpace: " break-spaces",
                                                  }}
                                                >
                                                  {replaceTerms(el.description)}
                                                </td>
                                                <td className="sorting_1">
                                                  {el.Qty}
                                                </td>
                                                {/* <td> ${el.amount<0?Math.abs(el.amount):-Math.abs(el.amount)}</td>
                                  <td>${el.amount<0?Math.abs(el.amount):-Math.abs(el.amount)}</td> */}
                                                <td>
                                                  {" "}
                                                  $
                                                  {el.amount < 0
                                                    ? Math.abs(el.amount)
                                                    : Math.abs(el.amount)}
                                                </td>
                                                <td>
                                                  {" "}
                                                  $
                                                  {el.amount < 0
                                                    ? Math.abs(el.amount)
                                                    : Math.abs(el.amount)}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <> No Transaction record to show </>
                                      )}{" "}
                                    </>
                                  </>
                                </tbody>
                              </table>

                              <div>
                                <span className="text-right">
                                  <p>
                                    <strong>
                                      Remaining Balance (after this purchase) :
                                      <span>
                                        ${" "}
                                        {Math.abs(
                                          invoiceDetails.ending_balance / 100
                                        )}
                                      </span>{" "}
                                    </strong>
                                    <br></br>
                                    <strong>
                                      Amount(you have to pay) :
                                      <span>
                                        ${" "}
                                        {Math.abs(
                                          invoiceDetails.amount_due / 100
                                        )}
                                      </span>{" "}
                                    </strong>
                                  </p>

                                  {/* <p>
                                <strong>Total: ${invoiceDetails.total / 100} </strong>
                              </p>
                              <p>
                                <strong>Amount Due:  ${invoiceDetails.amount_due / 100} </strong>
                              </p> */}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    onClick={closeConfirmation}
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    style={{ backgroundColor: "#4b49ac", color: "white" }}
                  >
                    Close
                  </button>
                  <button
                    style={{ backgroundColor: "#4b49ac", color: "white" }}
                    onClick={() => updateDowngradeSub(name)}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
