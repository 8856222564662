import React from 'react'
import FileViewer from './FileViewer'

export default function ImageComponenet({url, toggleImage, fileType}) {
   

        let fileTypeExt = fileType || 'txt'
      
      
  return (
   
        
                  <FileViewer
                    fileExt={fileTypeExt}
                    file={url}
                    close={toggleImage}
                  />
              
      
  )
}
