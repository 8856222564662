import React, { useState, useEffect } from "react";
import ProfileInfo from "./ProfileInfo/Index";
import UpadePassword from "./UpdatePassword/index";
import RoomSetting from "./Room/roomSetting";
import SettingNav from "./SetingLayout/SettingNav";
import StripePayments from "./StripeInvoice/StripeInvoice";
import CancelSubscription from "./CancelSubscription/CancelSubscription";
import { useSelector } from "react-redux";
import RecurringSubscription from "./RecurringSubscription/recurringSubscription";
import CardDetails from "./CardDetails";
import Refund from "./Refund/refund";
import Payments from "./Payments/Payments";
import { roomDestroy } from "../../Utils/ApiManager";

const Setting = () => {
  const [tab, setTabs] = useState("Profile");
  const {
    StripeePackageType,
    SubscriptionList,
    StripePackagePurchaseDetailObject,
    RoomSid,
  } = useSelector((state) => state);
  const handleSetTabs = (val) => {
    setTabs(val);
  };

  const handleReloadwithRoom = async () => {
    if (RoomSid) {
      let obj = { roomSid: RoomSid };
      await roomDestroy(obj);
    }
  };
  useEffect(() => {
    if (true) {
      window.onbeforeunload = function () {
        handleReloadwithRoom();
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const tabs = [
    { id: "1", name: "Profile" },
    { id: "8", name: "Update Password" },
    { id: "2", name: "Room" },
    { id: "4", name: "Cancel Subscription" },
    { id: "3", name: "Recurring" },

    { id: "5", name: "Payment Details" },
    { id: "6", name: "Card Details" },
    // { id: "8", name: "Payments" },
  ];
  const FreeTabs = [
    { id: "1", name: "Profile" },
    { id: "2", name: "Room" },
  ];
  const WhenSubscriptionNotActive = [
    { id: "1", name: "Profile" },
    { id: "8", name: "Update Password" },
    { id: "2", name: "Room" },
    { id: "6", name: "Card Details" },
  ];
  const oneTimeActivePackage = [
    { id: "1", name: "Profile" },
    { id: "2", name: "Room" },
    { id: "6", name: "Card Details" },
    { id: 7, name: "Refund" },
  ];
  let oneTimeCheck = StripePackagePurchaseDetailObject.packageName;
  return (
    <>
      <div className="d-flex">
        <div className=""></div>

        <div className="chat settings-main pt-2" id="middle">
          <div
            className="slimScrollDiv"
            style={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              height: "calc(100vh - 120px)",
            }}
          >
            <div
              className="slimscroll"
              style={{ overflow: "hidden", width: "100%" }}
            >
              <div className="settings-tab my-4">
                <SettingNav
                  handleSetTabs={handleSetTabs}
                  tab={tab}
                  tabs={
                    StripeePackageType === "free"
                      ? FreeTabs
                      : oneTimeCheck === "metalOneTime" ||
                        oneTimeCheck === "silverOneTime" ||
                        oneTimeCheck === "goldOneTime"
                      ? oneTimeActivePackage
                      : SubscriptionList &&
                        SubscriptionList.subscriptionLists &&
                        SubscriptionList.subscriptionLists.length === 0
                      ? WhenSubscriptionNotActive
                      : tabs
                  }
                />
                {tab === "Profile" ? <ProfileInfo /> : undefined}
                {tab === "Update Password" ? <UpadePassword /> : undefined}
                {tab === "Payment Details" ? <StripePayments /> : undefined}
                {tab === "Card Details" ? <CardDetails /> : undefined}
                {tab === "Refund" ? <Refund /> : undefined}
                {tab === "Payments" ? <Payments /> : undefined}

                {tab === "Room" ? <RoomSetting /> : undefined}
                {tab === "Cancel Subscription" ? (
                  <CancelSubscription />
                ) : undefined}
                {tab === "Recurring" ? <RecurringSubscription /> : undefined}
              </div>
            </div>
            <div
              className="slimScrollBar"
              style={{
                background: "rgb(204, 204, 204)",
                width: "7px",
                position: "absolute",
                top: "0px",
                opacity: "0.4",
                display: "none",
                borderRadius: "7px",
                zIndex: "99",
                right: "1px",
                height: "638.139px",
              }}
            ></div>
            <div
              className="slimScrollRail"
              style={{
                width: "7px",
                height: "100%",
                position: "absolute",
                top: "0px",
                display: "none",
                borderRadius: "7px",
                background: "rgb(51, 51, 51)",
                opacity: "0.2",
                zIndex: "90",
                right: "1px",
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
