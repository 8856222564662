import React, { useState } from "react";
import { useEffect } from "react";
import capitalizeFirst from "../../../../Common/CaptilizeFirstLetter";
import SignalStrengthIndicator from "../../../../Common/SignalStrengthIndicator/signalStrengthIndicator";

const Index = ({
  networkLevelForGroup,
  remoteParticipants,
  toggleSelectedParticipant,
  selectedParticipant,
}) => {
  const [currentParticipant, SetCurrentParticipant] = useState([]);
  useEffect(() => {
    SetCurrentParticipant(remoteParticipants);
  }, [remoteParticipants]);

  function capitalFirstLetters(str) {
    return str.slice(0, 2);
  }

  let remoteUsers =
    currentParticipant &&
    currentParticipant.map((remoteParticipants, index) => {
      return (
        <li
          onClick={() => {
            if (remoteParticipants.videoTracks.size < 2) {
            } else {
              toggleSelectedParticipant(index);
            }
          }}
          key={index}
        >
          <div
            className="call-user-avatar"
            style={{
              backgroundColor: "rgb(72 72 72 / 43%)",
              borderRadius: "5px",
              marginBottom: "10px",
              width: "87px",
            }}
          >
            <span
              className="call-action-group call-mute"
              style={{
                right: "21px",
                bottom: "29px",
                width: "45px",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  padding: "0.7rem !important",
                  height: "45px",
                  width: "45px",
                  borderRadius: "50% !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-circle bg-primary fw-bolder p-1"
              >
                {capitalizeFirst(capitalFirstLetters(remoteParticipants.identity))}
              </p>

              {networkLevelForGroup
                .filter((el) => el.participantIdentity === remoteParticipants.identity)
                .map((el) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                      position: "absolute",
                      zIndex: "9",
                      top: "127%",
                      left: "35%",
                      width: "100%",
                    }}
                  >
                    <SignalStrengthIndicator levels={el.levels} />
                  </div>
                ))}

            </span>
            {selectedParticipant === index ? (
              <>
                <button id="speech" class=" call-action-mic dominantdiv">
                  <div class="pulse-ring"></div>
                  <i
                    class="fa fa-microphone"
                    style={{
                      fontSize: "15px",
                    }}
                    aria-hidden="true"
                  ></i>
                </button>

              </>
            ) :
              null
            }
          </div>
        </li>
      );
    });

  return (
    <div>
      <div className="video-group-member">
        {currentParticipant && currentParticipant.length > 1 ? (
          <ul>{remoteUsers}</ul>
        ) : undefined}
      </div>

    </div>
  );
};
export default Index;
