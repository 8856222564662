import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import userAvatar from "../../../../Assets/images/userAvatar.svg";
const Index = ({
  toggleNoiseCacellationIcon,
  EnableDisableNoiseCancellation,
  isRecrodingOn,
  isRecordingOn,
  backToGlobalRoom,
  isShowCallActionsButton,
  cameraDevFound,
  handlePhoneCallEndOnPatientSide,
  thiswillHandleLogoutAndStatusOfUser,
  settoogleFullScreen,
  toogleFullScreen,
  onClickDisableVideo,
  isVideoDisable,
  onClickDisableAudio,
  audioDisable,
  isScreenShared,
  shareScreenToTheRoom,
  screenShareButtonToggle,
  callActionCircle,
}) => {
  let { PatientDataDetails, LoginObject, StripePackagePurchaseDetailObject } =
    useSelector((state) => state);
  const [draggableStyle, setDraggableStyle] = useState(false);
  console.log("toggleNoiseCacellationIcon", toggleNoiseCacellationIcon);
  let pkg = StripePackagePurchaseDetailObject?.packageName;
  let recordingCheck =
    StripePackagePurchaseDetailObject &&
    StripePackagePurchaseDetailObject?.recordings &&
    StripePackagePurchaseDetailObject?.recordings?.recordingEnbled;
  return (
    <div
      className={callActionCircle ? "video-call-actions " : "video-call-action"}
    >
      {callActionCircle ? (
        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              direction: "row",
              marginLeft: "-14%",
              marginTop: "-4%",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: "55px", width: "92px" }}
              src={userAvatar}
              alt="image"
            />
            <p
              style={{
                color: "#ffff",
                marginLeft: "-12%",
              }}
            >
              Toby Gins
            </p>
          </div>
          <p
            style={{
              color: "#ffff",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            12:00 AM
          </p>
        </div>
      ) : (
        <></>
      )}
      <ul>
        {!isShowCallActionsButton ? (
          <>
            <li>
              <a>
                <span className="material-icons">
                  <i
                    className={
                      toogleFullScreen
                        ? "fas fa-compress text-white"
                        : "fas fa-expand text-white"
                    }
                    onClick={() => settoogleFullScreen(!toogleFullScreen)}
                  ></i>
                </span>
              </a>
            </li>
            {pkg === "gold" && LoginObject.username ? (
              <>
                {toggleNoiseCacellationIcon ? (
                  <li>
                    <a>
                      <span className="material-icons">
                        <i
                          className={"fas fa-podcast"}
                          style={{ color: "red" }}
                          onClick={() =>
                            EnableDisableNoiseCancellation(false, null, false)
                          }
                        ></i>
                      </span>
                    </a>
                  </li>
                ) : (
                  <li>
                    <a>
                      <span className="material-icons">
                        <i
                          className={"fas fa-podcast"}
                          style={{ color: "white" }}
                          onClick={() =>
                            EnableDisableNoiseCancellation(false, null, true)
                          }
                        ></i>
                      </span>
                    </a>
                  </li>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <li>
            <a>
              <span className="material-icons">
                <i
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Return to room"
                  className="fa fa-arrows-alt text-white"
                  onClick={() => backToGlobalRoom(true)}
                ></i>
              </span>
            </a>
          </li>
        )}
        {!isShowCallActionsButton ? (
          <>
            <li>
              <a className="call-mute">
                {audioDisable ? (
                  <span
                    className="material-icons"
                    onClick={() => onClickDisableAudio(false)}
                  >
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Un-Mute"
                      className="fas fa-microphone-slash"
                    ></i>
                  </span>
                ) : (
                  <span
                    className="material-icons"
                    onClick={() => onClickDisableAudio(true)}
                  >
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Mute"
                      className="fas fa-microphone-alt"
                    ></i>
                  </span>
                )}
              </a>
            </li>
            {recordingCheck ? (
              <li>
                {isRecrodingOn ? (
                  <a className="call-end">
                    (
                    <span
                      className="material-icons"
                      onClick={() => isRecordingOn(false)}
                    >
                      <i className="fas fa-record-vinyl"></i>
                    </span>
                    )
                  </a>
                ) : (
                  <>
                    {" "}
                    <a className="call-end-white">
                      (
                      <span
                        className="material-icons"
                        onClick={() => isRecordingOn(true)}
                      >
                        <i className="fas fa-record-vinyl"></i>
                      </span>
                      )
                    </a>
                  </>
                )}
              </li>
            ) : null}
          </>
        ) : (
          <>
            {recordingCheck ? (
              <li>
                {isRecrodingOn ? (
                  <a className="call-end">
                    (
                    <span
                      className="material-icons"
                      onClick={() => isRecordingOn(false)}
                    >
                      <i className="fas fa-record-vinyl"></i>
                    </span>
                    )
                  </a>
                ) : (
                  <>
                    {" "}
                    <a className="call-end-white">
                      (
                      <span
                        className="material-icons"
                        onClick={() => isRecordingOn(true)}
                      >
                        <i className="fas fa-record-vinyl"></i>
                      </span>
                      )
                    </a>
                  </>
                )}
              </li>
            ) : null}
          </>
        )}

        <li>
          {!LoginObject.username ? (
            <a
              className="call-end"
              onClick={() =>
                handlePhoneCallEndOnPatientSide(PatientDataDetails)
              }
            >
              <span className="material-icons">
                <i className="fas fa-phone-slash"></i>
              </span>
            </a>
          ) : (
            <>
              <a
                className="call-end"
                onClick={() => thiswillHandleLogoutAndStatusOfUser()}
              >
                <span className="material-icons">
                  <i className="fas fa-phone-slash"></i>
                </span>
              </a>
            </>
          )}
        </li>
        {pkg === "silver" || pkg === "metal" ? null : (
          <>
            {" "}
            {!screenShareButtonToggle && (
              <li>
                <a className={isScreenShared ? "call-end" : "call-end-white"}>
                  {isScreenShared ? (
                    <span
                      className="material-icons"
                      onClick={() => shareScreenToTheRoom(false)}
                    >
                      <i className="fas fa-desktop "></i>
                    </span>
                  ) : (
                    <span
                      className="material-icons"
                      onClick={() => shareScreenToTheRoom(true)}
                    >
                      <i className="fas fa-desktop"></i>
                    </span>
                  )}
                </a>
              </li>
            )}
          </>
        )}

        <li>
          {cameraDevFound ? (
            <a>
              {isVideoDisable ? (
                <span
                  className="material-icons"
                  onClick={() => onClickDisableVideo(false)}
                >
                  <i className="fas fa-video-slash text-white"></i>
                </span>
              ) : (
                <span
                  className="material-icons"
                  onClick={() => onClickDisableVideo(true)}
                >
                  <i className="fas fa-video text-white"></i>
                </span>
              )}
            </a>
          ) : null}
        </li>
      </ul>
    </div>
  );
};
export default Index;
