import React from 'react'

export default function SubscriptionScreen({handleStepperButton,radioBtnValue,subscription,BackSubScreen}) {
  return (
    <>
     
                    {" "}
                    <div className="p-2">
                      <button
                        className="btn  d-flex"
                        onClick={() => handleStepperButton(BackSubScreen)}
                      >
                        <i className="fa fa-arrow-left mr-2"></i>
                      </button>
                      <p className="p-2">
                        We estimate how much hour you need to know the 
                        
                      </p>

                      <div className="d-flex justify-content-between">
                        <div className="d-flex p-2">
                          <div
                            style={{ alignSelf: "center" }}
                            className="mr-2"
                          >
                            {" "}
                            <input
                              type="radio"
                              aria-label="Radio button for following text input"
                              onClick={(e) => radioBtnValue(e)}
                              checked={subscription === "1M"}
                              name="1M"
                            />
                          </div>
                          <div
                            style={{ lineHeight: "0.5" }}
                            className="d-flex flex-column "
                          >
                            {" "}
                            <p className="font-weight-bolder">1 month</p>
                            <p>
                              {" "}
                              <mark>12:45PM</mark>
                            </p>
                          </div>
                        </div>

                        <div>
                          <h5>19 USDT</h5>
                        </div>
                      </div>

                      <hr />
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div
                            style={{ alignSelf: "center" }}
                            className="mr-2"
                          >
                            {" "}
                            <input
                              type="radio"
                              aria-label="Radio button for following text input"
                              onClick={(e) => radioBtnValue(e)}
                              name="3M"
                              checked={subscription === "3M"}
                            />
                          </div>
                          <div
                            style={{ lineHeight: "0.5" }}
                            className="d-flex flex-column "
                          >
                            {" "}
                            <p className="font-weight-bolder">3 month</p>
                            <p>
                              {" "}
                              <mark>12:45PM</mark>
                            </p>
                          </div>
                        </div>

                        <div>
                          <h5>19 USDT</h5>
                        </div>
                      </div>

                      <hr />
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div
                            style={{ alignSelf: "center" }}
                            className="mr-2"
                          >
                            {" "}
                            <input
                              type="radio"
                              aria-label="Radio button for following text input"
                              onClick={(e) => radioBtnValue(e)}
                              name="6M"
                              checked={subscription === "6M"}
                            />
                          </div>
                          <div
                            style={{ lineHeight: "0.5" }}
                            className="d-flex flex-column "
                          >
                            {" "}
                            <p className="font-weight-bolder">6 month</p>
                            <p>
                              {" "}
                              <mark>12:45PM</mark>
                            </p>
                          </div>
                        </div>

                        <div>
                          <h5>19 USDT</h5>
                        </div>
                      </div>
                    </div>
                    <p className="pricing-card-price">
                      $10<span className="pricing-card-price-text">/mo</span>
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                   
                   
                   
                   

                    
                  </>
  )
}
