import React, { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";

import UpdateCard from "./updateCard";
import { useSelector } from "react-redux";
import Loader from "../../../Common/Spinner";

export default function CardComp({ cardDetails, getCard, isLoading }) {
  const [openUpdateCardModel, setOpenUpdateCardModel] = useState(false);
  const { LoginObject,StripePackagePurchaseDetailObject } = useSelector((state) => state);
  const handleModel = (val) => {
    setOpenUpdateCardModel(val);
  };

  let check =
        StripePackagePurchaseDetailObject.packageName === "silverOneTime" ||
        StripePackagePurchaseDetailObject.packageName === "goldOneTime" ||
        StripePackagePurchaseDetailObject.packageName === "metalOneTime";
  return (
    <>

      {!isLoading && openUpdateCardModel ? (
        <UpdateCard getCard={getCard} handleModel={handleModel} />
      ) : null}
      <div className="settings-header">
        <h5> Checkout Credit Card Detail</h5>
      </div>
      {
        isLoading && <Loader />
      }
      {!isLoading &&

        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className=" border-0">
            <div
              className="row pl-3 pr-3 pt-4 CardDetailsinSettings"
              style={{ backgroundColor: "#ffff" }}
            >
              <div className="col-sm-7 border-line pb-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Name on the card</label>

                  <input
                    style={{ backgroundColor: "#fff" }}
                    type="text"
                    name="name"
                    disabled
                    className="form-control"
                    value={cardDetails && cardDetails.name}
                    placeholder="Name"
                    size="15"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="CardNumber">Card Number</label>

                  <div className="row px-3">
                    <input
                      style={{ backgroundColor: "#fff" }}
                      type="text"
                      name="card-num"
                      placeholder="0000 0000 0000 0000"
                      disabled
                      value={`************${cardDetails ? cardDetails.last4 : "0000"
                        }`}
                      size="18"
                      id="cr_no"
                      className="form-control"
                      minLength="19"
                      maxLength="19"
                    />
                    {/* <p className="mb-0 ml-3">/</p>
                                <img className="image ml-1" src="https://i.imgur.com/WIAP9Ku.jpg" /> */}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Brand">Brand</label>

                  <input
                    style={{ backgroundColor: "#fff" }}
                    type="text"
                    name="exp"
                    className="form-control"
                    disabled
                    value={cardDetails && cardDetails.brand}
                    placeholder="VISA"
                    size="6"
                    id="exp"
                    minLength="5"
                    maxLength="5"
                  />
                </div>
                <div className="form-inline">
                  {" "}
                  <div className="form-group">
                    <label htmlFor="ExpireYear mr-3">Expire Year</label>

                    <input
                      style={{ backgroundColor: "#fff" }}
                      type="text"
                      name="exp"
                      placeholder="MM/YY"
                      size="6"
                      className="form-control ml-3"
                      disabled
                      value={cardDetails && cardDetails.exp_year}
                      id="exp"
                      minLength="5"
                      maxLength="5"
                    />
                  </div>
                  <div className="form-group ml-5">
                    <label htmlFor="ExpireMonth ">Expire Month</label>
                    <input
                      style={{ backgroundColor: "#fff" }}
                      name="cvv"
                      placeholder="00"
                      size="1"
                      className="form-control ml-3"
                      minLength=""
                      disabled
                      value={cardDetails && cardDetails.exp_month}
                      maxLength="10"
                    />
                  </div>
                </div>
                {/* <div className="form-group">
                <label htmlFor="Funding">Funding</label>

                <input
                  name="cvv"
                  placeholder="000"
                  size="6"
                  className="form-control"
                  minLength=""
                  disabled
                  value={cardDetails && cardDetails.card.funding}
                  maxLength="10"
                />
              </div> */}
              </div>
              <div className="col-sm-5 text-sm-center justify-content-center pt-4 pb-4">
                <small className="text-sm text-muted">
                  <i className="fas fa-fingerprint fa-3x text-primary"></i>
                </small>
                <h5 className="mb-5">{cardDetails && cardDetails.fingerprint}</h5>

                <br></br>
                <small className="text-sm text-muted">Email</small>
                <h5 className="mb-5">{cardDetails && cardDetails.email}</h5>

                <button
                  type="submit"
                  className="btn btn-primary text-center mt-4"
                  onClick={() => handleModel(true)}
                  disabled={check?true:false}

                >
                  Change Card
                </button>
              </div>
            </div>
          </div>
        </div>
      }

    </>
  );
}