import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const MinGraph = ({
  filterDatesLabelForBarGraph,
  dataFilteredBetweenStartDateAndEndDate,
  dataSet,
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const labels =
    filterDatesLabelForBarGraph !== undefined || null
      ? filterDatesLabelForBarGraph
      : [];

  const data = {
    labels,
    datasets: [
      {
        label: "Total Duration Minutes",
        data: dataSet ? dataSet : [],
        backgroundColor: "#4b49ac",
      },
      // {
      //     label: 'Total Recording DurationSec',
      //     data: [],
      //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
      // {
      //     label: 'Total Participant DurationSec',
      //     data: [],
      //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };
  return <Bar options={options} data={data} />;
};
