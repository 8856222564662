import React, { useEffect, useState } from "react";
import { get_Payment_List } from "../../../Utils/ApiManager";
import { useSelector } from "react-redux";
import Loader from "../../../Common/Spinner";
import "./Payments.css";

export default function Payments() {
  const { SubscriptionList } = useSelector((state) => state);
  const [paymentEvents, setPaymentEvents] = useState([]);
  const [invoiceDetails, setinvoiceDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPaymentsEvents, setIsLoadingPaymentsEvents] = useState(false);
  useEffect(() => {
    let obj = {};
    obj.customer = SubscriptionList[0].customer;
    getPaymentList(obj);
  }, []);
  const getPaymentList = (obj) => {
    setIsLoading(true);
    get_Payment_List(obj).then((res) => {
      setIsLoading(false);
      if (res && res.data) {
        setPaymentEvents(res.data.AllCustomerPayment);
        setinvoiceDetails(res.data.AllCustomerPayment);
      }
    });
  };
  const timeStampIntoDate = (createdTimeStamp) => {
    let date = new Date(createdTimeStamp * 1000);

    return date.toLocaleString();
  };

  return (
    <>
      <>
        <div className="settings-header">
          <h5> Payments</h5>
        </div>
        <div
          className="content-wrapper"
          style={{
            height: "calc(100vh - 16rem)",
            //   height: "54vh",
            //   overflowX: "hidden",
            backgroundColor: "#fff",
          }}
        >
          {isLoading ? (
            <Loader large={true} />
          ) : (
            <>
              <div className="page-content container">
                <div className="container px-0">
                  <div className="row mt-4">
                    <div className="col-12 col-lg-12">
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <table
                    id="example"
                    className="display expandable-table dataTable no-footer"
                    style={{ width: "100%" }}
                    role="grid"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="select-checkbox sorting_disabled"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Quote#"
                          style={{ width: "246px" }}
                        >
                          Amount
                        </th>
                        <th
                          className=""
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Quote#"
                          aria-sort="ascending"
                          style={{ width: "186px" }}
                        >
                          Name
                        </th>
                        <th
                          className=""
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Quote#"
                          aria-sort="ascending"
                          style={{ width: "186px" }}
                        >
                          Status
                        </th>
                        <th
                          className=""
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          style={{ width: "186px" }}
                        >
                          Description
                        </th>

                        <th
                          className=""
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          style={{ width: "176px" }}
                        >
                          CustomerId
                        </th>
                        <th
                          className=""
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          style={{ width: "176px" }}
                        >
                          Date
                        </th>
                        <th
                          className=""
                          tabIndex="0"
                          aria-controls="example"
                          rowSpan="1"
                          colSpan="1"
                          style={{ width: "176px" }}
                        >
                          View Receipt
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoadingPaymentsEvents ? (
                        <>
                          <td>
                            <Loader />{" "}
                          </td>
                        </>
                      ) : (
                        <>
                          {paymentEvents &&
                            paymentEvents.map((innerArray, i) => (
                              <tr className="odd" key={i}>
                                {innerArray.charges.data.map((item, j) => (
                                  <>
                                    <td className=" select-checkbox" key={j}>
                                      ${item.amount / 100}
                                    </td>
                                    <td className=" select-checkbox" key={j}>
                                      {item.billing_details.name}
                                    </td>
                                    <td className=" select-checkbox" key={j}>
                                      <div className="success-box-custom">
                                        <div className="Box-root Flex-flex">
                                          <div className="Box-root Flex-flex">
                                            <div className="Badge Badge--variant--status Box-root Box-background--green100 Flex-inlineFlex Flex-alignItems--center Flex-direction--row">
                                              <span className="Badge-text Text-color--green600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline">
                                                <span>{item.status}</span>
                                              </span>
                                              <div className="Badge-icon--container Box-root Padding-left--4">
                                                <div
                                                  aria-hidden="true"
                                                  className="SVGInline SVGInline--cleaned SVG Icon Icon--check Icon-color Icon-color--green500 Box-root Flex-flex"
                                                >
                                                  <svg
                                                    aria-hidden="true"
                                                    className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--check-svg Icon-color-svg Icon-color--green500-svg"
                                                    height="12"
                                                    width="12"
                                                    viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M5.297 13.213.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z"
                                                      fill-rule="evenodd"
                                                    ></path>
                                                  </svg>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className=" select-checkbox" key={j}>
                                      {item.description}
                                    </td>
                                    <td className=" select-checkbox" key={j}>
                                      {item.billing_details.email}
                                    </td>

                                    <td className=" select-checkbox" key={j}>
                                      {timeStampIntoDate(item.created)}
                                    </td>
                                    <td className=" select-checkbox" key={j}>
                                      <a
                                        href={item.receipt_url}
                                        target="_blank"
                                      >
                                        View Receipt
                                      </a>
                                    </td>
                                  </>
                                ))}
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                  <div className="page-content container">
                    <div className="container px-0">
                      <div className="row mt-4">
                        <div className="col-12 col-lg-12">
                          <hr className="row brc-default-l1 mx-n1 mb-4" />

                          <div className="row">
                            <div className="col-sm-6"></div>

                            <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                              <hr className="d-sm-none" />
                              <div className="text-grey-m2">
                                {/* <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                Total
                              </div> */}

                                {/* <div className="my-2">
                                <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                                {RemainingAmount<0?'Your Remaining Balance  :':'Your Remaining Balance :'}
                               
                              {RemainingAmount<0?'$'+RemainingAmount:'$'+0}
                              </div>

                              <div className="my-2">
                                <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                                <span className="text-600 text-90">Remaining balance will be applied to upcoming invoice,it may change if you perform further upgradation/downgradation in this month</span> 
                               
                              </div> */}

                                {/* <div className="my-2">
                                <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                                <span className="text-600 text-90">
                                  Amount due:
                                </span>{" "}
                                <span className="badge badge-warning badge-pill px-25">
                                  ${invoiceDetails.amount_due / 100}
                                </span>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* <div className="card p-3 scroll" style={{ marginTop: "5px" }}>
          <div>
            <DataGrid
              height={"80vh"}
              columns={Paymentcolumns}
              rows={paymentEvents}
              //  rowClass={(row) => (row.packageName.includes('GOLD') ? highlightClassname : undefined)}
            />
          </div>
        </div> */}

          {/* **********total bill **************** */}
        </div>
      </>
    </>
  );
}
