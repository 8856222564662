import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import capitalizeFirst from "../../../../Common/CaptilizeFirstLetter";
import DefaultAudio from "../../../../Common/DefaultAudio";
import SignalStrengthIndicator from "../../../../Common/SignalStrengthIndicator/signalStrengthIndicator";

import { SocketContext } from "../../../../context/socket";

const Participant = ({
  screenShareButtonToggle,
  isShowCallActionsButton,
  setIsReconnectedPart,
  setProviderRoomFN,
  isReconnectedPart,
  isReconnectingParticpant,
  getReconnectedParticpant,
  getReconnectingParticipant,
  ForThreePartipants,
  toggleSide,
  localParticipant,
  participant,
  isVoiceMute,
  isAudio,
  isScreenShared,
  isScreenSharedWithRoom,
  isVideoDisable,
  localScreen,
  toggleScreenShareButton,
}) => {
  const {
    PatientInfo,
    IsProviderSystmCamEnable,
    LoginObject,
    IsPatSystmCamEnable,
    PatientDataDetails,
    isProviderCameFromEhrProv,
    isPatiCameFromEhrorTab,
    AllPatientsAccepted,
    StripePackagePurchaseDetailObject,
  } = useSelector((state) => state);
  const state = useSelector((state) => state);
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [isVideoEnabaled, setIsVideoEnabaled] = useState(true);
  const [isAudioEnabaled, setIsAudioEnabaled] = useState(true);
  const [effectState, setEffectState] = useState(false);
  const [isReconnectingScreenDisplay, setIsReconnectingScreenDisplay] =
    useState(false);
  const [levelProviderSideLocalParticpant, setLevelLocalPartipcant] =
    useState(0);
  const [isScrenShareOnAudio, setIsScreenShareOnAudio] = useState(false);
  const [isScrenShareOnAudioOn, setIsScreenShareOnAudioOn] = useState(false);
  const [levelRemoteParticpant, setRemotePartipcant] = useState(0);

  const socket = useContext(SocketContext);
  const videoRef = useRef();
  const ScreenRef = useRef();
  const photoRef = useRef();

  const audioRef = useRef();
  let dispearAudio;
  let isAudioScreenOn;
  //--------------------For Reconnecting --------------------------

  useEffect(() => {
    socket.on("enable-reconnecting-on-other-side", (data) => {
      if (
        LoginObject.username &&
        data.reconnectingMark === LoginObject.fullname
      ) {
        setIsReconnectingScreenDisplay(true);
      } else if (
        !LoginObject.username &&
        data.reconnectingMark === PatientDataDetails.joiningUser
      ) {
        setIsReconnectingScreenDisplay(true);
      }
    });
  }, []);
  useEffect(() => {
    if (videoTracks[0]) setIsVideoEnabaled(videoTracks[0]?.isEnabled);
  }, [videoTracks, videoTracks[0]?.isEnabled, participant]);

  useEffect(() => {
    if (audioTracks[0]) setIsAudioEnabaled(audioTracks[0]?.isEnabled);
  }, [audioTracks, audioTracks[0]?.isEnabled, participant]);

  useEffect(() => {
    if (isReconnectingParticpant === undefined) {
      return;
    }

    if (isReconnectingParticpant !== null) {
      if (!LoginObject.username) {
        toast.info(`Reconnecting ${isReconnectingParticpant.identity}`);
        let obj = {};

        obj.providerId = PatientDataDetails.providerId;
        obj.patId = PatientDataDetails.patId;
        obj.reconnectingMark = PatientDataDetails.ProviderFullName;
        obj.connectionLossPartipant = isReconnectingParticpant.identity;

        socket.emit("enable-reconnecting-on-other-side", obj);
        setIsReconnectingScreenDisplay(true);
        setIsReconnectedPart(null);
      }
      if (
        LoginObject.username &&
        LoginObject.fullname == isReconnectingParticpant.identity
      ) {
        toast.info(`Reconnecting ${isReconnectingParticpant.identity} `);
        let obj = {};
        AllPatientsAccepted &&
          AllPatientsAccepted.map((el) => {
            obj.patId = el.id;
            obj.reconnectingMark = el.lname + el.fname;
          });

        obj.providerId = LoginObject.id;
        obj.connectionLossPartipant = isReconnectingParticpant.identity;

        socket.emit("enable-reconnecting-on-other-side", obj);
        setIsReconnectingScreenDisplay(true);
        setIsReconnectedPart(null);
      }
    }
  }, [isReconnectingParticpant]);
  //---------------------------For Reconected ----------------------------------
  useEffect(() => {
    socket.on("enable-reconnected-on-other-side", (data) => {
      console.log("");
      if (
        LoginObject.username &&
        data.reconnectingMark === LoginObject.fullname
      ) {
        setIsReconnectingScreenDisplay(false);
      } else if (
        !LoginObject.username &&
        data.reconnectingMark === PatientDataDetails.joiningUser
      ) {
        setIsReconnectingScreenDisplay(false);
      }
    });
  }, []);
  useEffect(() => {
    if (isReconnectingParticpant === undefined) {
      return;
    }
    if (isReconnectedPart !== null) {
      if (!LoginObject.username) {
        toast.info(
          `Reconnected ${isReconnectedPart.localParticipant.identity}`
        );
        let obj = {};

        obj.providerId = PatientDataDetails.providerId;
        obj.patId = PatientDataDetails.patId;
        obj.reconnectingMark = PatientDataDetails.ProviderFullName;
        obj.connectionLossPartipant =
          isReconnectedPart.localParticipant.identity;

        socket.emit("enable-reconnected-on-other-side", obj);
        setIsReconnectingScreenDisplay(false);
        setProviderRoomFN(isReconnectedPart);
      }
      if (
        LoginObject.username &&
        LoginObject.fullname == isReconnectedPart.localParticipant.identity
      ) {
        toast.info(
          `Reconnected ${isReconnectedPart.localParticipant.identity} `
        );
        let obj = {};
        AllPatientsAccepted &&
          AllPatientsAccepted.map((el) => {
            obj.patId = el.id;
            obj.reconnectingMark = el.lname + el.fname;
          });

        obj.providerId = LoginObject.id;
        obj.connectionLossPartipant =
          isReconnectedPart.localParticipant.identity;

        socket.emit("enable-reconnected-on-other-side", obj);
        setIsReconnectingScreenDisplay(false);
        setProviderRoomFN(isReconnectedPart);
      }
    }
  }, [isReconnectedPart]);

  //--------------------End---------------------------------------------------------

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    if (participant && participant.videoTracks) {
      setVideoTracks(trackpubsToTracks(participant.videoTracks));
      setAudioTracks(trackpubsToTracks(participant.audioTracks));
    }

    const trackSubscribed = (track) => {
      track.on("disabled", () => updateDisabledTrackUI(track));
      track.on("enabled", () => updateEnabledTrackUI(track));

      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };
    const updateDisabledTrackUI = (track) => {
      if (track.kind === "audio") {
        setIsAudioEnabaled(track.isEnabled);

        return;
      }
      if (track.kind === "video") {
        setIsVideoEnabaled(track.isEnabled);
        return;
      }
    };
    const updateEnabledTrackUI = (track) => {
      if (track.kind === "audio") {
        setIsAudioEnabaled(track.isEnabled);

        return;
      }
      if (track.kind === "video") {
        setIsVideoEnabaled(track.isEnabled);
        return;
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };
    // const handleReconnecting = (participant) => {
    //   console.log(participant, "Reconnecting participant");
    //   getReconnectingParticipant(participant);
    // };

    const printNetworkQualityStats = () => {
      //------------ For Provider -------------

      let isRemote = participant.identity;
      //----------checking provider or patient -----
      let isRemoteTrue = LoginObject.username
        ? AllPatientsAccepted &&
          AllPatientsAccepted.filter((el) => el.fname + el.lname === isRemote)
            .length > 0
        : PatientDataDetails.ProviderFullName == isRemote;

      if (isRemoteTrue) {
        setRemotePartipcant(participant.networkQualityLevel);
      } else {
        setLevelLocalPartipcant(participant.networkQualityLevel);
      }
    };

    const handleReconnected = (participant) => {
      getReconnectedParticpant(participant);
      console.log(participant, "Reconnected participant");
    };

    participant && participant.on("trackSubscribed", trackSubscribed);
    participant && participant.on("trackUnsubscribed", trackUnsubscribed);
    // participant && participant.on("reconnecting", handleReconnecting);
    participant && participant.on("reconnected", handleReconnected);

    participant &&
      participant.on("networkQualityLevelChanged", printNetworkQualityStats);
    participant &&
      participant.on("disconnected", (room, error) => {
        console.log("room Disconenction", room);
        if (error && error?.code === 20104) {
          console.log(
            "Signaling reconnection failed due to expired AccessToken!"
          );
        } else if (error && error?.code === 53000) {
          console.log("Signaling reconnection attempts exhausted!");
        } else if (error && error?.code === 53002) {
          console.log("Signaling reconnection took too long!");
        }
      });

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      //participant.off("reconnecting", handleReconnecting);
      participant.on("networkQualityLevelChanged", printNetworkQualityStats);
      participant.off("reconnected", handleReconnected);
      // participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    if (participant && participant.videoTracks.size > 1 && localScreen) {
      setVideoTracks(trackpubsToTracks(participant.videoTracks));
      setAudioTracks(trackpubsToTracks(participant.audioTracks));
    }
  }, [participant?.videoTracks.size]);
  useEffect(() => {
    if (participant && participant.videoTracks.size == 1 && localScreen) {
      const tracks = Array.from(participant.videoTracks.values()).map(
        (publication) => publication.track
      );
      if (tracks[0]?.name == "screen") {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setIsScreenShareOnAudio(true);
      }
    } else {
      setIsScreenShareOnAudio(false);
    }
  }, [participant?.videoTracks.size]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    let screenShare;

    if (videoTrack) {
      if (videoTracks && videoTracks.length > 1) {
        screenShare = videoTracks[1];
        screenShare.attach(ScreenRef.current);
        if (!localScreen && toggleScreenShareButton) {
          toggleScreenShareButton(true);
        }
      } else if (
        // For screen share on Audio
        videoTracks &&
        videoTracks.length == 1 &&
        videoTracks[0]?.name == "screen" &&
        !localScreen
      ) {
        screenShare = videoTracks[0];
        screenShare.attach(ScreenRef.current);
        setIsScreenShareOnAudioOn(true);
        if (!localScreen && toggleScreenShareButton) {
          toggleScreenShareButton(true);
        }
      } else {
        if (!localScreen && toggleScreenShareButton) {
          toggleScreenShareButton(false);
        }
      }
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
        if (videoTracks && videoTracks.length > 1) {
          screenShare.detach();
        }
        if (
          videoTracks &&
          videoTracks.length == 1 &&
          videoTracks[0]?.name == "screen" &&
          !localScreen
        ) {
          screenShare.detach();
        }
      };
    } else {
      if (!localScreen && toggleScreenShareButton) {
        toggleScreenShareButton(false);
      }
    }
  }, [videoTracks, isReconnectingScreenDisplay]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  // useEffect(() => {
  //   if (audioTracks[0]) setIsAudioEnabaled(audioTracks[0]?.isEnabled);
  // }, [audioTracks, audioTracks[0]?.isEnabled, participant]);

  let provider = LoginObject && LoginObject.username;
  let fullNamePat = null;
  let fullname = null;
  if (LoginObject && LoginObject.fullname) {
    fullname = LoginObject && LoginObject.fullname.split(" ");
  } else {
    fullNamePat = PatientDataDetails && PatientDataDetails.joiningUser;
  }

  // *****************************For Proiveder Check***************
  let providerAudioCall = null;

  if (provider && IsProviderSystmCamEnable) {
    providerAudioCall = false;
  } else {
    providerAudioCall = true;
  }
  //*********************************For Pat Check******************** */
  let patAudioCall = null;

  if (!provider && IsPatSystmCamEnable) {
    patAudioCall = false;
  } else {
    patAudioCall = true;
  }

  let tabCheck = false;
  if (
    (isPatiCameFromEhrorTab && !LoginObject.username && !localScreen) ||
    (isProviderCameFromEhrProv && LoginObject.username && !localScreen)
  ) {
    tabCheck = true;
  }

  const tracks = Array.from(participant.videoTracks.values()).map(
    (publication) => publication.track
  );

  let isAudioScreenShare = tracks[0]?.name == "screen" && !localScreen;
  isAudioScreenOn = videoTracks[0]?.name == "screen" && localScreen;

  //For Patient side disapper default section
  // RENDER RENDER localScreen FALSE AUDIOsHARE TRUE
  let roomName = PatientDataDetails?.ProviderFullName;
  let name =
    state?.PatientDataDetails?.fname + state?.PatientDataDetails?.lname;
  let fullName = participant.identity == roomName;
  let fullNamePro = participant.identity == name;
  // For Provider Side disappear check

  if (name) {
    dispearAudio = isAudioScreenShare && fullNamePro;
  } else if (roomName) {
    dispearAudio = isAudioScreenShare && fullName;
  }

  // Array From tracks if zero index have kind screen then video Element
  console.log(
    "roomName",
    roomName,
    "name",
    name,
    "participant.identity",
    participant.identity,
    "localScreen",
    localScreen,
    "isAudioScreenOn",
    isAudioScreenOn,
    "isAudioScreenShare",
    isAudioScreenShare,
    screenShareButtonToggle
  );
  console.log("providerAudioCall", providerAudioCall);
  console.log("patAudioCall", patAudioCall);

  const pkg = StripePackagePurchaseDetailObject?.packageName;

  return (
    <>
      {/* For screen share on Audio */}
      {isAudioScreenShare && !localScreen ? (
        <>
          <video
            id={tabCheck ? "screenShareElement Adjusted" : "screenShareElement"}
            style={
              localScreen
                ? {
                    width: "100%",
                    height: "99%",
                    borderRadius: "7px",
                    position: "absolute",
                    marginTop: "95px",
                  }
                : {
                    width: "100%",
                    height: "99%",
                    borderRadius: "7px",
                  }
            }
            ref={ScreenRef}
            autoPlay={false}
          />
        </>
      ) : null}
      {isAudioScreenOn && !screenShareButtonToggle ? (
        <>
          <video
            className={
              tabCheck
                ? "screeen-shared-by-user-video Adjusted"
                : "screeen-shared-by-user-video"
            }
            id={"videoElement"}
            style={{
              top: "92%",
              transform: "rotate(360deg)",
              width: "100%",
              height: "99%",
              borderRadius: "7px",
            }}
            ref={videoRef}
            autoPlay={false}
          />
        </>
      ) : null}
      {/* // End */}
      {/* is Show call action button true  means its audio and call actiontray on another comp */}
      {!isShowCallActionsButton ? (
        <>
          {videoTracks && videoTracks.length > 1 && !isAudioScreenOn && (
            // For video screen with share by user
            <>
              <video
                className={
                  tabCheck
                    ? "screeen-shared-by-user-video Adjusted"
                    : "screeen-shared-by-user-video"
                }
                id={"videoElement"}
                style={{
                  width: "100%",
                  height: "99%",
                  borderRadius: "7px",
                }}
                ref={videoRef}
                autoPlay={false}
              />
              {/* // For video icon */}
              {!isVideoEnabaled && (
                <>
                  <i
                    className="fas fa-video-slash text-white"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      zIndex: "9",
                      top: "40%",
                      width: "100%",
                    }}
                  ></i>
                </>
              )}
              {/* // for audio icon */}
              {!isAudioEnabaled && (
                <>
                  <i
                    className="fas fa-microphone-slash text-white"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      zIndex: "9",
                      top: "40%",
                      width: "100%",
                    }}
                  ></i>
                </>
              )}
            </>
          )}
          {participant && participant.videoTracks.size > 1 ? (
            // For screen share capture large display
            <>
              <video
                id={
                  tabCheck
                    ? "screenShareElement Adjusted"
                    : "screenShareElement"
                }
                style={
                  localScreen
                    ? {
                        width: "100%",
                        height: "99%",
                        borderRadius: "7px",
                        position: "absolute",
                        marginTop: "95px",
                      }
                    : {
                        width: "100%",
                        height: "99%",
                        borderRadius: "7px",
                      }
                }
                ref={ScreenRef}
                autoPlay={false}
              />
            </>
          ) : // is ProviderAudioCall false Means if this is not audio
          !providerAudioCall ? (
            <>
              <div className="disabled-icons">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    position: "absolute",
                    zIndex: "9",
                    bottom: "5px",
                    width: "100%",
                    right: "5px",
                  }}
                >
                  {/* // Disabling signal strength indicator */}
                  {pkg === "silver" || pkg === "metal" ? null : (
                    <>
                      {" "}
                      <SignalStrengthIndicator levels={levelRemoteParticpant} />
                      <SignalStrengthIndicator
                        levels={levelProviderSideLocalParticpant}
                      />
                    </>
                  )}
                </div>

                {!isVideoEnabaled && (
                  <>
                    <i
                      className="fas fa-video-slash text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: "9",
                        top: "40%",
                        width: "100%",
                      }}
                    ></i>
                  </>
                )}
                {!isAudioEnabaled && (
                  <>
                    <i
                      className="fas fa-microphone-slash text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: "9",
                        top: "40%",
                        width: "100%",
                      }}
                    ></i>
                  </>
                )}
              </div>
              {isReconnectingScreenDisplay ? (
                <DefaultAudio circle={false} text={"Reconnecting"} />
              ) : (
                <>
                  <video
                    id={"videoElement"}
                    style={{
                      width: "100%",
                      height: "99%",
                      borderRadius: "7px",
                      zIndex: 1,
                    }}
                    ref={videoRef}
                    autoPlay={false}
                  />
                </>
              )}
            </>
          ) : !patAudioCall ? (
            <>
              <div className="disabled-icons">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    position: "absolute",
                    zIndex: "9",
                    bottom: "5px",
                    width: "100%",
                    right: "5px",
                  }}
                >
                  {pkg === "silver" || pkg === "metal" ? null : (
                    <>
                      <SignalStrengthIndicator levels={levelRemoteParticpant} />
                      <SignalStrengthIndicator
                        levels={levelProviderSideLocalParticpant}
                      />
                    </>
                  )}
                </div>
                {!isVideoEnabaled && (
                  <>
                    <i
                      className="fas fa-video-slash text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: "9",
                        top: "40%",
                        width: "100%",
                      }}
                    ></i>
                  </>
                )}
                {!isAudioEnabaled && (
                  <>
                    <i
                      className="fas fa-microphone-slash text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: "9",
                        top: "40%",
                        width: "100%",
                      }}
                    ></i>
                  </>
                )}
              </div>
              {isReconnectingScreenDisplay ? (
                <DefaultAudio circle={false} text={"Reconnecting"} />
              ) : (
                <video
                  id={"videoElement"}
                  style={{
                    width: "100%",
                    height: "99%",
                    borderRadius: "7px",
                  }}
                  ref={videoRef}
                  autoPlay={false}
                />
              )}
            </>
          ) : (
            <>
              {dispearAudio ? null : (
                <DefaultAudio
                  circle={true}
                  AudioName={
                    provider
                      ? capitalizeFirst(participant.identity[0]) +
                        capitalizeFirst(participant.identity[1])
                      : capitalizeFirst(participant.identity[0]) +
                        capitalizeFirst(participant.identity[1])
                  }
                />
              )}
            </>
          )}
        </>
      ) : (
        <audio ref={audioRef} autoPlay={true} muted={isVoiceMute} />
      )}
      {}
      <audio ref={audioRef} autoPlay={true} muted={isVoiceMute} />
    </>
  );
};

export default Participant;
