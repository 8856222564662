export function minMaxLength(text, minLength, maxLength) {
  let result = !text || text.length < minLength;
  if (maxLength) result = result || text.length < minLength;
  return result;
}

export function checkDate(theDate) {
  let Invalid = null;
  if (theDate.trim() === "" || isNaN(new Date(theDate).getTime())) {
    Invalid = true;
  } else {
    Invalid = false;
  }
  return Invalid;
}
export function EmptyCheck(value) {
  let emptyCheck = null;

  if (value == "" || value == null) {
    emptyCheck = true;
  } else {
    emptyCheck = false;
  }
  return emptyCheck;
}

export function ValidateEmail (input){
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {
    return false;
  } else {
    return true;
  }
};
