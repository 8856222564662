import React, { useEffect } from "react";
import "./App.css";
import MainRoutes from "./Components/Routes/MainRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { socket, SocketContext } from "./context/socket";
import { MySystemInfo } from "./Actions/Actions";
const { detect } = require("detect-browser");

const App = () => {
  const state = useSelector((state) => state);
  console.log("resdux", state);
  console.log("redux", state);
  let dispatch = useDispatch();

  useEffect(() => {
    let mySysInfo = detectBrowser();
    dispatch(MySystemInfo(mySysInfo));
  }, []);

  const detectBrowser = () => {
    const browser = detect();
    // handle the case where we don't detect the browser
    let sysInfo = null;
    switch (browser && browser.name) {
      case "chrome":
        console.log("supported", browser.name);
        return (sysInfo = browser);
      case "firefox":
        console.log("supported", browser.name);
        return (sysInfo = browser);
      case "edge":
        console.log("supported", browser.name);
        return (sysInfo = browser);
      default:
        return (sysInfo = browser);
    }
  };

  return (
    <>
      <SocketContext.Provider value={socket}>
        <MainRoutes />
      </SocketContext.Provider>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
