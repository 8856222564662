import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeItemFromNotification } from "../../../Actions/Actions";

export default function Notifcation({ notification }) {
  let dispatch = useDispatch();
  const { NotifyForCardExpires } = useSelector((state) => state);

  const clearOnClickNotification = (selectedIndex) => {
    NotifyForCardExpires &&
      NotifyForCardExpires.map((el, index) => {
        if (index == selectedIndex) {
          dispatch(removeItemFromNotification(selectedIndex));
        }
      });
  };

  return (
    <div
      className={
        notification
          ? "dropdown-menu dropdown-menu-right navbar-dropdown preview-list "
          : " dropdown-menu dropdown-menu-right navbar-dropdown preview-list  show"
      }
      aria-labelledby="notificationDropdown"
    >
      <p className="mb-0 font-weight-normal float-left dropdown-header">
        Notifications
      </p>
      <div
        className="list-notifiactions"
        style={{
          maxHeight: "60vh",
          width: "100%",
          overflow: "auto",
        }}
      >
        {NotifyForCardExpires &&
          NotifyForCardExpires.map((el, index) => {
            return (
              <>
                {/* <a className="dropdown-item preview-item" key={index}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="fas fa-info-circle mx-0"></i>
                    </div>
                  </div>

                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      {el.msg}
                    </h6>

                    <p className="font-weight-light small-text mb-0 text-muted">
                      Just now
                    </p>
                  </div>
                  <div>
                    <i
                      className="far fa-trash-alt p-2 m-2 btn-danger text-white rounded "
                      onClick={() => clearOnClickNotification(index)}
                    ></i>
                  </div>
                </a> */}
                <a className="dropdown-item preview-item" key={index}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="fas fa-info-circle mx-0"></i>
                    </div>
                  </div>
                  <i
                    className="far fa-trash-alt p-2 m-2 btn-danger text-white rounded"
                    onClick={() => clearOnClickNotification(index)}
                  ></i>

                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      {el.msg}
                    </h6>

                    <p className="font-weight-light small-text mb-0 text-muted">
                      Just now
                    </p>
                  </div>

                  <div className="d-flex">
                    <div className="ml-auto"></div>
                  </div>
                </a>
              </>
            );
          })}
      </div>
      {/* {NotifyForCardExpires.NotificationForCardExpire?
               :null} */}

      <a className="dropdown-item preview-item">
        {/* <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="fas fa-wrench mx-0"></i>
                    </div>
                  </div> */}
        {/* <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      Settings
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      Private message
                    </p>
                  </div> */}
      </a>
      {/* <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="fas fa-user mx-0"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      New user registration
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      2 days ago
                    </p>
                  </div>
                </a> */}
    </div>
  );
}
