import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import moment from "moment";

export const checkIfRoomExistInDB = async (roomName) => {
  let baseUrl = process.env.REACT_APP_APIS;

  let path = baseUrl + `check-if-room-exist=1&roomid=${roomName}`;
  try {
    let res = await axios.get(path);
    if (res && res.data === "User room exist") {
      return roomName;
    } else {
      toast.warning("Make sure you enter correct room name");
      return;
    }
  } catch (Err) {}
};

export const swalConfirmationforAccept = async ({
  text,
  title,
  icon,
  dangerMode,
  buttons,
  confirmButtonColor,
}) => {
  const willDelete = await swal({
    title: title ? title : "Are you sure?",
    text: text ? text : "Are you sure that you want to delete this User?",
    icon: icon ? icon : "warning",
    // dangerMode: dangerMode ? dangerMode : false,
    buttons: buttons ? buttons : ["No ", "Accept"],
  });
  return willDelete;
};

export const swalConfirmation = async ({
  text,
  title,
  icon,
  dangerMode,
  buttons,
  confirmButtonColor,
}) => {
  const willDelete = await swal({
    title: title ? title : "Are you sure?",
    text: text ? text : "Are you sure that you want to delete this User?",
    icon: icon ? icon : "warning",
    dangerMode: dangerMode ? dangerMode : true,
    buttons: buttons ? buttons : ["No ", "Accept"],
  });
  return willDelete;
};
export const swalSuccessBox = (head, body, status) => {
  swal(
    head ? head : "Deleted !",
    body ? body : "User has been deleted!",
    status ? status : "User has been deleted!"
  );
};

export const nodeMaileSendMail = async (patientEmail, Subject, body) => {
  let formData = new FormData();
  formData.append("mailsentto", patientEmail);
  formData.append("subject", Subject);
  formData.append("msg", body);
  formData.append("mailpass", "ug40jdxoflbqm");
  try {
    let res = await axios
      .post(process.env.REACT_APP_APIS, formData)
      .then((response) => {
        if (response && response.data) {
          toast.info(response.data);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong please try later");
      });
  } catch (error) {
    if (error.response) {
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      console.error(error.request);
    } else {
      console.error("Error", error.message);
    }
  }
};

const daysInMonth = (m, y) => {
  return m === 2
    ? y & 3 || (!(y % 25) && y & 15)
      ? 28
      : 29
    : 30 + ((m + (m >> 3)) & 1);
};

export const getTomorrowDate = async () => {
  const tomorrow = new Date();
  // add 1 day to today
  tomorrow.setDate(new Date().getDate() + 1);

  const defaultValue = new Date(tomorrow).toISOString().split("T")[0]; //
  let res = await daysInMonth(
    moment(defaultValue).format("MM"),
    moment(defaultValue).format("YY")
  );
  const StartDate = new Date();
  StartDate.setDate(new Date().getDate() - res);
  const startDefaultValue = new Date(StartDate).toISOString().split("T")[0];

  let obj = {};
  obj.startDefaultValue = startDefaultValue;
  obj.defaultValue = defaultValue;
  return obj;
};
const getAllDatesBetweenStartDateAndEndDate = (startDate, endDate) => {
  let dates = [];
  let startdate = new Date(startDate);
  const enddate = new Date(endDate);

  while (startdate <= enddate) {
    dates.push(new Date(startdate));
    startdate.setDate(startdate.getDate() + 1);
  }

  return dates;
};

export const searchDataBetweenDates = async (startDate, endDate) => {
  let res = await getAllDatesBetweenStartDateAndEndDate(startDate, endDate);

  let onlyDate = res.map((date) => {
    return moment(date).format("MM-DD-YYYY");
  });
  return onlyDate;
};

export const dateCheck = (from, to, check) => {
  let dates = [];
  const startdate = new Date(from);
  const enddate = new Date(to);

  while (startdate <= enddate) {
    dates.push(new Date(startdate));
    startdate.setDate(startdate.getDate() + 1);
  }
  return dates;
};

export const FilterArray = (refresh, resdate) => {
  let result =
    refresh &&
    refresh.filter((el) =>
      resdate.some((el2) => {
        if (new Date(el.create_time).toDateString() === el2.toDateString()) {
          return el;
        }
      })
    );

  let result2 =
    result &&
    result.map((el) => {
      return new Date(el?.create_time);
    });
  let dupes =
    result2 &&
    result2.reduce((a, c) => {
      let d = c.toDateString();
      a[d] = a[d] ? a[d] + 1 : 1;

      return a;
    }, {});
  let obj = {};
  obj.dupes = dupes;
  obj.result = result;
  return obj;
};

export const FilterArray2 = (filterDatesLabelForBarGraph, dupes) => {
  let keyVaue =
    filterDatesLabelForBarGraph &&
    filterDatesLabelForBarGraph.map((el) => {
      return { LabelKey: el };
    });
  var r = Object.keys(dupes ? dupes : {}).map((e) => ({
    label: moment(e).format("MM-DD-YYYY"),
    value: dupes[e],
  }));
  let map = {};

  keyVaue && keyVaue.forEach((item) => (map[item.LabelKey] = item));
  r.forEach((item) => (map[item.label] = item));
  const resultff = Object.values(map);

  let pushInDataSetArray = [];
  resultff.map((a) => {
    if (a.value !== undefined || null) {
      pushInDataSetArray.push(a.value);
    } else {
      pushInDataSetArray.push(0);
    }
  });
  const sum = pushInDataSetArray.reduce((partialSum, a) => partialSum + a, 0);
  let obj = {};
  obj.keyVaue = keyVaue;
  obj.pushInDataSetArray = pushInDataSetArray;
  obj.sum = sum;
  obj.days = pushInDataSetArray.length;
  return obj;
};
export const formmattedDateIntoDDMMYYY = () => {
  let a = Date.now();
  let z = new Date(a);
  let yyyy = z.getFullYear();
  let mm = z.getMonth() + 1;
  let dd = z.getDate();
  return dd + "/" + mm + "/" + yyyy;
};
let globalVariable = false;
function setGlobalVariable(value) {
  value.forEach(function (device) {
    if (device.kind === "videoinput" && device.deviceId != "") {
      globalVariable = true;
    }
  });
}

export const isSystemCameraAvaialble = async (SystemInfo) => {
  if (SystemInfo.name == "chrome") {
    let devices = await navigator.mediaDevices.enumerateDevices();
    let cameraFound = false;
    devices.forEach(function (device) {
      if (device.kind === "videoinput" && device.deviceId != "") {
        cameraFound = true;
      }
    });
    return cameraFound;
  } else {
    let devices = await navigator.mediaDevices.getUserMedia();
    let cameraFound = false;
    devices.forEach(function (device) {
      if (device.kind === "videoinput" && device.deviceId != "") {
        cameraFound = true;
      }
    });
    return cameraFound;
  }
};
export const convertTo12HourFormat = (datewithTime) => {
  if (datewithTime) {
    let res = datewithTime.split(" ");
    let time = res[1];
    let hours = parseInt(time.substr(0, 2));
    let minutes = time.substr(3, 2);
    let seconds = time.substr(6, 2);
    let suffix = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes}:${seconds} ${suffix}`;
  }
};

function convertTZ(date) {
  console.log("next Date", date);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  console.log(timezone);
  return (typeof date === "string" ? new Date(date) : date).toLocaleString(
    "en-US",
    {
      timeZone: timezone,
    }
  );
}

export const TimeZoneConversion = (newDate) => {
  let CurrentDate = convertTZ(newDate);
  console.log("Final CurrentDate", CurrentDate);
  if (CurrentDate) {
    return CurrentDate;
  }

  // let onlyDate = CurrentDate.split("T")[0];
  // let onlyTime = CurrentDate.split("T")[1];
};
export const sortPatientArr = (arr) => {
  const sortedArr = [...arr].sort(function (a, b) {
    return new Date(b.datetime) - new Date(a.datetime);
  });
  return sortedArr;
};
export const makeShortString = (text, count) => {
  return text && text.slice(0, count) + (text.length > count ? "..." : "");
};
export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
};

export const secondsToTime = (secs) => {
  const totalMs = secs * 1000;
  const result = new Date(totalMs).toISOString().slice(11, 19);

  return result;
};
