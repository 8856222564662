import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Layout from "./../../Components/layout/Layout";

import Dashboard from "../Dashboard/Dashboard";
import History from "../History/History";
import PageNotFound from "../PageNotFound/PageNotFound";
import RoomNew from "../Room";
import Setting from "../Settings/Setting";
import Upgrade from "../Upgrade/index";
import Login from "../Login/Login";
import PublicView from "../PublicView";
import PatientCall from "../PatientCall";
import PublicLayout from "../Publiclayout";
import PatientJoin from "../PublicView/PatientJoin";

import { useSelector, useDispatch } from "react-redux";

import {
  getPatientList,
  LoginUser,
  LoginUserFromEhr,
  removeParticpant,
  updateProviderStatus,
} from "../../Utils/ApiManager";
import {
  AllAcceptedPatients,
  AllPatientList,
  getEhrDimensions,
  IsUrlCameFromEhr,
  NavigateDasboardIssue,
  RoomForPatient,
  AllPendingStatus,
  EndCallWhenPatSwitchTab,
  GlobalRoomEnd,
} from "../../Actions/Actions";
import { reactLocalStorage } from "reactjs-localstorage";
import { SocketContext } from "../../context/socket";
import store from "../../Store/Store";
import Recordings from "../Recordings";

const MainRoutes = () => {
  const location = useLocation();
  let dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const {
    LoginObject,
    RoomInfo,
    AllPatientsAccepted,
    globalRoomEnds,
    PatientDataDetails,
  } = useSelector((state) => state);

  const [patientList, setPatientList] = useState(false);
  const [acceptedPatient, setAcceptedPatientlist] = useState(false);
  const [stripeLatestObject, setStripeLatestObject] = useState();
  const [SuccessUrlState, setSuccessUrlState] = useState(null);
  const [openDirectPatientJoin, setOpenDirectPatientJoin] = useState(null);
  const [callEndWhenProviderSwitchesTab, setCallEndWhenProviderSwitchesTab] =
    useState(false);
  const [isGlobalRoomCall, setIsGloablaRoomCall] = useState(false);
  const [isShowCallActionsButton, setIsShowCallActionsButton] = useState(false);
  const [instantEffectOnRoom, setInstantEffectOnRoom] = useState(false);

  useEffect(() => {
    dispatch(NavigateDasboardIssue(false));
  }, []);

  useEffect(() => {
    if (globalRoomEnds) {
      setIsGloablaRoomCall(false);
      setIsShowCallActionsButton(false);
      dispatch(GlobalRoomEnd(false));
    }
  }, [globalRoomEnds]);

  let PatientsInCall = store && store.getState()?.AllPatientsAccepted;
  useEffect(() => {
    if (
      location.pathname !== "/room" &&
      LoginObject.username &&
      isGlobalRoomCall
    ) {
      setIsShowCallActionsButton(true);
      setInstantEffectOnRoom(null);
    }
    if (location.pathname === "/room" && LoginObject.username) {
      setIsShowCallActionsButton(false);
    }
    console.log("Route changed to: ", location.pathname);
  }, [location]);

  // useEffect(() => {
  //   if (LoginObject.username) {
  //     window.addEventListener("beforeunload", updateProviderStatusOnRefresh);
  //     // window.addEventListener("unload", ()=>handleTabClosing());
  //     return () => {
  //       window.removeEventListener(
  //         "beforeunload",
  //         updateProviderStatusOnRefresh
  //       );
  //       // window.removeEventListener("unload", ()=>handleTabClosing());
  //     };
  //   }
  // }, [LoginObject]);

  useEffect(() => {
    window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("unload", handleTabClosing);
    };
  });

  const handleTabClosing = () => {
    updateProviderStatusOnRefresh();
  };

  const [ehrWidth, setEhrWidth] = useState(0);
  const [ehrHeight, setHeight] = useState(0);

  useEffect(() => {
    if (LoginObject.username && ehrWidth === 500 && ehrHeight === 500) {
      let obj = {};
      obj.ehrWidth = ehrWidth;
      obj.ehrHeight = ehrHeight;
      navigate("/room");
      dispatch(getEhrDimensions(obj));
    }
  }, [ehrWidth, ehrHeight]);
  useEffect(() => {
    const updateDimensions = () => {
      setEhrWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateDimensions);
    updateDimensions();
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  let visitingPath = window.location.href;
  let lastPart = null;

  useLayoutEffect(() => {
    // check for url where it comes from EHR
    if (visitingPath.includes("/?")) {
      lastPart = visitingPath.split("/?").pop();
      lastPart = lastPart.split("&&");
      let username = lastPart[0];
      let password = lastPart[1];
      if (username && password) {
        dispatch(IsUrlCameFromEhr(visitingPath));
        navigate("/login");
      }
    } else {
      // check for patient if url includes 'appointment'
      lastPart = window.location.href.split("/?").pop();

      if (lastPart) reactLocalStorage.set("Room", lastPart);
      if (lastPart.includes("appointment") && RoomInfo === null) {
        //  navigate("/join-doctor");

        let splitURL = visitingPath.split("appointment/");
        let roomNameFromURL = splitURL[splitURL.length - 1];

        dispatch(RoomForPatient(roomNameFromURL));
        setOpenDirectPatientJoin(true);
      }
    }
  }, []);

  const backToGlobalRoom = (val) => {
    setIsShowCallActionsButton(false);
    setIsGloablaRoomCall(val);
    navigate("/room");
    setInstantEffectOnRoom("Room");
  };

  useEffect(() => {
    let url = window.location.href;
    setSuccessUrlState(url);
    if (LoginObject && LoginObject.jwt) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (!LoginObject.jwt && RoomInfo) {
      navigate("/join-doctor");
    }
  }, [RoomInfo]);

  const getClickOnTab = (tabValue) => {
    if (tabValue == "Room") {
      setIsGloablaRoomCall(true);
    }
  };

  const updateProviderStatusOnRefresh = async () => {
    let statusObj = {};
    statusObj.status = "offline";
    socket.emit("show_provider_online", statusObj);
    await updateProviderStatus("offline", LoginObject.username).then(
      async (res) => {}
    );
  };

  const detectSideNavChange = (val) => {
    setCallEndWhenProviderSwitchesTab(true);
  };
  const callDisconnectApi = () => {
    if (!LoginObject.username) {
      removeParticpant(PatientDataDetails).then((res) => {});
    }
    // if (LoginObject.username) {
    //   let obj = {
    //     joiningUser: LoginObject.username,
    //     roomName: LoginObject.username,
    //   };
    //   removeParticpant(obj).then((res) => {});
    // }
  };
  const getPatientLists = (username) => {
    getPatientList(username).then((res) => {
      let list = res.data;
      setPatientList(list);

      const results = list.filter((element) => {
        return element.active === "accepted";
      });
      let pendingList = list.filter((element) => {
        return element.active === "pending";
      });
      dispatch(AllPendingStatus(pendingList));
      dispatch(AllAcceptedPatients(results));
      dispatch(AllPatientList(list));
      setAcceptedPatientlist(results);
    });
  };

  if (LoginObject && LoginObject.jwt) {
    return (
      <>
        {
          <Routes>
            <Route
              exact="true"
              path="/"
              element={
                <Layout
                  instantEffectOnRoom={instantEffectOnRoom}
                  backToGlobalRoom={backToGlobalRoom}
                  isShowCallActionsButton={isShowCallActionsButton}
                  setCallEndWhenProviderSwitchesTab={
                    setCallEndWhenProviderSwitchesTab
                  }
                  isGlobalRoomCall={isGlobalRoomCall}
                  getClickOnTab={getClickOnTab}
                  detectSideNavChange={detectSideNavChange}
                  callEndWhenProviderSwitchesTab={
                    callEndWhenProviderSwitchesTab
                  }
                />
              }
            >
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/room"
                element={
                  null
                  // <RoomNew
                  //   callEndWhenProviderSwitchesTab={
                  //     callEndWhenProviderSwitchesTab
                  //   }
                  //   setCallEndWhenProviderSwitchesTab={
                  //     setCallEndWhenProviderSwitchesTab
                  //   }
                  // />
                }
              />
              <Route path="/settings" element={<Setting />} />
              <Route path="/history" element={<History />} />
              <Route path="/Recordings" element={<Recordings />} />
              <Route
                path="/upgrade"
                element={
                  <Upgrade lastStripeTrasactionObject={stripeLatestObject} />
                }
              />
            </Route>
          </Routes>
        }
      </>
    );
  } else {
    return (
      <>
        {/* {openPopup?<PatientJoin openPopup={openPopup}/>:undefined} */}
        <Routes>
          <Route exact="true" path="/" element={<PublicLayout />}>
            {/* <Route path="/SignIn" element={<Auth />} /> */}
            {/* <Route path="/Home" element={<Auth />} /> */}
            <Route path="/join-doctor" element={<PatientJoin />} />
            {/* <Route path="/Help" element={<Auth />} /> */}
            <Route path="/" element={<PublicView />} />
            <Route
              path="/login"
              element={
                <Login
                  SuccessUrlState={SuccessUrlState}
                  getPatientLists={getPatientLists}
                  setStripeLatestObject={setStripeLatestObject}
                />
              }
            />
            <Route path="/public-view" element={<PublicView />} />
            <Route path="/patient-call" element={<PatientCall />} />
            <Route path="/room/:roomName" element={<RoomNew />} />
          </Route>
        </Routes>
      </>
    );
  }
};
export default MainRoutes;
