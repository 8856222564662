import React from "react";

export default function Modal({
  title,
  children,
  close,
  removeMarginTop,
  continuee,
}) {
  return (
    <div
      className="modal fade show d-flex "
      style={{ background: "#121212ad" }}
      id="exampleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className={
          removeMarginTop === "remove"
            ? "modal-dialog modal-lg-cus modal-dialog-centered modal-lg mt-0"
            : "modal-dialog modal-lg-cus modal-dialog-centered modal-lg"
        }
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                            onClick={close}>
                            <span aria-hidden="true">&times;</span>
                        </button> */}
          </div>
          <div className="modal-body modal-body-fluid">{children}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn  btn-primary theme-btn"
              data-dismiss="modal"
              onClick={close}
            >
              Close
            </button>
            {continuee ? (
              <button
                type="button"
                className="btn  btn-primary theme-btn"
                data-dismiss="modal"
                onClick={continuee}
              >
                Continue
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}